import { Skeleton } from 'antd';
import { useFetchGetUserInfo } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const RouteAuthority = ({ element, authKey }) => {
    const user = useSelector((state) => state.user);
    const { id } = user?.info;
    const { permissions } = user;

    const [authRouter, setAuthRouter] = useState([]);

    const [fetchUser] = useFetchGetUserInfo();


    const flattenArray = (permissions) => {
        const result = [];

        const flatten = (obj, prefix = "") => {
            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach(item => result.push(`${prefix}${item}`));
                } else if (typeof obj[key] === 'object') {
                    flatten(obj[key], `${prefix}${key}/`);
                }
            }
        };

        flatten(permissions);
        return result;
    };

    useEffect(() => {
		if (!id) {
			if(window.location.pathname !== "/login"){
				fetchUser({
					success:(data) =>{
					},
					error: () => {
					  window.location.href = "/login";
					},
				});
			}
		}
        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

    useEffect(()=>{
        if(id){
            setAuthRouter(flattenArray(permissions));
        }
    },[id, permissions]);

    return (
        <>
            {
                authRouter.length > 0
                ?
                (authRouter.includes(authKey) ? <>{element}</> : <Navigate to="/403" />)
                :
                (
                    <div style={{height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div style={{width:"80%"}}>
                            <Skeleton active />
                        </div>   
                    </div>
                )
            }
        </>
    )
};

export default RouteAuthority;