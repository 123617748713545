
import { Card, Col, Layout, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
	LayoutAuthRequired,
	LayoutHeaderNav
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";

const PageDataConfig = () => {
	const navigate = useNavigate();

	usePageTitle("配置管理 - 数据管理后台");

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["data/config"]} />
					<LayoutPageContent>
						<div style={{height:"calc(100vh - 160px)"}}>
							<Row gutter={60} justify="center" align="middle" style={{height:"100%", textAlign:"center"}}>
								<Col md={8}>
									<Card style={{padding:"60px 0"}} hoverable onClick={() => navigate("/data/config/detail?type=function")}>
										
										<Typography.Text style={{marginBottom:0, fontSize:"2.6rem"}}>职能配置</Typography.Text>
									</Card>
								</Col>
								<Col md={8}>
									<Card style={{padding:"60px 0"}} hoverable onClick={() => navigate("/data/config/detail?type=job_level")}>
										<Typography.Text style={{marginBottom:0, fontSize:"2.6rem"}}>职级配置</Typography.Text>
									</Card>
								</Col>
							</Row>
						</div>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageDataConfig;
