import texts from "./texts";
import bulk from "./bulk";
import enrichment from "./enrichment";
import consts from "./consts";
import prospectors from "./prospectors";

const lang = {
    locale: 'en',
    texts: {...texts, ...bulk, ...enrichment, ...consts, ...prospectors},
};

export default lang;
