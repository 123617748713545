import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton } from "antd";
import { useFetchGetInsightUserList } from "hooks";
import { langError, getDateCN } from "tools";

export const TableUserList = () => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [fetchGetInsightUserList] = useFetchGetInsightUserList();
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [currentUser, setCurrentUser] = useState(null);

	const statusMap = {
		1: <Tag color="green">Active</Tag>,
		11: <Tag color="red">Suspend</Tag>,
	};
	const levelMap = {
		'enterprise': <Tag color="green">Enterprise</Tag>,
		'free': <Tag color="grey">Free</Tag>,
		'trial': <Tag color="red">Trial</Tag>,
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			fixed: 'left',
		},
		{
			title: "姓名",
			dataIndex: "name",
			fixed: 'left',
		},
		{
			title: "电话",
			dataIndex: "mobile",
		},
		{
			title: "邮箱",
			dataIndex: "email",
		},
		{
			title: "公司名称",
			dataIndex: "company_name",
		},
		{
			title: "职位",
			dataIndex: "title",
		},
		{
			title: "状态",
			dataIndex: "userStatus",
		},
		{
			title: "Credit",
			dataIndex: "credit",
		},
		{
			title: "Level",
			dataIndex: "level",
		},
		{
			title: "过期时间",
			dataIndex: "time_expired",
		},
		{
			title: "创建时间",
			dataIndex: "time_created",
		},
		// {
		//   title: "操作",
		//   dataIndex: "operate",
		//   render: (id) => (
		//     <Dropdown
		//       menu={{
		//         items,
		//         onClick: () => {
		//           const currentUser = listData.filter((item) => item.id == id);
		//           setCurrentUser(currentUser[0]);
		//         },
		//       }}
		//     >
		//       <a onClick={(e) => e.stopPropagation()}>
		//         <Space>
		//           ...
		//           {/* <DownOutlined /> */}
		//         </Space>
		//       </a>
		//     </Dropdown>
		//   ),
		// },
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent };
			fetchGetInsightUserList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								userStatus: statusMap[item.status],
								level: levelMap[item.level],
								time_created: getDateCN(item.time_created),
								time_expired: item.time_expired ? getDateCN(item.time_expired) : '',
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						scroll={{
							x: 1300,
						}}
					/>
				</>
			)}
		</Fragment>
	);
};
