import React, { useEffect, useState } from 'react';
import { Button, Modal, App, Skeleton, Typography } from 'antd';
import { lang, langError } from 'tools';
import { useFetchGetMailboxMailDetail } from 'hooks';

export const ModalMailDetail = ({ open, currentId, onCancel }) => {
    const { notification } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [mailData, setMailData] = useState(null);

    const [fetchGetMailboxMailDetail] = useFetchGetMailboxMailDetail();

    useEffect(() => {
        setLoading(true);
        if (currentId) {
            fetchGetMailboxMailDetail({ id: currentId }, {
                success: (data) => {
                    setLoading(false);
                    setMailData(data);
                },
                error: (error) => {
                    setLoading(false);
                    notification.error({ message: langError(error.code) });
                },
            });
        }
    }, [currentId]);

    return (
        <Modal
            destroyOnClose
            open={open}
            title={"邮件详情"}
            width={800}
            footer={[
                <Button key="cancel" onClick={onCancel}>{lang('button_close')}</Button>,
            ]}
            onCancel={onCancel}
        >
            <div style={{ maxHeight: "600px", overflow: "scroll", paddingRight: "20px" }}>
                {
                    loading ? <Skeleton active /> :
                        <>
                            <b>{mailData?.from_name}</b>&lt;{mailData?.from_email}&gt;
                            <div style={{ marginTop: 10 }}>
                                <div><Typography.Title level={5}>标题： {mailData?.data?.subject}</Typography.Title></div>
                                <div>{mailData?.data?.text}</div>
                            </div>
                        </>
                }
            </div>
        </Modal>
    );
}