import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Space, Tag, Skeleton, Divider, Popover } from "antd";
import { useFetchGetMonitorList } from "hooks";
import { langError, getDateCN } from "tools";


export const StatusList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification} = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [fetchGetMonitorList] = useFetchGetMonitorList();

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { ...filterData };
			fetchGetMonitorList(params, {
				success: (data) => {
					setLoading(false);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								time_activity: getDateCN(item.time_activity)
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
	}, [id, filterData]);

	const contentPopover = (
		<div>
			<p>Content</p>
			<p>Content</p>
		</div>
	);

	const statusData = [
		{
			type:"ok",
			list:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
		},
		{
			type:"waring",
			list:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
		},
		{
			type:"error",
			list:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
		},
		{
			type:"cancel",
			list:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16]
		}
	];

	const numbers = [];
	for (let i = 1; i <= 50; i++) {
		numbers.push(i);
	}

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					{/* <Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={() => setOpenMonitorModal(true)}>
								添加监测点
							</Button>
						</Row>
					</Space> */}
					<Divider orientation="left">OK List</Divider>
					<Space size={[16,16]} wrap>
						{
							numbers.map(i => {
								return <Popover placement="top" title={`监测点${i+1}`} content={contentPopover} trigger="hover">
									<a><Tag color="#52c41a" style={{ width: 60, height: 24 }}></Tag></a>
								</Popover>
							})
						}
					</Space>
					<Divider orientation="left">Waring List</Divider>
					<Space size={[16,16]} wrap>
						{
							numbers.map(i => {
								return <Popover placement="top" title={`监测点${i+1}`} content={contentPopover} trigger="hover">
									<a><Tag color="#feb019" style={{ width: 60, height: 24 }}></Tag></a>
								</Popover>
							})
						}
					</Space>
					<Divider orientation="left">Error List</Divider>
					<Space size={[16,16]} wrap>
						{
							numbers.map(i => {
								return <Popover placement="top" title={`监测点${i+1}`} content={contentPopover} trigger="hover">
									<a><Tag color="#f5222d" style={{ width: 60, height: 24 }}></Tag></a>
								</Popover>
							})
						}
					</Space>
					<Divider orientation="left">Cancel List</Divider>
					<Space size={[16,16]} wrap>
						{
							numbers.map(i => {
								return <Popover placement="top" title={`监测点${i+1}`} content={contentPopover} trigger="hover">
									<a><Tag color="#bfbfbf" style={{ width: 60, height: 24 }}></Tag></a>
								</Popover>
							})
						}
					</Space>
				</>
			)}
		</Fragment>
	);
};
