import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Skeleton, Dropdown, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useFetchRiskifyPackageCombinesCancel, useFetchGetRiskifyPackageCombinesList } from "hooks";
import { langError } from "tools";
import { ModalCreate } from "./ModalCreate";

export const TablePackagecombinesList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification, modal } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [openSetModal, setOpenSetModal] = useState(false);
	const [forceFetch, setForceFetch] = useState(0);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);

	const [fetchGetRiskifyPackagecombinesList] = useFetchGetRiskifyPackageCombinesList();
	const [fetchRiskifyPackageCombinesCancel] = useFetchRiskifyPackageCombinesCancel();

	const items = [
		{
			label: "删除",
			key: "delete",
		},
	];

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "Package A",
			dataIndex: "pa",
		},
		{
			title: "Package B",
			dataIndex: "pb",
		},
		{
			title: "Package C",
			dataIndex: "pc",
		},
		{
			title: "比重",
			dataIndex: "weight",
		},
		{
			title: "操作",
			dataIndex: "operate",
			width: 80,
			render: (_, record) => (
				<Dropdown
					menu={{
						items,
						onClick: (item) => {
							if (item.key === "delete") {
								modal.confirm({
									title: "确认提示",
									content: "是否删除此条Package组合规则?",
									onOk() {
										return new Promise((resolve, reject) => {
											fetchRiskifyPackageCombinesCancel({
												app: "insight",
												id:record.id
											}, {
												success: (data) => {
													setForceFetch(forceFetch => forceFetch + 1);
													notification.success({ 'message': "已删除！" });
												},
												error: (error) => {
													notification.error({ 'message': langError(error.code) });
												},
											});
											setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
										}).catch(() => console.log('Oops errors!'));
									},
								});
							}
						},
					}}
				>
					<a onClick={(e) => e.stopPropagation()}>
						<Space>
							<EllipsisOutlined style={{ fontSize: 24 }} />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { ...filterData };
			fetchGetRiskifyPackagecombinesList(params, {
				success: (data) => {
					setLoading(false);
					setListData(data);
					setDataTotal(data.length);
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, filterData, forceFetch]);

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={() => { setOpenSetModal(true) }}>设置规则</Button>
						</Row>
					</Space>

					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={false}
						rowKey={record => record.id}
					/>
					{
						openSetModal &&
						<ModalCreate
							open={openSetModal}
							onCancel={() => setOpenSetModal(false)}
							onHandleUpdateInfo={() => setForceFetch(forceFetch => forceFetch + 1)}
						/>
					}
				</>
			)}
		</Fragment>
	);
};
