const { Statistic, Row, Col, Card } = require("antd");

export const PanelStatistic = ({loading, data, invoice}) => {
    return (
        <Row gutter={[16, 16]}>
            <Col lg={12} md={12}>
                <Card size="small">
                    <Statistic loading={loading} title="累计注册用户数量" value={data.count?data.count:0} />
                </Card>
            </Col>
            <Col lg={12} md={12}>
                <Card size="small">
                    <Statistic loading={loading} title="累计激活用户数量" value={data.active_user?data.active_user:0} />
                </Card>
            </Col>
            {/* <Col lg={4} md={6}>
                <Card size="small">
                    <Statistic loading={loading} title="累计付费用户数" value={data.premium_user?data.premium_user:0} />
                </Card>
            </Col>
            <Col lg={4} md={6}>
                <Card size="small">
                    <Statistic loading={loading} title="系统付费用户数" value={invoice.total_pay_user?invoice.total_pay_user:0} />
                </Card>
            </Col>
            <Col lg={4} md={6}>
                <Card size="small">
                    <Statistic loading={loading} title="平台付费金额" value={invoice.amount ? ('$' + (invoice.amount / 100)) :0} />
                </Card>
            </Col>
            <Col lg={4} md={6}>
                <Card size="small">
                    <Statistic loading={loading} title="当前订阅人数" value={data.subscription_user_active?data.subscription_user_active:0} />
                </Card>
            </Col> */}
        </Row>
    );
}

