import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { EllipsisOutlined } from "@ant-design/icons";
import { App, Button, Row, Space, Table, Typography, Dropdown, Tag, Skeleton } from "antd";
import {
	useFetchGetMonitorList,
	useFetchDeleteMonitor
} from "hooks";
import { langError, getDateCN } from "tools";
import { ModalAddMonitor } from "./ModalAddMonitor";
import { ModalEditMonitor } from "./ModalEditMonitor";


export const TableMonitorList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { modal, notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [fetchGetMonitorList] = useFetchGetMonitorList();
	const [fetchDeleteMonitor] = useFetchDeleteMonitor();
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [forceList, setForceList] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [openAddMonitor, setOpenMonitorModal] = useState(false);
	const [openEditMonitor, setOpenEditMonitorModal] = useState(false);
	const [currentData, setCurrentData] = useState({});

	const statusMap = {
		1: <Tag color="green">正常</Tag>,
		2: <Tag color="red">警告</Tag>,
		3: <Tag color="red">异常</Tag>,
		4: <Tag color="red">失联</Tag>,
		9: <Tag color="red">暂停</Tag>,
	};

	const items = [
		{
			label: "编辑",
			key: "edit",
		},
		{
			label: "删除",
			key: "delete",
		},
	];

	const handleDelete = (record) => {
		modal.confirm({
			title: "Tips",
			content: "您确定要删除该监测点吗?",
			maskClosable: 'true',
			okText: '确定',
			onOk() {
				return new Promise((resolve, reject) => {
					fetchDeleteMonitor({
						key:record.key
					},
						{
							success: (data) => {
								notification.success({ message: "删除成功！" });
								setForceList(forceList => forceList + 1)
							},
							error: (error) => {
								notification.error({ message: error.code });
							},
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
				}).catch(() => console.log('Oops errors!'));
			},
		})
	}


	const columns = [
		{
			title: "监测点",
			dataIndex: "key",
		},
		{
			title: "分类",
			dataIndex: "category",
		},
		{
			title: "状态",
			dataIndex: "status",
			render:(_, record) =>(
				<>{statusMap[record.status]}</>
			)
		},
		{
			title: "失联间隔",
			dataIndex: "missing",
			width: 150
		},
		{
			title: "反馈时间",
			dataIndex: "time_activity",
		},
		{
			title: "备注",
			dataIndex: "remark",
		}, {
			title: "操作",
			dataIndex: "operate",
			width: 100,
			fixed: "right",
			render: (_, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Dropdown
						menu={{
							items,
							onClick: (item) => {
								if (item.key === "edit") {
									setCurrentData(record);
									setOpenEditMonitorModal(true);
								} else if (item.key === "delete") {
									handleDelete(record);
								}
							},
						}}
					>
						<a>
							<Space>
								<EllipsisOutlined style={{ fontSize: 24 }} />
							</Space>
						</a>
					</Dropdown>
				</div>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMonitorList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.count);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								time_activity: getDateCN(item.time_activity)
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={() => setOpenMonitorModal(true)}>
								添加监测点
							</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={record => record.id}
					/>
				</>
			)}
			{openAddMonitor && (
				<ModalAddMonitor
					open={openAddMonitor}
					onCancel={() => {
						setOpenMonitorModal(false);
					}}
					onHandleUpdateList={() => setForceList(forceList => forceList + 1)}
				/>
			)}
			{openEditMonitor && (
				<ModalEditMonitor
					open={openEditMonitor}
					currentData={currentData}
					onCancel={() => {
						setOpenEditMonitorModal(false);
						setCurrentData({});
					}}
					onHandleUpdateList={() => setForceList(forceList => forceList + 1)}
				/>
			)}
		</Fragment>
	);
};
