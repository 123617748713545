import React, { useState } from "react";
import { Button, Form, Modal, Input, App } from "antd";
import { useFetchDataEstimateCreate } from "hooks";
import { langError } from "tools";

export const ModalCreateRecord = ({ open, onCancel, onUpdateList }) => {
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [fetchCreate] = useFetchDataEstimateCreate();

	const [form] = Form.useForm();

	const handleCreate = () => {
		form
			.validateFields()
			.then((values) => {
				values.requirement = values.requirement.split('\n')
				setLoading(true);
				fetchCreate(values, {
                    success: (data) => {
                        setLoading(false);
                        onCancel();
                        if(onUpdateList){
                            onUpdateList();
                        }
                        notification.success({ message: "创建成功！" });
                    },
                    error: (error) => {
                        setLoading(false);
                        onCancel();
                        notification.error({ message: langError(error.code) });
                    },
                });
			})
			.catch((info) => { });
	};

	return (
		<Modal
			title="创建预估记录"
			width={500}
			open={open}
			onCancel={onCancel}
			destroyOnClose={true}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					取消
				</Button>,
				<Button
					key="ok"
					type="primary"
					loading={loading}
					onClick={handleCreate}
				>
					确定
				</Button>,
			]}
		>
			<Form
				name="basic"
				form={form}
				layout="vertical"
				style={{ margin: "30px 0" }}
			>
				<Form.Item
					label="客户名"
					name="customer"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写客户名",
						},
					]}
				>
					<Input />
				</Form.Item>
                <Form.Item
					label="标题"
					name="subject"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写标题",
						},
					]}
				>
					<Input />
				</Form.Item>
                <Form.Item
					label="客户需求"
					name="requirement"
					style={{ margin: "10px 0" }}
                    rules={[
						{
							required: true,
							message: "请填写客户需求",
						},
					]}
				>
					<Input.TextArea rows={5} />
				</Form.Item>
			</Form>
		</Modal>
	);
};
