import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import {
	LeftOutlined,
	RightOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const labelItem = [
	{
		label:"用户管理",
		key:"insight/user",
		icon:<TeamOutlined />
	}
];

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type
	};
}

export const LayoutPageSidebar = ({ selectedKey }) => {
	const [collapsed, setCollapsed] = useState(false);
	const user = useSelector((state) => state.user);
    const { permissions } = user;

	const navigate = useNavigate();
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	const getLabelItems = ()=>{
		let labelArr = [];
		labelItem.map(item =>{
			if(permissions.insight && permissions.insight.length>0){
				permissions.insight.map(insight =>{
					if(insight === item.key){
						return labelArr.push(getItem(item.label, item.key,item.icon));
					}
				})	
			}
		})
		return labelArr;
	}

	const items = [getItem('Insight 管理后台', 'insight', null, 
		getLabelItems(),
	'group')];

	return (
		<Sider
			trigger={
				<div style={{ background: "#ededed", color: "#000" }}>
					{collapsed ? <RightOutlined /> : <LeftOutlined />}
				</div>
			}
			collapsible
			collapsed={collapsed}
			onCollapse={(value) => {setCollapsed(value)}}
			style={{
				background: colorBgContainer,
				overflow: 'auto',
				height: 'calc(100vh - 98px)',
				position: 'fixed',
				left: 0,
				top: 50,
				bottom: 0,
			}}
		>
			<Menu
				mode="inline"
				defaultSelectedKeys={selectedKey}
				defaultOpenKeys={["sub1"]}
				onClick={({ key }) => {
					navigate(`/${key}`);
				}}
				style={{
					height: "100%",
					borderRight: 0,
				}}
				items={items}
			/>
		</Sider>
	);
};
