import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import usePageTitle from 'hooks/usePageTitle';
import config from 'config';

export const PageError403 = () => {
    const mainApp = localStorage.getItem(config.keyStorageApp);
    const navigate = useNavigate();
    usePageTitle("403 - 无权限页面");

    let appPath = '';

	if (mainApp) {
		appPath = `/${mainApp}`;
	} else {
		appPath = config.pathApp;
	}

    return (
        <div>
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
                extra={<Button type="primary" onClick={()=>navigate(`${appPath}`)}>Back Home</Button>}
            />
        </div>
    );
};

export default PageError403;

