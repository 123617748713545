import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyToClipboard from 'react-copy-to-clipboard';
import { lang } from 'tools';

export const ModalResponse = ({ open, title = "", data, onCancel }) => {

    const [copied, setCopied] = useState(false);
    const [copyTooltipVisible, setCopyTooltipVisible] = useState(false);

    const onCopyTooltipVisibleChange = visible => {
        if (visible) {
            setCopyTooltipVisible(visible);
            setCopied(false);
            return;
        }
        setCopyTooltipVisible(visible);
    }

    return (
        <Modal
            destroyOnClose
            open={open}
            title={title}
            width={800}
            footer={[
                <Button key="cancel" onClick={onCancel}>{lang('button_close')}</Button>,
                <Tooltip
                    title={copied ? lang('label_copied') : lang('button_copy_script')}
                    open={copyTooltipVisible}
                    onOpenChange={onCopyTooltipVisibleChange}
                    key='copy'
                >
                    <CopyToClipboard text={data} onCopy={() => { setCopied(true); }}>
                        <Button  type="primary">Copy Json</Button>
                    </CopyToClipboard>
                </Tooltip>
            ]}
            onCancel={onCancel}
            >
            <div style={{maxHeight:"600px", overflow:"scroll", paddingRight:"20px"}}>
                <SyntaxHighlighter language="json" wrapLongLines style={vs}>
                    {data}
				</SyntaxHighlighter>
            </div>
        </Modal>
    );
}