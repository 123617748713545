import { Fragment, useEffect, useState } from "react";
import { Layout, App } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableLogList } from "./components";
import usePageTitle from "hooks/usePageTitle";
import { useFetchMsLogActionList } from "hooks";
import { langError } from "tools";

const PageMsLog = () => {
	const { notification } = App.useApp();
	const [filterData, setFilterData] = useState({});
	const [actionOptions, setActionOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	usePageTitle("系统日志列表 - AroundDeal管理后台");

	const [fetchMsLogActionList] = useFetchMsLogActionList();
	
	const inputOptions = [
		{ key: "uid", placeholder: "搜索UID" },
		{ key: "action", placeholder: "搜索操作" },
	];

	useEffect(()=>{
		setLoading(true);
		fetchMsLogActionList({}, {
			success: (data) => {
				let newArr = []
				if(data.length > 0){
					newArr = data.map(item =>{
						return {
							value: item,
							label:item
						}
					})
				}
				newArr.unshift({
					value:"",
					label:"请选择操作"
				})
				setActionOptions(newArr);
				setLoading(false);
			},
			error: (error) => {
			  setLoading(false);
			  notification.error({ message: langError(error.code) });
			},
		  });
	},[]);

	const selectOptions = [
		{
			key: "actionStr",
			placeholder: "请选择操作",
			options: actionOptions,
		}
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout hasSider>
					<LayoutPageSidebar selectedKey={["ms/log"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								datePickerKey={"time"}
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableLogList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsLog;
