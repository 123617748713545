import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Tag,
	Skeleton,
	Tooltip
} from "antd";
import { useFetchGetMsInvoiceList } from "hooks";
import { langError, getDateCN, invoiceStatusMap, invoicePayTypeMap, invoiceTypeMap } from "tools";
import numeral from "numeral";

export const TableInvoiceList = ({ filterData = {} }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [amountData, setAmountData] = useState(null);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetMsInvoiceList] = useFetchGetMsInvoiceList();

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			width: 100,
		},
		{
			title: "Key",
			dataIndex: "key",
			width: 100,
			render: (_, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Tooltip title="View Invoice">
						<Link target="_blank" to={`/ms/invoice/detail?key=${record.key}`}>
							{record.key}
						</Link>
					</Tooltip>
				</div>
			),
		},
		{
			title: "用户名",
			dataIndex: "user_name",
			render: (name, info) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Link target="_blank" to={`/ms/user/detail?uid=${info.uid}`}>
						{name}
					</Link>
				</div>
			),
		},
		{
			title: "Plan",
			dataIndex: "plan",
		},
		{
			title: "类型",
			dataIndex: "type",
			width: 120,
		},
		{
			title: "Promocode",
			dataIndex: "promocode",
		},
		{
			title: "数量",
			dataIndex: "quantity",
			width: 100,
		},
		{
			title: "金额",
			dataIndex: "total",
			width: 100,
			render: (_, record) => (
				<span> {record.currency == 1 ? '$' : '￥'}{record.total ? (record.total / 100).toFixed(2) : "0.00"}</span>
			)
		},
		{
			title: "支付类型",
			dataIndex: "pay_type",
			width: 150,
		},
		{
			title: "状态",
			dataIndex: "invoiceStatus",
			width: 100,
		},
		{
			title: "支付时间",
			dataIndex: "time_done",
		},
		{
			title: "创建时间",
			dataIndex: "time_created",
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };

			fetchGetMsInvoiceList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setAmountData(data.amount);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								invoiceStatus: invoiceStatusMap[item.status],
								pay_type: invoicePayTypeMap[item.pay_type],
								type: invoiceTypeMap[item.type],
								time_done: item.time_done ? getDateCN(item.time_done) : "",
								time_created: getDateCN(item.time_created),
								plan:
									item.data.package.level +
									(item.data.package.type == 2 ? " 年付" : " 月付"),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<div className="cursor">
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果 (美元: <span className="text-primary">${numeral(amountData?.usd).format('0,0.00')}</span>, 人民币: <span className="text-primary">¥{numeral(amountData?.cny).format('0,0.00')}</span>, 总计: <span className="text-primary">¥{numeral(amountData?.total).format('0,0.00')}</span>)</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						scroll={{ x: 1500, y: 600 }}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						onRow={(record, index) => {
							return {
								onClick: event => {
									window.open(`/ms/invoice/detail?key=${record.key}`);
								},
							};
						}}
					/>
				</div>
			)}
		</Fragment>
	);
};
