import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Skeleton
} from "antd";
import { useFetchGetMsVisitorList } from "hooks";
import { langError, getDateCN, visitorPeopleFilterMap, visitorStatusMap, visitorVerifiedMap } from "tools";

export const TableVisitorList = () => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);
	
	const [fetchGetMsVisitorList] = useFetchGetMsVisitorList();

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			width: 100,
		},
		{
			title: "Key",
			dataIndex: "key",
			width: 100,
		},
		{
			title: "用户名",
			dataIndex: "user_name",
			render: (name, info) => (
				<div onClick={e => e.stopPropagation()}>
					<Link
						target="_blank"
						to={`/ms/user/detail?uid=${info.uid}`}
					>
						{name}
					</Link>
				</div>
			),
		},
		{
			title: "Domain",
			dataIndex: "domain",
		},
		{
			title: "状态",
			dataIndex: "status",
		},
		{
			title: "验证状态",
			dataIndex: "is_verified",
		},
		{
			title: "开启People识别",
			dataIndex: "use_people_filter",
		},
		{
			title: "每日上限",
			dataIndex: "max_daily",
		},
		{
			title: "每小时上限",
			dataIndex: "max_hourly",
		},
		{
			title: "识别公司数量",
			dataIndex: "company_number",
		},
		{
			title: "已识别人数量",
			dataIndex: "people_number",
		},
		{
			title: "创建时间",
			dataIndex: "time_created",
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent };
			fetchGetMsVisitorList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								is_verified: visitorVerifiedMap[item.is_verified],
								status: visitorStatusMap[item.status],
								use_people_filter: visitorPeopleFilterMap[item.use_people_filter],
								time_created: getDateCN(item.time_created),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<div className="cursor">
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						scroll={{ x: 1500 }}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						onRow={(record, index) => {
							return {
								onClick: event => {
									window.open(`/ms/visitor/detail?uid=${record.id}`);
								},
							};
						}}
					/>
				</div>
			)}
		</Fragment>
	);
};
