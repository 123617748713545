import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton } from "antd";
import { useFetchGetDataCriteriaList } from "hooks";
import { langError, getDateCN, jsonEncode } from "tools";

export const TableCriteriaList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	
	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetDataCriteriaList] = useFetchGetDataCriteriaList();


	const typeMap = {
		"people": <Tag color="orange">People</Tag>,
		"company": <Tag color="green">Company</Tag>,
	};

	const statusMap = {
		1: <Tag color="grey">等待</Tag>,
		8: <Tag color="green">成功</Tag>,
		9: <Tag color="orange">失败</Tag>,
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "id",

		},
		{
			title: "状态",
			dataIndex: "status",
		},
		{
			title: "Data",
			dataIndex: "data",
			ellipsis: true,
			width:400
		},
		{
			title: "Result",
			dataIndex: "result",
			ellipsis: true,
			width: 400
		},
		{
			title: "创建时间",
			dataIndex: "time_create",
		}
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetDataCriteriaList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								type: typeMap[item.type],
								status: statusMap[item.status],
								data : jsonEncode(item.data),
								result : jsonEncode(item.result),
								time_create: getDateCN(item.time_create),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>

				</>
			)}
		</Fragment>
	);
};
