import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
	Card,
	Col,
	Layout,
	Row,
	Select,
	Skeleton,
	Space,
	App
} from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";

import usePageTitle from "hooks/usePageTitle";
import {
	PanelStatistic,
	ChartRegisteredUser,
	ChartSourceAnalyse,
	ChartRegisterWayAnalyse,
	ChartEverydayAnalyse,
	ChartIncomeAnalyse,
	TablePayUserRanking,
	ChartEverydayActiveNumber,
	ChartSystemLogMonitor,
	ChartEverydayEnrollmentRate,
} from "./components";
import { useFetchMsDashboard } from "hooks";
import { langError } from "tools";

const PageMsDashboard = () => {
	const user = useSelector((state) => state.user);
	const { notification } = App.useApp();
	const { id } = user?.info;
	const [loading, setLoading] = useState(false);
	const [dashboardData, setDashboardData] = useState({});

	usePageTitle("Dashboard - AroundDeal管理后台");
	const navigate = useNavigate();
	const location = useLocation();
	let [searchParams] = useSearchParams();
	const filterActiveUser = searchParams.get('active_user');
	const filterRegisterUser = searchParams.get('register_user');
	const filterTrailUser = searchParams.get('trail_user');
	const filterPaidType = searchParams.get('paid_type');
	const filterUserSource = searchParams.get('user_source');
	const filterUserEmailSource = searchParams.get('user_email_source');

	const [fetchDashboard] = useFetchMsDashboard();

	useEffect(() => {
		if (id) {
			setLoading(true);
			let params = {};
			if (filterActiveUser) {
				params.active_user = parseInt(filterActiveUser);
			}
			if (filterRegisterUser) {
				params.register_user = parseInt(filterRegisterUser);
			}
			if (filterTrailUser) {
				params.trail_user = parseInt(filterTrailUser);
			}
			if (filterPaidType) {
				params.paid_type = filterPaidType
			}
			if (filterUserSource) {
				params.user_source = filterUserSource
			}
			if (filterUserEmailSource) {
				params.user_email_source = filterUserEmailSource
			}

			fetchDashboard(
				{ ...params },
				{
					success: (data) => {
						setLoading(false);
						setDashboardData(data);
					},
					error: (error) => {
						setLoading(false);
						notification.error({ message: langError(error.code) });
					},
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, searchParams]);

	const handleChangeFilter = (value, key) => {
		let url = `/ms/dashboard?active_user=30&register_user=30&trail_user=30&paid_type=day&user_source=day&user_email_source=day`;
		if (key === "active_user") {
			url = `/ms/dashboard?active_user=${value}&register_user=${filterRegisterUser ? filterRegisterUser : 30}&trail_user=${filterTrailUser ? filterTrailUser : 30}&paid_type=${filterPaidType ? filterPaidType : "day"}&user_source=${filterUserSource ? filterUserSource : "day"}&user_email_source=${filterUserEmailSource ? filterUserEmailSource : "day"}`;
		} else if (key === "register_user") {
			url = `/ms/dashboard?active_user=${filterActiveUser ? filterActiveUser : 30}&register_user=${value}&trail_user=${filterTrailUser ? filterTrailUser : 30}&paid_type=${filterPaidType ? filterPaidType : "day"}&user_source=${filterUserSource ? filterUserSource : "day"}&user_email_source=${filterUserEmailSource ? filterUserEmailSource : "day"}`;
		} else if (key === "trail_user") {
			url = `/ms/dashboard?active_user=${filterActiveUser ? filterActiveUser : 30}&register_user=${filterRegisterUser ? filterRegisterUser : 30}&trail_user=${value}&paid_type=${filterPaidType ? filterPaidType : "day"}&user_source=${filterUserSource ? filterUserSource : "day"}&user_email_source=${filterUserEmailSource ? filterUserEmailSource : "day"}`;
		} else if (key === "paid_type") {
			url = `/ms/dashboard?active_user=${filterActiveUser ? filterActiveUser : 30}&register_user=${filterRegisterUser ? filterRegisterUser : 30}&trail_user=${filterTrailUser ? filterTrailUser : 30}&paid_type=${value}&user_source=${filterUserSource ? filterUserSource : "day"}&user_email_source=${filterUserEmailSource ? filterUserEmailSource : "day"}`;
		} else if (key === "user_source") {
			url = `/ms/dashboard?active_user=${filterActiveUser ? filterActiveUser : 30}&register_user=${filterRegisterUser ? filterRegisterUser : 30}&trail_user=${filterTrailUser ? filterTrailUser : 30}&paid_type=${filterPaidType ? filterPaidType : "day"}&user_source=${value}&user_email_source=${filterUserEmailSource ? filterUserEmailSource : "day"}`;
		} else if (key === "user_email_source") {
			url = `/ms/dashboard?active_user=${filterActiveUser ? filterActiveUser : 30}&register_user=${filterRegisterUser ? filterRegisterUser : 30}&trail_user=${filterTrailUser ? filterTrailUser : 30}&paid_type=${filterPaidType ? filterPaidType : "day"}&user_source=${filterUserSource ? filterUserSource : "day"}&user_email_source=${value}`;
		}
		navigate(url);
	}

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/dashboard"]} />
					<LayoutPageContent>
						{!id ? (
							<Skeleton active />
						) : (
							<Space
								direction="vertical"
								style={{ display: "flex" }}
								size="middle"
							>
								<PanelStatistic
									loading={loading}
									data={dashboardData.user ? dashboardData.user : {}}
									invoice={dashboardData.invoice ? dashboardData.invoice : {}}
								/>
								<Row gutter={[16, 16]}>
									<Col lg={12} md={24}>
										<Card
											loading={loading}
											title={
												<Row align="middle" justify="space-between">
													<h3>每日新增注册用户</h3>
													<Select defaultValue={filterRegisterUser ? filterRegisterUser : "30"} onChange={value => handleChangeFilter(value, "register_user")}>
														<Select.Option value="7" key="7">
															过去7天
														</Select.Option>
														<Select.Option value="15" key="15">
															过去15天
														</Select.Option>
														<Select.Option value="30" key="30">
															过去30天
														</Select.Option>
														<Select.Option value="60" key="60">
															过去60天
														</Select.Option>
													</Select>
												</Row>
											}
										>
											<ChartRegisteredUser
												registerUser={dashboardData.register}
												activeRegister={dashboardData.today_active_register}
												noActiveRegister={
													dashboardData.today_no_active_register
												}
											/>
										</Card>
									</Col>
									<Col lg={12} md={24}>
										<Card
											loading={loading}
											title={
												<Row align="middle" justify="space-between">
													<h3>注册来源分析</h3>
													<Select defaultValue={filterUserSource ? filterUserSource : "day"} onChange={value => handleChangeFilter(value, "user_source")}>
														<Select.Option value="day" key="day">
															日
														</Select.Option>
														<Select.Option value="week" key="week">
															周
														</Select.Option>
														<Select.Option value="month" key="month">
															月
														</Select.Option>
													</Select>
												</Row>
											}
										>
											<ChartSourceAnalyse
												userSource={dashboardData.user_source}
											/>
										</Card>
									</Col>
									<Col lg={12} md={24}>
										<Card
											loading={loading}
											title={
												<Row align="middle" justify="space-between">
													<h3>收入 分析($)</h3>
													<Select defaultValue={filterPaidType ? filterPaidType : "day"} onChange={value => handleChangeFilter(value, "paid_type")}>
														<Select.Option value="day" key="day">
															日
														</Select.Option>
														<Select.Option value="week" key="week">
															周
														</Select.Option>
														<Select.Option value="month" key="month">
															月
														</Select.Option>
													</Select>
												</Row>
											}
										>
											<ChartIncomeAnalyse
												paidMoney={dashboardData.paid_money}
											/>
										</Card>
									</Col>
									<Col lg={12} md={24}>
										<Card
											loading={loading}
											title={
												<Row align="middle" justify="space-between">
													<h3>每日活跃人数</h3>
													<Select defaultValue={filterActiveUser ? filterActiveUser : "30"} onChange={value => handleChangeFilter(value, "active_user")}>
														<Select.Option value="7" key="7">
															过去7天
														</Select.Option>
														<Select.Option value="15" key="15">
															过去15天
														</Select.Option>
														<Select.Option value="30" key="30">
															过去30天
														</Select.Option>
														<Select.Option value="60" key="60">
															过去60天
														</Select.Option>
													</Select>
												</Row>
											}
										>
											<ChartEverydayActiveNumber
												activeUser={dashboardData.active_user}
											/>
										</Card>
									</Col>
									<Col lg={12} md={24}>
										<Card
											loading={loading}
											title={
												<Row align="middle" justify="space-between">
													<h3>24H 系统日志监测</h3>
													<></>
												</Row>
											}
										>
											<ChartSystemLogMonitor logData={dashboardData.log} />
										</Card>
									</Col>
									<Col lg={12} md={24}>
										<Card
											loading={loading}
											title={
												<Row align="middle" justify="space-between">
													<h3>注册方式分析</h3>
													<Select defaultValue={filterUserEmailSource ? filterUserEmailSource : "day"} onChange={value => handleChangeFilter(value, "user_email_source")}>
														<Select.Option value="day" key="day">
															日
														</Select.Option>
														<Select.Option value="week" key="week">
															周
														</Select.Option>
														<Select.Option value="month" key="month">
															月
														</Select.Option>
													</Select>
												</Row>
											}
										>
											<ChartRegisterWayAnalyse
												userEmailSource={dashboardData.user_email_source}
											/>
										</Card>
									</Col>
								</Row>
							</Space>
						)}
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsDashboard;
