
import { Layout } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableVisitorList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageMsInvoice = () => {

	usePageTitle("Visitor列表 - AroundDeal管理后台");

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/visitor"]} />
					<LayoutPageContent>
						<TableVisitorList />
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsInvoice;
