import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { EllipsisOutlined } from "@ant-design/icons";
import { App, Button, Row, Space, Table, Typography, Dropdown, Tag, Skeleton } from "antd";
import { useFetchDaemonDelete, useFetchGetDaemonList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalAddDaemon } from "./ModalAddDaemon";


export const TableDaemonList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { modal, notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(1000);
	const [forceList, setForceList] = useState(0);
	const [openAddDaemon, setOpenDaemonModal] = useState(false);
	const [currentDaemon, setCurrentDaemon] = useState({});

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);
	
	const [fetchGetDaemonList] = useFetchGetDaemonList();
	const [fetchDeleteDaemon] = useFetchDaemonDelete();

	const statusMap = {
		0: <Tag color="green">在线</Tag>,
		1: <Tag color="red">暂停</Tag>,
	};

	const items = [
		{
			label: "编辑",
			key: "edit",
		},
		{
			label: "删除",
			key: "delete",
		},
	];

	const handleDelete = (record) => {
		modal.confirm({
			title: "Tips",
			content: "您确定要删除该配置吗?",
			maskClosable: 'true',
			okText: '确定',
			onOk() {
				return new Promise((resolve, reject) => {
					fetchDeleteDaemon({
						scope: filterData.scope,
						key:record.key
					},
						{
							success: (data) => {
								notification.success({ message: "删除成功！" });
								setForceList(forceList => forceList + 1)
							},
							error: (error) => {
								notification.error({ message: error.code });
							},
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
				}).catch(() => console.log('Oops errors!'));
			},
		})
	}

	const columns = [
		{
			title: "配置文件名",
			dataIndex: "key",
			width:300,
			fixed:"left"
		},
		{
			title: "状态",
			dataIndex: "status",
			width:100,
			fixed:"left"
		},
		{
			title: "Url",
			dataIndex: "url",
			width:500,
		},
		{
			title: "Last Time",
			dataIndex: "time_difference",
			width:150,
		},
		{
			title: "Sleep",
			dataIndex: "sleep",
			width:100,
		},
		{
			title: "timeout",
			dataIndex: "timeout",
			width:100,
		},
		{
			title: "Remark",
			dataIndex: "remark",
			width:100,
		},
		{
			title: "操作",
			dataIndex: "operate",
			width:100,
			fixed:"right",
			render: (_, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Dropdown
						menu={{
							items,
							onClick: (item) => {
								if (item.key === "edit") {
									setCurrentDaemon(record);
									setOpenDaemonModal(true);
								} else if (item.key === "delete") {
									handleDelete(record);
								}
							},
						}}
					>
						<a>
							<Space>
								<EllipsisOutlined style={{ fontSize: 24 }} />
							</Space>
						</a>
					</Dropdown>
				</div>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { ...filterData };
			if(params.scope){
				fetchGetDaemonList(params, {
					success: (data) => {
						setLoading(false);
						setDataTotal(data.length);
						setListData(() => {
							return data.map((item) => {
								return {
									...item,
									operate: item.id,
									status: statusMap[item.pause]
								};
							});
						});
					},
					error: (error) => {
						setLoading(false);
						notification.error({ message: langError(error.code) });
					},
				});
			}
		}
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
								<Button type="primary" onClick={() => setOpenDaemonModal(true)}>
								添加配置
							</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						scroll={{x:"mit-content"}}
					/>
				</>
			)}
			{openAddDaemon && (
				<ModalAddDaemon
					open={openAddDaemon}
					scope={filterData.scope}
					currentDaemon={currentDaemon}
					onCancel={() => {
						setOpenDaemonModal(false);
						setCurrentDaemon({});
					}}
					onHandleUpdateList={() => setForceList(forceList => forceList + 1)}
				/>
			)}
		</Fragment>
	);
};
