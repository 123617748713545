import { Fragment, useState } from "react";
import { Layout } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav, LayoutNavForms } from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableSubscriptionList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageMsSubscription = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("Subscription列表 - AroundDeal管理后台");

	const selectOptions = [
		{
			placeholder: "请选择付款类型",
			key: "package_type",
			options: [
				{ value: 0, label: "请选择付款类型" },
				{ value: 2, label: "年付" },
				{ value: 1, label: "月付" },
			],
		},
		{
			placeholder: "请选择状态",
			key: "status",
			options: [
				{ value: 0, label: "请选择状态" },
				{ value: 1, label: "Init" },
				{ value: 2, label: "Active" },
				{ value: 3, label: "Cancelled" },
				{ value: 4, label: "Under Review" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/subscription"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableSubscriptionList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsSubscription;
