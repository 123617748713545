import { useState } from "react";
import { Layout, theme } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableUserList } from "./components";
// import { Sidebar } from "components";
import usePageTitle from "hooks/usePageTitle";

const { Content } = Layout;

const PageInsightUser = () => {
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	usePageTitle("User列表 - INSIGHT");


	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["insight/user"]} />
					<LayoutPageContent>
						<TableUserList />
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageInsightUser;
