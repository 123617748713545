import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Form, Modal, App, Select } from "antd";
import { useFetchEDMTemplate } from "hooks";
import { langError } from "tools";

export const ModalAddNewsletter = ({ open, onCancel }) => {
    const user = useSelector((state) => state.user);
    const { id } = user?.info;
    const { notification } = App.useApp();
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [templateList, setTemplateList] = useState([]);

    const [fetchTemplate] = useFetchEDMTemplate();

    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setLoadingTemplate(true);
            fetchTemplate({}, {
                success: (data) => {
                    setLoadingTemplate(false);
                    if(data.sales){
                        setTemplateList(data.sales);
                    }
                },
                error: (error) => {
                    setLoadingTemplate(false);
                    notification.error({ message: langError(error.code) });
                },
            });


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleAdd = () => {
        form
            .validateFields()
            .then((values) => {
                navigate(`/edm/newsletter/edit?template_id=${values.template}`);
            })
            .catch((info) => { });
    };

    return (
        <Modal
            title="创建Newsletter"
            width={500}
            open={open}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    取消
                </Button>,
                <Button
                    key="ok"
                    type="primary"
                    onClick={handleAdd}
                >
                    确定
                </Button>,
            ]}
        >
            <Form
                name="basic"
                form={form}
                layout="vertical"
                style={{ margin: "30px 0" }}
            >
                <Form.Item
                    label="请选择模版"
                    name="template"
                    style={{ margin: "10px 0" }}
                    rules={[
                        {
                            required: true,
                            message: "请选择模版",
                        },
                    ]}
                >
                    <Select loading={loadingTemplate}>
                        {
                            templateList.length > 0 ?
                                templateList.map(t => (
                                    <Select.Option value={t.id} key={t.id}>{t.name}</Select.Option>
                                )) : null
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
