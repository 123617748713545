import React from 'react';
import config from 'config';
import { Navigate } from 'react-router-dom';

export const LayoutAuthRequired = (props) => {
    const token = localStorage.getItem(config.keyStorageToken);
    if (!token) {
        return <Navigate to="/login"></Navigate>
    } else {
        return (
            <>{props.children}</>
        );
    }
}
