import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, InputNumber, DatePicker, Select, Col, Row, Input } from 'antd';
import { lang, consoleLog, langError, getDateSimple } from 'tools';
import { useFetchAddMsTimelineList } from "hooks";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const dateFormat = 'YYYY-MM-DD';

export const ModalAddTimeline = ({ open, currentId, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

    const user = useSelector((state) => state.user);
	const { name } = user?.info;
    const [dateString, setDateString] = useState('');

    const [fetchSave] = useFetchAddMsTimelineList();


    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                content: values.content,
                type: values.type,
                date: values.time
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"操作成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }

    const handleDateChange = (date, dateString) =>{
        setDateString(dateString);
    }

    return (
        <Modal
            destroyOnClose
            open={open}
            title={"新增Timeline"}
            onCancel={onCancel}
            width={600}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >

                <Row>

                    <Col span="24">
                        <Form.Item
                            name="content"
                            label={"Timeline内容"}
                        >
                            <Input.TextArea placeholder='请输入...' style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>

                    <Col span="24">
                        <Form.Item
                            name="type"
                            label="Type"
                        >
                            <Select>
                                <Select.Option value="1">Regular</Select.Option>
                                <Select.Option value="2">Special</Select.Option>
                                <Select.Option value="9">Milestone</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>


                <Row>
                    <Col span="24">
                        <Form.Item
                            name="time"
                            label="时间"
                        >
                            <DatePicker onChange={handleDateChange} style={{width:"100%"}} format={dateFormat} allowClear={false} />
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Modal>
    );
};
