import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { App, Layout, Space } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { CardBasicInfo, CardListInfo } from "./components";
import { useFetchBemPackageCombines, useFetchBemPackageDetail } from "hooks";

const PageBemPackageDetails = () => {
    const { notification } = App.useApp();
    const [loading, setLoading] = useState(false);

    usePageTitle("Package详情 - Bem");

    const [search] = useSearchParams();
    const navigate = useNavigate();
    const id = search.get("uid");
    const type = search.get("type");
    const filter_type = search.get("filter_type");

    const [fetchPackageDetail] = useFetchBemPackageDetail();
    const [fetchPackageCombines] = useFetchBemPackageCombines();
    const [userInfo, setUserInfo] = useState(null);
    const [listCombines, setListCombines] = useState([]);
    const [forceFetch, setForceFetch] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetchPackageDetail({
            app: type,
            key: id
        },
            {
                success: (data) => {
                    setUserInfo(data);
                    setLoading(false);
                },
                error: (error) => {
                    setLoading(false);
                },
            });
            fetchPackageCombines({
            app: type,
            key: id
        },
            {
                success: (data) => {
                    setListCombines(data);
                    setLoading(false);
                },
                error: (error) => {
                    setLoading(false);
                },
            });
    }, [id, search, forceFetch]);

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            <CardBasicInfo loading={loading} id={id} type={type} userInfo={userInfo} listCombines={listCombines} onHandleUpdateInfo={() => setForceFetch(forceFetch => forceFetch + 1)} />
                            {/* <CardListInfo loading={loading} listData={listData} activeKey={filter_type ? filter_type : "log"} pageCurrent={pageCurrent} dataTotal={dataTotal} pageSize={pageSize} onHandleChangeTab={handleChangeTab} onHandleChangePage={handleChangePage} /> */}
                        </Space>
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageBemPackageDetails;
