import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import {
    LeftOutlined,
    RightOutlined,
    AppstoreOutlined,
    TeamOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

const items = [
    getItem("用户管理", "user", <TeamOutlined />),
    getItem("App管理", "app", <AppstoreOutlined />),
];

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

export const LayoutSidebar = ({ selectedKey }) => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Sider
            trigger={
                <div style={{ background: "#ededed", color: "#000" }}>
                    {collapsed ? <RightOutlined /> : <LeftOutlined />}
                </div>
            }
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            style={{
                background: colorBgContainer,
                overflow: 'auto',
                height: 'calc(100vh - 98px)',
                position: 'fixed',
                left: 0,
                top: 50,
                bottom: 0,
            }}
        >
            <Menu
                mode="inline"
                defaultSelectedKeys={selectedKey}
                defaultOpenKeys={["sub1"]}
                onClick={({ key }) => {
                    navigate(`/${key}`);
                }}
                style={{
                    height: "100%",
                    borderRight: 0,
                }}
                items={items}
            />
        </Sider>
    );
};
