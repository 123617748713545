import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { App, Layout, Space } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { CardBasicInfo } from "./components";
import { useFetchEDMTaskDetail } from "hooks";

const PageEdmTaskDetails = () => {
    const { notification } = App.useApp();
    const [loading, setLoading] = useState(false);

    usePageTitle("群发任务 - EDM管理后台");

    const [search] = useSearchParams();
    const navigate = useNavigate();
    const id = search.get("uid");

    const [fetchTaskDetail] = useFetchEDMTaskDetail();
    const [taskInfo, setTaskInfo] = useState(null);
    const [forceFetch, setForceFetch] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetchTaskDetail({
            key:id
        },
            {
                success: (data) => {
                    setTaskInfo(data);
                    setLoading(false);
                },
                error: (error) => {
                    console.log(error);
                    setLoading(false);
                },
            });
    }, [id, search, forceFetch]);

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            <CardBasicInfo loading={loading} taskInfo={taskInfo} onHandleUpdateInfo={() =>setForceFetch(forceFetch => forceFetch +1)} />
                        </Space>
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageEdmTaskDetails;
