import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, Select, InputNumber, DatePicker } from 'antd';
import { lang, consoleLog, langError, getDateSimple } from 'tools';
import { useFetchUserSettingLevel } from "hooks";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const dateFormat = 'YYYY-MM-DD';

export const ModalSetUserLevel = ({ open, userInfo, currentId,  onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const user = useSelector((state) => state.user);
	const { name } = user?.info;
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果
    const [dateString, setDateString] = useState('');

    const [fetchSave] = useFetchUserSettingLevel();

    useEffect(()=>{
        if(userInfo){
            form.setFieldsValue({
                level: userInfo.level,
                number: userInfo.number?userInfo.number:1,
                time_expired: userInfo.time_expired ? dayjs(getDateSimple(userInfo.time_expired), dateFormat):'',
            });
            setDateString( userInfo.time_expired ? getDateSimple(userInfo.time_expired):'')
        }
    },[userInfo]);

    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                package: values.level,
                number: values.number,
                time_expired: dateString,
                admin: name,
                uid: currentId
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"操作成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }

    const handleDateChange = (date, dateString) =>{
        setDateString(dateString);
    }
    return (
        <Modal
            destroyOnClose
            open={open}
            title={"设置账号等级"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="level"
                    label="等级"
                    initialValue="enterprise"
                >
                    <Select>
                        <Select.Option value="">请选择等级</Select.Option>
                        <Select.Option value="basic">Basic</Select.Option>
                        <Select.Option value="premium">Premium</Select.Option>
                        <Select.Option value="enterprise">Enterprise</Select.Option>
                        <Select.Option value="ultimate">Ultimate</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="number"
                    label={"组员人数"}
                    initialValue={1}
                >
                    <InputNumber style={{width:"100%"}} min={1} />
                </Form.Item>
                <Form.Item
                    name="time_expired"
                    label="过期时间"
                >
                    <DatePicker onChange={handleDateChange} style={{width:"100%"}} format={dateFormat} allowClear={false} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
