import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableRedeemcodeList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageRiskifyRedeemcode = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("Redeemcode - Riskify插件管理后台");

	const inputOptions = [
		{ key: "user", placeholder: "搜索UID" },
		{ key: "code", placeholder: "搜索code" },
		{ key: "target", placeholder: "搜索兑换内容" },
	];

	const selectOptions = [
		{
			key: "status",
			placeholder: "选择状态",
			options: [
				{ value: "", label: "选择状态" },
				{ value: 1, label: "WAITING" },
				{ value: 2, label: "SUCCESS" },
				{ value: 3, label: "EXPIRED" },
				{ value: 4, label: "DELETED" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["riskify/redeemcode"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								selectOptions={selectOptions}
								inputOptions={inputOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableRedeemcodeList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageRiskifyRedeemcode;
