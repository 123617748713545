import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Tag,
	Skeleton
} from "antd";
import { useFetchGetMsMailList } from "hooks";
import { langError, getDateCN, mailStatusMap } from "tools";

export const TableMailList = ({filterData}) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetMsMailList] = useFetchGetMsMailList();

	const columns = [
		{
			title: "From",
			dataIndex: "from",
			width: 100,
		},
		{
			title: "To",
			dataIndex: "to",
		},
		{
			title: "Subject",
			dataIndex: "subject",
		},
		{
			title: "推送状态",
			dataIndex: "status",
		},

		{
			title: "创建时间",
			dataIndex: "time_add",
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMsMailList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								from: item.data.from.name,
								to: item.data.to.email,
								status: mailStatusMap[item.status],
								time_add: getDateCN(item.time_add),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={(record) => record.id}
					/>
				</>
			)}
		</Fragment>
	);
};
