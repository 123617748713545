import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableVerifierList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageMsVerifier = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("Verifier列表 - AroundDeal管理后台");

	const inputOptions = [{ key: "name", placeholder: "搜索任务名称" }];

	const selectOptions = [
		{
			key: "status",
			placeholder: "请选择状态",
			options: [
				{ value: "", label: "请选择状态" },
				{ value: 1, label: "待处理" },
				{ value: 2, label: "处理中" },
				{ value: 3, label: "已完成" },
				{ value: 4, label: "已扣费" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	// const handleChangeFilter = (key, value) => {
	// 	if(value){
	// 		setFilterData((state) => {
	// 			return {
	// 				...state,
	// 				[key]: value,
	// 			};
	// 		});
	// 	}else{
	// 		delete filterData[key];
	// 		setFilterData({...filterData});
	// 	}
	// };

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/verify"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableVerifierList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsVerifier;
