import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { EllipsisOutlined } from "@ant-design/icons";
import { App, Button, Row, Space, Table, Typography, Dropdown, Tag, Skeleton } from "antd";
import { useFetchGetDomainList, useFetchCheckDomain, useFetchDeleteDomain } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalAddDomain } from "./ModalAddDomain";


export const TableDomainList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { modal, notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [fetchGetDomainList] = useFetchGetDomainList();
	const [fetchCheckDomain] = useFetchCheckDomain();
	const [fetchDeleteDomain] = useFetchDeleteDomain();
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [forceList, setForceList] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [openAddDomain, setOpenDomainModal] = useState(false);


	const statusMap = {
		1: <Tag color="green">Active</Tag>,
		2: <Tag color="orange">Warning</Tag>,
		3: <Tag color="red">Expired</Tag>,
	};

	const items = [
		{
			label: "重新检测",
			key: "refresh",
		},
		{
			label: "删除",
			key: "delete",
		},
	];


	const handleRefresh = (record) => {
		modal.confirm({
			title: "Tips",
			content: "您确定要重新检测该域名吗?",
			maskClosable: 'true',
			okText: '确定',
			onOk() {
				return new Promise((resolve, reject) => {
					console.log(record);
					fetchCheckDomain({
						domain: record.domain,
					},
						{
							success: (data) => {
								notification.success({ message: "重新检测成功！" });
								setForceList(forceList => forceList + 1)
							},
							error: (error) => {
								notification.error({ message: error.code });
							},
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
				}).catch(() => console.log('Oops errors!'));
			},
		})
	}

	const handleDelete = (record) => {
		modal.confirm({
			title: "Tips",
			content: "您确定要删除该域名吗?",
			maskClosable: 'true',
			okText: '确定',
			onOk() {
				return new Promise((resolve, reject) => {
					console.log(record);
					fetchDeleteDomain({
						domain: record.domain,
					},
						{
							success: (data) => {
								notification.success({ message: "删除成功！" });
								setForceList(forceList => forceList + 1)
							},
							error: (error) => {
								notification.error({ message: error.code });
							},
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
				}).catch(() => console.log('Oops errors!'));
			},
		})
	}

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "域名",
			dataIndex: "domain",
		},
		{
			title: "状态",
			dataIndex: "status",
		},
		{
			title: "过期倒计时",
			dataIndex: "left_time",
		},
		{
			title: "过期时间",
			dataIndex: "time_expired",
		},
		{
			title: "检测时间",
			dataIndex: "time_check",
		},
		{
			title: "操作",
			dataIndex: "operate",
			render: (_, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Dropdown
						menu={{
							items,
							onClick: (item) => {
								if (item.key === "refresh") {
									handleRefresh(record);
								} else if (item.key === "delete") {
									handleDelete(record);
								}
							},
						}}
					>
						<a>
							<Space>
								<EllipsisOutlined style={{ fontSize: 24 }} />
							</Space>
						</a>
					</Dropdown>
				</div>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetDomainList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								time_expired: getDateCN(item.time_expired),
								time_check: getDateCN(item.time_check),
								status: statusMap[item.status],

							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
								<Button type="primary" onClick={() => setOpenDomainModal(true)}>
								添加Domain
							</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={record => record.id}
					/>
				</>
			)}
			{openAddDomain && (
				<ModalAddDomain
					open={openAddDomain}
					onCancel={() => {
						setOpenDomainModal(false);
					}}
					onHandleUpdateList={() => setForceList(forceList => forceList + 1)}
				/>
			)}
		</Fragment>
	);
};
