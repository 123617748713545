import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, InputNumber, DatePicker, Select } from 'antd';
import { lang, consoleLog, langError, getDateSimple } from 'tools';
import { useFetchUserSettingCredit } from "hooks";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const dateFormat = 'YYYY-MM-DD';

export const ModalSetTeamCredit = ({ open, userInfo, currentId, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

    const user = useSelector((state) => state.user);
	const { name } = user?.info;
    const [dateString, setDateString] = useState('');

    const [fetchSave] = useFetchUserSettingCredit();

    useEffect(()=>{
        if(userInfo){
            form.setFieldsValue({
                // number: userInfo.credit?userInfo.credit:0,
                time_expired: userInfo.time_expired ? dayjs(getDateSimple(userInfo.time_expired), dateFormat):'',
            });
            setDateString( userInfo.time_expired ? getDateSimple(userInfo.time_expired):'')
        }
    },[userInfo]);


    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                credit: values.number,
                type: values.type,
                time_expired: dateString,
                admin: name,
                uid: currentId
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"操作成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }

    const handleDateChange = (date, dateString) =>{
        setDateString(dateString);
    }

    return (
        <Modal
            destroyOnClose
            open={open}
            title={"发放权益"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="type"
                    label="权益类型"
                    initialValue="1"
                    rules={[
                        {
                            required: true,
                            message: "请选择类型",
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value="1">Credit</Select.Option>
                        <Select.Option value="2">Verifier</Select.Option>
                        <Select.Option value="3">AI Queries</Select.Option>
                        <Select.Option value="4">Company Visitor</Select.Option>
                        <Select.Option value="6">Exports</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="number"
                    label={"数量"}
                    rules={[
                        {
                            required: true,
                            message: "请输入数量",
                        },
                    ]}
                >
                    <InputNumber placeholder='请输入数量' style={{width:"100%"}} />
                </Form.Item>
                <Form.Item
                    name="time_expired"
                    label="过期时间"
                >
                    <DatePicker onChange={handleDateChange} style={{width:"100%"}} format={dateFormat} allowClear={false} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
