import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Button } from "antd";
import { useFetchGetRiskifyUserExport, useFetchGetRiskifyUserList } from "hooks";
import { bemLevelMap, getDateCN, langError } from "tools";
import { Link } from "react-router-dom";

export const TableUserList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [loadingExport, setLoadingExport] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [amountData, setAmountData] = useState(null);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);

	const [fetchGetRiskifyUserList] = useFetchGetRiskifyUserList();
	const [fetchUserExport] = useFetchGetRiskifyUserExport();

	const typeMap = {
		1: <Tag color="green">正常</Tag>,
		9: <Tag color="red">禁用</Tag>,
	};

	const columns = [
		{
			title: "UID",
			dataIndex: "key",
			render: (_, record) => (
				<Link target="_blank" to={`/riskify/user/detail?uid=${record.key}`}>{record.key}</Link>
			),

		},
		{
			title: "用户名",
			dataIndex: "name",
		},
		{
			title: "邮箱",
			dataIndex: "email",
		},
		{
			title: "状态",
			dataIndex: "user_status",
		},
		{
			title: "类型",
			dataIndex: "membership_type",
			render: (_, record) => (
				<span>{bemLevelMap[record.membership_type]}</span>
			)
		},
		{
			title: "注册时间",
			dataIndex: "time_registered",
		},
		{
			title: "过期时间",
			render: (_, record) => (
				<>{record.membership_expired? getDateCN(record.membership_expired):"Lifetime"}</>
			)
		},
		{
			title: "付款时间",
			dataIndex: "time_paid",
		}
	];

	useEffect(() =>{
		setPageCurrent(1);
	},[filterData])

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetRiskifyUserList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								key: item.key,
								user_status: typeMap[item.status],
								time_registered: getDateCN(item.time_registered),
								time_paid: item.time_paid?getDateCN(item.time_paid):"-",
							};
						});
					});
					if(data.counts){
						setAmountData(data.counts);
					}else{
						setAmountData(null);
					}
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	const onExport = () => {
		setLoadingExport(true);
		const params = { ...filterData};
		fetchUserExport(params, {
			success: (data) => {
				setLoadingExport(false);
				if(data.url){
					window.location.href = data.url;
					notification.success({ message: "导出成功！" });
				}
			},
			error: (error) => {
				setLoadingExport(false);
				notification.error({ message: langError(error.code) });
			},
		});
	}

	const getCountsUI = () =>{
		let ui = null;
		if(amountData?.paid && amountData?.paying){
			ui = `(有付费记录: <span class="text-primary">${amountData.paid}</span>, 付费账号: <span class="text-primary">${amountData.paying}</span>)`;
		}
		return ui;
	}

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
						<Typography.Text>{dataTotal} 条结果 <span dangerouslySetInnerHTML={{__html: getCountsUI()}}></span></Typography.Text>
							<Button type="primary" loading={loadingExport} onClick={onExport}>导出</Button>
						</Row>
					</Space>

					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
				</>
			)}
		</Fragment>
	);
};
