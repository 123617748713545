import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, Space, App, Row, Table, Typography, Tag, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { useFetchGetDataConfigList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalSetConfig } from "./components";

const PageDataConfigDetails = () => {
    usePageTitle("职能配置 - 配置管理 - 数据管理后台");

    const [search] = useSearchParams();
    const type = search.get("type");

    const user = useSelector((state) => state.user);
    const { id } = user?.info;
    const { notification } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [openSetConfig, setOpenSetConfig] = useState(false);
    const [currentKey, setCurrentKey] = useState("");
    const [sid, setSid] = useState("");

    const [fetchGetDataConfigList] = useFetchGetDataConfigList();

    const statusMap = {
        1: <Tag color="orange">待处理</Tag>,
        8: <Tag color="green">成功</Tag>,
        9: <Tag color="red">失败</Tag>,
    };

    const items = [
        {
            label: "设置matches",
            key: "match_keywords",
        },
        {
            label: "设置catches",
            key: "match_catches",
        },
    ];

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
        },
        {
            title: "SID",
            dataIndex: "sid",
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Time",
            dataIndex: "time",
        },
        {
            title: "操作",
            dataIndex: "operate",
            render: (_, record) => (
                <div onClick={(e) => e.stopPropagation()}>
                    <Dropdown
                        menu={{
                            items,
                            onClick: (item) => {
                                setCurrentKey(item.key);
                                setSid(record.sid);
                                setOpenSetConfig(true);
                            },
                        }}
                    >
                        <a>
                            <Space>
                                <EllipsisOutlined style={{ fontSize: 24 }} />
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (id && type) {
            setLoading(true);
            const params = { type: type };
            fetchGetDataConfigList(params, {
                success: (data) => {
                    setLoading(false);
                    setListData(() => {
                        return data.list.map((item) => {
                            return {
                                ...item,
                                key: item.id,
                                status: statusMap[item.status],
                                time: getDateCN(item.time),
                            };
                        });
                    });
                },
                error: (error) => {
                    setLoading(false);
                    notification.error({ message: langError(error.code) });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, type]);

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Typography.Title level={3}>{type === "function" ? '职能配置' : '职级配置'}</Typography.Title>
                        <Space size="middle" direction="vertical" style={{ display: "flex" }}>
                            <Row justify="space-between" style={{ marginBottom: "10px" }}>
                                <Typography.Text>{listData?.length} 条结果</Typography.Text>
                            </Row>
                        </Space>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={listData}
                            pagination={false}
                        />
                        {
                            openSetConfig &&
                            <ModalSetConfig
                                open={openSetConfig}
                                currentKey={currentKey}
                                type={type}
                                sid={sid}
                                onCancel={() => {setOpenSetConfig(false); setCurrentKey(""); setSid("");}}
                            />
                        }
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageDataConfigDetails;
