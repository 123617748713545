import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useFetchGetRiskifyFeedbackList } from "hooks";
import { getDateCN, langError } from "tools";
import { ModalSettings } from "./ModalSettings";

export const TableFeedbackList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [openModal, setOpenModal] = useState(false);
	const [currentRow, setCurrentRow] = useState({});
    const [forceFetch, setForceFetch] = useState(0);

	const [fetchGetRiskifyFeedbackList] = useFetchGetRiskifyFeedbackList();

	const items = [
		{
			label: "设置状态",
			key: "set_status",
		},
	];

	const statusMap = {
		1: <Tag color="orange">等待回复</Tag>,
		2: <Tag color="green">已回复</Tag>,
		8: <Tag color="green">已解决</Tag>,
		9: <Tag color="gray">已关闭</Tag>,
	};

	const columns = [
		{
			title: "app",
			dataIndex: "app",

		},
		{
			title: "user",
			dataIndex: "user",
		},
		{
			title: "seq",
			dataIndex: "seq",
		},
		{
			title: "status",
			dataIndex: "status",
			render:(_,record) =>(
				<>{statusMap[record.status]}</>
			)
		},
		{
			title: "thread",
			dataIndex: "thread",
		},
		{
			title: "time_active",
			dataIndex: "time_active",
			render:(_,record) =>(
				<span>{getDateCN(record.time_active)}</span>
			)
		},
		{
			title: "time_create",
			dataIndex: "time_create",
			render:(_,record) =>(
				<span>{getDateCN(record.time_create)}</span>
			)
		},
		{
			title: "time_done",
			dataIndex: "time_done",
			render:(_,record) =>(
				<span>{getDateCN(record.time_done)}</span>
			)
		},
		{
			title: "meta",
			dataIndex: "meta",
			render:(_,record) =>(
				<>
					{record.meta && JSON.stringify(record.meta)}
				</>
			)
		},
		{
			title: "data",
			dataIndex: "data",
			render:(_,record) =>(
				<>
					{record.data && JSON.stringify(record.data)}
				</>
			)
		},
		{
			title: "操作",
			dataIndex: "operate",
			width:80,
			render: (_, record) => (
				<Dropdown
					menu={{
						items,
						onClick: (item) => {
							if (item.key === "set_status") {
								setCurrentRow(record);
								setOpenModal(true);
							}
						},
					}}
				>
					<a onClick={(e) => e.stopPropagation()}>
						<Space>
							<EllipsisOutlined style={{ fontSize: 24 }} />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetRiskifyFeedbackList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(data.list);
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData, forceFetch]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							{/* <Button type="primary">操作</Button> */}
						</Row>
					</Space>
					
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={record => record.id}
						scroll={{x:"fit-content"}}
					/>
					<ModalSettings
						open={openModal}
						currentRow={currentRow}
						onCancel={()=>{setOpenModal(false);setCurrentRow({});}}
						onHandleUpdateInfo={() =>setForceFetch(forceFetch => forceFetch +1)}
					/>
				</>
			)}
		</Fragment>
	);
};
