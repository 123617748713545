import { configureStore } from "@reduxjs/toolkit";
import initialReducer from "./reducer/initial";
import userReducer from "./reducer/user";
import userListReducer from "./reducer/userList";

const store = configureStore({
  reducer: {
    user: userReducer,
    initial: initialReducer,
    userList: userListReducer,
  },
});

export default store;
