import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Button, Row, Space, Table, Typography, Tag, Skeleton } from "antd";
import { useFetchDeleteMonitor, useFetchGetFroxyList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalAddFroxy } from "./ModalAddFroxy";


export const TableList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { modal, notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [fetchGetFroxyList] = useFetchGetFroxyList();
	const [fetchDeleteMonitor] = useFetchDeleteMonitor();
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [forceList, setForceList] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [openAddFroxy, setOpenFroxyModal] = useState(false);

	const statusMap = {
		1: <Tag color="green">在线</Tag>,
		2: <Tag color="red">异常</Tag>,
		9: <Tag color="red">禁用</Tag>,
	};

	const items = [
		{
			label: "编辑",
			key: "edit",
		},
		{
			label: "删除",
			key: "delete",
		},
	];

	const handleDelete = (record) => {
		modal.confirm({
			title: "Tips",
			content: "您确定要删除该监测点吗?",
			maskClosable: 'true',
			okText: '确定',
			onOk() {
				return new Promise((resolve, reject) => {
					fetchDeleteMonitor({
						key:record.key
					},
						{
							success: (data) => {
								notification.success({ message: "删除成功！" });
								setForceList(forceList => forceList + 1)
							},
							error: (error) => {
								notification.error({ message: error.code });
							},
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
				}).catch(() => console.log('Oops errors!'));
			},
		})
	}


	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "IPV4",
			dataIndex: "ipv4",
		},
		{
			title: "Host",
			dataIndex: "host",
		},
		{
			title: "Tld",
			dataIndex: "tld",
		},
		{
			title: "状态",
			dataIndex: "status",
			render:(_, record) =>(
				<>{statusMap[record.status]}</>
			)
		},
		{
			title: "Time",
			dataIndex: "time",
			render:(_, record) =>(
				<>{getDateCN(record.time)}</>
			)
		}
		// {
		// 	title: "操作",
		// 	dataIndex: "operate",
		// 	width: 100,
		// 	fixed: "right",
		// 	render: (_, record) => (
		// 		<div onClick={(e) => e.stopPropagation()}>
		// 			<Dropdown
		// 				menu={{
		// 					items,
		// 					onClick: (item) => {
		// 						if (item.key === "edit") {
		// 							setCurrentData(record);
		// 							setOpenEditMonitorModal(true);
		// 						} else if (item.key === "delete") {
		// 							handleDelete(record);
		// 						}
		// 					},
		// 				}}
		// 			>
		// 				<a>
		// 					<Space>
		// 						<EllipsisOutlined style={{ fontSize: 24 }} />
		// 					</Space>
		// 				</a>
		// 			</Dropdown>
		// 		</div>
		// 	),
		// },
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetFroxyList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(data.list);
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={() => setOpenFroxyModal(true)}>
								添加Froxy
							</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={record => record.id}
					/>
				</>
			)}
			{openAddFroxy && (
				<ModalAddFroxy
					open={openAddFroxy}
					onCancel={() => {
						setOpenFroxyModal(false);
					}}
					onHandleUpdateList={() => setForceList(forceList => forceList + 1)}
				/>
			)}
		</Fragment>
	);
};
