import { Fragment, useEffect, useRef, useState } from "react";
import { Layout, App, Card, Row, Col, Tag, Button, Skeleton } from "antd";
import { useSearchParams } from "react-router-dom";
import { useFetchGetMsInvoiceDetail } from "hooks";
import { useSelector } from "react-redux";
import { LayoutAuthRequired, LayoutHeaderNav, LogoLg } from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { capitalizeFirstLetter, getCurrency, getDateCN, lang, langError } from "tools";
import numeral from "numeral";
import ReactToPrint from "react-to-print";

const PageMsInvoice = () => {
    const user = useSelector((state) => state.user);
    const { notification } = App.useApp();
    const { id } = user?.info;
    const [loading, setLoading] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});
    let componentRef = useRef();

    const [search] = useSearchParams();
    const key = search.get("key");

    usePageTitle(`#${key} - Invoice列表 - AroundDeal管理后台`);

    const [fetchGetMsInvoiceDetail] = useFetchGetMsInvoiceDetail();

    let product = '';
    if (invoiceData && invoiceData.level) {
        product = `AroundDeal ${capitalizeFirstLetter(invoiceData.level.toString())} ${invoiceData.package_type == 1 ? 'Monthly' : 'Annual'} Plan`;
    }

    useEffect(() => {
        if (id) {
            setLoading(true);
            fetchGetMsInvoiceDetail({ key: key }, {
                success: (data) => {
                    setLoading(false);
                    setInvoiceData(data);
                },
                error: (error) => {
                    setLoading(false);
                    notification.error({ message: langError(error.code) });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar selectedKey={["ms/invoice"]} />
                    <LayoutPageContent>
                        {
                            !id ? (
                                <Skeleton />
                            ) : (
                                <Fragment>
                                    <div>
                                        <Row align="center">
                                            <Col style={{ width: 730 }}>
                                                {/* <Button type="link" href={`/ms/invoice`}>返回Invoice列表</Button> */}
                                                <Card loading={loading} style={{ margin: 30 }} ref={(el) => (componentRef = el)}>
                                                    <Row justify="space-between">
                                                        <Col>
                                                            <LogoLg width={160} />
                                                        </Col>
                                                        <Col>
                                                            <div style={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                                <h2>{lang('label_invoice')} #{key}</h2>
                                                                {
                                                                    invoiceData.status === 1 ?
                                                                        <Tag color="#bfbfbf" style={{ marginLeft: 20, marginRight: 0 }}>UNPAID</Tag> : null
                                                                }
                                                                {
                                                                    invoiceData.status === 2 ?
                                                                        <Tag color="#52c41a" style={{ marginLeft: 20, marginRight: 0 }}>PAID</Tag> : null
                                                                }
                                                            </div>
                                                            <div style={{ textAlign: 'right', color: "#909090" }}>
                                                                {lang('label_date_of_due')}
                                                                <span style={{ marginLeft: 20 }}>{getDateCN(invoiceData.time_create)}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row justify="space-between" style={{ marginTop: 30 }}>
                                                        <Col>
                                                            <div style={{ color: "#909090" }}>{invoiceData.user && invoiceData.user.name}</div>
                                                            <div style={{ color: "#909090" }}>{invoiceData.user && invoiceData.user.email}</div>
                                                        </Col>
                                                        <Col style={{ width: 400 }}>
                                                            <p style={{ textAlign: 'right', color: "#909090" }}>
                                                                <span>{lang('label_address_info_1')}</span><br />
                                                                <span>{lang('label_address_info_2')}</span><br />
                                                                <span>{lang('label_address_info_3')}</span>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <h3 style={{ margin: "30px 0" }}>{getCurrency(invoiceData.currency)}{numeral((invoiceData.total * invoiceData.quantity) / 100).format('0,0.00')} due on {getDateCN(invoiceData.time_create)}</h3>
                                                    <table className='ad-checkout-table'>
                                                        <thead>
                                                            <tr>
                                                                <th>{lang('label_description')}</th>
                                                                <th>{lang('label_qty')}</th>
                                                                <th>{lang('label_unit_price')}</th>
                                                                <th style={{ textAlign: 'right' }}>{lang('label_invoice_col_amount')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <h4 className='mb-0'>{product}</h4>
                                                                    <p className='mb-0'>{invoiceData.credit} credits/{invoiceData.package_type == 1 ? 'mo' : 'yr'}/user</p>
                                                                </td>
                                                                <td>
                                                                    {invoiceData.quantity}
                                                                </td>
                                                                <td>
                                                                    {getCurrency(invoiceData.currency)}{numeral(invoiceData.amount / 100).format('0,0.00')}
                                                                </td>
                                                                <td style={{ textAlign: 'right' }}>
                                                                    {getCurrency(invoiceData.currency)}{numeral((invoiceData.amount * invoiceData.quantity) / 100).format('0,0.00')}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div style={{ textAlign: 'right', marginTop: 30 }}>
                                                        <h4 className="mb-0">{lang('label_invoice_subtotal')}: {getCurrency(invoiceData.currency)}{numeral((invoiceData.amount * invoiceData.quantity) / 100).format('0,0.00')}</h4>
                                                        {invoiceData.discount ? (<p>{lang('label_invoice_discount')}: -{getCurrency(invoiceData.currency)}{numeral(invoiceData.discount / 100).format('0,0.00')}</p>) : ''}
                                                        <h2 className="font-weight-semibold mt-3">{lang('label_invoice_total')}: {getCurrency(invoiceData.currency)}{numeral((invoiceData.total) / 100).format('0,0.00')}</h2>
                                                    </div>
                                                </Card>
                                                <ReactToPrint
                                                    trigger={() => <Button type="primary" style={{marginLeft:30}}>
                                                            <span>{lang('button_print')}</span>
                                                        </Button>
                                                    }
                                                    content={() => componentRef}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Fragment>
                            )
                        }
                    </LayoutPageContent >
                </Layout >
            </Layout >
        </LayoutAuthRequired >
    );
};
export default PageMsInvoice;
