import React from "react";
import { Layout,theme } from "antd";

export const LayoutPageContent = ({ children }) => {
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	return (
		<Layout
            style={{
                padding: "24px 24px",
            }}
        >
            <Layout.Content
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                    marginLeft: 200,
                    background: colorBgContainer
                }}
            >
                {children}
            </Layout.Content>
        </Layout>
	);
};
