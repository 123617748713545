import { Fragment, useEffect, useState } from "react";
import { Layout, App } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableFeedbackList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageBemFeedback = () => {
	const { notification } = App.useApp();
	const [filterData, setFilterData] = useState({});

	usePageTitle("Feedback - 插件矩阵管理后台");

	const inputOptions = [
		{ key: "name", placeholder: "搜索用户名" },
		{ key: "email", placeholder: "搜索邮箱" },
		{ key: "source", placeholder: "搜索来源" },
		{ key: "partner_key", placeholder: "搜索Partner Key" },
	];

	const selectOptions = [
		{
			key: "type",
			placeholder: "选择分类",
			options: [
				{ value: "", label: "选择分类" },
				{ value: "reply", label: "回复" },
				{ value: "auto", label: "自动回复" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["bem/feedback"]} />
					<LayoutPageContent>
						<Fragment>
							{/* <LayoutNavForms
								selectOptions={selectOptions}
								datePickerKey={"time"}
								inputOptions={inputOptions}
								onHandleChangeFilter={handleChangeFilter}
								initialValues={{email: filterData.email}}
							/> */}
							<TableFeedbackList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageBemFeedback;
