import React, { useState } from "react";
import { Button, Form, Modal, Input, App } from "antd";
import { useFetchAddApp, useFetchEditApp } from "hooks";
import { langError } from "tools";

export const ModalAddApp = ({ open, onCancel, currentApp, onHandleUpdateList }) => {
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [fetchAddApp] = useFetchAddApp();
	const [fetchEditApp] = useFetchEditApp();

	const [form] = Form.useForm();

	const handleAddApp = () => {
		form
			.validateFields()
			.then((values) => {
				values.permissions = values.permissions.split('\n')
				setLoading(true);
				if (currentApp) {
					fetchEditApp(values, {
						success: (data) => {
							setLoading(false);
							onCancel();
							notification.success({ message: "修改成功" });
							if (onHandleUpdateList) {
								onHandleUpdateList();
							}
						},
						error: (error) => {
							setLoading(false);
							onCancel();
							notification.error({ message: langError(error.code) });
						},
					});
				} else {
					fetchAddApp(values, {
						success: (data) => {
							setLoading(false);
							onCancel();
							notification.success({ message: "添加成功" });
							if (onHandleUpdateList) {
								onHandleUpdateList();
							}
						},
						error: (error) => {
							setLoading(false);
							onCancel();
							notification.error({ message: langError(error.code) });
						},
					});
				}
			})
			.catch((info) => { });
	};

	return (
		<Modal
			title={`${currentApp ? "编辑" : "新增"}App`}
			width={500}
			open={open}
			onCancel={onCancel}
			destroyOnClose={true}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					取消
				</Button>,
				<Button
					key="add"
					type="primary"
					loading={loading}
					onClick={handleAddApp}
				>
					确定
				</Button>,
			]}
		>
			<Form
				name="basic"
				form={form}
				layout="vertical"
				style={{ margin: "30px 0" }}
			>
				<Form.Item
					label="名称"
					name="name"
					style={{ margin: "10px 0" }}
					initialValue={currentApp && currentApp.name}
					rules={[
						{
							required: true,
							message: "请填写名称",
						},
					]}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label="标识"
					name="key"
					style={{ margin: "10px 0" }}
					initialValue={currentApp && currentApp.key}
					rules={[
						{
							required: true,
							message: "请填写标识",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="页面"
					name="permissions"
					style={{ margin: "10px 0" }}
					initialValue={currentApp && currentApp.data.join('\n')}
					rules={[
						{
							required: true,
							message: "请填写页面",
						},
					]}
				>
					<Input.TextArea rows={5} />
				</Form.Item>
			</Form>
		</Modal>
	);
};
