import { createSlice } from "@reduxjs/toolkit";

const initValue = {
  users: [],
};

const userListSlice = createSlice({
  name: "userList",
  initialState: initValue,
  reducers: {
    setData(state, action) {
      state.users = action.payload;
    },
  },
});

export const { setData } = userListSlice.actions;
export default userListSlice.reducer;
