import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  App,
  Row,
  Space,
  Table,
  Typography,
  Skeleton,
  Tooltip,
} from "antd";
import { useFetchGetMsRecentSearchList } from "hooks";
import { langError, getDateCN, recentSearchTypeMap} from "tools";
import { ModalResponse } from "components";



export const TableRecentSearchList = ({filterData}) => {
  const user = useSelector((state) => state.user);
  const { id } = user?.info;

  const { notification } = App.useApp();

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataTotal, setDataTotal] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [currentRow, setCurrentRow] = useState({});
	const [currentModal, setCurrentModal] = useState("");

  // useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

  const [fetchGetMsRecentSearchList] = useFetchGetMsRecentSearchList();

  const columns = [
    {
      title: "用户名",
      dataIndex: "name",
      width: 200,
      render: (name, info) => (
        <Link
          target="_blank"
          to={`/ms/user/detail?uid=${info.uid}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
			title: "Rule",
			dataIndex: "rule",
			ellipsis: true,
			render:(_,record) =>(
				<a onClick={()=> {setOpenModal(true);setCurrentRow(record);setCurrentModal("rule")}}>{record.rule}</a>
			)
		},
    {
      title: "Count",
      dataIndex: "result_count",
    },
    {
      title: "创建时间",
      dataIndex: "time_created",
    },
  ];

  useEffect(() => {
    if (id) {
      setLoading(true);
      const params = { page: pageCurrent, ...filterData };
      fetchGetMsRecentSearchList(params, {
        success: (data) => {
          setLoading(false);
          setDataTotal(data.page.total);
          setPageSize(data.page.limit);
          setListData(() => {
            return data.list.map((item) => {
              return {
                ...item,
                operate: item.id,
                type: recentSearchTypeMap[item.type],
                time_created: getDateCN(item.time_created),
              };
            });
          });
        },
        error: (error) => {
          setLoading(false);
          notification.error({ message: langError(error.code) });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageCurrent, filterData]);

  const handleChangePage = (page) => {
    setPageCurrent(page);
    window.scroll(0, 0);
  };

  return (
    <Fragment>
      {!id ? (
        <Skeleton active />
      ) : (
        <>
          <Space size="middle" direction="vertical" style={{ display: "flex" }}>
            <Row justify="space-between" style={{ marginBottom: "10px" }}>
              <Typography.Text>{dataTotal} 条结果</Typography.Text>
            </Row>
          </Space>
          <Table
            loading={loading}
            columns={columns}
            dataSource={listData}
            rowKey={(item) => item.id}
            pagination={{
              current: pageCurrent,
              pageSize: pageSize,
              total: dataTotal,
              showSizeChanger: false,
              onChange: handleChangePage,
            }}
          />
          <ModalResponse
						open={openModal}
						title={currentModal === "rule" ? "Rule详情":"request详情"}
						onCancel={()=>{setOpenModal(false);setCurrentRow({});setCurrentModal("")}}
						data={currentModal === "rule" ? (currentRow?.rule):(currentRow?.request)}
					/>
        </>
      )}
    </Fragment>
  );
};
