import { useState } from "react";
import { Layout, theme } from "antd";
import {
  LayoutAuthRequired,
  LayoutHeaderNav,
  LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableEnrichList } from "./components";
import usePageTitle from "hooks/usePageTitle";


const PageLarkEnrich  = () => {
  const [filterData, setFilterData] = useState({});
  usePageTitle("Enrich列表 - Lark插件管理后台");

  // const inputOptions = [
  //   { key: "data", placeholder: "搜索Data" },
  //   { key: "result", placeholder: "搜索Result" },
  // ];

  const selectOptions = [
    {
      key: "type",
      placeholder: "选择补全类型",
      options: [
        { value: "", label: "选择补全类型" },
        { value: 1, label: "people" },
        { value: 2, label: "company" },
      ],
    },
    {
      key: "status",
      placeholder: "选择补全状态",
      options: [
        { value: "", label: "选择补全状态" },
        { value: 8, label: "success" },
        { value: 9, label: "failed" },
        { value: 11, label: "insuffcient" },
      ],
    },
  ];

  const handleChangeFilter = (key, value) => {
    setFilterData((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  return (
    <LayoutAuthRequired>
      <Layout
        theme="light"
        style={{
          minHeight: "100vh",
        }}
      >

        <LayoutHeaderNav />
        <Layout>
          <LayoutPageSidebar selectedKey={["lark/enrich"]} />
          <LayoutPageContent>
            <LayoutNavForms
              // inputOptions={inputOptions}
              selectOptions={selectOptions}
              onHandleChangeFilter={handleChangeFilter}
            />
            <TableEnrichList filterData={filterData} />

          </LayoutPageContent>
        </Layout>
      </Layout>
    </LayoutAuthRequired>
  );
};
export default PageLarkEnrich;
