import { lang } from 'tools';

const usePageTitle = (title = '') => {
    const defaultTitle = lang('info_page_title_default');
    const titleNew = title?(title + ' - ' + defaultTitle):defaultTitle;
    document.title = titleNew;
    return titleNew;
}
export default usePageTitle;

