import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Tag,
	Skeleton
} from "antd";
import { useFetchGetMsApiKeyList } from "hooks";
import { langError, getDateCN, apiKeyStatusMap } from "tools";

export const TableApiKeyList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetMsApiKeyList] = useFetchGetMsApiKeyList();



	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "用户名",
			dataIndex: "user_name",
			render: (name, info) => (
				<Link
					target="_blank"
					to={`/ms/user/detail?uid=${info.uid}`}
				>
					{name}
				</Link>
			),
		},
		{
			title: "Email",
			dataIndex: "user_email",
		},
		{
			title: "Token",
			dataIndex: "token",
		},
		{
			title: "状态",
			dataIndex: "status",
		},
		{
			title: "过期时间",
			dataIndex: "time_expired",
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMsApiKeyList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								status: apiKeyStatusMap[item.status],
								time_expired: getDateCN(item.time_expired),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						rowKey={(record) => record.id}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
				</>
			)}
		</Fragment>
	);
};
