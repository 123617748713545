import React from "react";
import { Card, Descriptions, Spin } from "antd";
import { getDateCN, visitorPeopleFilterMap, visitorStatusMap, visitorVerifiedMap } from "tools";

export const CardBasicInfo = ({ loading, dataInfo }) => {

    const dataSource = [
        { key: "用户", value: dataInfo && dataInfo.visitor && dataInfo.visitor.user_name ?dataInfo.visitor.user_name:"-" },
        { key: "每日公司上限", value: dataInfo && dataInfo.max_daily },
        { key: "ID", value: dataInfo && dataInfo.visitor?.id },
        { key: "每小时公司上限", value: dataInfo && dataInfo.max_hourly },
        { key: "Key", value: dataInfo && dataInfo.visitor?.key },
        { key: "启用People", value: dataInfo && dataInfo.use_people_filter? visitorPeopleFilterMap[dataInfo.use_people_filter]:"-" },
        { key: "Token", value: dataInfo && dataInfo.visitor?.token },
        { key: "已识别公司数量", value: dataInfo && dataInfo.company_number },
        { key: "域名", value: dataInfo && dataInfo.domain },
        { key: "已识别人数量", value: dataInfo && dataInfo.people_number },
        { key: "状态", value: dataInfo && dataInfo.status ? visitorStatusMap[dataInfo.status]:"-" },
        { key: "创建时间", value: dataInfo && dataInfo.visitor?.time_created ? getDateCN(dataInfo.visitor.time_created) : null },
        { key: "验证状态", value: dataInfo && visitorVerifiedMap[dataInfo.is_verified] },
    ];

    const formatData = dataSource.map((item) => {
        return {
            ...item
        };
    });

    return (
        <>
            <h2 style={{ marginBottom: "25px" }}>
                Visitor详情 - { dataInfo && dataInfo.domain ? dataInfo.domain: <Spin /> }
            </h2>
            <Card title="基础信息" loading={loading}>
                <Descriptions
                    bordered
                    layout="horizontal"
                    labelStyle={{ fontWeight: "bold" }}
                >
                    {formatData.map((item, index) => (
                        <Descriptions.Item label={item.key} key={index}>
                            {item.value}
                        </Descriptions.Item>
                    ))}
                </Descriptions>
            </Card>
        </>
    );
};
