import React, { useEffect } from "react";
import { Modal } from "antd";

export const ModalPreview = ({ open, onCancel, iniTemplate }) => {

    useEffect(() =>{
        if(iniTemplate.html || iniTemplate.ampHtml){
            updateContent(iniTemplate.html,iniTemplate.ampHtml);
        }
    },[iniTemplate]);

    const updateContent = (html, ampHtml) => {
        let htmlToSet = ampHtml || html;
        var iframeDesktop = document.querySelector('#iframeDesktop');
        iframeDesktop.contentWindow.document.open('text/html', 'replace');
        iframeDesktop.contentWindow.document.write(htmlToSet);
        iframeDesktop.contentWindow.document.close();

        var iframeMobile = document.querySelector('#iframeMobile');
        iframeMobile.contentWindow.document.open('text/html', 'replace');
        iframeMobile.contentWindow.document.write(htmlToSet);
        iframeMobile.contentWindow.document.close();
    };

    return (
        <Modal
            title="External Preview Popup"
            width={1200}
            open={open}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={null}
        >
            <div className="preview-row">
                <div className="preview-col-sm-8">
                    <div className="esdev-desktop-device">
                        <div className="esdev-email-window-panel">
                            <div className="esdev-email-subject" style={{minHeight:20}}></div>
                        </div>
                        <div className="esdev-desktop-device-screen">
                            <iframe id="iframeDesktop" frameBorder="0" title="esdev-desktop" width="100%" height="642"></iframe>
                        </div>
                    </div>
                </div>
                <div className="preview-col-sm-4 esdev-no-padding-left">
                    <div className="esdev-mobile-device center-block">
                        <div className="esdev-mobile-device-screen">
                            <img src="/img/mobile-view-top-bar.png" alt="mobile-view-top-bar" />
                            <iframe id="iframeMobile" frameBorder="0" title="esdev-mobile" width="100%" height="459"></iframe>
                            <img className="esdev-mail-bottom-bar" src="/img/mobile-view-bottom-bar.png" alt="mobile-view-bottom-bar" />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
