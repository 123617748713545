import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableUserList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageRisikyUser = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("用户列表 - Riskify插件管理后台");

	const inputOptions = [
		{ key: "email", placeholder: "搜索邮箱" },
	];

	const selectOptions = [
		{
			key: "membership_type",
			placeholder: "选择等级",
			options: [
				{ value: "", label: "选择等级" },
				{ value: "FREE", label: "FREE" },
				{ value: "STARTER", label: "STARTER" },
				{ value: "PRO", label: "PRO" },
				{ value: "UNLIMITED", label: "UNLIMITED" },
				{ value: "UNLIMITED+", label: "UNLIMITED+" }
			],
		},
		{
			key: "tag",
			placeholder: "筛选标签",
			options: [
				{ value: "", label: "筛选标签" },
				{ value: "PAYING", label: "PAYING" },
				{ value: "PAID", label: "PAID" }
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["riskify/user"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								// datePickerKey={"time"}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableUserList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageRisikyUser;
