import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Skeleton,
} from "antd";
import { useFetchGetMsPartnerList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalResponse } from "components";

export const TablePartnerList = ({ filterData = {} }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [currentRow, setCurrentRow] = useState({});

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetMsPartnerList] = useFetchGetMsPartnerList();

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			width: 100,
		},
		{
			title: "name",
			dataIndex: "name",
		},
		{
			title: "link",
			dataIndex: "link",
		},
		{
			title: "注册人数",
			dataIndex: "user",
		},
		{
			title: "累计付费人数(含试用)",
			dataIndex: "pay_user",
		},
		{
			title: "累计收入",
			dataIndex: "amount",
			render: (_, record) => (
				<span>  ${record.amount ? (record.amount / 100).toFixed(2) : "0.00"}</span>
			)
		},
		{
			title: "创建时间",
			dataIndex: "time_created",
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMsPartnerList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								link: 'https://ms.arounddeal.com/p/' + item.key,
								time_created: getDateCN(item.time_created),

							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						rowKey={(item) => item.id}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
					<ModalResponse
						open={openModal}
						title={"Content详情"}
						onCancel={()=>{setOpenModal(false);setCurrentRow({});}}
						data={currentRow?.data}
					/>
				</>
			)}
		</Fragment>
	);
};
