import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Skeleton,
	Button,
} from "antd";
import { useFetchRedeemCodeList } from "hooks";
import { langError, getDateCN, redeemCodeStatusMap, redeemCodeTypeMap } from "tools";
import { ModalAddRedeemCode } from "./ModalAddRedeemCode";

export const TableRedeemCodeList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [openRedeemCode, setOpenRedeemCode] = useState(false);
	const [currentRow, setCurrentRow] = useState({});

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetMsRedeemCodeList] = useFetchRedeemCodeList();

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			width: 100,
		},
		{
			title: "Partner",
			dataIndex: "partner_name",
		},
		{
			title: "兑换人",
			dataIndex: "user_name",
		},
		{
			title: "Code",
			dataIndex: "code",
		},
		{
			title: "状态",
			dataIndex: "status",
			render: (_, record) => (
				<span>{redeemCodeStatusMap[record.status]}</span>
			)
		},
		{
			title: "类型",
			dataIndex: "status",
			render: (_, record) => (
				<span>{redeemCodeTypeMap[record.type]}</span>
			)
		},
		{
			title: "兑换内容",
			dataIndex: "data",
		},
		{
			title: "兑换时间",
			dataIndex: "time_updated",
			ellipsis: true,
			render: (_, record) => (
				<span>{record.time_updated ? getDateCN(record.time_updated) : ''}</span> 
			)
		},
		{
			title: "过期时间",
			dataIndex: "time_expired",
			ellipsis: true,
			render: (_, record) => (
				<span>{getDateCN(record.time_expired)}</span>
			)
		},
		{
			title: "创建时间",
			dataIndex: "time_created",
			render: (_, record) => (
				<span>{getDateCN(record.time_created)}</span>
			)
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMsRedeemCodeList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(data.list);
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={()=> setOpenRedeemCode(true)}>新增兑换码</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={(item) => item.id}
					/>
					<ModalAddRedeemCode 
						open={openRedeemCode}
						onCancel={() => {setOpenRedeemCode(false)}}
					/>
				</>
			)}
		</Fragment>
	);
};
