import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Form, Input } from 'antd';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { LogoLg } from 'components';
import { useFetchLogin } from 'hooks';
import { lang } from 'tools';
import config from 'config';

const PageLogin = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);   // 注册按钮loading效果
    const [error, setError] = useState('');   // 注册时错误信息

    const [fetchLogin] = useFetchLogin();

    const navigate = useNavigate();

    const onFinish = (values) => {
        form.validateFields()
            .then(values => {
                setLoading(true);
                fetchLogin(values, {
                    success: (data) => {
                        if(data.jwt){
                            setLoading(false);
                            if(data.permissions && data.permissions.ms && data.permissions.ms.length > 0){
                                navigate(`/${data.permissions.ms[0]}`);
                                localStorage.setItem(config.keyStorageApp, `${data.permissions.ms[0]}`);
                            }else{
                                navigate(`/`);
                            }
                        } else {
                            setLoading(false);
                            setError('error_unknown');
                        }
                    },
                    error: (error) => {
                        setLoading(false);
                        setError('error_' + error.code.toLowerCase());
                    },
                });
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <div className="ardl-login-container">
            <div className="ardl-login-content">
                <div className="ardl-login-wrapper">
                    <div style={{marginBottom:20}}>
                        <LogoLg width={150} />
                        <h1 style={{paddingTop:10}}>内部系统</h1>
                        {/* <p>请输入邮箱和密码进入系统</p> */}
                    </div>
                    <div style={{ marginTop: 10 }}>
                        {
                            error ? <Alert style={{marginBottom:16}} type="error" showIcon message={lang(error)}></Alert>:null
                        }
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            layout="vertical"
                            onFinish={onFinish}
                            style={{width:330}}
                            form={form}
                        >
                            <Form.Item
                                name="email"
                                label="邮箱"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入邮箱！',
                                    },
                                ]}
                            >
                                <Input size="large" prefix={<MailOutlined className="site-form-item-icon" />} placeholder="请输入邮箱" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                label="密码"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码！',
                                    },
                                ]}
                            >
                                <Input.Password size="large" prefix={<LockOutlined className="site-form-item-icon" />} placeholder="请输入密码" />
                            </Form.Item>

                            <Form.Item>
                                <Button loading={loading} block size="large" type="primary" htmlType="submit" className="login-form-button">
                                    进入系统
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageLogin;