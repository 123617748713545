import React, { useState } from "react";
import { Button, Form, Modal, Input, Select, App } from "antd";
import { useFetchAddUser, useFetchEditUser } from "hooks";
import { langError } from "tools";

const typeArr = [
	{ value: "1", label: "全职" },
	{ value: "2", label: "试用期" },
	{ value: "3", label: "实习生" },
	{ value: "4", label: "兼职" },
	{ value: "5", label: "合作伙伴" },
];

export const ModalAddUser = ({ open, onCancel, currentUser }) => {
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);

	const [form] = Form.useForm();

	const [fetchAddUser] = useFetchAddUser();
	const [fetchEditUser] = useFetchEditUser();

	const handleAddUser = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				if (currentUser) {
					const params = { ...values, id: currentUser.id };
					fetchEditUser(params, {
						success: (data) => {
							setLoading(false);
							onCancel();
							notification.success({ message: "修改成功" });
						},
						error: (error) => {
							setLoading(false);
							onCancel();
							notification.error({ message: langError(error.code) });
						},
					});
				} else {
					fetchAddUser(values, {
						success: (data) => {
							setLoading(false);
							onCancel();
							notification.success({ message: "User add successfully." });
						},
						error: (error) => {
							setLoading(false);
							onCancel();
							notification.error({ message: langError(error.code) });
						},
					});
				}
			})
			.catch((info) => { });
	};

	return (
		<Modal
			title={`${currentUser ? "编辑" : "新增"}用户`}
			width={500}
			open={open}
			onCancel={onCancel}
			destroyOnClose={true}
			footer={[
				<Button key="add" onClick={onCancel}>
					取消
				</Button>,
				<Button
					key="cancel"
					type="primary"
					loading={loading}
					onClick={handleAddUser}
				>
					确定
				</Button>,
			]}
		>
			<Form
				name="basic"
				form={form}
				layout="vertical"
				style={{ margin: "30px 0" }}
			>
				{!currentUser && (
					<>
						<Form.Item
							label="姓名"
							name="name"
							style={{ margin: "10px 0" }}
							initialValue={currentUser && currentUser.name}
							rules={[
								{
									required: true,
									message: "请填写姓名",
								},
							]}
						>
							<Input />
						</Form.Item>

						<Form.Item
							label="邮箱"
							name="email"
							style={{ margin: "10px 0" }}
							initialValue={currentUser && currentUser.email}
							rules={[
								{
									required: true,
									message: "请填写邮箱",
								},
							]}
						>
							<Input />
						</Form.Item>

					</>
				)}
				<Form.Item
					label="密码"
					name="password"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写密码",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="类型"
					name="type"
					style={{ margin: "10px 0" }}
					initialValue={currentUser && typeArr[currentUser.type - 1].value}
					rules={[
						{
							required: true,
							message: "请选择类型",
						},
					]}
				>
					<Select>
						{typeArr.map((item) => (
							<Select.Option value={item.value} key={item.value}>
								{item.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};
