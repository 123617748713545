import { useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutSidebar,
} from "components";
import { TableUserList } from "./components";
import usePageTitle from "hooks/usePageTitle";
import { LayoutPageContent } from "components/layout/LayoutPageContent";

const PageUser = () => {

	usePageTitle("User列表 - ADMIN");

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutSidebar selectedKey={["user"]} />
					<LayoutPageContent>
						<TableUserList />
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageUser;
