import React, { useState } from "react";
import { Button, Form, Modal, Input, App, Select } from "antd";
import { useFetchEditMonitor } from "hooks";
import { langError } from "tools";

export const ModalEditMonitor = ({ open, currentData, onCancel, onHandleUpdateList }) => {
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);

	const [fetchEditMonitor] = useFetchEditMonitor();

	const [form] = Form.useForm();

	const handleAddApp = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				fetchEditMonitor({...values, old_key: currentData.key}, {
					success: (data) => {
						setLoading(false);
						onCancel();
						notification.success({ message: "编辑成功！" });
						if (onHandleUpdateList) {
							onHandleUpdateList();
						}
					},
					error: (error) => {
						setLoading(false);
						onCancel();
						notification.error({ message: langError(error.code) });
					},
				});
			})
			.catch((info) => { });
	};

	return (
		<Modal
			title={`编辑监测点`}
			width={500}
			open={open}
			onCancel={onCancel}
			destroyOnClose={true}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					取消
				</Button>,
				<Button
					key="add"
					type="primary"
					loading={loading}
					onClick={handleAddApp}
				>
					确定
				</Button>,
			]}
		>
			<Form
				name="edit_monitor"
				form={form}
				layout="vertical"
				style={{ margin: "30px 0" }}
			>

				<Form.Item
					label="所属分类"
					name="category"
					style={{ margin: "10px 0" }}
					initialValue={currentData.category}
					rules={[
						{
							required: true,
							message: "请填写所属分类",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="监测点名称"
					name="key"
					initialValue={currentData.key}
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写监测点名称",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="失联间隔"
					name="missing"
					initialValue={currentData.missing}
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写失联间隔",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="脚本状态"
					name="status"
					initialValue={currentData.status}
					style={{ margin: "10px 0" }}
				>
					<Select>
						<Select.Option key={1} value={1}>正常</Select.Option>
						<Select.Option key={2} value={2}>警告</Select.Option>
						<Select.Option key={3} value={3}>异常</Select.Option>
						<Select.Option key={4} value={4}>失联</Select.Option>
						<Select.Option key={9} value={9}>暂停</Select.Option>
					</Select>
				</Form.Item>
				<Form.Item
					label="备注"
					name="remark"
					initialValue={currentData.remark}
					style={{ margin: "10px 0" }}
					placeholder="请填写备注"
				>
					<Input.TextArea rows={5} />
				</Form.Item>
			</Form>
		</Modal>
	);
};
