import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { TableMonitorList } from "./components";

const PageOperationMonitor = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("检测系统 - IT管理后台");

	const inputOptions = [
	  { key: "key", placeholder: "搜索监测点" },
	  { key: "category", placeholder: "搜索分类" },
	];

	const selectOptions = [
	  {
	    key: "status",
	    placeholder: "选择状态",
	    options: [
	      { value: 0, label: "选择状态" },
	      { value: 1, label: "正常" },
	      { value: 2, label: "警告" },
	      { value: 3, label: "异常" },
	      { value: 4, label: "失联" },
	      { value: 9, label: "暂停" },
	    ],
	  },
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["operation/monitor"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableMonitorList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageOperationMonitor;
