import React, { useEffect, useState } from "react";
import { App, Card, Descriptions, Row, Spin, Dropdown, Button, Modal, notification, Tag, Table, Space, Typography } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { getDateCN, langError } from "tools";
import { useFetchEDMTaskClickList } from "hooks";
import { LayoutNavForms } from "components";
import { ModalEmailDetail } from "./ModalEmailDetail";

export const CardBasicInfo = ({ loading, taskInfo, onHandleUpdateInfo }) => {
	const { key, statistics, status, name, app, data, user, result, time_create, options } = taskInfo || {};
	const { modal } = App.useApp();

	const [loadingTable, setLoadingTable] = useState(false);
	const [openDetailModal, setOpenDetailModal] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [filterData, setFilterData] = useState({});
	const [currentId, setCurrentId] = useState(null);

	const [fetchTaskClickList] = useFetchEDMTaskClickList();

	useEffect(() => {
		setLoadingTable(true);
		if (key) {
			const params = { page: pageCurrent, ...filterData, key: key };
			fetchTaskClickList(params,
				{
					success: (data) => {
						setLoadingTable(false);
						setDataTotal(data.page.total);
						setPageSize(data.page.limit);
						setListData(() => {
							return data.list.map((item) => {
								return {
									...item,
									key: item.id,
									email: item.email,
									time_click: getDateCN(item.time_click),
								};
							});
						});
					},
					error: (error) => {
						console.log(error);
						setLoadingTable(false);
					},
				});
		}

	}, [key, pageCurrent, filterData]);

	const statusMap = {
		1: <Tag color="grey">准备中</Tag>,
		2: <Tag color="purple">处理中</Tag>,
		3: <Tag color="blue">投递中</Tag>,
		8: <Tag color="#52c41a">成功</Tag>,
		9: <Tag color="red">失败</Tag>,
	};

	const emailTypeMap = {
		1: <Tag color="green">只发公邮</Tag>,
		2: <Tag color="green">只发私邮</Tag>,
		3: <Tag color="green">全都发</Tag>,
	}

	const getTitleText = () => {
		let ui = "";
		if (data && data.subjects && data.subjects.length > 0) {
			ui = data.subjects.map(item => item.value).join("<br/>")
		}

		return ui;

	}

	const dataSource = [
		{ key: "总数量", value: statistics && statistics.count && statistics.count.all },
		{ key: "已发数", value: statistics && statistics.count && statistics.count.done },
		{ key: "已发率", value: `${(statistics && statistics.percent && statistics.percent.done / 100)} %` },
		{ key: "送达数", value: statistics && statistics.count && statistics.count.sent },
		{ key: "送达率", value: `${(statistics && statistics.percent && statistics.percent.sent / 100)} %` },
		{ key: "打开数", value: statistics && statistics.count && statistics.count.open },
		{ key: "打开率", value: `${(statistics && statistics.percent && statistics.percent.open / 100)} %` },
		{ key: "点击数", value: statistics && statistics.count && statistics.count.click },
		{ key: "点击率", value: `${(statistics && statistics.percent && statistics.percent.click / 100)} %` },
		{ key: "打开点击率", value: `${(statistics && statistics.percent && statistics.percent.click_when_open / 100)} %` },
		{ key: "App", value: app },
		{ key: "Key", value: key },
		{ key: "状态", value: statusMap[status] },
		{ key: "创建人", value: user },
		{ key: "发件箱", value: `${data && data.sender && data.sender.name}<${data && data.sender && data.sender.user}@...>` },
		{ key: "创建时间", value: getDateCN(time_create) },
		{ key: "Cover ID", value: result && result.cover && result.cover.cover_id },
		{ key: "群发标题", value: getTitleText() },
		{ key: "发送参数", value: data && data.options && emailTypeMap[data.options.email_type] },
	];

	const inputOptions = [
		{ key: "email", placeholder: "搜索邮箱" },
	];

	const formatData = dataSource.map((item) => {
		return {
			...item,
			value: item.value,
		};
	});

	// const itemsDropdown = [
	// 	{
	// 		label: status === 1 ? "禁用":"启用",
	// 		key: 'edit',
	// 	},
	// 	membership_type === "FREE"?
	// 	{
	// 		label: "设为会员",
	// 		key: 'set',
	// 	}:null
	// ];

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	const items = [
		{
			label: "查看详情",
			key: "view_detail",
		},
	];

	const columns = [
		{
			title: "Email",
			dataIndex: "email",
		},
		{
			title: "点击时间",
			dataIndex: "time_click",
		},
		{
			title: "操作",
			render: (_, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Dropdown
						menu={{
							items,
							onClick: (item) => {
								if (item.key = "view_detail") {
									setOpenDetailModal(true);
									setCurrentId(record.id);
								}
							},
						}}
					>
						<a>
							<Space>
								<EllipsisOutlined style={{ fontSize: 24 }} />
							</Space>
						</a>
					</Dropdown>
				</div>
			),
		},
	];

	const columnsEmailTitle = [
		{
			title: "邮件标题",
			dataIndex: "subject",
		},
		{
			title: "打开次数",
			dataIndex: "open",
			render: (_, record) => (
				<>{record.open ? record.open : 0}</>
			)
		},
		{
			title: "点击次数",
			dataIndex: "click",
			render: (_, record) => (
				<>{record.click ? record.click : 0}</>
			)
		},
	];

	return (
		<>
			<Row align="middle" justify="space-between" style={{ marginBottom: "25px" }}>
				<div>
					<h2>
						任务详情 - {!name ? <Spin /> : name}
					</h2>
				</div>
				{/* <div>
					<Dropdown
						menu={{
							items: itemsDropdown,
							onClick: (item) => {
								if (item.key === "edit") {
									modal.confirm({
										title:"设置状态",
										content:`确定${status === 1 ? "禁用":"启用"}吗？`,
										onOk:() =>{
											if(status === 1){
												fetchSuspend({
													app:"insight",
													key:key
												}, {
													success: (data) => {
														if(onHandleUpdateInfo){
															onHandleUpdateInfo();
														}
														notification.success({ message: "禁用成功" });
													},
													error: (error) => {
														notification.error({ message: langError(error.code) });
													},
												});
											}else{
												fetchUnSuspend({
													app:"insight",
													key:key
												}, {
													success: (data) => {
														if(onHandleUpdateInfo){
															onHandleUpdateInfo();
														}
														notification.success({ message: "启用成功" });
													},
													error: (error) => {
														notification.error({ message: langError(error.code) });
													},
												});
											}
											
										}
									})
								} else if (item.key === "set") {
									setOpenSettingModal(true);
								}
							},
						}}
						trigger={['click']}
						placement="bottomRight"
					>
						<Button type="primary">操作 <DownOutlined /></Button>
					</Dropdown>
				</div> */}
			</Row>
			<Card title="基础信息" loading={loading}>
				<Descriptions
					bordered
					layout="horizontal"
					labelStyle={{ fontWeight: "bold" }}
				>
					{formatData.map((item, index) => (
						<Descriptions.Item label={item.key} key={index}>
							{item.key === "群发标题" ? <span dangerouslySetInnerHTML={{ __html: item.value }}></span> : item.value}
						</Descriptions.Item>
					))}
				</Descriptions>
			</Card>
			<Card title="群发统计" style={{ marginTop: 20 }}>
				<Space size="middle" direction="vertical" style={{ display: "flex" }}>
					<Row justify="space-between" style={{ marginBottom: "10px" }}>
						<Typography.Text>{statistics && statistics.subjects && statistics.subjects.length} 条结果</Typography.Text>
					</Row>
				</Space>
				<Table
					columns={columnsEmailTitle}
					dataSource={statistics && statistics.subjects}
					pagination={false}
					rowKey={record => record.subject}
				/>
			</Card>

			<Card title="有点击行为数据" style={{ marginTop: 20 }}>
				<LayoutNavForms
					inputOptions={inputOptions}
					onHandleChangeFilter={handleChangeFilter}
				/>
				<Space size="middle" direction="vertical" style={{ display: "flex" }}>
					<Row justify="space-between" style={{ marginBottom: "10px" }}>
						<Typography.Text>{dataTotal} 条结果</Typography.Text>
					</Row>
				</Space>
				<Table
					loading={loadingTable}
					columns={columns}
					dataSource={listData}
					pagination={{
						current: pageCurrent,
						pageSize: pageSize,
						total: dataTotal,
						showSizeChanger: false,
						showQuickJumper:true,
						onChange: handleChangePage,
					}}
				/>
				{
					openDetailModal && 
					<ModalEmailDetail
						open={openDetailModal}
						currentId={currentId}
						onCancel={() => {setOpenDetailModal(false);setCurrentId(null)}} 
					/>
				}
			</Card>
		</>
	);
};
