import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, theme } from "antd";
import { ApartmentOutlined, LeftOutlined, RightOutlined, TeamOutlined, DollarOutlined, MessageOutlined, MenuOutlined, ProfileOutlined, BarcodeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const labelItem = [
	{
		label: "用户列表",
		key: "bem/user",
		icon: <TeamOutlined />
	},
	{
		label: "Billings",
		key: "bem/billings",
		icon: <DollarOutlined />
	},
	{
		label: "Package",
		key: "bem/package",
		icon: <ProfileOutlined />
	},
	{
		label: "Package组合规则",
		key: "bem/packagecombines",
		icon: <ApartmentOutlined />
	},
	{
		label: "Redeemcode",
		key: "bem/redeemcode",
		icon: <BarcodeOutlined />
	},
	{
		label: "Feedback",
		key: "bem/feedback",
		icon: <MessageOutlined />
	},
	{
		label: "Logs",
		key: "bem/log",
		icon: <MenuOutlined />
	}
];

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type
	};
}

export const LayoutPageSidebar = ({ selectedKey }) => {
	const [collapsed, setCollapsed] = useState(false);
	const user = useSelector((state) => state.user);
	const { permissions } = user;

	const navigate = useNavigate();
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	const getLabelItems = () => {
		let labelArr = [];
		labelItem.map(item => {
			if (permissions.bem && permissions.bem.length > 0) {
				permissions.bem.map(bem => {
					if (bem === item.key) {
						return labelArr.push(getItem(item.label, item.key, item.icon));
					}
				})
			}
		})
		return labelArr;
	}

	const items = [getItem('插件矩阵管理后台', 'bem', null,
		getLabelItems(),
		'group')];

	return (
		<Sider
			trigger={
				<div style={{ background: "#ededed", color: "#000" }}>
					{collapsed ? <RightOutlined /> : <LeftOutlined />}
				</div>
			}
			collapsible
			collapsed={collapsed}
			onCollapse={(value) => { setCollapsed(value) }}
			style={{
				background: colorBgContainer,
				overflow: 'auto',
				height: 'calc(100vh - 98px)',
				position: 'fixed',
				left: 0,
				top: 50,
				bottom: 0,
			}}
		>
			<Menu
				mode="inline"
				defaultSelectedKeys={selectedKey}
				defaultOpenKeys={["sub1"]}
				onClick={({ key }) => {
					navigate(`/${key}`);
				}}
				style={{
					height: "100%",
					borderRight: 0,
				}}
				items={items}
			/>
		</Sider>
	);
};

