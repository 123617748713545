import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableInvoiceList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageLarkInvoice = () => {

	usePageTitle("Invoice列表 - Lark插件管理后台");

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["lark/invoice"]} />
					<LayoutPageContent>
						<TableInvoiceList />
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageLarkInvoice;
