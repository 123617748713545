import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Skeleton,
	Button,
} from "antd";
import { useFetchGetMsSystemLogList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalResponse } from "components";

export const TableSystemLogList = ({filterData}) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [currentRow, setCurrentRow] = useState({});
	const [currentModal, setCurrentModal] = useState("");

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetMsSystemLogList] = useFetchGetMsSystemLogList();

	const columns = [
		{
			title: "用户名",
			dataIndex: "name",
			width:150,
			render: (name, info) => (
				<Link
					target="_blank"
					to={`/ms/user/detail?uid=${info.uid}`}
				>
					{name}
				</Link>
			),
		},
		{
			title: "Method",
			dataIndex: "request_method",
			width:100
		},
		{
			title: "Url",
			dataIndex: "url",
		},
		{
			title: "请求参数",
			dataIndex: "request",
			ellipsis: true,
			render:(_,record) =>(
				<a onClick={()=> {setOpenModal(true);setCurrentRow(record);setCurrentModal("request")}}>{record.request}</a>
			)
		},
		{
			title: "Response Code",
			dataIndex: "response_code",
			width:150
		},
		{
			title: "IP",
			dataIndex: "ip",
			width:150
		},
		{
			title: "Response",
			dataIndex: "response",
			ellipsis: true,
			render:(_,record) =>(
				<a onClick={()=> {setOpenModal(true);setCurrentRow(record);setCurrentModal("response")}}>{record.response}</a>
			)
		},
		{
			title: "创建时间",
			dataIndex: "time",
			width: 200,
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMsSystemLogList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								time: getDateCN(item.time),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					{/* <Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space> */}
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={(record) =>record.id}
					/>
					<ModalResponse
						open={openModal}
						title={currentModal === "response" ? "response详情":"request详情"}
						onCancel={()=>{setOpenModal(false);setCurrentRow({});setCurrentModal("")}}
						data={currentModal === "response" ? (currentRow?.response):(currentRow?.request)}
					/>
				</>
			)}
		</Fragment>
	);
};
