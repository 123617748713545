import { useSelector } from "react-redux";
import { fetchSystemAPIWithAuth } from "tools";

export const useFetchGetAppListPermissions = () => {
	const { auth } = useSelector((state) => state.initial);

	const fetch = (data, opts) => {
		const options = Object.assign(
			{
				success: null,
				error: null,
			},
			opts
		);
		return fetchSystemAPIWithAuth(auth, "/sa/app/permissions", {
			method: "post",
			data: data,
			success: (res) => {
				if (options["success"]) {
					options["success"](res);
				}
			},
			error: (error) => {
				if (options["error"]) {
					options["error"](error);
				}
			},
		});
	};
	return [fetch];
};
