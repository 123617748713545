import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, Select } from 'antd';
import { lang, consoleLog, langError } from 'tools';
import { useFetchUserSettingStatus } from "hooks";
import { useSelector } from 'react-redux';

export const ModalSetUserStatus = ({ open, userInfo, currentId, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const user = useSelector((state) => state.user);
	const { name } = user?.info;
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

    const [fetchSave] = useFetchUserSettingStatus();

    useEffect(()=>{
        if(userInfo){
            form.setFieldsValue({
                status: userInfo.status?userInfo.status:0,
            });
        }
    },[userInfo]);

    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                status: values.status,
                admin: name,
                uid: currentId
            }, {
                success: (data) => {
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    setLoadingSave(false);
                    onCancel();
                    notification.success({"message":"操作成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }
    return (
        <Modal
            destroyOnClose
            open={open}
            title={"设置用户状态"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="status"
                    label={"用户状态"}
                >
                   <Select placeholder="请选择用户状态">
                        <Select.Option value="1">未激活</Select.Option>
                        <Select.Option value="2">已激活</Select.Option>
                        <Select.Option value="8">待审核</Select.Option>
                        <Select.Option value="9">邀请待激活</Select.Option>
                        <Select.Option value="11">已暂停</Select.Option>
                        <Select.Option value="19">已禁用</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
