import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Input, App, Select } from "antd";
import { useFetchDaemonAddConfig, useFetchDaemonEditConfig } from "hooks";
import { langError } from "tools";

export const ModalAddDaemon = ({ open, scope, currentDaemon, onCancel, onHandleUpdateList }) => {
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [fetchAddConfig] = useFetchDaemonAddConfig();
	const [fetchEditConfig] = useFetchDaemonEditConfig();

	const [form] = Form.useForm();

	useEffect(()=>{
		if(currentDaemon.key){
			form.setFieldsValue({key: currentDaemon.key});
			form.setFieldsValue({url: currentDaemon.url});
			form.setFieldsValue({sleep: currentDaemon.sleep});
			form.setFieldsValue({timeout: currentDaemon.timeout});
			form.setFieldsValue({pause: currentDaemon.pause});
			form.setFieldsValue({remark: currentDaemon.remark});
		}
	},[currentDaemon, form]);

	const handleAddConfig = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				//编辑情况
				if(currentDaemon.key){
					let formData = {};
					formData.key = values.key;
					formData.scope = scope;
					formData.data = {
						url : values.url,
						sleep: values.sleep,
						timeout: values.timeout,
						pause: values.pause,
						remark: values.remark,
					}
					formData.key = values.key;
					fetchEditConfig(formData, {
						success: (data) => {
							setLoading(false);
							onCancel();
							notification.success({ message: "修改成功" });
							if (onHandleUpdateList) {
								onHandleUpdateList();
							}
						},
						error: (error) => {
							setLoading(false);
							onCancel();
							notification.error({ message: langError(error.code) });
						},
					});
				}else{
					fetchAddConfig({ ...values, scope }, {
						success: (data) => {
							setLoading(false);
							onCancel();
							notification.success({ message: "添加成功" });
							if (onHandleUpdateList) {
								onHandleUpdateList();
							}
						},
						error: (error) => {
							setLoading(false);
							onCancel();
							notification.error({ message: langError(error.code) });
						},
					});
				}
			})
			.catch((info) => { });
	};

	return (
		<Modal
			title={currentDaemon.key ? "编辑配置" : "添加配置"}
			width={500}
			open={open}
			onCancel={onCancel}
			destroyOnClose={true}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					取消
				</Button>,
				<Button
					key="add"
					type="primary"
					loading={loading}
					onClick={handleAddConfig}
				>
					确定
				</Button>,
			]}
		>
			<Form
				name="basic"
				form={form}
				layout="vertical"
				style={{ margin: "30px 0" }}
			>

				<Form.Item
					label="配置文件名"
					name="key"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写配置文件名",
						},
					]}
				>
					<Input disabled={currentDaemon.key} />
				</Form.Item>
				<Form.Item
					label="URL"
					name="url"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写URL",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Sleep"
					name="sleep"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写Sleep",
						},
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label="Timeout"
					name="timeout"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写Timeout",
						},
					]}
				>
					<Input />
				</Form.Item>
				{
					currentDaemon.key ?
						<Form.Item
							label="状态"
							name="pause"
							style={{ margin: "10px 0" }}
						>
							<Select placeholder="请选择是否暂停">
								<Select.Option key={0} value={0}>在线</Select.Option>
								<Select.Option key={1} value={1}>暂停</Select.Option>
							</Select>
						</Form.Item> : null
				}

				<Form.Item
					label="Remark"
					name="remark"
					style={{ margin: "10px 0" }}
				>
					<Input />
				</Form.Item>

			</Form>
		</Modal>
	);
};
