import { Fragment } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TablePackageList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageRiskifyPackage = () => {

	usePageTitle("Package - Riskify插件管理后台");

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["riskify/package"]} />
					<LayoutPageContent>
						<Fragment>
							<TablePackageList filterData={{}} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageRiskifyPackage;
