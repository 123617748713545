import { Fragment, useEffect, useState } from "react";
import { Layout, App } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableBillingList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageBemBillings = () => {
	const { notification } = App.useApp();
	const [filterData, setFilterData] = useState({});

	usePageTitle("Billings - 插件矩阵管理后台");

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["bem/billings"]} />
					<LayoutPageContent>
						<Fragment>
							{/* <LayoutNavForms
								selectOptions={selectOptions}
								datePickerKey={"time"}
								inputOptions={inputOptions}
								onHandleChangeFilter={handleChangeFilter}
								initialValues={{email: filterData.email}}
							/> */}
							<TableBillingList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageBemBillings;
