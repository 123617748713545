import { useSelector } from "react-redux";
import { fetchSystemAPIWithAuth } from "tools";

export const useFetchVisitorDetail = () => {
    const { auth } = useSelector((state) => state.initial);
    const fetch = (data, opts) => {
        const options = Object.assign(
            {
                success: null,
                error: null,
            },
            opts
        );

        return fetchSystemAPIWithAuth(auth, "/ad-system/visitor/info", {
            method: "post",
            data: data,
            success: (res) => {
                if (options["success"]) {
                    options["success"](res);
                }
            },
            error: (error) => {
                if (options["error"]) {
                    options["error"](error);
                }
            },
        });
    };
    return [fetch];
};
