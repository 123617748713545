import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Skeleton,
	Button,
	Tag
} from "antd";
import { useFetchGetMsTimelineList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalAddTimeline } from "./ModalAddTimeline";

export const TableTimelineList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);
	const [openTimeline, setOpenTimeline] = useState(false);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetMsTimelineList] = useFetchGetMsTimelineList();

	const typeMap = {
		1: <Tag color="blue">Regular</Tag>,
		2: <Tag color="green">Special</Tag>,
		9: <Tag color="red">Milestone</Tag>,
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			width: 100,
		},
		{
			title: "APP",
			dataIndex: "project",
		},
		{
			title: "Type",
			dataIndex: "type",
		},
		{
			title: "内容",
			dataIndex: "intro",
			width : 800
		},
		{
			title: "创建时间",
			dataIndex: "time_created",
			render: (_, record) => (
				<span>{getDateCN(record.time)}</span>
			)
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMsTimelineList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								type: typeMap[item.type],
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={()=> setOpenTimeline(true)}>新增Timeline</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={(item) => item.id}
					/>
					<ModalAddTimeline 
						open={openTimeline}
						onCancel={() => {setOpenTimeline(false)}}
					/>
				</>
			)}
		</Fragment>
	);
};
