import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Button } from "antd";
import { useFetchGetDataEstimateList } from "hooks";
import { langError, getDateCN, jsonEncode } from "tools";
import { ModalCreateRecord } from "./ModalCreateRecord";

export const TableList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [openRecord, setOpenRecord] = useState(false);
	const [forceList, setForceList] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);
	
	const [fetchGetDataEstimateList] = useFetchGetDataEstimateList();

	const statusMap = {
		1: <Tag color="orange">待处理</Tag>,
		8: <Tag color="green">成功</Tag>,
		9: <Tag color="red">失败</Tag>,
	};

	const columns = [
		{
			title: "客户名",
			dataIndex: "customer",

		},
		{
			title: "标题",
			dataIndex: "subject",
		},
		{
			title: "状态",
			dataIndex: "status",
		},
		{
			title: "创建时间",
			dataIndex: "time_create",
		}
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetDataEstimateList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								key:item.id,
								status: statusMap[item.status],
								time_create: getDateCN(item.time_create),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={() => setOpenRecord(true)}>创建</Button>
						</Row>
					</Space>
					
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
					{
						openRecord && 
						<ModalCreateRecord
							open={openRecord} 
							onCancel={() => setOpenRecord(false)}
							onUpdateList={() => setForceList(forceList => forceList + 1)}
						/>
					}
				</>
			)}
		</Fragment>
	);
};
