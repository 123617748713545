import React, { useEffect, useState } from "react";
import { Button, Form, Modal, App } from "antd";
import { useFetchEditAppListPermissions } from "hooks";

import { TreeSelect } from 'antd';
import { langError } from "tools";
const { SHOW_PARENT } = TreeSelect;

export const ModalEditApp = ({ open, onCancel, currentUser, appList, onUpdateList }) => {
    const { notification } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [form] = Form.useForm();
    const [fetchEditApp] = useFetchEditAppListPermissions();

    useEffect(()=>{
        if(currentUser){
            if(currentUser.permissions){
                form.setFieldsValue({app_names:currentUser.permissions});
            }
        }
    },[currentUser,form]);

    const handleOK = () => {
        form
            .validateFields()
            .then((values) => {
                setLoading(true);
                fetchEditApp({
                    permission: selectedValues,
                    obj:currentUser.id,
                    access:"allow"
                }, {
                    success: (data) => {
                        setLoading(false);
                        if(onUpdateList){
                            onUpdateList();
                        }
                        onCancel();
                        notification.success({ message: "设置成功！" });
                    },
                    error: (error) => {
                        setLoading(false);
                        onCancel();
                        notification.error({ message: langError(error.code) });
                    },
                });
            })
            .catch((info) => { });
    };

    const onChange = (newValue) => {
        setSelectedValues(newValue);
    };

    const tProps = {
        treeData:appList,
        value:currentUser.permissions,
        onChange,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: '请选择App',
        style: {
            width: '100%',
        },
    };

    return (
        <Modal
            title="分配App"
            width={500}
            open={open}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={[
                <Button key="add" onClick={onCancel}>
                    取消
                </Button>,
                <Button
                    key="cancel"
                    type="primary"
                    loading={loading}
                    onClick={handleOK}
                >
                    确定
                </Button>,
            ]}
        >
            <Form
                name="edit_app_form"
                form={form}
                layout="vertical"
                style={{ margin: "30px 0" }}
            >
                <Form.Item
                    label="App名称"
                    name="app_names"
                    style={{ margin: "10px 0" }}
                    rules={[
                        {
                            required: true,
                            message: "请填写App名称",
                        },
                    ]}
                >
                    <TreeSelect {...tProps} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
