import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import {
  App,
  Button,
  Row,
  Space,
  Table,
  Typography,
  Dropdown,
  Tag,
  Skeleton,
  Tooltip,
} from "antd";
import { useFetchGetMsPromocodeList } from "hooks";
import { langError, getDateCN } from "tools";

export const TableCleanseList = () => {
  const user = useSelector((state) => state.user);
  const { id } = user?.info;

  const { notification } = App.useApp();

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  
  const [dataTotal, setDataTotal] = useState(0);
  const [fetchGetMsPromocodeList] = useFetchGetMsPromocodeList();

  const statusMap = {
    1: <Tag color="#87d068">已启用</Tag>,
    2: <Tag color="grey">未启用</Tag>,
  };
  const peopleFilterMap = {
    1: <Tag color="#87d068">已启用</Tag>,
    2: <Tag color="grey">未启用</Tag>,
  };

  const VerifiedMap = {
    1: <Tag color="grey">未验证</Tag>,
    2: <Tag color="blue">验证中</Tag>,
    3: <Tag color="#87d068">验证通过</Tag>,
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 100,
    },
    {
      title: "Code",
      dataIndex: "code",
      width: 200,
    },
    {
      title: "使用次数",
      dataIndex: "ip",
      width: 200,
    },
    {
      title: "Content",
      dataIndex: "data",
      ellipsis: true,
      width: 500,
      render: (_, record) => (
        <Tooltip title={record.data}>{record.data}</Tooltip>
      ),
    },
    {
      title: "过期时间",
      dataIndex: "time_expired",
    },
    {
      title: "创建时间",
      dataIndex: "time_created",
    },
  ];

  useEffect(() => {
    if (id) {
      setLoading(true);
      const params = { page: pageCurrent };
      fetchGetMsPromocodeList(params, {
        success: (data) => {
          setLoading(false);
          setDataTotal(data.page.total);
          setPageSize(data.page.limit);
          setListData(() => {
            return data.list.map((item) => {
              return {
                ...item,
                operate: item.id,
                ip: item.data.ip,
                content: item.data.content,
                device: item.data.agent,
                time_expired: getDateCN(item.time_expired),
                time_created: getDateCN(item.time_created),
              };
            });
          });
        },
        error: (error) => {
          setLoading(false);
          notification.error({ message: langError(error.code) });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageCurrent]);

  const handleChangePage = (page) => {
    setPageCurrent(page);
    window.scroll(0, 0);
  };

  return (
    <Fragment>
      {!id ? (
        <Skeleton active />
      ) : (
        <>
          <Space size="middle" direction="vertical" style={{ display: "flex" }}>
            <Row justify="space-between" style={{ marginBottom: "10px" }}>
              <Typography.Text>{dataTotal} 条结果</Typography.Text>
            </Row>
          </Space>
          <Table
            loading={loading}
            columns={columns}
            dataSource={listData}
            scroll={{ x: 1500 }}
            rowKey={(item) => item.id}
            pagination={{
              current: pageCurrent,
              pageSize: pageSize,
              total: dataTotal,
              showSizeChanger: false,
              onChange: handleChangePage,
            }}
          />
        </>
      )}
    </Fragment>
  );
};
