import { Fragment, useEffect, useState, version } from "react";
import { Layout, Modal, Form, Button, App, InputNumber, DatePicker, Select, Col, Row, Input } from 'antd';

import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { useFetchUseChatgpt } from "hooks";

import { lang, consoleLog, langError, getDateSimple } from 'tools';


const PageMsChat = () => {
	const { notification, modal } = App.useApp();

	const [filterData, setFilterData] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

	const [fetchSave] = useFetchUseChatgpt();

	const [form] = Form.useForm();

	usePageTitle("Chat - AroundDeal管理后台");

	const handleSave = () => {
		form
			.validateFields()
			.then(values => {
				setLoadingSave(true);
				fetchSave({
					version: values.version,
					question: values.question,
				}, {
					success: (data) => {
						setLoadingSave(false);
						// notification.success({ "message": "回答成功" });
						modal.success(({
							title: "上线成功",
							content: data.answer
						}));
					},
					error: (error) => {
						setLoadingSave(false);
						notification.error({ 'message': langError(error.code) });
					},
				});
			})
			.catch(info => {
				consoleLog('Validate Failed:', info);
			});
	}

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout hasSider>
					<LayoutPageSidebar selectedKey={["ms/chat"]} />
					<LayoutPageContent>
						<Fragment>
							<Form
								form={form}
								layout="vertical"
								autoComplete='off'
							>
								<Row>

									<Col span="6">
										<Form.Item
											name="version"
											label="Chatgpt Version"
											initialValue="3.5"
										>
											<Select>
												<Select.Option value="3.5">3.5</Select.Option>
												<Select.Option value="4.0">4.0</Select.Option>
											</Select>
										</Form.Item>
									</Col>
								</Row>

								<Row>

									<Col span="12">
										<Form.Item
											name="question"
											label={"问题内容"}
										>
											<Input.TextArea placeholder='请输入...' style={{ width: "100%" }} />
										</Form.Item>
									</Col>
								</Row>

							</Form>
							<Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>

						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsChat;
