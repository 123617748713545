import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Layout, Space, Card } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { CardBasicInfo, TableCriteriaList } from "./components";
import { useFetchGetDataMissionDetail } from "hooks";

const PageDataMissionDetails = () => {
    const [loading, setLoading] = useState(false);

    usePageTitle("手动交付任务详情 - 数据管理后台");

    const [search] = useSearchParams();
    const navigate = useNavigate();
    const id = search.get("id");

    const [fetchGetDataMissionDetail] = useFetchGetDataMissionDetail();
    const [taskInfo, setTaskInfo] = useState(null);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [forceFetch, setForceFetch] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetchGetDataMissionDetail({
            id,
            page: pageCurrent
        },
            {
                success: (data) => {
                    setTaskInfo(data);
                    setLoading(false);
                },
                error: (error) => {
                    console.log(error);
                    setLoading(false);
                },
            });
    }, [id, search, pageCurrent, forceFetch]);

    const handleChangeTab = key => {
        let url = `&filter_type=${key}`;
        navigate(`/ms/user/detail?uid=${id}${url}`)
    }

    const handleChangePage = (page) => {
        setPageCurrent(page);
        window.scroll(0, 0);
    };

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            <CardBasicInfo loading={loading} taskInfo={taskInfo} currentId={id} onHandleUpdateInfo={() =>setForceFetch(forceFetch => forceFetch +1)} />
                            {/* <CardListInfo loading={loading} listData={listData} activeKey={filter_type ? filter_type : "log"} pageCurrent={pageCurrent} dataTotal={dataTotal} pageSize={pageSize} onHandleChangeTab={handleChangeTab} onHandleChangePage={handleChangePage} /> */}
                            <Card title="Criteria 列表" loading={loading}>
                                <TableCriteriaList filterData={{"id_mission": id}} />
                            </Card>
                            <Card title="Task 列表" loading={loading}>
                                {/* <TableTaskList filterData={{"id_mission": id}} /> */}
                            </Card>
                        </Space>
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageDataMissionDetails;
