import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  App,
  Row,
  Space,
  Table,
  Typography,
  Skeleton,
} from "antd";
import { useFetchGetMsEnrichmentList } from "hooks";
import { langError, getDateCN, enrichmentStatusMap, enrichmentTypeMap } from "tools";

export const TableEnrichmentList = ({filterData}) => {
  const user = useSelector((state) => state.user);
  const { id } = user?.info;

  const { notification } = App.useApp();

  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [dataTotal, setDataTotal] = useState(0);

  // useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

  const [fetchGetMsEnrichmentList] = useFetchGetMsEnrichmentList();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 300,
    },
    {
      title: "用户名",
      dataIndex: "user_name",
      render: (name, info) => (
        <Link
          target="_blank"
          to={`/ms/user/detail?uid=${info.uid}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "类型",
      dataIndex: "type",
    },
    {
      title: "进度",
      dataIndex: "percent",
    },
    {
      title: "Total",
      dataIndex: "total",
    },
    {
      title: "Success",
      dataIndex: "success",
    },
    {
      title: "Failed",
      dataIndex: "failed",
    },
    {
      title: "Committee",
      dataIndex: "bluk",
    },
    {
      title: "创建时间",
      dataIndex: "time_created",
    },
  ];

  useEffect(() => {
    if (id) {
      setLoading(true);
      const params = { page: pageCurrent, ...filterData };
      fetchGetMsEnrichmentList(params, {
        success: (data) => {
          setLoading(false);
          setDataTotal(data.page.total);
          setPageSize(data.page.limit);
          setListData(() => {
            return data.list.map((item) => {
              return {
                ...item,
                operate: item.id,
                type: enrichmentTypeMap[item.type],
                status: enrichmentStatusMap[item.status],
                total: item.result.all,
                success: item.result.success,
                failed: item.result.failed,
                bluk: item.result.bluk,
                percent: item.percent / 100 + "%",
                time_created: getDateCN(item.time_created),
              };
            });
          });
        },
        error: (error) => {
          setLoading(false);
          notification.error({ message: langError(error.code) });
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageCurrent, filterData]);

  const handleChangePage = (page) => {
    setPageCurrent(page);
    window.scroll(0, 0);
  };

  return (
    <Fragment>
      {!id ? (
        <Skeleton active />
      ) : (
        <>
          <Space size="middle" direction="vertical" style={{ display: "flex" }}>
            <Row justify="space-between" style={{ marginBottom: "10px" }}>
              <Typography.Text>{dataTotal} 条结果</Typography.Text>
            </Row>
          </Space>
          <Table
            loading={loading}
            columns={columns}
            dataSource={listData}
            scroll={{ x: 1500 }}
            pagination={{
              current: pageCurrent,
              pageSize: pageSize,
              total: dataTotal,
              showSizeChanger: false,
              onChange: handleChangePage,
            }}
          />
        </>
      )}
    </Fragment>
  );
};
