import React from "react";
import Chart from "react-apexcharts";

export const ChartEverydayActiveNumber = ({ activeUser = {} }) => {
  let data = [{ name: "Desktops", data: activeUser?.values || [] }];
  let options = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    stroke: {
      curve: "straight",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: activeUser?.keys || [],
    },
    title: {
      text: "每日活跃人数",
      align: "center",
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          // return numeral(value).format("0,0");
          return value;
        },
      },
    },
  };

  return (
    <Chart
      options={options}
      series={data}
      type="line"
      width="100%"
      height="350"
    />
  );
};
