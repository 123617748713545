import React, { useEffect, useState } from 'react';
import { Modal, Skeleton, App, Input } from 'antd';
import { useFetchGetRiskifyRedeemcodeList } from 'hooks';
import { langError } from 'tools';

export const ModalRedeemcode = ({ open, onCancel, pageCurrent, filterData, pageSize }) => {
    const { notification } = App.useApp();
    const [fieldCodeList, setfFieldCodeList] = useState([]);
    const [loading, setLoading] = useState(false);

    const [fetchGetRiskifyRedeemcodeList] = useFetchGetRiskifyRedeemcodeList();
    useEffect(() => {
        if (open) {
            setLoading(true);
            const params = { page: pageCurrent, ...filterData, app: "insight", pagesize: pageSize, field: "code" };
            fetchGetRiskifyRedeemcodeList(params, {
                success: (data) => {
                    setLoading(false);
                    setfFieldCodeList(data.code_list);
                },
                error: (error) => {
                    setLoading(false);
                    notification.error({ message: langError(error.code) });
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Modal
            destroyOnClose
            open={open}
            title={"Redeemcode列表"}
            onCancel={onCancel}
            width={500}
            footer={false}
        >
            {
                loading ? <Skeleton /> : 
                <Input.TextArea rows={20} style={{ overflow: "scroll" }} value={fieldCodeList.join("\n")} />
            }
        </Modal>
    );
};
