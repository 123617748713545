import { Fragment, useState } from "react";
import { Layout, App } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageDataProject = () => {
	const [filterData, setFilterData] = useState({});
	const [loading, setLoading] = useState(false);

	usePageTitle("数据交付项目 - 数据管理后台");


	const inputOptions = [
		{ key: "name", placeholder: "搜索用户名" },
	];

	const selectOptions = [
		{
			key: "type",
			placeholder: "选择分类",
			options: [
				{ value: "", label: "选择分类" },
				{ value: "reply", label: "回复" },
				{ value: "auto", label: "自动回复" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["data/project"]} />
					<LayoutPageContent>
						<Fragment>
							{/* <LayoutNavForms
								loading={loading}
								// selectOptions={selectOptions}
								// datePickerKey={"time"}
								// inputOptions={inputOptions}
								onHandleChangeFilter={handleChangeFilter}
							/> */}
							<TableList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageDataProject;
