import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, Select } from 'antd';
import { lang, consoleLog, langError } from 'tools';
import { useFetchUserSettingType } from "hooks";
import { useSelector } from 'react-redux';

export const ModalSetUserType = ({ open, userInfo, currentId, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const user = useSelector((state) => state.user);
	const { name } = user?.info;
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

    const [fetchSave] = useFetchUserSettingType();

    useEffect(()=>{
        if(userInfo){
            form.setFieldsValue({
                type: userInfo.type?userInfo.type:0,
            });
        }
    },[userInfo]);

    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                type: values.type,
                admin: name,
                uid: currentId
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"操作成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }
    return (
        <Modal
            destroyOnClose
            open={open}
            title={"设置用户类型"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="type"
                    label={"类型"}
                >
                   <Select placeholder="请选择用户类型">
                        <Select.Option value="1">普通账号</Select.Option>
                        <Select.Option value="7">试用账号</Select.Option>
                        <Select.Option value="11">内部账号</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
