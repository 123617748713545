import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Dropdown, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useFetchEDMTaskPause, useFetchEDMTaskStart, useFetchGetEDMTaskList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalAddTask } from "./ModalAddTask";
import { Link } from "react-router-dom";


export const TableTaskList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification, modal } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [currentRow, setCurrentRow] = useState({});
	const [openTask, setOpenTask] = useState(false);
	const [forceList, setForceList] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetEDMTaskList] = useFetchGetEDMTaskList();
	const [fetchStart] = useFetchEDMTaskStart();
	const [fetchPause] = useFetchEDMTaskPause();



	const items = [
		{
			label: "启动",
			key: "start",
		},
		{
			label: "暂停",
			key: "pause",
		},
	];

	const statusMap = {
		1: <Tag color="grey">准备中</Tag>,
		2: <Tag color="purple">处理中</Tag>,
		3: <Tag color="blue">投递中</Tag>,
		8: <Tag color="#52c41a">成功</Tag>,
		9: <Tag color="red">失败</Tag>,
	};

	const onTaskStart = key => {
		modal.confirm({
			title: "确认提示",
			content: "是否启动该任务？",
			onOk() {
				return new Promise((resolve, reject) => {
					fetchStart({
						key: key
					},
						{
							success: (data) => {
								setForceList(forceList => forceList + 1);
								notification.success({ message: "操作成功！" });
							},
							error: (error) => {
								notification.error({ message: error.code });
							},
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
				}).catch(() => console.log('Oops errors!'));
			},
		});
	}

	const onTaskPause = key => {
		modal.confirm({
			title: "确认提示",
			content: "是否暂停该任务？",
			onOk() {
				return new Promise((resolve, reject) => {
					fetchPause({
						key: key
					},
						{
							success: (data) => {
								setForceList(forceList => forceList + 1);
								notification.success({ message: "操作成功！" });
							},
							error: (error) => {
								notification.error({ message: error.code });
							},
						});
					setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
				}).catch(() => console.log('Oops errors!'));
			},
		});
	}

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "Key",
			dataIndex: "key",
		},
		{
			title: "任务名称",
			dataIndex: "name",
			render:(_, record) =>(
				<Link to={`/edm/task/detail?uid=${record.key}`} target="_blank">{record.name}</Link>
			)
		},
		{
			title: "所属人",
			dataIndex: "user",
		},
		{
			title: "状态",
			dataIndex: "status",
		},
		{
			title: "总数",
			dataIndex: "all",
		},
		{
			title: "送达数",
			dataIndex: "sent",
		},
		{
			title: "打开数",
			dataIndex: "open",
		},
		{
			title: "点击数",
			dataIndex: "click",
		},

		{
			title: "创建时间",
			dataIndex: "time_create",
		},
		{
			title: "操作",
			dataIndex: "operate",
			width: 80,
			render: (_, record) => (
				<Dropdown
					menu={{
						items,
						onClick: (item) => {
							setCurrentRow(record);
							if (item.key === "start") {
								onTaskStart(record.key);
							} else if (item.key === "pause") {
								onTaskPause(record.key);
							}
						},
					}}
				>
					<a onClick={(e) => e.stopPropagation()}>
						<Space>
							<EllipsisOutlined style={{ fontSize: 24 }} />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetEDMTaskList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								id: item.id,
								status: statusMap[item.status],
								all: item.statistics.count.all,
								sent: item.statistics.count.sent,
								open: item.statistics.count.open,
								click: item.statistics.count.click,
								time_create: getDateCN(item.time_create),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});


		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};


	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<div>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							{/* <Button type="primary" onClick={() => setOpenTask(true)}>提交群发需求</Button> */}
						</Row>
					</Space>

					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
					<ModalAddTask
						open={openTask}
						onCancel={() => { setOpenTask(false) }}
					/>
				</div>
			)}
		</Fragment>
	);
};
