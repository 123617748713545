import { Layout} from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableUserList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageLarkUser = () => {

	usePageTitle("User列表 - Lark插件管理后台");

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["lark/user"]} />
					<LayoutPageContent>
						<TableUserList />
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageLarkUser;
