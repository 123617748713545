import React from "react";
import { Layout,theme } from "antd";

export const LayoutPageContentWithBreadcrumb = ({ breadcrumb, content }) => {
	const {
		token: { colorBgContainer },
	} = theme.useToken();

	return (
		<Layout style={{ padding: "0 24px 24px 24px" }}>
            <div style={{ margin: "15px 0" , marginLeft: 200,}}>
                {breadcrumb}
            </div>
            <Layout.Content
                style={{
                    padding: 24,
                    margin: 0,
                    minHeight: 280,
                    marginLeft: 200,
                    background: colorBgContainer,
                }}
            >
                {content}
            </Layout.Content>
        </Layout>
	);
};
