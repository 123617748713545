import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, App, Breadcrumb, Row, Col, Button, Skeleton, Form, Input, Space } from "antd";
import { SaveOutlined, EyeOutlined } from "@ant-design/icons";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar } from "../components";
import { ModalPreview } from "./components";
import { useFetchEDMTemplateDetail, useFetchEDMCoverSave, useFetchEDMCoverDetail } from "hooks";
import usePageTitle from "hooks/usePageTitle";
import { langError } from "tools";
import { LayoutPageContentWithBreadcrumb } from "pages/ms/components";

window.ExternalPreviewPopup = (function() {
    var externalPreviewPopup;

    var close = function() {
        externalPreviewPopup.parentNode.remove();
        externalPreviewPopup = null;
    };

    var initPreviewPopup = function() {
        var div = document.createElement('div');
        div.innerHTML = '\
            <div id="externalPreviewPopup">\
                <div class="ant-modal-content">\
                    <button type="button" aria-label="Close" class="ant-modal-close"><span class="ant-modal-close-x"><span role="img" aria-label="close" class="anticon anticon-close ant-modal-close-icon"><svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></span></span></button>\
                    <div class="ant-modal-header"><div class="ant-modal-title" id=":rj:">发送测试邮件</div></div>\
                    <div id="content" style="padding: 15px;" class="preview-container-fluid">\
                       <div class="preview-row">\
                            <div class="preview-col-sm-8">\
                                <div class="esdev-desktop-device">\
                                    <div class="esdev-email-window-panel">\
                                        <div class="esdev-email-subject" style="min-height: 20px"></div>\
                                    </div>\
                                    <div class="esdev-desktop-device-screen">\
                                        <iframe id="iframeDesktop" frameborder="0" width="100%" height="642"></iframe>\
                                    </div>\
                                </div>\
                            </div>\
                            <div class="preview-col-sm-4 esdev-no-padding-left">\
                                <div class="esdev-mobile-device center-block">\
                                    <div class="esdev-mobile-device-screen">\
                                        <img src="mobile-view-top-bar.png" alt="">\
                                        <iframe id="iframeMobile" frameborder="0" width="100%" height="459"></iframe>\
                                        <img class="esdev-mail-bottom-bar" src="mobile-view-bottom-bar.png" alt="">\
                                    </div>\
                                </div>\
                            </div>\
                       </div>\
                    </div>\
                </div>\
            </div>';
        document.body.appendChild(div);

        externalPreviewPopup = document.getElementById('externalPreviewPopup');
        externalPreviewPopup.querySelector('.close').addEventListener('click', close);
    };

    var openPreviewPopup = function(html, ampHtml) {
        initPreviewPopup();
        // updateContent(html, ampHtml);
        externalPreviewPopup.style.visibility = 'visible';
    };

    

    return {
        openPreviewPopup: openPreviewPopup
    };
})();


const PageEDMNewsletterEdit = () => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const user = useSelector((state) => state.user);
    const { id } = user?.info;
    let [searchParams] = useSearchParams();
    const filterID = searchParams.get('id');
    const filterTemplateID = searchParams.get('template_id');

    const [saveLoading, setSaveLoading] = useState(false);
    const [contentLoading, setContentLoading] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [openPreview, setOpenPreview] = useState(false);
    const [iniTemplate, setIniTemplate] = useState({});

    usePageTitle("编辑Newsletter - EDM管理后台");
    const navigate = useNavigate();

    const [fetchEDMTemplateDetail] = useFetchEDMTemplateDetail();
    const [fetchGetCover] = useFetchEDMCoverDetail();
    const [fetchSaveCover] = useFetchEDMCoverSave();

    function loadDemoTemplate(callback) {
        if (filterID) {
            fetchGetCover({ id: filterID }, {
                success: (data) => {
                    callback({ html: data.data.html, css: data.data.css });
                    form.setFieldsValue({ name: data.name });
                },
                error: (error) => {
                    notification.error({ message: langError(error.code) });
                },
            });
        }else{
            if(filterTemplateID){
                fetchEDMTemplateDetail({ id: filterTemplateID }, {
                    success: (data) => {
                        callback({ html: data.data.html, css: data.data.css });
                        form.setFieldsValue({ name: data.name });
                    },
                    error: (error) => {
                        notification.error({ message: langError(error.code) });
                    },
                });
            }
        }

    }

    const iniStripo = (template) => {
        const script = document.createElement("script");
        script.id = "stripoScript";
        script.type = "text/javascript";
        script.src = "https://plugins.stripo.email/static/latest/stripo.js";
        script.onload = function () {
            window.Stripo.init({
                settingsId: "stripoSettingsContainer",
                previewId: "stripoPreviewContainer",
                html: template.html,
                css: template.css,
                apiRequestData: {
                    emailId: "mary@arounddeal.com"
                },
                ignoreClickOutsideSelectors: true,
                previewIframeAttributes: { foo: "bar", withoutValue: "" },
                notifications: {
                    info: (message) => { setMessageText(message) },
                    error: (message) => { setMessageText(message) },
                    success: (message) => { setMessageText(message) },
                    warn: (message) => { setMessageText(message) },
                    loader: (message) => { setMessageText(message) },
                    // hide:()=>{setMessageText(message)},
                },
                getAuthToken: function (callback) {
                    //put here get Auth Token logic
                    window
                        .fetch("https://plugins.stripo.email/api/v1/auth", {
                            method: "POST",
                            body: JSON.stringify({
                                pluginId: "d1b6c5c7a87f4a03812fe4a90ac79811",
                                secretKey: "2dc6aea33aa44d5cbb1c08ecd496ebf0",
                                role: "admin"
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                        .then((response) => response.json())
                        .then((body) => callback(body.token));
                },
                onTemplateLoaded: function () { setContentLoading(false); }
            });
        };
        document.body.appendChild(script);
    }

    const handlePreview = () =>{
        window.StripoApi.compileEmail(function (error, html, ampHtml) {
            setIniTemplate({html, ampHtml});
            setOpenPreview(true);
        });
    }

    const handleSave = () => {
        window.StripoApi.getTemplate(function (HTML, CSS) {
            form.validateFields()
                .then(values => {
                    let formData = {};
                    formData.name = values.name;
                    formData.type = "stripo";
                    formData.html = HTML;
                    formData.css = CSS;
                    setSaveLoading(true);
                    if (filterID) {
                        //编辑情况
                        formData.id_cover = filterID;
                        fetchSaveCover({ ...formData }, {
                            success: (data) => {
                                setSaveLoading(false);
                                notification.success({ message: "保存成功！" });
                                navigate("/edm/cover");
                            },
                            error: (error) => {
                                setSaveLoading(false);
                                notification.error({ message: langError(error.code) });
                            },
                        });
                    } else {
                        //创建情况
                        fetchSaveCover({ ...formData }, {
                            success: (data) => {
                                setSaveLoading(false);
                                notification.success({ message: "创建成功！" });
                                navigate("/edm/cover");
                            },
                            error: (error) => {
                                setSaveLoading(false);
                                notification.error({ message: langError(error.code) });
                            },
                        });
                    }
                })
                .catch(info => {
                    console.log('Validate Failed:', info);
                });
        });
    }

    useEffect(() => {
        setContentLoading(true);
        if (id) {
            loadDemoTemplate(iniStripo);
        }
        return () => { if (window.StripoApi) { window.StripoApi.stop() } };
    }, [id]);

    const breadcrumbItems = [
        {
            title: <a href="/edm/cover">Cover 列表</a>,
        },
        {
            title: '编辑Cover',
        },
    ]

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar selectedKey={["edm/cover"]} />
                    <LayoutPageContentWithBreadcrumb
                        breadcrumb={<Breadcrumb items={breadcrumbItems} />}
                        content={
                            <div>
                                <div>
                                    <Row style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Col span={18}>
                                            <Form
                                                form={form}
                                                layout="vertical"
                                            >
                                                <Form.Item
                                                    name="name"
                                                    label="Name"
                                                    rules={[
                                                        {
                                                            required: true,
                                                        },
                                                    ]}
                                                    style={{ width: "100%" }}
                                                >
                                                    <Input style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                        <Col span={6} style={{ textAlign: "right" }}>
                                            <Space>
                                                <Button loading={saveLoading} icon={<EyeOutlined />} type="primary" onClick={handlePreview}>预览</Button>
                                                <Button loading={saveLoading} icon={<SaveOutlined />} type="primary" onClick={handleSave}>保存</Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="notification-zone">{messageText}</div>
                                <Row style={{border:"1px solid #ccc"}}>
                                    <Col span={8}>
                                        <div id="stripoSettingsContainer"></div>
                                    </Col>
                                    <Col span={16}>
                                        <div id="stripoPreviewContainer"></div>
                                    </Col>
                                    {
                                        contentLoading ? <Skeleton style={{padding:30}} /> : null
                                    }
                                </Row>
                                {
                                    openPreview && 
                                        <ModalPreview 
                                            open={openPreview}
                                            onCancel={()=> setOpenPreview(false)}
                                            iniTemplate={iniTemplate}
                                        />
                                }
                            </div>
                        }
                    >
                    </LayoutPageContentWithBreadcrumb>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};
export default PageEDMNewsletterEdit;
