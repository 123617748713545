import React, { useEffect, useState } from 'react';
import { Modal, Button, Skeleton, Descriptions } from 'antd';
import { getDateCN } from 'tools';
import { useFetchEDMTaskClickDetail } from "hooks";

export const ModalEmailDetail = ({ open, currentId, onCancel }) => {

    const [emailData, setEmailData] = useState({});
    const [loading, setLoading] = useState(false);  // loading效果

    const [fetchTaskClickDetail] = useFetchEDMTaskClickDetail();

    useEffect(() => {
        if (currentId) {
            setLoading(true);
            const params = { id: currentId };
            fetchTaskClickDetail(params,
                {
                    success: (data) => {
                        setLoading(false);
                        setEmailData(data);
                    },
                    error: (error) => {
                        console.log(error);
                        setLoading(false);
                    },
                });
        }
    }, [currentId]);

    return (
        <Modal
            destroyOnClose
            open={open}
            title={"邮件详情"}
            onCancel={onCancel}
            width={800}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>关闭</Button>
                ]
            }
        >
            <div style={{ marginTop: 20 }}>
                {
                    loading ? <Skeleton active /> :
                        <Descriptions bordered column={1}>
                            <Descriptions.Item label="Subject">{emailData?.sent_data?.mail?.subject}</Descriptions.Item>
                            <Descriptions.Item label="Date">{emailData && emailData.time_sent ? getDateCN(emailData.time_sent) : "-"}</Descriptions.Item>
                            <Descriptions.Item label="Receiver">{emailData?.email}</Descriptions.Item>
                            <Descriptions.Item label="HTML" contentStyle={{ height: 400, overflow: "scroll", display: "block" }}>
                                <div dangerouslySetInnerHTML={{ __html: emailData?.sent_data?.mail?.html }}></div>
                            </Descriptions.Item>
                        </Descriptions>
                }
            </div>
        </Modal>
    );
};
