import { Fragment, useEffect, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableRedeemCodeList } from "./components";
import usePageTitle from "hooks/usePageTitle";
import { useFetchGetMsPartnerList } from "hooks";

const PageMsRedeemCode = () => {
	const [filterData, setFilterData] = useState({});
	usePageTitle("兑换码列表 - AroundDeal管理后台");
	const [loading, setLoading] = useState(false);

    const [partnerOptions, setPartnerOptions] = useState([]);
	const [fetchGetMsPartnerList] = useFetchGetMsPartnerList();

	useEffect(() => {
        setLoading(true);
        fetchGetMsPartnerList({}, {
            success: (data) => {
				setLoading(false);
                let newArr = []
                if (data && data.list.length > 0) {
                    newArr = data.list.map((item) => {
                        return {
                            value: item.id,
                            label: item.name
                        }
                    })
                }
                setPartnerOptions([{value:"", label:"请选择Partner"}, ...newArr]);
            },
            error: (error) => {
                setLoading(false);
            },
        });
    }, []);

	const inputOptions = [
		{ key: "code", placeholder: "搜索Code" },
		{ key: "uid", placeholder: "搜索uid" },
	];

	const selectOptions = [
		{
			placeholder: "请选择状态",
			key: "status",
			options: [
				{ value: "", label: "请选择状态" },
				{ value: 2, label: "已使用" },
				{ value: 1, label: "未使用" },
				{ value: 3, label: "已销毁" },
			],
		},
		{
			placeholder: "请选择Partner",
			key: "id_partner",
			options: partnerOptions,
			loading:loading
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/redeemcode"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableRedeemCodeList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsRedeemCode;
