
const texts = {
    "const_industry_799": "Abrasives and Nonmetallic Minerals Manufacturing",
    "const_industry_2190": "Accommodation Services",
    "const_industry_47": "Accounting",
    "const_industry_73": "Administration of Justice",
    "const_industry_1912": "Administrative and Support Services",
    "const_industry_80": "Advertising Services",
    "const_industry_709": "Agricultural Chemical Manufacturing",
    "const_industry_901": "Agriculture, Construction, Mining Machinery Manufacturing",
    "const_industry_2366": "Air, Water, and Waste Program Management",
    "const_industry_94": "Airlines and Aviation",
    "const_industry_120": "Alternative Dispute Resolution",
    "const_industry_125": "Alternative Medicine",
    "const_industry_2077": "Ambulance Services",
    "const_industry_2167": "Amusement Parks and Arcades",
    "const_industry_481": "Animal Feed Manufacturing",
    "const_industry_127": "Animation and Post-production",
    "const_industry_598": "Apparel Manufacturing",
    "const_industry_112": "Appliances, Electrical, and Electronics Manufacturing",
    "const_industry_852": "Architectural and Structural Metal Manufacturing",
    "const_industry_50": "Architecture and Planning",
    "const_industry_71": "Armed Forces",
    "const_industry_703": "Artificial Rubber and Synthetic Fiber Manufacturing",
    "const_industry_38": "Artists and Writers",
    "const_industry_973": "Audio and Video Equipment Manufacturing",
    "const_industry_147": "Automation Machinery Manufacturing",
    "const_industry_52": "Aviation and Aerospace Component Manufacturing",
    "const_industry_529": "Baked Goods Manufacturing",
    "const_industry_41": "Banking",
    "const_industry_2217": "Bars, Taverns, and Nightclubs",
    "const_industry_2197": "Bed-and-Breakfasts, Hostels, Homestays",
    "const_industry_142": "Beverage Manufacturing",
    "const_industry_390": "Biomass Electric Power Generation",
    "const_industry_3134": "Blockchain Services",
    "const_industry_3125": "Blogs",
    "const_industry_861": "Boilers, Tanks, and Shipping Container Manufacturing",
    "const_industry_82": "Book and Periodical Publishing",
    "const_industry_1602": "Book Publishing",
    "const_industry_562": "Breweries",
    "const_industry_36": "Broadcast Media Production and Distribution",
    "const_industry_406": "Building Construction",
    "const_industry_453": "Building Equipment Contractors",
    "const_industry_460": "Building Finishing Contractors",
    "const_industry_436": "Building Structure and Exterior Contractors",
    "const_industry_11": "Business Consulting and Services",
    "const_industry_3129": "Business Content",
    "const_industry_3128": "Business Intelligence Platforms",
    "const_industry_1641": "Cable and Satellite Programming",
    "const_industry_129": "Capital Markets",
    "const_industry_2212": "Caterers",
    "const_industry_54": "Chemical Manufacturing",
    "const_industry_690": "Chemical Raw Materials Manufacturing",
    "const_industry_2128": "Child Day Care Services",
    "const_industry_2048": "Chiropractors",
    "const_industry_2139": "Circuses and Magic Shows",
    "const_industry_90": "Civic and Social Organizations",
    "const_industry_51": "Civil Engineering",
    "const_industry_1738": "Claims Adjusting, Actuarial Services",
    "const_industry_773": "Clay and Refractory Products Manufacturing",
    "const_industry_341": "Coal Mining",
    "const_industry_1938": "Collection Agencies",
    "const_industry_1798": "Commercial and Industrial Equipment Rental",
    "const_industry_2247": "Commercial and Industrial Machinery Maintenance",
    "const_industry_918": "Commercial and Service Industry Machinery Manufacturing",
    "const_industry_964": "Communications Equipment Manufacturing",
    "const_industry_2374": "Community Development and Urban Planning",
    "const_industry_2115": "Community Services",
    "const_industry_118": "Computer and Network Security",
    "const_industry_109": "Computer Games",
    "const_industry_3": "Computer Hardware Manufacturing",
    "const_industry_5": "Computer Networking Products",
    "const_industry_24": "Computers and Electronics Manufacturing",
    "const_industry_2368": "Conservation Programs",
    "const_industry_48": "Construction",
    "const_industry_871": "Construction Hardware Manufacturing",
    "const_industry_1786": "Consumer Goods Rental",
    "const_industry_91": "Consumer Services",
    "const_industry_3068": "Correctional Institutions",
    "const_industry_2019": "Cosmetology and Barber Schools",
    "const_industry_3065": "Courts of Law",
    "const_industry_1673": "Credit Intermediation",
    "const_industry_849": "Cutlery and Handtool Manufacturing",
    "const_industry_65": "Dairy Product Manufacturing",
    "const_industry_2135": "Dance Companies",
    "const_industry_2458": "Data Infrastructure and Analytics",
    "const_industry_3130": "Data Security Software Products",
    "const_industry_1": "Defense and Space Manufacturing",
    "const_industry_2045": "Dentists",
    "const_industry_99": "Design Services",
    "const_industry_3101": "Desktop Computing Software Products",
    "const_industry_564": "Distilleries",
    "const_industry_132": "E-Learning Providers",
    "const_industry_2375": "Economic Programs",
    "const_industry_1999": "Education",
    "const_industry_69": "Education Administration Programs",
    "const_industry_998": "Electric Lighting Equipment Manufacturing",
    "const_industry_383": "Electric Power Generation",
    "const_industry_382": "Electric Power Transmission, Control, and Distribution",
    "const_industry_2468": "Electrical Equipment Manufacturing",
    "const_industry_2240": "Electronic and Precision Equipment Maintenance",
    "const_industry_3099": "Embedded Software Products",
    "const_industry_2122": "Emergency and Relief Services",
    "const_industry_3242": "Engineering Services",
    "const_industry_935": "Engines and Power Transmission Equipment Manufacturing",
    "const_industry_28": "Entertainment Providers",
    "const_industry_388": "Environmental Quality Programs",
    "const_industry_86": "Environmental Services",
    "const_industry_1779": "Equipment Rental Services",
    "const_industry_110": "Events Services",
    "const_industry_76": "Executive Offices",
    "const_industry_1923": "Executive Search Services",
    "const_industry_840": "Fabricated Metal Products",
    "const_industry_122": "Facilities Services",
    "const_industry_2060": "Family Planning Centers",
    "const_industry_63": "Farming",
    "const_industry_201": "Farming, Ranching, Forestry",
    "const_industry_615": "Fashion Accessories Manufacturing",
    "const_industry_43": "Financial Services",
    "const_industry_2025": "Fine Arts Schools",
    "const_industry_3070": "Fire Protection",
    "const_industry_66": "Fisheries",
    "const_industry_2020": "Flight Training",
    "const_industry_23": "Food and Beverage Manufacturing",
    "const_industry_1339": "Food and Beverage Retail",
    "const_industry_34": "Food and Beverage Services",
    "const_industry_2255": "Footwear and Leather Goods Repair",
    "const_industry_622": "Footwear Manufacturing",
    "const_industry_298": "Forestry and Logging",
    "const_industry_385": "Fossil Fuel Electric Power Generation",
    "const_industry_87": "Freight and Package Transportation",
    "const_industry_504": "Fruit and Vegetable Preserves Manufacturing",
    "const_industry_101": "Fundraising",
    "const_industry_1742": "Funds and Trusts",
    "const_industry_26": "Furniture and Home Furnishings Manufacturing",
    "const_industry_29": "Gambling Facilities and Casinos",
    "const_industry_389": "Geothermal Electric Power Generation",
    "const_industry_779": "Glass Product Manufacturing",
    "const_industry_145": "Glass, Ceramics and Concrete Manufacturing",
    "const_industry_2179": "Golf Courses and Country Clubs",
    "const_industry_75": "Government Administration",
    "const_industry_148": "Government Relations Services",
    "const_industry_140": "Graphic Design",
    "const_industry_1495": "Ground Passenger Transportation",
    "const_industry_2353": "Health and Human Services",
    "const_industry_68": "Higher Education",
    "const_industry_431": "Highway, Street, and Bridge Construction",
    "const_industry_2161": "Historical Sites",
    "const_industry_1905": "Holding Companies",
    "const_industry_2074": "Home Health Care Services",
    "const_industry_150": "Horticulture",
    "const_industry_31": "Hospitality",
    "const_industry_2081": "Hospitals",
    "const_industry_14": "Hospitals and Health Care",
    "const_industry_2194": "Hotels and Motels",
    "const_industry_1080": "Household and Institutional Furniture Manufacturing",
    "const_industry_1005": "Household Appliance Manufacturing",
    "const_industry_2318": "Household Services",
    "const_industry_2369": "Housing and Community Development",
    "const_industry_3081": "Housing Programs",
    "const_industry_137": "Human Resources Services",
    "const_industry_923": "HVAC and Refrigeration Equipment Manufacturing",
    "const_industry_384": "Hydroelectric Power Generation",
    "const_industry_88": "Individual and Family Services",
    "const_industry_135": "Industrial Machinery Manufacturing",
    "const_industry_1909": "Industry Associations",
    "const_industry_84": "Information Services",
    "const_industry_42": "Insurance",
    "const_industry_1737": "Insurance Agencies and Brokerages",
    "const_industry_1743": "Insurance and Employee Benefit Funds",
    "const_industry_1725": "Insurance Carriers",
    "const_industry_3126": "Interior Design",
    "const_industry_74": "International Affairs",
    "const_industry_141": "International Trade and Development",
    "const_industry_1285": "Internet Marketplace Platforms",
    "const_industry_3124": "Internet News",
    "const_industry_3132": "Internet Publishing",
    "const_industry_1504": "Interurban and Rural Bus Services",
    "const_industry_1720": "Investment Advice",
    "const_industry_45": "Investment Banking",
    "const_industry_46": "Investment Management",
    "const_industry_96": "IT Services and IT Consulting",
    "const_industry_3102": "IT System Custom Software Development",
    "const_industry_3106": "IT System Data Services",
    "const_industry_1855": "IT System Design Services",
    "const_industry_3104": "IT System Installation and Disposal",
    "const_industry_3103": "IT System Operations and Maintenance",
    "const_industry_3107": "IT System Testing and Evaluation",
    "const_industry_3105": "IT System Training and Support",
    "const_industry_1965": "Janitorial Services",
    "const_industry_2934": "Landscaping Services",
    "const_industry_2029": "Language Schools",
    "const_industry_2272": "Laundry and Drycleaning Services",
    "const_industry_77": "Law Enforcement",
    "const_industry_9": "Law Practice",
    "const_industry_128": "Leasing Non-residential Real Estate",
    "const_industry_1770": "Leasing Real Estate Agents and Brokers",
    "const_industry_1759": "Leasing Residential Real Estate",
    "const_industry_616": "Leather Product Manufacturing",
    "const_industry_10": "Legal Services",
    "const_industry_72": "Legislative Offices",
    "const_industry_85": "Libraries",
    "const_industry_794": "Lime and Gypsum Products Manufacturing",
    "const_industry_1696": "Loan Brokers",
    "const_industry_55": "Machinery Manufacturing",
    "const_industry_994": "Magnetic and Optical Media Manufacturing",
    "const_industry_25": "Manufacturing",
    "const_industry_95": "Maritime Transportation",
    "const_industry_97": "Market Research",
    "const_industry_1862": "Marketing Services",
    "const_industry_1095": "Mattress and Blinds Manufacturing",
    "const_industry_983": "Measuring and Control Instrument Manufacturing",
    "const_industry_521": "Meat Products Manufacturing",
    "const_industry_3133": "Media and Telecommunications",
    "const_industry_126": "Media Production",
    "const_industry_2069": "Medical and Diagnostic Laboratories",
    "const_industry_17": "Medical Equipment Manufacturing",
    "const_industry_13": "Medical Practices",
    "const_industry_139": "Mental Health Care",
    "const_industry_345": "Metal Ore Mining",
    "const_industry_883": "Metal Treatments",
    "const_industry_887": "Metal Valve, Ball, and Roller Manufacturing",
    "const_industry_928": "Metalworking Machinery Manufacturing",
    "const_industry_2391": "Military and International Affairs",
    "const_industry_56": "Mining",
    "const_industry_3100": "Mobile Computing Software Products",
    "const_industry_2214": "Mobile Food Services",
    "const_industry_3131": "Mobile Gaming Apps",
    "const_industry_53": "Motor Vehicle Manufacturing",
    "const_industry_1042": "Motor Vehicle Parts Manufacturing",
    "const_industry_1611": "Movies and Sound Recording",
    "const_industry_35": "Movies, Videos and Sound",
    "const_industry_2159": "Museums",
    "const_industry_37": "Museums, Historical Sites, and Zoos",
    "const_industry_115": "Musicians",
    "const_industry_114": "Nanotechnology Research",
    "const_industry_397": "Natural Gas Distribution",
    "const_industry_3096": "Natural Gas Extraction",
    "const_industry_81": "Newspaper Publishing",
    "const_industry_100": "Non-profit Organizations",
    "const_industry_356": "Nonmetallic Mineral Mining",
    "const_industry_413": "Nonresidential Building Construction",
    "const_industry_386": "Nuclear Electric Power Generation",
    "const_industry_2091": "Nursing Homes and Residential Care Facilities",
    "const_industry_1916": "Office Administration",
    "const_industry_1090": "Office Furniture and Fixtures Manufacturing",
    "const_industry_679": "Oil and Coal Product Manufacturing",
    "const_industry_57": "Oil and Gas",
    "const_industry_3095": "Oil Extraction",
    "const_industry_332": "Oil, Gas, and Mining",
    "const_industry_1445": "Online and Mail Order Retail",
    "const_industry_113": "Online Audio and Video Media",
    "const_industry_2401": "Operations Consulting",
    "const_industry_2050": "Optometrists",
    "const_industry_2063": "Outpatient Care Centers",
    "const_industry_123": "Outsourcing and Offshoring Consulting",
    "const_industry_146": "Packaging and Containers Manufacturing",
    "const_industry_722": "Paint, Coating, and Adhesive Manufacturing",
    "const_industry_61": "Paper and Forest Product Manufacturing",
    "const_industry_1745": "Pension Funds",
    "const_industry_39": "Performing Arts",
    "const_industry_2130": "Performing Arts and Spectator Sports",
    "const_industry_1600": "Periodical Publishing",
    "const_industry_2258": "Personal and Laundry Services",
    "const_industry_18": "Personal Care Product Manufacturing",
    "const_industry_2259": "Personal Care Services",
    "const_industry_2282": "Pet Services",
    "const_industry_15": "Pharmaceutical Manufacturing",
    "const_industry_131": "Philanthropic Fundraising Services",
    "const_industry_136": "Photography",
    "const_industry_2054": "Physical, Occupational and Speech Therapists",
    "const_industry_2040": "Physicians",
    "const_industry_1520": "Pipeline Transportation",
    "const_industry_743": "Plastics and Rubber Product Manufacturing",
    "const_industry_117": "Plastics Manufacturing",
    "const_industry_107": "Political Organizations",
    "const_industry_1573": "Postal Services",
    "const_industry_67": "Primary and Secondary Education",
    "const_industry_807": "Primary Metal Manufacturing",
    "const_industry_83": "Printing Services",
    "const_industry_1911": "Professional Organizations",
    "const_industry_1810": "Professional Services",
    "const_industry_105": "Professional Training and Coaching",
    "const_industry_2360": "Public Assistance Programs",
    "const_industry_2358": "Public Health",
    "const_industry_79": "Public Policy Offices",
    "const_industry_98": "Public Relations and Communications Services",
    "const_industry_78": "Public Safety",
    "const_industry_2143": "Racetracks",
    "const_industry_1633": "Radio and Television Broadcasting",
    "const_industry_1481": "Rail Transportation",
    "const_industry_62": "Railroad Equipment Manufacturing",
    "const_industry_64": "Ranching",
    "const_industry_256": "Ranching and Fisheries",
    "const_industry_44": "Real Estate",
    "const_industry_1757": "Real Estate and Equipment Rental Services",
    "const_industry_40": "Recreational Facilities",
    "const_industry_89": "Religious Institutions",
    "const_industry_3241": "Renewable Energy Equipment Manufacturing",
    "const_industry_3240": "Renewable Energy Power Generation",
    "const_industry_144": "Renewable Energy Semiconductor Manufacturing",
    "const_industry_2225": "Repair and Maintenance",
    "const_industry_70": "Research Services",
    "const_industry_408": "Residential Building Construction",
    "const_industry_32": "Restaurants",
    "const_industry_27": "Retail",
    "const_industry_19": "Retail Apparel and Fashion",
    "const_industry_1319": "Retail Appliances, Electrical, and Electronic Equipment",
    "const_industry_3186": "Retail Art Dealers",
    "const_industry_111": "Retail Art Supplies",
    "const_industry_1409": "Retail Books and Printed News",
    "const_industry_1324": "Retail Building Materials and Garden Equipment",
    "const_industry_1423": "Retail Florists",
    "const_industry_1309": "Retail Furniture and Home Furnishings",
    "const_industry_1370": "Retail Gasoline",
    "const_industry_22": "Retail Groceries",
    "const_industry_1359": "Retail Health and Personal Care Products",
    "const_industry_143": "Retail Luxury Goods and Jewelry",
    "const_industry_1292": "Retail Motor Vehicles",
    "const_industry_1407": "Retail Musical Instruments",
    "const_industry_138": "Retail Office Equipment",
    "const_industry_1424": "Retail Office Supplies and Gifts",
    "const_industry_1431": "Retail Recyclable Materials & Used Merchandise",
    "const_industry_2253": "Reupholstery and Furniture Repair",
    "const_industry_763": "Rubber Products Manufacturing",
    "const_industry_1649": "Satellite Telecommunications",
    "const_industry_1678": "Savings Institutions",
    "const_industry_1512": "School and Employee Bus Services",
    "const_industry_528": "Seafood Product Manufacturing",
    "const_industry_2012": "Secretarial Schools",
    "const_industry_1713": "Securities and Commodity Exchanges",
    "const_industry_121": "Security and Investigations",
    "const_industry_1956": "Security Guards and Patrol Services",
    "const_industry_1958": "Security Systems Services",
    "const_industry_7": "Semiconductor Manufacturing",
    "const_industry_3243": "Services for Renewable Energy",
    "const_industry_2112": "Services for the Elderly and Disabled",
    "const_industry_1625": "Sheet Music Publishing",
    "const_industry_58": "Shipbuilding",
    "const_industry_1517": "Shuttles and Special Needs Transportation Services",
    "const_industry_1532": "Sightseeing Transportation",
    "const_industry_2181": "Skiing Facilities",
    "const_industry_727": "Soap and Cleaning Product Manufacturing",
    "const_industry_3127": "Social Networking Platforms",
    "const_industry_4": "Software Development",
    "const_industry_387": "Solar Electric Power Generation",
    "const_industry_1623": "Sound Recording",
    "const_industry_3089": "Space Research and Technology",
    "const_industry_435": "Specialty Trade Contractors",
    "const_industry_33": "Spectator Sports",
    "const_industry_20": "Sporting Goods Manufacturing",
    "const_industry_2027": "Sports and Recreation Instruction",
    "const_industry_2142": "Sports Teams and Clubs",
    "const_industry_873": "Spring and Wire Product Manufacturing",
    "const_industry_104": "Staffing and Recruiting",
    "const_industry_404": "Steam and Air-Conditioning Supply",
    "const_industry_102": "Strategic Management Services",
    "const_industry_428": "Subdivision of Land",
    "const_industry_495": "Sugar and Confectionery Product Manufacturing",
    "const_industry_1505": "Taxi and Limousine Services",
    "const_industry_2018": "Technical and Vocational Training",
    "const_industry_6": "Technology, Information and Internet",
    "const_industry_1594": "Technology, Information and Media",
    "const_industry_8": "Telecommunications",
    "const_industry_1644": "Telecommunications Carriers",
    "const_industry_1931": "Telephone Call Centers",
    "const_industry_1925": "Temporary Help Services",
    "const_industry_60": "Textile Manufacturing",
    "const_industry_2133": "Theater Companies",
    "const_industry_130": "Think Tanks",
    "const_industry_21": "Tobacco Manufacturing",
    "const_industry_108": "Translation and Localization",
    "const_industry_1029": "Transportation Equipment Manufacturing",
    "const_industry_3085": "Transportation Programs",
    "const_industry_116": "Transportation, Logistics, Supply Chain and Storage",
    "const_industry_30": "Travel Arrangements",
    "const_industry_92": "Truck Transportation",
    "const_industry_1750": "Trusts and Estates",
    "const_industry_876": "Turned Products and Fastener Manufacturing",
    "const_industry_1497": "Urban Transit Services",
    "const_industry_59": "Utilities",
    "const_industry_3086": "Utilities Administration",
    "const_industry_419": "Utility System Construction",
    "const_industry_2226": "Vehicle Repair and Maintenance",
    "const_industry_106": "Venture Capital and Private Equity Principals",
    "const_industry_16": "Veterinary Services",
    "const_industry_2125": "Vocational Rehabilitation Services",
    "const_industry_93": "Warehousing and Storage",
    "const_industry_1981": "Waste Collection",
    "const_industry_1986": "Waste Treatment and Disposal",
    "const_industry_400": "Water Supply and Irrigation Systems",
    "const_industry_398": "Water, Waste, Steam, and Air Conditioning Services",
    "const_industry_124": "Wellness and Fitness Services",
    "const_industry_133": "Wholesale",
    "const_industry_1267": "Wholesale Alcoholic Beverages",
    "const_industry_1222": "Wholesale Apparel and Sewing Supplies",
    "const_industry_1171": "Wholesale Appliances, Electrical, and Electronics",
    "const_industry_49": "Wholesale Building Materials",
    "const_industry_1257": "Wholesale Chemical and Allied Products",
    "const_industry_1157": "Wholesale Computer Equipment",
    "const_industry_1221": "Wholesale Drugs and Sundries",
    "const_industry_1231": "Wholesale Food and Beverage",
    "const_industry_1230": "Wholesale Footwear",
    "const_industry_1137": "Wholesale Furniture and Home Furnishings",
    "const_industry_1178": "Wholesale Hardware, Plumbing, Heating Equipment",
    "const_industry_134": "Wholesale Import and Export",
    "const_industry_1208": "Wholesale Luxury Goods and Jewelry",
    "const_industry_1187": "Wholesale Machinery",
    "const_industry_1166": "Wholesale Metals and Minerals",
    "const_industry_1128": "Wholesale Motor Vehicles and Parts",
    "const_industry_1212": "Wholesale Paper Products",
    "const_industry_1262": "Wholesale Petroleum and Petroleum Products",
    "const_industry_1153": "Wholesale Photography Equipment and Supplies",
    "const_industry_1250": "Wholesale Raw Farm Products",
    "const_industry_1206": "Wholesale Recyclable Materials",
    "const_industry_2489": "Wind Electric Power Generation",
    "const_industry_2500": "Wineries",
    "const_industry_119": "Wireless Services",
    "const_industry_625": "Women's Handbag Manufacturing",
    "const_industry_784": "Wood Product Manufacturing",
    "const_industry_103": "Writing and Editing",
    "const_industry_2163": "Zoos and Botanical Gardens",
    "const_industry_12": "Biotechnology",

    "const_company_type_c": "Public Company",
    "const_company_type_d": "Educational",
    "const_company_type_e": "Self Employed",
    "const_company_type_g": "Government Agency",
    "const_company_type_n": "Non Profit",
    "const_company_type_o": "Self Owned",
    "const_company_type_p": "Privately Held",
    "const_company_type_s": "Partnership",

    "const_joblevel_owner": "Owner",
    "const_joblevel_partner": "Partner",
    "const_joblevel_cxo": "CXO",
    "const_joblevel_vp": "VP",
    "const_joblevel_director": "Director",
    "const_joblevel_manager": "Manager",
    "const_joblevel_senior": "Senior Level",
    "const_joblevel_entry": "Entry Level",
    "const_joblevel_assistant": "Assistant",
    "const_joblevel_trainee": "Trainee",
    "const_joblevel_freelancer": "Freelancer",

    "const_country_af": "Afghanistan",
    "const_country_al": "Albania",
    "const_country_dz": "Algeria",
    "const_country_as": "American Samoa",
    "const_country_ad": "Andorra",
    "const_country_ao": "Angola",
    "const_country_ai": "Anguilla",
    "const_country_aq": "Antarctica",
    "const_country_ag": "Antigua and Barbuda",
    "const_country_ar": "Argentina",
    "const_country_am": "Armenia",
    "const_country_aw": "Aruba",
    "const_country_au": "Australia",
    "const_country_at": "Austria",
    "const_country_az": "Azerbaijan",
    "const_country_bs": "Bahamas",
    "const_country_bh": "Bahrain",
    "const_country_bd": "Bangladesh",
    "const_country_bb": "Barbados",
    "const_country_by": "Belarus",
    "const_country_be": "Belgium",
    "const_country_bz": "Belize",
    "const_country_bj": "Benin",
    "const_country_bm": "Bermuda",
    "const_country_bt": "Bhutan",
    "const_country_bo": "Bolivia",
    "const_country_ba": "Bosnia and Herzegovina",
    "const_country_bw": "Botswana",
    "const_country_bv": "Bouvet Island",
    "const_country_br": "Brazil",
    "const_country_bq": "British Antarctic Territory",
    "const_country_io": "British Indian Ocean Territory",
    "const_country_vg": "British Virgin Islands",
    "const_country_bn": "Brunei",
    "const_country_bg": "Bulgaria",
    "const_country_bf": "Burkina Faso",
    "const_country_bi": "Burundi",
    "const_country_kh": "Cambodia",
    "const_country_cm": "Cameroon",
    "const_country_ca": "Canada",
    "const_country_ct": "Canton and Enderbury Islands",
    "const_country_cv": "Cape Verde",
    "const_country_ky": "Cayman Islands",
    "const_country_cf": "Central African Republic",
    "const_country_td": "Chad",
    "const_country_cl": "Chile",
    "const_country_cn": "China",
    "const_country_cx": "Christmas Island",
    "const_country_cc": "Cocos [Keeling] Islands",
    "const_country_co": "Colombia",
    "const_country_km": "Comoros",
    "const_country_cg": "Congo - Brazzaville",
    "const_country_cd": "Congo - Kinshasa",
    "const_country_ck": "Cook Islands",
    "const_country_cr": "Costa Rica",
    "const_country_hr": "Croatia",
    "const_country_cu": "Cuba",
    "const_country_cy": "Cyprus",
    "const_country_cz": "Czech Republic",
    "const_country_ci": "Côte d’Ivoire",
    "const_country_dk": "Denmark",
    "const_country_dj": "Djibouti",
    "const_country_dm": "Dominica",
    "const_country_do": "Dominican Republic",
    "const_country_nq": "Dronning Maud Land",
    "const_country_dd": "East Germany",
    "const_country_ec": "Ecuador",
    "const_country_eg": "Egypt",
    "const_country_sv": "El Salvador",
    "const_country_gq": "Equatorial Guinea",
    "const_country_er": "Eritrea",
    "const_country_ee": "Estonia",
    "const_country_et": "Ethiopia",
    "const_country_fk": "Falkland Islands",
    "const_country_fo": "Faroe Islands",
    "const_country_fj": "Fiji",
    "const_country_fi": "Finland",
    "const_country_fr": "France",
    "const_country_gf": "French Guiana",
    "const_country_pf": "French Polynesia",
    "const_country_tf": "French Southern Territories",
    "const_country_fq": "French Southern and Antarctic Territories",
    "const_country_ga": "Gabon",
    "const_country_gm": "Gambia",
    "const_country_ge": "Georgia",
    "const_country_de": "Germany",
    "const_country_gh": "Ghana",
    "const_country_gi": "Gibraltar",
    "const_country_gr": "Greece",
    "const_country_gl": "Greenland",
    "const_country_gd": "Grenada",
    "const_country_gp": "Guadeloupe",
    "const_country_gu": "Guam",
    "const_country_gt": "Guatemala",
    "const_country_gg": "Guernsey",
    "const_country_gn": "Guinea",
    "const_country_gw": "Guinea-Bissau",
    "const_country_gy": "Guyana",
    "const_country_ht": "Haiti",
    "const_country_hm": "Heard Island and McDonald Islands",
    "const_country_hn": "Honduras",
    "const_country_hk": "Hong Kong SAR China",
    "const_country_hu": "Hungary",
    "const_country_is": "Iceland",
    "const_country_in": "India",
    "const_country_id": "Indonesia",
    "const_country_ir": "Iran",
    "const_country_iq": "Iraq",
    "const_country_ie": "Ireland",
    "const_country_im": "Isle of Man",
    "const_country_il": "Israel",
    "const_country_it": "Italy",
    "const_country_jm": "Jamaica",
    "const_country_jp": "Japan",
    "const_country_je": "Jersey",
    "const_country_jt": "Johnston Island",
    "const_country_jo": "Jordan",
    "const_country_kz": "Kazakhstan",
    "const_country_ke": "Kenya",
    "const_country_ki": "Kiribati",
    "const_country_kw": "Kuwait",
    "const_country_kg": "Kyrgyzstan",
    "const_country_la": "Laos",
    "const_country_lv": "Latvia",
    "const_country_lb": "Lebanon",
    "const_country_ls": "Lesotho",
    "const_country_lr": "Liberia",
    "const_country_ly": "Libya",
    "const_country_li": "Liechtenstein",
    "const_country_lt": "Lithuania",
    "const_country_lu": "Luxembourg",
    "const_country_mo": "Macau SAR China",
    "const_country_mk": "Macedonia",
    "const_country_mg": "Madagascar",
    "const_country_mw": "Malawi",
    "const_country_my": "Malaysia",
    "const_country_mv": "Maldives",
    "const_country_ml": "Mali",
    "const_country_mt": "Malta",
    "const_country_mh": "Marshall Islands",
    "const_country_mq": "Martinique",
    "const_country_mr": "Mauritania",
    "const_country_mu": "Mauritius",
    "const_country_yt": "Mayotte",
    "const_country_fx": "Metropolitan France",
    "const_country_mx": "Mexico",
    "const_country_fm": "Micronesia",
    "const_country_mi": "Midway Islands",
    "const_country_md": "Moldova",
    "const_country_mc": "Monaco",
    "const_country_mn": "Mongolia",
    "const_country_me": "Montenegro",
    "const_country_ms": "Montserrat",
    "const_country_ma": "Morocco",
    "const_country_mz": "Mozambique",
    "const_country_mm": "Myanmar [Burma]",
    "const_country_na": "Namibia",
    "const_country_nr": "Nauru",
    "const_country_np": "Nepal",
    "const_country_nl": "Netherlands",
    "const_country_an": "Netherlands Antilles",
    "const_country_nt": "Neutral Zone",
    "const_country_nc": "New Caledonia",
    "const_country_nz": "New Zealand",
    "const_country_ni": "Nicaragua",
    "const_country_ne": "Niger",
    "const_country_ng": "Nigeria",
    "const_country_nu": "Niue",
    "const_country_nf": "Norfolk Island",
    "const_country_kp": "North Korea",
    "const_country_mp": "Northern Mariana Islands",
    "const_country_no": "Norway",
    "const_country_om": "Oman",
    "const_country_pc": "Pacific Islands Trust Territory",
    "const_country_pk": "Pakistan",
    "const_country_pw": "Palau",
    "const_country_ps": "Palestinian Territories",
    "const_country_pa": "Panama",
    "const_country_pz": "Panama Canal Zone",
    "const_country_pg": "Papua New Guinea",
    "const_country_py": "Paraguay",
    "const_country_yd": "People's Democratic Republic of Yemen",
    "const_country_pe": "Peru",
    "const_country_ph": "Philippines",
    "const_country_pn": "Pitcairn Islands",
    "const_country_pl": "Poland",
    "const_country_pt": "Portugal",
    "const_country_pr": "Puerto Rico",
    "const_country_qa": "Qatar",
    "const_country_ro": "Romania",
    "const_country_ru": "Russia",
    "const_country_rw": "Rwanda",
    "const_country_re": "Réunion",
    "const_country_bl": "Saint Barthélemy",
    "const_country_sh": "Saint Helena",
    "const_country_kn": "Saint Kitts and Nevis",
    "const_country_lc": "Saint Lucia",
    "const_country_mf": "Saint Martin",
    "const_country_pm": "Saint Pierre and Miquelon",
    "const_country_vc": "Saint Vincent and the Grenadines",
    "const_country_ws": "Samoa",
    "const_country_sm": "San Marino",
    "const_country_sa": "Saudi Arabia",
    "const_country_sn": "Senegal",
    "const_country_rs": "Serbia",
    "const_country_cs": "Serbia and Montenegro",
    "const_country_sc": "Seychelles",
    "const_country_sl": "Sierra Leone",
    "const_country_sg": "Singapore",
    "const_country_sk": "Slovakia",
    "const_country_si": "Slovenia",
    "const_country_sb": "Solomon Islands",
    "const_country_so": "Somalia",
    "const_country_za": "South Africa",
    "const_country_gs": "South Georgia and the South Sandwich Islands",
    "const_country_kr": "South Korea",
    "const_country_es": "Spain",
    "const_country_lk": "Sri Lanka",
    "const_country_sd": "Sudan",
    "const_country_sr": "Suriname",
    "const_country_sj": "Svalbard and Jan Mayen",
    "const_country_sz": "Swaziland",
    "const_country_se": "Sweden",
    "const_country_ch": "Switzerland",
    "const_country_sy": "Syria",
    "const_country_st": "São Tomé and Príncipe",
    "const_country_tw": "Taiwan, Province of China",
    "const_country_tj": "Tajikistan",
    "const_country_tz": "Tanzania",
    "const_country_th": "Thailand",
    "const_country_tl": "Timor-Leste",
    "const_country_tg": "Togo",
    "const_country_tk": "Tokelau",
    "const_country_to": "Tonga",
    "const_country_tt": "Trinidad and Tobago",
    "const_country_tn": "Tunisia",
    "const_country_tr": "Turkey",
    "const_country_tm": "Turkmenistan",
    "const_country_tc": "Turks and Caicos Islands",
    "const_country_tv": "Tuvalu",
    "const_country_um": "U.S. Minor Outlying Islands",
    "const_country_pu": "U.S. Miscellaneous Pacific Islands",
    "const_country_vi": "U.S. Virgin Islands",
    "const_country_ug": "Uganda",
    "const_country_ua": "Ukraine",
    "const_country_su": "Union of Soviet Socialist Republics",
    "const_country_ae": "United Arab Emirates",
    "const_country_gb": "United Kingdom",
    "const_country_us": "United States",
    "const_country_zz": "Unknown or Invalid Region",
    "const_country_uy": "Uruguay",
    "const_country_uz": "Uzbekistan",
    "const_country_vu": "Vanuatu",
    "const_country_va": "Vatican City",
    "const_country_ve": "Venezuela",
    "const_country_vn": "Vietnam",
    "const_country_wk": "Wake Island",
    "const_country_wf": "Wallis and Futuna",
    "const_country_eh": "Western Sahara",
    "const_country_ye": "Yemen",
    "const_country_zm": "Zambia",
    "const_country_zw": "Zimbabwe",
    "const_country_ax": "Åland Islands",

    "const_contact_type_phone": "Phone",
    "const_contact_type_email": "Email",

    "const_distributor_type_yes": "Yes",
    "const_distributor_type_no": "No",


    "const_function_administrative" : "Administrative",
    "const_function_arts_design" : "Arts and Design",
    "const_function_bd" : "Business Development",
    "const_function_community_social" : "Community and Social Services",
    "const_function_consulting" : "Consulting",
    "const_function_custom" : "Custom",
    "const_function_education" : "Education",
    "const_function_engineering" : "Engineering",
    "const_function_finance" : "Finance",
    "const_function_healthcare" : "Healthcare Services",
    "const_function_human_resources" : "Human Resources",
    "const_function_it" : "Information Technology",
    "const_function_legal" : "Legal",
    "const_function_marketing" : "Marketing",
    "const_function_media_communication" : "Media and Communication",
    "const_function_military_protective" : "Military and Protective Services",
    "const_function_operations" : "Operations",
    "const_function_product_management" : "Product Management",
    "const_function_program_and_project_management" : "Program and Project Management",
    "const_function_purchasing" : "Purchasing",
    "const_function_quality_assurance" : "Quality Assurance",
    "const_function_real_estate" : "Real Estate",
    "const_function_research" : "Research",
    "const_function_sales" : "Sales",
    "const_function_general_management" : "General Management",
    "const_function_rd" : "R&D",
    "const_function_logistics_and_supply_chain" : "Logistics and Supply Chain",
    "const_function_accounting" : "Accounting",


    "const_employee_range_a": "Myself Only",
    "const_employee_range_b": "2 - 10",
    "const_employee_range_c": "11 - 50",
    "const_employee_range_d": "51 - 200",
    "const_employee_range_e": "201 - 500",
    "const_employee_range_f": "501 - 1,000",
    "const_employee_range_g": "1,001 - 5,000",
    "const_employee_range_h": "5,001 - 10,000",
    "const_employee_range_i": "Over 10,000",
    
    "const_people_status_active": "Active",
    "const_people_status_retired": "Retired",
    "const_people_status_died": "Died",
    "const_people_status_fake": "Fake",

    "const_company_status_active": "Active",
    "const_company_status_defunct": "Defunct",
    "const_company_status_virtual": "Virtual",

    "const_stock_market_nyse": "New York Stock Exchange",
    "const_stock_market_nasdaq": "Nasdaq",
    "const_stock_market_tyo": "Tokyo Stock Exchange",
    "const_stock_market_sha": "Shanghai Stock Exchange",
    "const_stock_market_hkg": "The Stock Exchange of Hong Kong",
    "const_stock_market_she": "Shenzhen Stock Exchange",
    "const_stock_market_bom": "Bombay Stock Exchange",
    "const_stock_market_nse": "National Stock Exchange of India",
    "const_stock_market_krx": "Korea Stock Exchange",
    "const_stock_market_tpe": "Taiwan Stock Exchange",
    "const_stock_market_sgx": "Singapore Exchange",
    "const_stock_market_bkk": "The Stock Exchange of Thailand",
    "const_stock_market_lon": "London Stock Exchange",
    "const_stock_market_fra": "Frankfurt Stock Exchange",
    "const_stock_market_tse": "Toronto Stock Exchange",
    "const_stock_market_jse": "Johannesburg Stock Exchange",
    "const_stock_market_swx": "SIX Swiss Exchange",

    "const_language_proficiency_0": "No Proficiency",
    "const_language_proficiency_1": "Elementary Proficiency",
    "const_language_proficiency_2": "Limited Working Proficiency",
    "const_language_proficiency_3": "Professional Working Proficiency",
    "const_language_proficiency_4": "Full Professional Proficiency",
    "const_language_proficiency_5": "Native or Bilingual Proficiency",

    "const_product_15050201": "3D Glasses",
    "const_product_19010101": "3D Printer Material",
    "const_product_20020401": "3D Printer Material",
    "const_product_13070101": "3D Printing Service",
    "const_product_140105": "3D Scanner",
    "const_product_170106": "3D Scanner",
    "const_product_130108": "3D Scanner",
    "const_product_23010101": "AB Exerciser",
    "const_product_250101": "Abrasive & Grinding",
    "const_product_25010101": "Abrasive Brush",
    "const_product_25010102": "Abrasive Cloth & Mesh",
    "const_product_25010103": "Abrasive Grains",
    "const_product_13010201": "Abrator",
    "const_product_4010101": "ABS Sensor",
    "const_product_10010701": "ABS Sensor",
    "const_product_10030201": "AC Motor",
    "const_product_2101": "Access Control & Surveillance System",
    "const_product_21010101": "Access Control Card",
    "const_product_21010102": "Access Control Card Reader",
    "const_product_21010103": "Access Control Keypad",
    "const_product_21010104": "Access Control Power Supply",
    "const_product_21010105": "Access Control Software",
    "const_product_210101": "Access Control System",
    "const_product_21010106": "Access Controller",
    "const_product_204": "Accessories",
    "const_product_220401": "Accommodation Services",
    "const_product_23040301": "Accordion",
    "const_product_6040401": "Acetate",
    "const_product_6040201": "Acid",
    "const_product_6020201": "Acid Dye",
    "const_product_2601": "Action Toys",
    "const_product_9010301": "AD Player",
    "const_product_15090101": "AD Player",
    "const_product_60501": "Additive",
    "const_product_60503": "Adhesive",
    "const_product_20020402": "Adhesive",
    "const_product_15010701": "Adhesive Label",
    "const_product_15090201": "Adhesive Label",
    "const_product_15020901": "Adult Diapers",
    "const_product_260702": "Adult Toys",
    "const_product_15020902": "Adult Wipes",
    "const_product_1509": "Advertising & Packaging",
    "const_product_15090102": "Advertising Letter",
    "const_product_27040201": "Advertising Truck",
    "const_product_1701": "Agricultural & Food Machinery",
    "const_product_10402": "Agricultural Chemicals",
    "const_product_60801": "Agricultural Chemicals",
    "const_product_10502": "Agricultural Machinery",
    "const_product_170101": "Agricultural Machinery",
    "const_product_17010101": "Agricultural Machinery Parts",
    "const_product_17010102": "Agricultural Mower",
    "const_product_17010103": "Agricultural Sprayers",
    "const_product_1050101": "Agricultural Sprayers",
    "const_product_220301": "Agricultural Support Services",
    "const_product_4010701": "Agricultural Tire",
    "const_product_1": "Agriculture & Food",
    "const_product_10401": "Agriculture Manure",
    "const_product_104": "Agrochemicals",
    "const_product_608": "Agrochemicals",
    "const_product_130101": "Air Clean Equipment",
    "const_product_13010101": "Air Clean Equipment Parts",
    "const_product_9030101": "Air Conditioner",
    "const_product_9030301": "Air Conditioner Parts",
    "const_product_90301": "Air Conditioner, Purifier & Humidifier",
    "const_product_9030102": "Air Cooler",
    "const_product_20010201": "Air Dunnage Bags",
    "const_product_13010102": "Air Filter",
    "const_product_13010401": "Air Filter",
    "const_product_170107": "Air Filter Making Machine",
    "const_product_15040101": "Air Freshener",
    "const_product_9030501": "Air Fryer",
    "const_product_4020201": "Air Intake",
    "const_product_23010201": "Air Mattress",
    "const_product_9030103": "Air Purifier",
    "const_product_13010103": "Air Purifier",
    "const_product_13010301": "Air Purifier",
    "const_product_9030302": "Air Purifier Parts",
    "const_product_13010104": "Air Shower",
    "const_product_13050601": "Air Vent",
    "const_product_61101": "Airbag",
    "const_product_8080401": "Airport Fence",
    "const_product_21020101": "Alarm",
    "const_product_2102": "Alarm & Fire Fighting System",
    "const_product_21020102": "Alarm & Security System Accessories",
    "const_product_210201": "Alarm & Security Systems",
    "const_product_100403": "Alarm & Security Systems",
    "const_product_15050101": "Alarm Clock",
    "const_product_21020103": "Alarm Host",
    "const_product_6030101": "Alcoholate & Derivative",
    "const_product_10303": "Alcoholic Beverage",
    "const_product_6040202": "Alkali",
    "const_product_190102": "All in One Copier, Printer & Fax",
    "const_product_70701": "All in One Copier, Printer & Fax",
    "const_product_90402": "All in One Copier, Printer & Fax",
    "const_product_10010301": "Alligator Clip",
    "const_product_180105": "Alloy",
    "const_product_18010401": "Alloy Steel",
    "const_product_18020101": "AlNiCo Magnet",
    "const_product_180101": "Aluminum",
    "const_product_18010101": "Aluminum Bar",
    "const_product_18010102": "Aluminum Coils",
    "const_product_8050301": "Aluminum Composite Panel",
    "const_product_18010103": "Aluminum Foil",
    "const_product_20010301": "Aluminum Foil",
    "const_product_10030301": "Aluminum Foil",
    "const_product_20010101": "Aluminum Foil Container Production Line",
    "const_product_18010104": "Aluminum Ingot",
    "const_product_18010105": "Aluminum Profile",
    "const_product_8060401": "Aluminum Profile",
    "const_product_18010201": "Aluminum Scrap",
    "const_product_18010106": "Aluminum Sheet",
    "const_product_18010107": "Aluminum Strip",
    "const_product_18010108": "Aluminum Tube & Pipe",
    "const_product_8080301": "Aluminum Tube & Pipe",
    "const_product_18010109": "Aluminum Wire",
    "const_product_6030102": "Amine",
    "const_product_6030103": "Amino Acid",
    "const_product_18020102": "Amorphous Core",
    "const_product_9010101": "Amplifier",
    "const_product_230401": "Amusement Park",
    "const_product_23040101": "Amusement Rides",
    "const_product_1401": "Analysis & Testing Instrument",
    "const_product_140101": "Analysis Instrument",
    "const_product_12030201": "Analysis Instrument",
    "const_product_13030301": "Angle Valve",
    "const_product_1204": "Animal & Veterinary",
    "const_product_10201": "Animal Byproducts",
    "const_product_1030201": "Animal Extract",
    "const_product_102": "Animal Husbandry",
    "const_product_2602": "Animals & Stuffed Toys",
    "const_product_23010401": "Ankle Support",
    "const_product_100307": "Antenna",
    "const_product_210502": "Anti-Counterfeiting",
    "const_product_210503": "Anti-Static",
    "const_product_8030201": "Anti-static Flooring",
    "const_product_180203": "Anticorrosive Material",
    "const_product_4030201": "Antifreeze & Coolant",
    "const_product_18010301": "Antimony",
    "const_product_30501": "Antique & Collection",
    "const_product_110501": "Antique & Reproduction Furniture",
    "const_product_11050101": "Antique Cabinet",
    "const_product_11050102": "Antique Chair",
    "const_product_11050103": "Antique Table & Desk",
    "const_product_201": "Apparel",
    "const_product_2": "Apparel & Accessories",
    "const_product_205": "Apparel & Shoes Machinery",
    "const_product_1702": "Apparel & Textile Machinery",
    "const_product_2040101": "Apparel Beads",
    "const_product_2040102": "Apparel Clip",
    "const_product_3010101": "Apparel Clip",
    "const_product_2040103": "Apparel Eyelet",
    "const_product_20104": "Apparel Stocks",
    "const_product_6020101": "Appliance Paint",
    "const_product_20204": "Apron",
    "const_product_150110": "Apron",
    "const_product_23030101": "Aquariums & Accessories",
    "const_product_10202": "Aquatic & Preparation",
    "const_product_13060201": "Arc Welder",
    "const_product_10020101": "Arrester",
    "const_product_10020501": "Arrester",
    "const_product_30401": "Art Box",
    "const_product_30601": "Art Frame",
    "const_product_3060101": "Art Frame Accessories",
    "const_product_3060201": "Art Print",
    "const_product_30502": "Artificial Antique Crafts",
    "const_product_30503": "Artificial Crafts",
    "const_product_60504": "Artificial Graphite & Active Carbon",
    "const_product_23010301": "Artificial Grass",
    "const_product_8020101": "Artificial Stone",
    "const_product_17050201": "Artificial Stone Machinery",
    "const_product_8030101": "Artificial Thatch",
    "const_product_8050302": "Artificial Thatch",
    "const_product_260801": "Artificial Toys",
    "const_product_3": "Arts & Crafts",
    "const_product_30701": "Arts & Crafts Stocks",
    "const_product_18020201": "Asbestos",
    "const_product_15080301": "Ashtray",
    "const_product_170604": "Assembly Line & Production Line",
    "const_product_10030202": "Asynchronous Motor",
    "const_product_2030401": "Athletic & Sports Shoes",
    "const_product_23020801": "Athletic & Sports Shoes",
    "const_product_9040101": "ATM",
    "const_product_18020202": "Attapulgite Earth",
    "const_product_23020101": "ATV & Quad",
    "const_product_270102": "ATV & Quad",
    "const_product_2701": "ATV & Scooter",
    "const_product_27040401": "ATV Trailer",
    "const_product_230201": "ATV, Quad & Parts",
    "const_product_90101": "Audio & Sets",
    "const_product_9010701": "Audio & Video Cable",
    "const_product_10030302": "Audio & Video Cable",
    "const_product_10010302": "Audio & Video Connectors",
    "const_product_901": "Audio & Video Devices",
    "const_product_9010201": "Audio & Video Products",
    "const_product_10030501": "Audio & Video Socket",
    "const_product_14010101": "Audio Analyzer",
    "const_product_21010107": "Audio Door Phone",
    "const_product_220101": "Audiovisual Services",
    "const_product_11031101": "Auditorium Chairs",
    "const_product_401": "Auto Accessories",
    "const_product_4020301": "Auto Bearing",
    "const_product_13020101": "Auto Bearing",
    "const_product_13030101": "Auto Compressor",
    "const_product_4010301": "Auto Compressor",
    "const_product_4020302": "Auto Compressor",
    "const_product_4020202": "Auto Cylinder",
    "const_product_4020401": "Auto Cylinder",
    "const_product_40201": "Auto Electrical System",
    "const_product_40205": "Auto Engine",
    "const_product_40202": "Auto Engine Structure",
    "const_product_4020303": "Auto Filter",
    "const_product_4020101": "Auto Meter",
    "const_product_4030301": "Auto Painting Equipment",
    "const_product_402": "Auto Parts",
    "const_product_40203": "Auto Parts",
    "const_product_270302": "Auto Parts",
    "const_product_4020102": "Auto Relay",
    "const_product_403": "Auto Repair & Decoration",
    "const_product_404": "Auto Safety",
    "const_product_40101": "Auto Sensor",
    "const_product_4020304": "Auto Steering Systems",
    "const_product_40108": "Auto Winch",
    "const_product_4": "Auto, Motorcycle Parts & Accessories",
    "const_product_8040101": "Automatic Door",
    "const_product_10030601": "Automatic Transfer Switch",
    "const_product_4030302": "Automotive Test Equipment",
    "const_product_270503": "Aviation",
    "const_product_16050101": "Aviation Obstruction Light",
    "const_product_15080101": "Awning",
    "const_product_80108": "Awning",
    "const_product_150801": "Awning, Umbrella & Raincoat",
    "const_product_25010401": "Axe",
    "const_product_4020305": "Axles",
    "const_product_15070101": "Baby Bib",
    "const_product_15070102": "Baby Car Seats",
    "const_product_15070103": "Baby Changing Station",
    "const_product_11010101": "Baby Crib",
    "const_product_15020903": "Baby Diapers",
    "const_product_15070104": "Baby Diapers",
    "const_product_1507": "Baby Goods",
    "const_product_150701": "Baby Goods",
    "const_product_11010109": "Baby Playpen",
    "const_product_15070105": "Baby Safety Lock",
    "const_product_15070106": "Baby Shoes",
    "const_product_2030402": "Baby Shoes",
    "const_product_15070107": "Baby Toys",
    "const_product_260703": "Baby Toys",
    "const_product_15020904": "Baby Wipes",
    "const_product_15070108": "Baby Wipes",
    "const_product_23020301": "Backpack",
    "const_product_50401": "Backpack",
    "const_product_24060101": "Backrest Pillow & Cushion",
    "const_product_15030101": "Backrest Pillow & Cushion",
    "const_product_30405": "Badge",
    "const_product_23010202": "Badminton Set, Racket & Net",
    "const_product_5060101": "Bag & Luggage Buckle",
    "const_product_5060102": "Bag & Luggage Lock",
    "const_product_15040201": "Bag Clip",
    "const_product_5060103": "Bag Hanger",
    "const_product_20010102": "Bag Making Machine",
    "const_product_50601": "Bag Parts & Accessories",
    "const_product_5060104": "Bag Ring",
    "const_product_501": "Bags By Style",
    "const_product_5": "Bags, Cases & Boxes",
    "const_product_15010601": "Bakeware",
    "const_product_14020401": "Balance",
    "const_product_8080402": "Balcony Fence",
    "const_product_17010104": "Baler",
    "const_product_260402": "Ball",
    "const_product_13020102": "Ball Bearing",
    "const_product_19020101": "Ball Pen",
    "const_product_130206": "Ball Screw",
    "const_product_13030302": "Ball Valve",
    "const_product_16070101": "Ballast",
    "const_product_260601": "Balloon & Inflatable",
    "const_product_30406": "Balloon & Inflatable",
    "const_product_8080701": "Bamboo",
    "const_product_11040101": "Bamboo Bed",
    "const_product_11040102": "Bamboo Cabinet",
    "const_product_30302": "Bamboo Crafts",
    "const_product_15010301": "Bamboo Fiber Cup",
    "const_product_8030202": "Bamboo Floor",
    "const_product_110401": "Bamboo Furniture",
    "const_product_11040103": "Bamboo Sofa",
    "const_product_11040104": "Bamboo Table & Chair",
    "const_product_12030401": "Bandage",
    "const_product_2030301": "Bandana, Headband & Cravat",
    "const_product_23040302": "Banjo",
    "const_product_15090103": "Banner Stand",
    "const_product_15010101": "Bar Accessories",
    "const_product_11030202": "Bar Chairs",
    "const_product_110302": "Bar Furniture",
    "const_product_11030205": "Bar Furniture Sets",
    "const_product_15010102": "Bar Set",
    "const_product_11030201": "Bar Stools",
    "const_product_11030203": "Bar Table",
    "const_product_15010103": "Bar Tool",
    "const_product_230202": "Barbecue",
    "const_product_8080801": "Barbed Wire",
    "const_product_23010102": "Barbell",
    "const_product_11030801": "Barber Chair",
    "const_product_130109": "Barcode Scanner",
    "const_product_9040102": "Barcode Scanner",
    "const_product_27050101": "Barge",
    "const_product_18020203": "Barite",
    "const_product_270406": "Barrow, Trolley & Cart",
    "const_product_150101": "Barware",
    "const_product_8020102": "Basalt",
    "const_product_23010203": "Baseball Bat, Gloves & Ball",
    "const_product_6020202": "Basic Dye",
    "const_product_603": "Basic Organic Chemicals",
    "const_product_8010201": "Basin Faucet",
    "const_product_23010204": "Basketball & Basketball Equipment",
    "const_product_30407": "Basketry",
    "const_product_23040201": "Bass",
    "const_product_240101": "Bast Fiber Fabric",
    "const_product_80109": "Bath Mat",
    "const_product_11080201": "Bath Mirror",
    "const_product_15020101": "Bath Pillow",
    "const_product_15020102": "Bath Salt",
    "const_product_15020103": "Bath Sponge, Brush & Scrubber",
    "const_product_150201": "Bath Supplies",
    "const_product_24030201": "Bath Towel",
    "const_product_15070109": "Bathing & Skin Care",
    "const_product_801": "Bathroom & Kitchen",
    "const_product_8010601": "Bathroom Basin & Sink",
    "const_product_11010302": "Bathroom Cabinets",
    "const_product_80101": "Bathroom Fittings & Accessories",
    "const_product_25020101": "Bathroom Fittings & Accessories",
    "const_product_110103": "Bathroom Furniture",
    "const_product_8010101": "Bathroom Hardware Sets",
    "const_product_90309": "Bathroom Heater",
    "const_product_8010102": "Bathroom Mirror",
    "const_product_8050401": "Bathroom Mirror",
    "const_product_11010301": "Bathroom Mirror",
    "const_product_8010103": "Bathroom Rack",
    "const_product_15080401": "Bathroom Rack",
    "const_product_15020201": "Bathroom Sets",
    "const_product_80110": "Bathtub",
    "const_product_15080402": "Bathtub Rack",
    "const_product_90201": "Battery & Charger",
    "const_product_10030101": "Battery Holder",
    "const_product_10030102": "Battery Management System",
    "const_product_10030103": "Battery Materials",
    "const_product_10030104": "Battery Pack",
    "const_product_14010401": "Battery Tester",
    "const_product_90208": "Battery, Storage Battery & Charger",
    "const_product_100301": "Battery, Storage Battery & Charger",
    "const_product_23020201": "BBQ Accessories",
    "const_product_23020202": "BBQ Grill",
    "const_product_23020203": "BBQ Tools",
    "const_product_23020302": "Beach Chair",
    "const_product_11020102": "Beach Chair",
    "const_product_23020303": "Beach Mat & Picnic Blanket",
    "const_product_24030202": "Beach Towel",
    "const_product_14040101": "Beaker",
    "const_product_10102": "Bean & Preparation",
    "const_product_130201": "Bearing",
    "const_product_25020401": "Bearing",
    "const_product_13020103": "Bearing Accessories",
    "const_product_1502": "Beauty & Personal Care",
    "const_product_150202": "Beauty & Personal Care Products",
    "const_product_140501": "Beauty Equipment",
    "const_product_120201": "Beauty Equipment",
    "const_product_90310": "Beauty Equipment",
    "const_product_11010204": "Bed Bench",
    "const_product_15030102": "Bed Pillow",
    "const_product_24060102": "Bed Pillow",
    "const_product_15030103": "Bed Sheets",
    "const_product_150301": "Bedding",
    "const_product_240303": "Bedding",
    "const_product_15030104": "Bedding Set",
    "const_product_11010201": "Bedroom Bed",
    "const_product_110102": "Bedroom Furniture",
    "const_product_11010209": "Bedroom Furniture Sets",
    "const_product_15030105": "Bedspread",
    "const_product_1050102": "Beekeeping Equipment",
    "const_product_20101": "Belt & Accessories",
    "const_product_3010102": "Belt & Accessories",
    "const_product_13050201": "Belt Drying Machine",
    "const_product_2010101": "Belt Parts & Accessories",
    "const_product_17110101": "Bending Machine",
    "const_product_18020204": "Bentonite",
    "const_product_6030104": "Benzene & Derivative",
    "const_product_14020301": "BET Surface Area Analyzer",
    "const_product_17050101": "Beveling Machine",
    "const_product_10304": "Beverage",
    "const_product_17010401": "Beverage Processing Machinery",
    "const_product_27020101": "Bicycle Bell",
    "const_product_27020102": "Bicycle Brake",
    "const_product_27020103": "Bicycle Cassette & Freewheel",
    "const_product_27020104": "Bicycle Chain",
    "const_product_27020105": "Bicycle Crank & Chainwheel",
    "const_product_27020106": "Bicycle Derailleur",
    "const_product_27020107": "Bicycle Fork",
    "const_product_27020108": "Bicycle Frame",
    "const_product_27020109": "Bicycle Handlebar",
    "const_product_27020110": "Bicycle Handlebar Grips",
    "const_product_23020501": "Bicycle Helmet",
    "const_product_27020111": "Bicycle Hubs",
    "const_product_27020112": "Bicycle Light",
    "const_product_230105": "Bicycle Parts & Accessories",
    "const_product_270201": "Bicycle Parts & Accessories",
    "const_product_27020113": "Bicycle Pedal",
    "const_product_27020114": "Bicycle Pump",
    "const_product_27020115": "Bicycle Seat & Saddle",
    "const_product_27020116": "Bicycle Seat Post",
    "const_product_27020117": "Bicycle Spoke",
    "const_product_27020118": "Bicycle Stem",
    "const_product_27020119": "Bicycle Tire",
    "const_product_4010702": "Bicycle Tire",
    "const_product_27020120": "Bicycle Wheel",
    "const_product_8010701": "Bidet",
    "const_product_8010202": "Bidet Faucet",
    "const_product_8010702": "Bidet Faucet",
    "const_product_230106": "Bike",
    "const_product_270202": "Bike",
    "const_product_2702": "Bike & Tricycle",
    "const_product_27020121": "Bike Basket",
    "const_product_27020122": "Bike Computer",
    "const_product_27040402": "Bike Trailer",
    "const_product_20020301": "Bill Printing Machine",
    "const_product_15090104": "Billboard",
    "const_product_23010205": "Billiards",
    "const_product_190103": "Binding Machine",
    "const_product_20020101": "Binding Machine",
    "const_product_1040101": "Bio Fertilizer",
    "const_product_60901": "Biochemical",
    "const_product_180302": "Biofuel",
    "const_product_120101": "Biological Products",
    "const_product_17010105": "Biomass Briquette Machine",
    "const_product_17010106": "Biomass Dryer",
    "const_product_13040301": "Biomass Generator",
    "const_product_3020201": "Birthday Gifts & Crafts",
    "const_product_18010302": "Bismuth",
    "const_product_19030201": "Blackboard",
    "const_product_15030106": "Blanket",
    "const_product_25010104": "Blasting Media",
    "const_product_23010302": "Bleachers",
    "const_product_11031102": "Bleachers",
    "const_product_13030303": "Blind Valve",
    "const_product_110808": "Blind, Shade & Shutter",
    "const_product_80405": "Blinds, Shades & Shutters",
    "const_product_20010202": "Blister Cards",
    "const_product_17070101": "Blister Machine",
    "const_product_20010103": "Blister Packaging Machine",
    "const_product_8080702": "Blockboard",
    "const_product_70201": "Blockchain Miner",
    "const_product_17070102": "Blow Molding Machine",
    "const_product_4010302": "Blower Motor",
    "const_product_13050602": "Blowers",
    "const_product_17100201": "Blowing Mould",
    "const_product_9020201": "Bluetooth Dongle",
    "const_product_4010401": "Bluetooth Dongle",
    "const_product_9020202": "Bluetooth Gloves",
    "const_product_9020701": "Bluetooth Gloves",
    "const_product_9010401": "Bluetooth Headset",
    "const_product_9020203": "Bluetooth Headset",
    "const_product_70502": "Bluetooth Products",
    "const_product_90202": "Bluetooth Products",
    "const_product_15020501": "Blush",
    "const_product_27020201": "BMX Bicycle",
    "const_product_230405": "Board Game & Card",
    "const_product_260301": "Board Game & Card",
    "const_product_17040101": "Board Making Machine",
    "const_product_10010303": "Board to Board Connector",
    "const_product_27050201": "Boat Anchors",
    "const_product_27050202": "Boat Covers",
    "const_product_27050203": "Boat Fenders",
    "const_product_6020102": "Boat Paint",
    "const_product_27050204": "Boat Propellers",
    "const_product_27040403": "Boat Trailer",
    "const_product_270501": "Boats & Ships",
    "const_product_12020101": "Body Art Tools",
    "const_product_15020202": "Body Art Tools",
    "const_product_20010203": "Body Bag",
    "const_product_3040201": "Body Bag",
    "const_product_12020102": "Body Beauty Equipment",
    "const_product_15021001": "Body Cream & Lotion",
    "const_product_12030202": "Body Fluid-Processing & Circulation Device",
    "const_product_15020502": "Body Glitter",
    "const_product_3010103": "Body Piercing",
    "const_product_14020402": "Body Scale",
    "const_product_15021002": "Body Scrub",
    "const_product_12030301": "Body Temperature Measurement Instrument",
    "const_product_13050101": "Boiler",
    "const_product_130501": "Boiler & Furnace",
    "const_product_13050102": "Boiler Parts",
    "const_product_13020301": "Bolts",
    "const_product_20030101": "Book & Magazine Printing",
    "const_product_19020201": "Book Cover & Case",
    "const_product_11060301": "Bookshelf",
    "const_product_4030303": "Booster Cables",
    "const_product_2030403": "Boots",
    "const_product_17110401": "Boring Machine",
    "const_product_13020201": "Boring Tool",
    "const_product_5010101": "Boston Bag",
    "const_product_15040301": "Bottle",
    "const_product_17070103": "Bottle Blowing Machine",
    "const_product_3040401": "Bottle Opener",
    "const_product_15010602": "Bottle Opener",
    "const_product_23010206": "Bow, Arrow & Shooting Equipment",
    "const_product_23010207": "Bowling Equipment",
    "const_product_15010901": "Bowls",
    "const_product_27040501": "Box Truck",
    "const_product_2010301": "Bra",
    "const_product_3010104": "Bracelet & Bangle",
    "const_product_25020201": "Brackets",
    "const_product_4020306": "Brake",
    "const_product_4010501": "Brake Light",
    "const_product_13060202": "Brazing Machine",
    "const_product_9030502": "Bread & Cake Maker",
    "const_product_10010201": "Breadboard",
    "const_product_100201": "Breaker & Protector",
    "const_product_15020203": "Breast Care",
    "const_product_8030102": "Brick",
    "const_product_17040102": "Brick Making Machine",
    "const_product_80301": "Brick, Tile & Accessories",
    "const_product_2020301": "Bridesmaid Dresses",
    "const_product_8060101": "Bridge",
    "const_product_80601": "Bridge & Bridge Structure",
    "const_product_8060102": "Bridge Bearing",
    "const_product_8080403": "Bridge Fence",
    "const_product_50103": "Briefcase",
    "const_product_17060201": "Briquette Machines",
    "const_product_13020202": "Broach",
    "const_product_20030102": "Brochure Printing",
    "const_product_3010105": "Brooch",
    "const_product_15040401": "Broom & Dustpan",
    "const_product_25010402": "Brush",
    "const_product_15060101": "Brush Cutter",
    "const_product_17010201": "Brush Making Machine",
    "const_product_16030101": "Bubble Machine",
    "const_product_15040302": "Bucket",
    "const_product_15040402": "Bucket",
    "const_product_5010102": "Bucket Bag",
    "const_product_2040104": "Buckle, Hook & Loop",
    "const_product_6020103": "Building Coating",
    "const_product_80501": "Building Glass",
    "const_product_250201": "Building Hardware",
    "const_product_80801": "Building Heater",
    "const_product_170401": "Building Material Making Machinery",
    "const_product_17040103": "Building Material Making Machinery Parts",
    "const_product_80605": "Building Sealing",
    "const_product_80602": "Building Steel & Structure",
    "const_product_160601": "Bulb & Lamp",
    "const_product_17040201": "Bulk Machine",
    "const_product_1030101": "Bulking Agents",
    "const_product_17040202": "Bulldozer",
    "const_product_2020101": "Bullet Proof Clothing",
    "const_product_21030101": "Bullet Proof Clothing",
    "const_product_15070110": "Bunting Bag",
    "const_product_27050205": "Buoy",
    "const_product_14040102": "Buret",
    "const_product_270301": "Bus",
    "const_product_40602": "Bus",
    "const_product_27030101": "Bus Parts",
    "const_product_11031104": "Bus Shelter & Taxi Stop",
    "const_product_10020401": "Busbar",
    "const_product_17120101": "Busbar Machine",
    "const_product_8080601": "Bushing",
    "const_product_190203": "Business Card Case",
    "const_product_20020302": "Business Card Printing Machine",
    "const_product_13060203": "Butt Welder",
    "const_product_13030304": "Butterfly Valve",
    "const_product_2040105": "Button",
    "const_product_10030105": "Button Cell Battery",
    "const_product_9020101": "Button Cell Battery",
    "const_product_17020101": "Button Making Machine",
    "const_product_17020102": "Buttonhole Machine",
    "const_product_1010101": "Cabbage & Broccoli",
    "const_product_11070201": "Cabinet Doors",
    "const_product_10030303": "Cable Assembly",
    "const_product_10010101": "Cable Clips",
    "const_product_10010102": "Cable Conduit",
    "const_product_10010103": "Cable End Caps",
    "const_product_17120102": "Cable Extrusion Machine",
    "const_product_10010104": "Cable Gland",
    "const_product_10010105": "Cable Marker",
    "const_product_100101": "Cable Parts",
    "const_product_10010106": "Cable Sleeve",
    "const_product_14010402": "Cable Tester",
    "const_product_10010107": "Cable Ties",
    "const_product_140502": "Cable Tools",
    "const_product_10030304": "Cable Tools",
    "const_product_250107": "Cable Tools",
    "const_product_10010108": "Cable Tray",
    "const_product_17120103": "Cable Wrapping Machine",
    "const_product_18010303": "Cadmium",
    "const_product_1050103": "Cage & Coop for Poultry, Livestock",
    "const_product_8050201": "Calcium Silicate Ceiling",
    "const_product_90403": "Calculator",
    "const_product_190104": "Calculator",
    "const_product_19020202": "Calendar",
    "const_product_30603": "Calendar",
    "const_product_20030103": "Calendar Printing",
    "const_product_14010301": "Calibrator",
    "const_product_10040201": "Call Center Equipment",
    "const_product_3060202": "Calligraphy",
    "const_product_50202": "Camera Bag",
    "const_product_70703": "Camera Bag",
    "const_product_9020601": "Camera Bag",
    "const_product_9020602": "Camera Battery",
    "const_product_10030106": "Camera Battery",
    "const_product_9020603": "Camera Charger",
    "const_product_21010202": "Camera Housing",
    "const_product_9020604": "Camera Lens",
    "const_product_2010302": "Camisole & Lingerie",
    "const_product_150602": "Camping & Outdoor Gear",
    "const_product_230203": "Camping & Outdoor Gear",
    "const_product_23020304": "Camping Bed",
    "const_product_11020111": "Camping Bed",
    "const_product_11020110": "Camping Chairs",
    "const_product_23020305": "Camping Chairs",
    "const_product_23020306": "Camping Light",
    "const_product_160502": "Camping Light",
    "const_product_23020307": "Camping Table",
    "const_product_11020112": "Camping Table",
    "const_product_3020101": "Candle",
    "const_product_30201": "Candle & Candle Holders",
    "const_product_3020102": "Candle Holders",
    "const_product_16010301": "Candle Light",
    "const_product_10305": "Canned Food",
    "const_product_23020601": "Canoe",
    "const_product_5050301": "Canvas Shopping Bag",
    "const_product_8080602": "Cap",
    "const_product_15010501": "Cap Highlights",
    "const_product_15020401": "Cap Highlights",
    "const_product_17020103": "Cap Making Machine",
    "const_product_10010501": "Capacitor",
    "const_product_40603": "Car",
    "const_product_270303": "Car",
    "const_product_2703": "Car & Bus",
    "const_product_270304": "Car Accessories",
    "const_product_40102": "Car Accessories",
    "const_product_40103": "Car Air Conditioning System",
    "const_product_4060101": "Car Air Purifier",
    "const_product_4040101": "Car Alarm",
    "const_product_4020103": "Car Alternator",
    "const_product_4010402": "Car Audio",
    "const_product_4020104": "Car Battery",
    "const_product_10030107": "Car Battery",
    "const_product_4030304": "Car Bench",
    "const_product_4040102": "Car Black Box",
    "const_product_4010201": "Car Bumper",
    "const_product_4010403": "Car Charger",
    "const_product_4030202": "Car Cleaning Chemical",
    "const_product_4010404": "Car Communications",
    "const_product_4010303": "Car Condenser",
    "const_product_4030101": "Car Cover",
    "const_product_40301": "Car Decoration",
    "const_product_4010202": "Car Door",
    "const_product_4040103": "Car DVR",
    "const_product_40601": "Car Electrical Appliances",
    "const_product_40104": "Car Electronics",
    "const_product_100501": "Car Electronics",
    "const_product_4010304": "Car Evaporator",
    "const_product_4060102": "Car Fan",
    "const_product_4010203": "Car Fender",
    "const_product_4010204": "Car Glass & Window",
    "const_product_4010205": "Car Grille",
    "const_product_4030102": "Car Holder",
    "const_product_4010502": "Car Interior Light",
    "const_product_4030305": "Car Lift",
    "const_product_40105": "Car Light & Auto Mirror",
    "const_product_160503": "Car Light & Auto Mirror",
    "const_product_40402": "Car Lock, Motorcycle Lock & Bicycle Lock",
    "const_product_27020123": "Car Lock, Motorcycle Lock & Bicycle Lock",
    "const_product_270805": "Car Lock, Motorcycle Lock & Bicycle Lock",
    "const_product_8040301": "Car Lock, Motorcycle Lock & Bicycle Lock",
    "const_product_40302": "Car Maintenance",
    "const_product_4030103": "Car Mat",
    "const_product_4030104": "Car Organizer",
    "const_product_6020104": "Car Paint",
    "const_product_4030105": "Car Perfume & Air Freshener",
    "const_product_4030203": "Car Polisher",
    "const_product_4040104": "Car Radar",
    "const_product_4010206": "Car Ramp",
    "const_product_4040105": "Car Rearview Camera",
    "const_product_4040106": "Car Rearview Monitor",
    "const_product_4060103": "Car Refrigerator",
    "const_product_9030601": "Car Refrigerator",
    "const_product_4060104": "Car Rice Cooker",
    "const_product_4030106": "Car Roof Box & Rack",
    "const_product_40401": "Car Safety",
    "const_product_210402": "Car Safety",
    "const_product_4010207": "Car Seat",
    "const_product_4030107": "Car Seat Cover & Cushion",
    "const_product_4020105": "Car Speakers & Horn",
    "const_product_4030108": "Car Sticker",
    "const_product_4030109": "Car Sunshade",
    "const_product_4020106": "Car Switch",
    "const_product_4010703": "Car Tire",
    "const_product_4020307": "Car Tire",
    "const_product_27040404": "Car Trailer",
    "const_product_4060105": "Car Vacuum Cleaner",
    "const_product_4010405": "Car Video",
    "const_product_9010801": "Car Video",
    "const_product_4030204": "Car Wax",
    "const_product_4030110": "Car Window Film",
    "const_product_6030105": "Carbohydrate",
    "const_product_6040301": "Carbon Fiber",
    "const_product_6040402": "Carbonate",
    "const_product_17050301": "Carbonization Furnace",
    "const_product_6030106": "Carboxylic Acid & Derivative",
    "const_product_50104": "Card Holder",
    "const_product_8040302": "Card Lock",
    "const_product_20030104": "Card Printing",
    "const_product_70101": "Card Reader",
    "const_product_9020605": "Card Reader",
    "const_product_20010302": "Cardboard",
    "const_product_270401": "Cargo & Storage",
    "const_product_27050102": "Cargo Ship",
    "const_product_240304": "Carpet, Rug & Mat",
    "const_product_150302": "Carpet, Rug & Mat",
    "const_product_1010102": "Carrot & Radish",
    "const_product_2704": "Cart & Truck",
    "const_product_17080101": "Carton Machine",
    "const_product_20010104": "Carton Machine",
    "const_product_260101": "Cartoon Character",
    "const_product_30504": "Carving & Sculpture",
    "const_product_8020103": "Carving & Sculpture",
    "const_product_20010401": "Case Packer",
    "const_product_11030901": "Cash Counter",
    "const_product_230406": "Casino Supplies",
    "const_product_9010205": "Cassette & Tape",
    "const_product_1710": "Casting & Forging",
    "const_product_171001": "Casting & Forging",
    "const_product_13070102": "Casting & Forging",
    "const_product_26070101": "Cat Toys",
    "const_product_6050201": "Catalyst & Active Agent",
    "const_product_8080201": "Caulking Gun",
    "const_product_21050101": "Caution Board",
    "const_product_21050102": "Caution Tape",
    "const_product_21010201": "CCTV Camera",
    "const_product_7040201": "CD & DVD Media",
    "const_product_9010202": "CD & DVD Media",
    "const_product_70702": "CD Bag & Case",
    "const_product_9010203": "CD Bag & Case",
    "const_product_50201": "CD Bag & Case",
    "const_product_150805": "CD Bag & Case",
    "const_product_7040101": "CD Burner & DVD Burner",
    "const_product_9010204": "CD Burner & DVD Burner",
    "const_product_7040102": "CD Drive",
    "const_product_15080403": "CD Rack",
    "const_product_11010610": "CD Rack",
    "const_product_9010601": "CD, MD & Cassette Player",
    "const_product_80502": "Ceiling",
    "const_product_8050202": "Ceiling Grid",
    "const_product_16020101": "Ceiling Light",
    "const_product_2020302": "Celebrity Dresses",
    "const_product_23040303": "Cello",
    "const_product_80606": "Cement & Precast Component",
    "const_product_8050303": "Cement Board",
    "const_product_17040104": "Cement Making Machine",
    "const_product_17060301": "Cementing Tool",
    "const_product_13030102": "Centrifugal Compressor",
    "const_product_13030201": "Centrifugal Pump",
    "const_product_14040103": "Centrifuge Tube",
    "const_product_15010302": "Ceramic Cup & Mug",
    "const_product_6040302": "Ceramic Fiber",
    "const_product_170802": "Ceramic Machine",
    "const_product_180204": "Ceramic Material",
    "const_product_260602": "Ceramic Toys",
    "const_product_15050102": "Ceramic Watch",
    "const_product_150111": "Ceramics, Porcelain & Enamel",
    "const_product_10103": "Cereal",
    "const_product_15010801": "Chafing Dish",
    "const_product_25020202": "Chain",
    "const_product_23010303": "Chain Link Fence",
    "const_product_8080802": "Chain Link Fence",
    "const_product_15060102": "Chainsaw",
    "const_product_11060201": "Chair",
    "const_product_24030101": "Chair Cover",
    "const_product_25020203": "Chair Mechanism",
    "const_product_11070202": "Chair Mechanism",
    "const_product_11010601": "Chaise Lounge",
    "const_product_19030101": "Chalk",
    "const_product_17050102": "Chamfering Machine",
    "const_product_16040101": "Chandelier",
    "const_product_13030305": "Check Valve",
    "const_product_14020403": "Check Weigher",
    "const_product_11031105": "Check-in Counter",
    "const_product_14020404": "Checking Fixture",
    "const_product_15010603": "Cheese Tools",
    "const_product_1703": "Chemical & Pharmaceutical Machinery",
    "const_product_6050202": "Chemical Auxiliary",
    "const_product_60502": "Chemical Auxiliary & Catalyst",
    "const_product_17030101": "Chemical Drying Equipment",
    "const_product_13050202": "Chemical Drying Equipment",
    "const_product_170301": "Chemical Equipment & Machinery",
    "const_product_61102": "Chemical Equipment & Machinery",
    "const_product_240103": "Chemical Fabrics",
    "const_product_240102": "Chemical Fiber",
    "const_product_17020201": "Chemical Fiber Machine",
    "const_product_60505": "Chemical Filling",
    "const_product_17030102": "Chemical Granulators",
    "const_product_17030103": "Chemical Machine Parts",
    "const_product_611": "Chemical Machinery",
    "const_product_2020102": "Chemical Protective Clothing",
    "const_product_17030104": "Chemical Reactor",
    "const_product_60506": "Chemical Reagent",
    "const_product_605": "Chemical Reagent & Auxiliary",
    "const_product_61201": "Chemical Stocks",
    "const_product_17030105": "Chemical Storage & Transportation Equipment",
    "const_product_21050103": "Chemical Storage Cabinet",
    "const_product_61202": "Chemical Waste",
    "const_product_6": "Chemicals",
    "const_product_1030102": "Chewing Gum Bases",
    "const_product_110101": "Children & Baby Furniture",
    "const_product_15070111": "Children & Baby Furniture",
    "const_product_20105": "Children's Apparel",
    "const_product_2030404": "Children's Shoes",
    "const_product_2010201": "Children's T-Shirts",
    "const_product_1010103": "Chilli & Pepper",
    "const_product_50504": "Chip Case & Bag",
    "const_product_17120104": "Chip Mounter",
    "const_product_25010403": "Chisel & Punch",
    "const_product_6040403": "Chloride",
    "const_product_15010604": "Chopping Board",
    "const_product_17010202": "Chopstick Making Machine",
    "const_product_15010902": "Chopsticks",
    "const_product_3020202": "Christmas Gifts & Crafts",
    "const_product_14010102": "Chromatography, Spectroscopy Instrument",
    "const_product_13020203": "Chuck",
    "const_product_15080302": "Cigar Humidor & Case",
    "const_product_15080303": "Cigarette Case & Box",
    "const_product_13020302": "Circlip",
    "const_product_100102": "Circuit Board",
    "const_product_10020102": "Circuit Breaker",
    "const_product_10020502": "Circuit Breaker",
    "const_product_10010304": "Circular Connector",
    "const_product_27030102": "City Bus",
    "const_product_14010302": "Clamp Meter",
    "const_product_25020204": "Clamps",
    "const_product_23040304": "Clarinet",
    "const_product_11050301": "Classic Bed",
    "const_product_11050302": "Classic Cabinet",
    "const_product_11050303": "Classic Chair",
    "const_product_110503": "Classic Furniture",
    "const_product_11050304": "Classic Sofa",
    "const_product_11050305": "Classic Table & Desk",
    "const_product_23040202": "Classical Guitar",
    "const_product_1301": "Cleaning & Environmental Protection Equipment",
    "const_product_1504": "Cleaning and Storage",
    "const_product_15040403": "Cleaning Ball, Sponge & Cloth",
    "const_product_4030205": "Cleaning Ball, Sponge & Cloth",
    "const_product_4030206": "Cleaning Brush",
    "const_product_15040404": "Cleaning Brush",
    "const_product_130102": "Cleaning Machine",
    "const_product_13010202": "Cleaning Machine Parts",
    "const_product_100108": "Cleaning Swab",
    "const_product_8050101": "Clear Glass",
    "const_product_23020802": "Climbing Gear",
    "const_product_23040102": "Climbing Wall",
    "const_product_19020203": "Clip",
    "const_product_30604": "Clock & Watch",
    "const_product_90404": "Clock & Watch",
    "const_product_150501": "Clock & Watch",
    "const_product_15050103": "Clock & Watch Accessories",
    "const_product_4020308": "Clock Spring",
    "const_product_1505": "Clock, Watch & Eyewear",
    "const_product_30605": "Cloisonne",
    "const_product_20401": "Clothing Accessories",
    "const_product_4020309": "Clutch",
    "const_product_13040401": "Clutch",
    "const_product_5010103": "Clutch Bag",
    "const_product_17110402": "CNC Machine Tools",
    "const_product_27030103": "Coach",
    "const_product_180303": "Coal & Charcoal",
    "const_product_3040402": "Coaster & Placemat",
    "const_product_15010903": "Coaster & Placemat",
    "const_product_8010104": "Coat Hooks",
    "const_product_11010701": "Coat Rack",
    "const_product_11060302": "Coat Racks & Shoe Racks",
    "const_product_20020403": "Coated Paper",
    "const_product_6050203": "Coating Auxiliary Agent",
    "const_product_20020102": "Coating Machine",
    "const_product_13070103": "Coating Service",
    "const_product_10010305": "Coaxial Connector",
    "const_product_16010201": "COB LED",
    "const_product_18010304": "Cobalt",
    "const_product_2020303": "Cocktail Dresses",
    "const_product_15010605": "Cocktail Shaker",
    "const_product_10104": "Cocoa, Coffee & Preparation",
    "const_product_20020303": "Code Printing Machine",
    "const_product_20010402": "Coding Machine",
    "const_product_15010401": "Coffee & Tea Set",
    "const_product_17010402": "Coffee Bean Roaster",
    "const_product_9030503": "Coffee Grinder",
    "const_product_9030504": "Coffee Maker",
    "const_product_11010607": "Coffee Table",
    "const_product_3040202": "Coffin & Casket",
    "const_product_17120105": "Coil Winding Machine",
    "const_product_9040103": "Coin Counter & Sorter",
    "const_product_9020401": "Coin Operated Game Machine",
    "const_product_230407": "Coin Operated Game Machine",
    "const_product_5010201": "Coin Purse",
    "const_product_180401": "Coke",
    "const_product_17100101": "Cold Forging",
    "const_product_13050501": "Cold Room",
    "const_product_190304": "Color Card & Color Comparator",
    "const_product_20020201": "Color Card & Color Comparator",
    "const_product_240402": "Color Card & Color Comparator",
    "const_product_140205": "Color Card & Color Comparator",
    "const_product_20020304": "Color Printing Press",
    "const_product_17110601": "Color Sorter",
    "const_product_3040203": "Columbarium",
    "const_product_13020303": "Combination & Joint Fittings",
    "const_product_8040303": "Combination Lock",
    "const_product_11060104": "Combination Sofa",
    "const_product_10030602": "Combination Switch",
    "const_product_90401": "Commerce & Finance Electronics",
    "const_product_904": "Commerce & Office Electronics",
    "const_product_1103": "Commercial Furniture",
    "const_product_110312": "Commercial Furniture",
    "const_product_13050502": "Commercial Refrigerator & Freezer",
    "const_product_9030602": "Commercial Refrigerator & Freezer",
    "const_product_220302": "Commercial Service",
    "const_product_12020401": "Commode Chair",
    "const_product_170102": "Commodity Making Machine",
    "const_product_10030305": "Communication Cable",
    "const_product_10040202": "Communication Module",
    "const_product_7060101": "Communication Module",
    "const_product_2201": "Communication Services",
    "const_product_160602": "Compact Bulb & Lamp",
    "const_product_17040203": "Compactor",
    "const_product_23020308": "Compass",
    "const_product_19020204": "Compasses",
    "const_product_14010403": "Component Tester",
    "const_product_8040102": "Composite Door",
    "const_product_20010303": "Composite Packaging Materials",
    "const_product_8080302": "Composite Pipe & Tube",
    "const_product_8040401": "Composite Window",
    "const_product_240104": "Compound Fabric",
    "const_product_1040102": "Compound Fertilizer",
    "const_product_6080201": "Compound Fertilizer",
    "const_product_20020103": "Compound Machine",
    "const_product_13050203": "Compressed Air Dryer",
    "const_product_17100202": "Compression Mould",
    "const_product_9030603": "Compressor",
    "const_product_130301": "Compressor",
    "const_product_13030103": "Compressor Parts",
    "const_product_13030104": "Compressor Unit",
    "const_product_70202": "Computer",
    "const_product_70503": "Computer Bag",
    "const_product_50203": "Computer Bag",
    "const_product_70504": "Computer Cable",
    "const_product_10030306": "Computer Cable",
    "const_product_70302": "Computer Case",
    "const_product_703": "Computer Components",
    "const_product_11030401": "Computer Desk",
    "const_product_7": "Computer Products",
    "const_product_70704": "Computer Products Stocks",
    "const_product_8010501": "Computerized Shower Room",
    "const_product_15020503": "Concealer",
    "const_product_14010103": "Concentration Meter",
    "const_product_17040204": "Concrete Machinery",
    "const_product_80202": "Concrete,Mortar & Admixtures",
    "const_product_6050101": "Concrete,Mortar & Admixtures",
    "const_product_13050503": "Condenser",
    "const_product_10306": "Condiment & Seasoning",
    "const_product_11030403": "Conference Table",
    "const_product_190105": "Conferencing System",
    "const_product_90405": "Conferencing System",
    "const_product_4020402": "Connecting Rod",
    "const_product_100103": "Connector & Terminals",
    "const_product_11010705": "Console Table",
    "const_product_8": "Construction & Decoration",
    "const_product_80507": "Construction Accessories",
    "const_product_27080101": "Construction Elevator",
    "const_product_17040205": "Construction Elevator",
    "const_product_80802": "Construction Equipment & Tools",
    "const_product_250108": "Construction Equipment & Tools",
    "const_product_80607": "Construction Materials Stocks",
    "const_product_80803": "Construction Pipe & Tube",
    "const_product_25020102": "Construction Pipe & Tube",
    "const_product_220201": "Consulting Service",
    "const_product_9": "Consumer Electronics",
    "const_product_90501": "Consumer Electronics Stocks",
    "const_product_15050202": "Contact Lens & Care Products",
    "const_product_10010601": "Contactor",
    "const_product_20010204": "Container & Fittings",
    "const_product_270504": "Container & Fittings",
    "const_product_19010102": "Continuous Ink System",
    "const_product_13030306": "Control Valve",
    "const_product_21040101": "Convex Mirror",
    "const_product_13040101": "Conveyor",
    "const_product_130401": "Conveyor & Parts",
    "const_product_13040102": "Conveyor Belt",
    "const_product_13040103": "Conveyor Chain",
    "const_product_13040104": "Conveyor Frame",
    "const_product_13040105": "Conveyor Idler",
    "const_product_13040106": "Conveyor Roller",
    "const_product_17010403": "Cooking & Baking Equipment",
    "const_product_150102": "Cookware",
    "const_product_15010201": "Cookware Parts",
    "const_product_15010202": "Cookware Set",
    "const_product_150806": "Cooler Bag & Box",
    "const_product_120305": "Cooler Bag & Box",
    "const_product_23020309": "Cooler Bag & Box",
    "const_product_50402": "Cooler Bag & Box",
    "const_product_70303": "Cooling Fan & Heatsinks",
    "const_product_4020203": "Cooling System",
    "const_product_13050504": "Cooling Tower",
    "const_product_70705": "Copier & Copier Supplies",
    "const_product_90406": "Copier & Copier Supplies",
    "const_product_190106": "Copier & Copier Supplies",
    "const_product_18010305": "Copper",
    "const_product_8080303": "Copper Pipe & Tube",
    "const_product_8060402": "Copper Profile",
    "const_product_18010202": "Copper Scrap",
    "const_product_19030202": "Cork Board",
    "const_product_8050304": "Corner Bead",
    "const_product_13010203": "Corner Cleaning Machine",
    "const_product_11060103": "Corner Sofa",
    "const_product_20010304": "Corrugated Paper & Board",
    "const_product_17080102": "Corrugated Paper Making Machine",
    "const_product_15020601": "Cosmetic Bag",
    "const_product_5050101": "Cosmetic Bag",
    "const_product_3040101": "Cosmetic Bag & Case",
    "const_product_50501": "Cosmetic Bag & Case",
    "const_product_150211": "Cosmetic Bag & Case",
    "const_product_15020602": "Cosmetic Case",
    "const_product_5050102": "Cosmetic Case",
    "const_product_240105": "Cotton",
    "const_product_15020603": "Cotton Buds",
    "const_product_15020604": "Cotton Pads",
    "const_product_17010203": "Cotton Swab Machine",
    "const_product_9040104": "Counterfeit Detector",
    "const_product_8020104": "Countertop & Vanity Top",
    "const_product_8080603": "Coupling",
    "const_product_23010304": "Court Maintenance Equipment",
    "const_product_14040104": "Cover Glass",
    "const_product_70301": "CPU",
    "const_product_303": "Crafts By Material",
    "const_product_305": "Crafts By Style",
    "const_product_304": "Crafts By Use",
    "const_product_170103": "Crane",
    "const_product_14020405": "Crane Scale",
    "const_product_40204": "Crank Mechanism",
    "const_product_4020403": "Crankshaft",
    "const_product_17010301": "Crawler Crane",
    "const_product_19020102": "Crayon",
    "const_product_8080803": "Crimped Wire Mesh",
    "const_product_8080604": "Cross",
    "const_product_25010404": "Crowbar",
    "const_product_9010302": "CRT Display",
    "const_product_9010702": "CRT TV",
    "const_product_180205": "Crucible",
    "const_product_15010606": "Cruet, Spice Jar & Bottle",
    "const_product_27020202": "Cruiser Bicycle",
    "const_product_171107": "Crushing & Culling Machine",
    "const_product_30303": "Crystal Crafts",
    "const_product_3010301": "Crystal Jewelry",
    "const_product_3040301": "Crystal Key Chain",
    "const_product_2040106": "Cuff Links",
    "const_product_3010106": "Cuff Links",
    "const_product_150103": "Cup & Mug",
    "const_product_15010402": "Cup & Mug",
    "const_product_15010303": "Cup & Saucer Set",
    "const_product_20010205": "Cup Sleeve",
    "const_product_14020101": "Current Meter",
    "const_product_10010702": "Current Sensor",
    "const_product_10020601": "Current Transmitter",
    "const_product_240305": "Curtain",
    "const_product_150303": "Curtain",
    "const_product_8040201": "Curtain Rod",
    "const_product_8040202": "Curtain Rod Accessories",
    "const_product_8040203": "Curtain Track",
    "const_product_8040204": "Curtain Track Fittings",
    "const_product_8060301": "Curtain Wall",
    "const_product_80603": "Curtain Wall & Accessories",
    "const_product_8060302": "Curtain Wall Profile",
    "const_product_15030107": "Cushion Cover",
    "const_product_24060103": "Cushion Cover",
    "const_product_20501": "Customized Service for Apparel",
    "const_product_70102": "Customized Service for Computer Products",
    "const_product_90311": "Customized Service for Electronics",
    "const_product_100109": "Customized Service for Electronics",
    "const_product_120601": "Customized Service for Health & Medical",
    "const_product_170108": "Customized Service for Machinery",
    "const_product_240602": "Customized Service for Textile",
    "const_product_17050103": "Cut to Length Line",
    "const_product_15010904": "Cutlery Sets",
    "const_product_25020402": "Cutter & Tongs",
    "const_product_130202": "Cutter & Tongs",
    "const_product_171101": "Cutting & Fold-bend Machine",
    "const_product_13010601": "Cylinder",
    "const_product_4020404": "Cylinder Head",
    "const_product_4020405": "Cylinder Head Cover",
    "const_product_4020406": "Cylinder Head Gasket",
    "const_product_4020407": "Cylinder Liner",
    "const_product_23040305": "Cymbal",
    "const_product_17010404": "Dairy Processing Machinery",
    "const_product_10203": "Dairy Products",
    "const_product_2030405": "Dance Shoes",
    "const_product_150807": "Darning & Sewing Utensil",
    "const_product_17020104": "Darning & Sewing Utensil",
    "const_product_230209": "Dart Board",
    "const_product_260403": "Dart Board",
    "const_product_14010303": "Data Logger",
    "const_product_220202": "Database Services",
    "const_product_10030203": "DC Motor",
    "const_product_100404": "Decoder",
    "const_product_80503": "Decoration Materials",
    "const_product_3040102": "Decorative Box",
    "const_product_50505": "Decorative Box",
    "const_product_240306": "Decorative Cloth",
    "const_product_150304": "Decorative Cloth",
    "const_product_8050305": "Decorative Film",
    "const_product_11080202": "Decorative Mirror",
    "const_product_8050402": "Decorative Mirrors",
    "const_product_8050306": "Decorative Paper",
    "const_product_14040105": "Deep Well Plate",
    "const_product_9030104": "Dehumidifier",
    "const_product_25010601": "Demolition Hammer",
    "const_product_140503": "Demonstrational & Teaching Utensil",
    "const_product_190301": "Demonstrational & Teaching Utensil",
    "const_product_240106": "Denim",
    "const_product_12030101": "Dental Apparatus",
    "const_product_140504": "Dental Apparatus",
    "const_product_12030102": "Dental Care",
    "const_product_12030103": "Dental Chair",
    "const_product_12030104": "Dental Consumables",
    "const_product_12030105": "Dental Drill & Accessories",
    "const_product_120301": "Dental Equipment & Supplies",
    "const_product_15020801": "Dental Floss",
    "const_product_12030106": "Dental Handpiece",
    "const_product_12030107": "Dental Kit",
    "const_product_15020204": "Deodorant & Antiperspirant",
    "const_product_6050204": "Desiccant & Sorbent",
    "const_product_20030105": "Design & Photography",
    "const_product_15050104": "Desk & Table Clock",
    "const_product_19010103": "Desktop 3D Printer",
    "const_product_20020305": "Desktop 3D Printer",
    "const_product_702": "Desktops & Servers",
    "const_product_140102": "Detector",
    "const_product_20020202": "Developing Machine",
    "const_product_4030306": "Diagnostic Tool",
    "const_product_25010201": "Diamond Cutter",
    "const_product_25010202": "Diamond Drilling Tools",
    "const_product_25010203": "Diamond Fickert",
    "const_product_25010204": "Diamond Grinding Wheel",
    "const_product_3010302": "Diamond Jewelry",
    "const_product_25010205": "Diamond Router Bit",
    "const_product_25010206": "Diamond Saw Blade",
    "const_product_250102": "Diamond Tools",
    "const_product_171108": "Diamond Tools",
    "const_product_25010207": "Diamond Wire Saw",
    "const_product_50506": "Diaper Bag",
    "const_product_17080103": "Diaper Machine",
    "const_product_13030202": "Diaphragm Pump",
    "const_product_13030307": "Diaphragm Valve",
    "const_product_17100102": "Die Casting",
    "const_product_17050104": "Die Casting Machine",
    "const_product_20020104": "Die Cutting Machine",
    "const_product_17100203": "Die-Casting Mould",
    "const_product_13040302": "Diesel Generator",
    "const_product_10010703": "Differential Pressure Sensor",
    "const_product_10020602": "Differential Pressure Transmitter",
    "const_product_70706": "Digital Camera",
    "const_product_9020606": "Digital Camera",
    "const_product_902": "Digital Devices",
    "const_product_9010802": "Digital Photo Frame",
    "const_product_30606": "Digital Photo Frame",
    "const_product_20020306": "Digital Printing Machine",
    "const_product_502": "Digital Products Bags",
    "const_product_7050101": "Digital Speaker",
    "const_product_16010302": "Dimmable Bulb",
    "const_product_16070102": "Dimmer",
    "const_product_11010501": "Dining Chairs",
    "const_product_110105": "Dining Room Furniture",
    "const_product_11010504": "Dining Room Furniture Sets",
    "const_product_11010502": "Dining Table",
    "const_product_15010905": "Dinner Knife",
    "const_product_15010906": "Dinner Sets",
    "const_product_10030603": "DIP Switch",
    "const_product_6020203": "Direct Dye",
    "const_product_40502": "Dirt Bike",
    "const_product_230210": "Dirt Bike",
    "const_product_270205": "Dirt Bike",
    "const_product_15040102": "Dish Detergent",
    "const_product_9030505": "Dishwasher & Disinfective Apparatus",
    "const_product_12030203": "Disinfect Equipment & Sterilizer",
    "const_product_20030106": "Disk / CD Replication & Printing",
    "const_product_90102": "Disk, Cassette, Tape & Record",
    "const_product_6020204": "Disperse Dye",
    "const_product_10010704": "Displacement Sensor",
    "const_product_70304": "Display & Accessories",
    "const_product_90103": "Display & Accessories",
    "const_product_11030903": "Display Stand",
    "const_product_15090105": "Display Stand",
    "const_product_15080201": "Disposable Apron",
    "const_product_15080202": "Disposable Cap",
    "const_product_15010304": "Disposable Cup & Mug",
    "const_product_15080203": "Disposable Gloves",
    "const_product_12030402": "Disposable Medical Supplies",
    "const_product_15080204": "Disposable Oversleeves",
    "const_product_150802": "Disposable Products",
    "const_product_15080205": "Disposable Sanitary Products",
    "const_product_15080206": "Disposable Shoe Covers",
    "const_product_15080207": "Disposable Tableware",
    "const_product_15080208": "Disposable Towel",
    "const_product_13020204": "Dividing Head",
    "const_product_23010208": "Diving Equipment",
    "const_product_26070102": "Dog Toys",
    "const_product_260201": "Doll & Puppet",
    "const_product_18020205": "Dolomite",
    "const_product_21010204": "Dome Camera",
    "const_product_80401": "Door",
    "const_product_804": "Door & Window",
    "const_product_8040205": "Door & Window Bolt",
    "const_product_80402": "Door & Window Hardware",
    "const_product_25020103": "Door & Window Hardware",
    "const_product_25020205": "Door & Window Hardware",
    "const_product_8040206": "Door & Window Hinges",
    "const_product_8040207": "Door & Window Hook",
    "const_product_8040208": "Door & Window Roller",
    "const_product_8040209": "Door & Window Slide",
    "const_product_8040210": "Door Closer",
    "const_product_8040211": "Door Curtains",
    "const_product_21010108": "Door Exit Button",
    "const_product_8040212": "Door Handle",
    "const_product_25020104": "Door Handle",
    "const_product_8040304": "Door Lock",
    "const_product_8040213": "Door Opener",
    "const_product_8050307": "Door Skin",
    "const_product_8040214": "Door Stop",
    "const_product_8040215": "Door Viewer",
    "const_product_80406": "Doorbell",
    "const_product_11030504": "Dormitory Bed",
    "const_product_19010104": "Dot Matrix Printer",
    "const_product_23040306": "Double Bass",
    "const_product_11060102": "Double Sofa",
    "const_product_10010202": "Double-Sided PCB",
    "const_product_20106": "Down & Winter Apparel",
    "const_product_16020102": "Down Light",
    "const_product_8010105": "Drain",
    "const_product_17010107": "Drainage & Irrigation Machinery",
    "const_product_11010206": "Drawer Chest",
    "const_product_11070101": "Drawer Slides",
    "const_product_19020205": "Drawing & Paint",
    "const_product_17100204": "Drawing Die",
    "const_product_17060202": "Dredger",
    "const_product_11010207": "Dresser",
    "const_product_11010208": "Dresser Stool",
    "const_product_20107": "Dresses",
    "const_product_10105": "Dried Fruit",
    "const_product_25010301": "Drill Bit",
    "const_product_25010302": "Drill Collar",
    "const_product_25010303": "Drill Rod",
    "const_product_25010304": "Drill Sleeves",
    "const_product_25010305": "Drilling Jar",
    "const_product_17110403": "Drilling Machine",
    "const_product_170601": "Drilling Rig",
    "const_product_25010306": "Drilling Stabilizer",
    "const_product_250103": "Drilling Tools",
    "const_product_17060302": "Drilling Tools",
    "const_product_15010104": "Drinking Straw",
    "const_product_150104": "Drinkware",
    "const_product_70401": "Drive",
    "const_product_4020310": "Drive Shaft",
    "const_product_9020301": "Drone & UAV",
    "const_product_90203": "Drone & UAV & Accessories",
    "const_product_130110": "Drone & UAV & Accessories",
    "const_product_9020302": "Drone Accessories",
    "const_product_14010201": "Drug Detector",
    "const_product_23040307": "Drum",
    "const_product_19010105": "Drum & Toner Catridge",
    "const_product_13050204": "Drum Drying Machine",
    "const_product_13050205": "Dry Cabinet",
    "const_product_90312": "Dry Cabinet",
    "const_product_17040105": "Dry Mortar Machine",
    "const_product_8010301": "Dry Sauna Room",
    "const_product_13010701": "Dry Washer",
    "const_product_17020105": "Dry Washer",
    "const_product_11030802": "Dryer Chair",
    "const_product_130502": "Drying Machine",
    "const_product_17010108": "Drying Machine",
    "const_product_17080104": "Drying Machine",
    "const_product_13050206": "Drying Oven",
    "const_product_15080404": "Drying Rack",
    "const_product_17040106": "Duct Making Machine",
    "const_product_23010103": "Dumbbell",
    "const_product_27040502": "Dump Truck",
    "const_product_20020203": "Duplicator",
    "const_product_20108": "Dust Coat",
    "const_product_4030307": "Dust Collector",
    "const_product_13010402": "Dust Collector",
    "const_product_15040405": "Dustbin",
    "const_product_25020501": "Dustbin Wheel",
    "const_product_15040406": "Duster",
    "const_product_15010203": "Dutch Ovens",
    "const_product_7040103": "DVD Drive",
    "const_product_21010203": "DVR",
    "const_product_14020302": "Dynamometer",
    "const_product_15080304": "E-Cigarette",
    "const_product_120205": "E-Cigarette",
    "const_product_220303": "E-Commerce",
    "const_product_21050104": "Ear Protection, Earmuffs",
    "const_product_1050104": "Ear Tag",
    "const_product_9010402": "Earbuds Headphones",
    "const_product_9010403": "Earhook Headphones",
    "const_product_9010404": "Earphone & Headphone Accessories",
    "const_product_9010405": "Earphone Case",
    "const_product_70505": "Earphones,Headphones & Accessories",
    "const_product_90104": "Earphones,Headphones & Accessories",
    "const_product_3010107": "Earrings",
    "const_product_15060103": "Earth Auger",
    "const_product_25010307": "Earth Auger",
    "const_product_10020103": "Earthing System",
    "const_product_10020503": "Earthing System",
    "const_product_21020104": "EAS & Accessories",
    "const_product_3020203": "Easter Gifts & Crafts",
    "const_product_90219": "eBook Reader",
    "const_product_10106": "Econ-valuable Vegetable",
    "const_product_17100205": "Economic Mould",
    "const_product_20010206": "Edge & Corner Protector",
    "const_product_11070203": "Edge Banding",
    "const_product_2040107": "Edging & Tassel",
    "const_product_240307": "Edging & Tassel",
    "const_product_10107": "Edible Fungus & Algae",
    "const_product_220402": "Education & Training",
    "const_product_2603": "Educational Toys",
    "const_product_16030201": "Effect Light",
    "const_product_10204": "Egg & Preparation",
    "const_product_9030506": "Egg Cooker",
    "const_product_17080105": "Egg Tray Machine",
    "const_product_15010607": "Egg Whisk",
    "const_product_8080605": "Elbow",
    "const_product_23010402": "Elbow Pad",
    "const_product_27020301": "Electric Bike",
    "const_product_4010704": "Electric Bike Tire",
    "const_product_27020302": "Electric Car",
    "const_product_100110": "Electric Contact & Materials",
    "const_product_25010308": "Electric Drill",
    "const_product_25010602": "Electric Drill",
    "const_product_15060104": "Electric Fence Energizer",
    "const_product_23040203": "Electric Guitar",
    "const_product_90313": "Electric Iron & Steam Iron",
    "const_product_100302": "Electric Motor",
    "const_product_130111": "Electric Motor",
    "const_product_10030204": "Electric Motor Parts",
    "const_product_27020303": "Electric Motorcycle",
    "const_product_9030507": "Electric Oven",
    "const_product_25010603": "Electric Planer",
    "const_product_25020206": "Electric Power Fitting",
    "const_product_10020402": "Electric Power Fitting",
    "const_product_10020504": "Electric Power Fitting",
    "const_product_9030508": "Electric Pressure Cooker",
    "const_product_25010604": "Electric Saw",
    "const_product_23020701": "Electric Scooter",
    "const_product_27020304": "Electric Scooter",
    "const_product_25010605": "Electric Screwdriver",
    "const_product_9030509": "Electric Skillet",
    "const_product_13060204": "Electric Soldering Iron",
    "const_product_9030510": "Electric Tea Maker",
    "const_product_90314": "Electric Toothbrush",
    "const_product_15020802": "Electric Toothbrush",
    "const_product_27020305": "Electric Tricycle",
    "const_product_27020401": "Electric Tricycle",
    "const_product_25010606": "Electric Trimmer",
    "const_product_270203": "Electric Vehicle & Parts",
    "const_product_9030801": "Electric Water Heater",
    "const_product_10030307": "Electric Wire & Cable",
    "const_product_25010607": "Electric Wrench",
    "const_product_807": "Electrical",
    "const_product_10": "Electrical & Electronics",
    "const_product_140505": "Electrical Control System",
    "const_product_100207": "Electrical Control System",
    "const_product_1002": "Electrical Equipment",
    "const_product_9030201": "Electrical Fan",
    "const_product_90302": "Electrical Fan & Exhaust Fan",
    "const_product_9030511": "Electrical Kettle",
    "const_product_90315": "Electrical Pesticidal Utensil",
    "const_product_150808": "Electrical Pesticidal Utensil",
    "const_product_90209": "Electrical Toys",
    "const_product_260603": "Electrical Toys",
    "const_product_100111": "Electrode",
    "const_product_140506": "Electrode",
    "const_product_13060101": "Electrode Holder",
    "const_product_100502": "Electromagnetic Equipment",
    "const_product_1003": "Electromechanical",
    "const_product_1001": "Electronic Components",
    "const_product_140103": "Electronic Instrument",
    "const_product_100503": "Electronic Instrument",
    "const_product_8040305": "Electronic Lock",
    "const_product_21010109": "Electronic Lock",
    "const_product_23040308": "Electronic Organ",
    "const_product_220203": "Electronic Publishing",
    "const_product_13010302": "Electronic Recycling Machine",
    "const_product_17110501": "Electronic Recycling Machine",
    "const_product_14020406": "Electronic Scale",
    "const_product_100104": "Electronic Tube & Transistor",
    "const_product_100504": "Electronics Stocks",
    "const_product_130112": "Electrostatic Eliminator",
    "const_product_14010104": "Element Analyzer",
    "const_product_220501": "Elementary Education",
    "const_product_6040203": "Elementary Substance",
    "const_product_270801": "Elevator & Funicular Car",
    "const_product_23010104": "Elliptical Trainer",
    "const_product_70203": "Embedded Computer & SCM",
    "const_product_17080106": "Embossing Machine",
    "const_product_20020105": "Embossing Machine",
    "const_product_240107": "Embroidery & Drawnwork",
    "const_product_17020106": "Embroidery Machine",
    "const_product_16050102": "Emergency Light",
    "const_product_160501": "Emergency Light & Indicator Light",
    "const_product_21020201": "Emergency Light & Indicator Light",
    "const_product_210203": "Emergency Light & Indicator Light",
    "const_product_10040203": "Emission Transmission Equipment",
    "const_product_120501": "Empty Capsule",
    "const_product_10030308": "Enameled Wire",
    "const_product_17120106": "Enameling Machine",
    "const_product_100405": "Encoder",
    "const_product_1803": "Energy",
    "const_product_14020102": "Energy Meter & Power Meter",
    "const_product_100308": "Energy Saver",
    "const_product_13040201": "Engine",
    "const_product_130402": "Engine & Part",
    "const_product_4020204": "Engine Mount",
    "const_product_13040202": "Engine Parts",
    "const_product_8030203": "Engineered Wood Flooring",
    "const_product_1704": "Engineering & Construction Machinery",
    "const_product_170402": "Engineering & Construction Machinery",
    "const_product_17040206": "Engineering & Construction Machinery Parts",
    "const_product_17040207": "Engineering Drilling Rig",
    "const_product_17060101": "Engineering Drilling Rig",
    "const_product_17050105": "English Wheel",
    "const_product_171102": "Engraving Machine",
    "const_product_220403": "Entertainment",
    "const_product_70707": "Entertainment Electronics",
    "const_product_90204": "Entertainment Electronics",
    "const_product_9020402": "Entertainment Electronics Accessories",
    "const_product_19020206": "Envelope & Letter Pad",
    "const_product_130103": "Environment Protection Device",
    "const_product_14010404": "Environmental Test Chamber",
    "const_product_6010101": "EPE Foam",
    "const_product_23010209": "Equestrian Equipment",
    "const_product_19020207": "Eraser & Rubber",
    "const_product_27080102": "Escalator",
    "const_product_15020205": "Essential Oil",
    "const_product_15021003": "Essential Oil",
    "const_product_60701": "Essential Oil, Balsam & Fine Chemicals",
    "const_product_6030107": "Ester & Derivative",
    "const_product_171109": "Etching Machine",
    "const_product_20205": "Ethnic & Folk Apparel",
    "const_product_11050501": "European Bed",
    "const_product_11050502": "European Cabinet",
    "const_product_110505": "European Furniture",
    "const_product_11050503": "European Sofa",
    "const_product_11050504": "European Table & Desk",
    "const_product_40304": "EV Charging Station",
    "const_product_6010102": "EVA Foam",
    "const_product_180501": "Evaporation Material",
    "const_product_1050105": "Evaporative Cooling Pad",
    "const_product_13050505": "Evaporative Cooling Pad",
    "const_product_5010104": "Evening Bags",
    "const_product_2020304": "Evening Dresses",
    "const_product_17040208": "Excavator",
    "const_product_11030402": "Executive Table",
    "const_product_23010105": "Exercise Bike",
    "const_product_9030202": "Exhaust Fan",
    "const_product_4020205": "Exhaust System",
    "const_product_13030308": "Exhaust Valve",
    "const_product_220204": "Exhibition",
    "const_product_150901": "Exhibition and Advertising Equipment",
    "const_product_8080804": "Expanded Metal Mesh",
    "const_product_8080606": "Expansion Joint",
    "const_product_60601": "Explosive & Combustible",
    "const_product_14010202": "Explosive Detector",
    "const_product_20020204": "Exposure Machine",
    "const_product_220102": "Express Delivery Services",
    "const_product_10030502": "Extension Socket",
    "const_product_16070103": "Extension Socket",
    "const_product_17100206": "Extrusion Die",
    "const_product_15021004": "Eye Cream",
    "const_product_15021005": "Eye Mask",
    "const_product_12020301": "Eye Massager",
    "const_product_15020504": "Eye Shadow",
    "const_product_15020505": "Eyebrow Pencil",
    "const_product_15020605": "Eyebrow Trimmer",
    "const_product_15020606": "Eyebrow Tweezers",
    "const_product_15020607": "Eyelash Curler",
    "const_product_15020608": "Eyelash Glue",
    "const_product_15020506": "Eyeliner",
    "const_product_15020609": "Eyeshadow Brush",
    "const_product_21050105": "Eyewash",
    "const_product_150502": "Eyewear",
    "const_product_2010102": "Fabric Belt",
    "const_product_17020202": "Fabric Cutting Machine",
    "const_product_110402": "Fabric Furniture",
    "const_product_5050201": "Fabric Gift Bags",
    "const_product_11040201": "Fabric Sofa",
    "const_product_11040202": "Fabric Wardrobe",
    "const_product_15021006": "Face Cream",
    "const_product_15021007": "Face Lotion & Gel",
    "const_product_16070104": "Face Plate",
    "const_product_10030503": "Face Plate",
    "const_product_24030203": "Face Towel",
    "const_product_15021008": "Facial Cleanser",
    "const_product_15021009": "Facial Mask",
    "const_product_21010110": "Facial Recognition System",
    "const_product_15020905": "Facial Tissue & Serviette",
    "const_product_15020610": "False Eyelashes",
    "const_product_3040403": "Fan",
    "const_product_10501": "Farm Facilities & Equipment",
    "const_product_8080404": "Farm Fence",
    "const_product_1050106": "Farm Irrigation System",
    "const_product_27040405": "Farm Trailer",
    "const_product_101": "Farming",
    "const_product_301": "Fashion Accessories",
    "const_product_30101": "Fashion Accessories",
    "const_product_40109": "Fastener & Fitting",
    "const_product_130203": "Fastener & Fitting",
    "const_product_25020403": "Fastener & Fitting",
    "const_product_10307": "Fat & Oil",
    "const_product_8010203": "Faucet Parts",
    "const_product_80102": "Faucet, Tap & Mixer",
    "const_product_90407": "Fax Machine",
    "const_product_190107": "Fax Machine",
    "const_product_240202": "Feather & Down",
    "const_product_30304": "Feather & Shell Crafts",
    "const_product_10308": "Feed Additives",
    "const_product_6050102": "Feed Additives",
    "const_product_17010109": "Feed Processing Machinery",
    "const_product_171110": "Feeder",
    "const_product_15070112": "Feeding Supplies",
    "const_product_18020206": "Feldspar",
    "const_product_23010305": "Fence",
    "const_product_80804": "Fence",
    "const_product_30505": "Fengshui Crafts",
    "const_product_10403": "Fertilizer",
    "const_product_60802": "Fertilizer",
    "const_product_17010110": "Fertilizer Production Machinery",
    "const_product_30202": "Festival & Party Supplies",
    "const_product_2401": "Fiber & Fabrics",
    "const_product_10010306": "Fiber Connector",
    "const_product_10040204": "Fiber Optic Equipment",
    "const_product_16040102": "Fiber Optic Light",
    "const_product_10010705": "Fiber Optic Sensor",
    "const_product_8080703": "Fiberboard",
    "const_product_6040303": "Fiberglass",
    "const_product_6040101": "Fiberglass Cloth",
    "const_product_6040102": "Fiberglass Mat",
    "const_product_6040103": "Fiberglass Mesh",
    "const_product_8080805": "Fiberglass Mesh",
    "const_product_80111": "Fiberglass Products",
    "const_product_60401": "Fiberglass Products",
    "const_product_6040104": "Fiberglass Profile",
    "const_product_8060403": "Fiberglass Profile",
    "const_product_6040105": "Fiberglass Tape",
    "const_product_6040106": "Fiberglass Yarn",
    "const_product_19020208": "File Folder",
    "const_product_19020209": "File Tray",
    "const_product_25010405": "Files",
    "const_product_11030409": "Filing Cabinets",
    "const_product_20010403": "Filling Machine",
    "const_product_9020607": "Film",
    "const_product_17070104": "Film Blowing Machine",
    "const_product_9020608": "Film Camera",
    "const_product_13010403": "Filter Bag & Cartridge",
    "const_product_13050603": "Filter Bag & Cartridge",
    "const_product_13010404": "Filter Cloth",
    "const_product_13050604": "Filter Cloth",
    "const_product_13010405": "Filter Element",
    "const_product_13010406": "Filter Membrane",
    "const_product_13010407": "Filter Mesh",
    "const_product_13050605": "Filter Mesh",
    "const_product_13010408": "Filter Paper",
    "const_product_13050606": "Filter Paper",
    "const_product_13010409": "Filter Plate",
    "const_product_13010410": "Filter Press",
    "const_product_8080806": "Filter Screen",
    "const_product_90316": "Filtration Equipment & Parts",
    "const_product_130104": "Filtration Equipment & Parts",
    "const_product_190108": "Financial Products",
    "const_product_220304": "Financial Services",
    "const_product_30607": "Fine Timepiece",
    "const_product_15050105": "Fine Timepiece",
    "const_product_8080704": "Finger Jointed Boards",
    "const_product_17050302": "Finger Jointer",
    "const_product_21010111": "Fingerprint Access Control",
    "const_product_805": "Finishing Building Materials",
    "const_product_21020202": "Fire Alarm & Smoke Detector",
    "const_product_14010203": "Fire Alarm & Smoke Detector",
    "const_product_21020203": "Fire Blanket",
    "const_product_210202": "Fire Control",
    "const_product_21020204": "Fire Extinguisher",
    "const_product_21020205": "Fire Extinguisher Cabinet",
    "const_product_21020206": "Fire Extinguisher Powder",
    "const_product_21020207": "Fire Extinguisher Valve",
    "const_product_21020208": "Fire Hose",
    "const_product_21020209": "Fire Hose Fittings",
    "const_product_21020210": "Fire Hose Reel & Cabinet",
    "const_product_21020211": "Fire Hydrant",
    "const_product_21020212": "Fire Nozzle",
    "const_product_80608": "Fire Retardant & Fireproof Material",
    "const_product_21020213": "Fire Sprinkler",
    "const_product_21020214": "Fire Truck",
    "const_product_27040202": "Fire Truck",
    "const_product_8080101": "Fireplace, Stove & Parts",
    "const_product_21020215": "Fireproof Suit",
    "const_product_21050106": "Fireproof Suit",
    "const_product_2020103": "Fireproof Suit",
    "const_product_30203": "Fireworks",
    "const_product_12030403": "First Aid Kit",
    "const_product_17010111": "Fishery Machinery",
    "const_product_23020401": "Fishfinder",
    "const_product_23020402": "Fishing Bag",
    "const_product_23020602": "Fishing Boat",
    "const_product_23020403": "Fishing Float",
    "const_product_23020404": "Fishing Hook",
    "const_product_23020405": "Fishing Line",
    "const_product_23020406": "Fishing Lure",
    "const_product_23020407": "Fishing Net",
    "const_product_23020408": "Fishing Reel",
    "const_product_23020409": "Fishing Rod",
    "const_product_230204": "Fishing Tackle",
    "const_product_150603": "Fishing Tackle",
    "const_product_23020410": "Fishing Tackle Box",
    "const_product_23020411": "Fishing Waders & Wears",
    "const_product_23020412": "Fishing Weight",
    "const_product_23010106": "Fitness Accessories",
    "const_product_230101": "Fitness Equipment & Body Building",
    "const_product_150604": "Fitness Equipment & Body Building",
    "const_product_10040205": "Fixed Wireless Terminals",
    "const_product_15090106": "Flag & Banner",
    "const_product_3040404": "Flag & Banner",
    "const_product_15090107": "Flagpole",
    "const_product_17050106": "Flame Cutting Machine",
    "const_product_17110102": "Flame Cutting Machine",
    "const_product_2020104": "Flame Retardant Clothing",
    "const_product_21050107": "Flame Retardant Clothing",
    "const_product_13020304": "Flange",
    "const_product_25010105": "Flap Disc",
    "const_product_13050207": "Flash Drying Machine",
    "const_product_14040106": "Flask",
    "const_product_18010402": "Flat Steel",
    "const_product_607": "Flavours & Fragrances",
    "const_product_20020307": "Flexographic Printer",
    "const_product_16010202": "Flip Chip LED",
    "const_product_17010302": "Floating Crane",
    "const_product_270505": "Floating Dock",
    "const_product_170109": "Flocking Machine",
    "const_product_16020201": "Floodlight",
    "const_product_8080102": "Floor Heating Systems & Parts",
    "const_product_16020103": "Floor Lamp",
    "const_product_13010204": "Floor Polisher",
    "const_product_13010205": "Floor Scrubber",
    "const_product_10030504": "Floor Socket & Table Socket",
    "const_product_16070105": "Floor Socket & Table Socket",
    "const_product_13010206": "Floor Sweeper",
    "const_product_8030103": "Floor Tile",
    "const_product_8030204": "Floor Tile",
    "const_product_17040107": "Floorboard Making Machinery",
    "const_product_8030205": "Flooring Accessories",
    "const_product_80302": "Floors & Flooring",
    "const_product_7040202": "Floppy Disk",
    "const_product_7040104": "Floppy Drive",
    "const_product_10108": "Flour & Preparation",
    "const_product_14020201": "Flow Meter",
    "const_product_4010102": "Flow Sensor",
    "const_product_10010706": "Flow Sensor",
    "const_product_10030604": "Flow Switch",
    "const_product_10020603": "Flow Transmitter",
    "const_product_10109": "Flower & Gardening Plant",
    "const_product_2020305": "Flower Girl Dresses",
    "const_product_15060105": "Flower Pot & Planter",
    "const_product_15080405": "Flower Stand",
    "const_product_30608": "Flower, Bonsai & Decorative Plant",
    "const_product_13050208": "Fluid Bed Drying Machine",
    "const_product_16060101": "Fluorescent Light",
    "const_product_6040404": "Fluoride",
    "const_product_23040309": "Flute",
    "const_product_60507": "Flux & Impregnant",
    "const_product_4020408": "Flywheel",
    "const_product_60101": "Foam",
    "const_product_17070105": "Foam Machinery",
    "const_product_20010207": "Foam Packaging",
    "const_product_10205": "Fodder, Feed",
    "const_product_4010503": "Fog Light",
    "const_product_20010105": "Folder Gluer",
    "const_product_27020203": "Folding Bike",
    "const_product_110803": "Folding Furniture",
    "const_product_17080107": "Folding Machine",
    "const_product_20020106": "Folding Machine",
    "const_product_30609": "Folding Screen",
    "const_product_8050501": "Folding Screen",
    "const_product_110804": "Folding Screen",
    "const_product_23040204": "Folk & Acoustic Guitar",
    "const_product_260802": "Folk Toys",
    "const_product_30506": "Folk-custom Crafts",
    "const_product_103": "Food & Beverage",
    "const_product_17010405": "Food & Beverage Sterilizer",
    "const_product_1030103": "Food Acidity Regulators",
    "const_product_10301": "Food Additive",
    "const_product_6050103": "Food Additive",
    "const_product_1030104": "Food Antioxidants",
    "const_product_9030512": "Food Blender",
    "const_product_23030102": "Food Bowl & Water Bowl",
    "const_product_1030105": "Food Colorants",
    "const_product_9030513": "Food Dehydrator",
    "const_product_1030106": "Food Emulsifiers",
    "const_product_1030107": "Food Enzyme Preparations",
    "const_product_1030108": "Food Flavor",
    "const_product_1030109": "Food Flavours Enhancers",
    "const_product_1030110": "Food Preservatives",
    "const_product_9030514": "Food Processors",
    "const_product_1030111": "Food Stabilizers and Coagulators",
    "const_product_10309": "Food Stocks",
    "const_product_1030112": "Food Sweeteners",
    "const_product_1030113": "Food Thickeners",
    "const_product_27040203": "Food Trailer",
    "const_product_27040406": "Food Trailer",
    "const_product_9030515": "Food Waste Disposer",
    "const_product_17010406": "Food, Beverage & Cereal Production Line",
    "const_product_12020302": "Foot Massager",
    "const_product_23010210": "Football & Soccer Equipment",
    "const_product_17010112": "Forestry Machine",
    "const_product_17100207": "Forging Mould",
    "const_product_15010907": "Fork",
    "const_product_17040301": "Forklift",
    "const_product_27010101": "Forklift",
    "const_product_27010102": "Forklift Parts",
    "const_product_270101": "Forklifts & Parts",
    "const_product_8080202": "Formwork",
    "const_product_15020507": "Foundation",
    "const_product_80112": "Fountain",
    "const_product_30507": "Fountain",
    "const_product_19020103": "Fountain Pen",
    "const_product_10010203": "FPC",
    "const_product_9030303": "Freezer & Refrigerator Parts",
    "const_product_9030604": "Freezer & Refrigerator Parts",
    "const_product_27080103": "Freight Elevator",
    "const_product_14010304": "Frequency Meter",
    "const_product_13060205": "Friction Welder",
    "const_product_3040405": "Fridge Magnets",
    "const_product_230211": "Frisbee",
    "const_product_260404": "Frisbee",
    "const_product_10040206": "Front-end Equipment",
    "const_product_8050102": "Frosted Glass",
    "const_product_10310": "Frozen Food",
    "const_product_6010201": "FRP/GRP",
    "const_product_10110": "Fruit & Melon",
    "const_product_17010407": "Fruit & Vegetable Processing Machinery",
    "const_product_1030202": "Fruit Extract",
    "const_product_9030516": "Fryer",
    "const_product_4030207": "Fuel Additives",
    "const_product_6050104": "Fuel Additives",
    "const_product_10030108": "Fuel Cell",
    "const_product_27080201": "Fuel Dispenser",
    "const_product_27080202": "Fuel Nozzle",
    "const_product_4020206": "Fuel System",
    "const_product_11080203": "Full-Length Mirror",
    "const_product_8050403": "Full-Length Mirror",
    "const_product_11030602": "Fume Hood",
    "const_product_240108": "Functional Textile",
    "const_product_3040204": "Funeral Accessories",
    "const_product_30402": "Funeral Products",
    "const_product_6080101": "Fungicide & Bactericide",
    "const_product_14040107": "Funnel",
    "const_product_240203": "Fur Fabric",
    "const_product_13050103": "Furnace",
    "const_product_13050104": "Furnace Parts",
    "const_product_11": "Furniture",
    "const_product_11070102": "Furniture Bolt",
    "const_product_1104": "Furniture By Material",
    "const_product_1105": "Furniture By Style",
    "const_product_11070103": "Furniture Casters",
    "const_product_25020502": "Furniture Casters",
    "const_product_240301": "Furniture Cover",
    "const_product_11070204": "Furniture Frame",
    "const_product_11070104": "Furniture Handles & Pulls",
    "const_product_110701": "Furniture Hardware",
    "const_product_11070105": "Furniture Hinges",
    "const_product_11070205": "Furniture Legs",
    "const_product_25020207": "Furniture Legs",
    "const_product_8040306": "Furniture Lock",
    "const_product_11070106": "Furniture Lock",
    "const_product_170504": "Furniture Making Machine",
    "const_product_6020105": "Furniture Paint",
    "const_product_1107": "Furniture Parts & Accessories",
    "const_product_110702": "Furniture Parts & Accessories",
    "const_product_10020104": "Fuse",
    "const_product_10020105": "Fuse Block & Holder",
    "const_product_4020107": "Fuse Holder & Car Fuses",
    "const_product_19010106": "Fuser Assembly",
    "const_product_19010107": "Fuser Film Sleeve",
    "const_product_19010108": "Fuser Roller",
    "const_product_8080807": "Gabion",
    "const_product_18010306": "Gallium",
    "const_product_11030415": "Gaming Chair",
    "const_product_17010303": "Gantry Crane",
    "const_product_8040103": "Garage Door",
    "const_product_15040407": "Garbage Bag",
    "const_product_27040204": "Garbage Truck",
    "const_product_15060106": "Garden Blower & Vacuum",
    "const_product_13010207": "Garden Blower & Vacuum",
    "const_product_11020105": "Garden Chairs",
    "const_product_15060107": "Garden Fencing",
    "const_product_8080405": "Garden Fencing",
    "const_product_11020117": "Garden Furniture Sets",
    "const_product_15060108": "Garden Hose",
    "const_product_15060109": "Garden Hose Reel",
    "const_product_16020202": "Garden Light",
    "const_product_15060110": "Garden Ornaments",
    "const_product_150605": "Garden Sprayer",
    "const_product_17010113": "Garden Sprayer",
    "const_product_15060111": "Garden Sprinkler",
    "const_product_11020109": "Garden Swing",
    "const_product_1506": "Gardening & Entertainment",
    "const_product_15060112": "Gardening Tool Parts",
    "const_product_1010104": "Garlic",
    "const_product_1030203": "Garlic Extract",
    "const_product_20402": "Garment Bag",
    "const_product_50301": "Garment Bag",
    "const_product_150809": "Garment Bag",
    "const_product_20403": "Garment Labels",
    "const_product_240501": "Garment Labels",
    "const_product_240403": "Garment Tag",
    "const_product_20404": "Garment Tag",
    "const_product_15010702": "Garment Tag",
    "const_product_20010208": "Garment Tag",
    "const_product_6040204": "Gas",
    "const_product_14010105": "Gas Analyzer",
    "const_product_9030517": "Gas Burner & Gas Stove",
    "const_product_27080203": "Gas Dispenser",
    "const_product_17030106": "Gas Generation Equipment & Parts",
    "const_product_130305": "Gas Generation Equipment & Parts",
    "const_product_13040303": "Gas Generator",
    "const_product_14020202": "Gas Meter",
    "const_product_270103": "Gas Scooter",
    "const_product_10010707": "Gas Sensor",
    "const_product_4010103": "Gas Sensor",
    "const_product_40604": "Gas Station & Equipment",
    "const_product_270802": "Gas Station & Equipment",
    "const_product_27080204": "Gas Station Equipment Accessories",
    "const_product_9030802": "Gas Water Heater",
    "const_product_13020305": "Gasket & Washer",
    "const_product_13030203": "Gasoline & Gas Pump",
    "const_product_13040304": "Gasoline Generator",
    "const_product_13030309": "Gate Valve",
    "const_product_14020303": "Gauge",
    "const_product_14020407": "Gauge",
    "const_product_12030404": "Gauze Pads",
    "const_product_11020115": "Gazebo",
    "const_product_13040402": "Gear",
    "const_product_17110404": "Gear Cutting Machine",
    "const_product_13030204": "Gear Pump",
    "const_product_13040403": "Gearbox",
    "const_product_4020311": "Gearbox",
    "const_product_19020104": "Gel Pen",
    "const_product_15020701": "Gel Polish",
    "const_product_3010303": "Gemstone Jewelry",
    "const_product_27040503": "General Cargo Truck",
    "const_product_1711": "General Machinery & Tools",
    "const_product_1302": "General Mechanical Components",
    "const_product_13040305": "Generator Parts & Accessories",
    "const_product_24020101": "Genuine Leather",
    "const_product_8080501": "Geocell",
    "const_product_8080502": "Geocomposite",
    "const_product_140206": "Geographic Surveying Instrument",
    "const_product_8080503": "Geogrid",
    "const_product_17060102": "Geological Drilling Rig",
    "const_product_8080504": "Geomembrane",
    "const_product_8080505": "Geonet",
    "const_product_80805": "Geosynthetics Products",
    "const_product_8080506": "Geotextile",
    "const_product_18010307": "Germanium",
    "const_product_200107": "Gift Box & Bag",
    "const_product_3040103": "Gift Box & Bag",
    "const_product_50502": "Gift Box & Bag",
    "const_product_1010105": "Ginger",
    "const_product_1030204": "Ginger Extract",
    "const_product_1030205": "Ginseng Extract",
    "const_product_11040301": "Glass Coffee Table",
    "const_product_30305": "Glass Crafts",
    "const_product_15010305": "Glass Cup & Mug",
    "const_product_8080203": "Glass Cutter",
    "const_product_11040302": "Glass Dining Table",
    "const_product_8040104": "Glass Door",
    "const_product_110403": "Glass Furniture",
    "const_product_17040108": "Glass Machine",
    "const_product_170803": "Glass Machine",
    "const_product_3060203": "Glass Painting",
    "const_product_20020308": "Glass Printing Machine",
    "const_product_8060303": "Glass Spider",
    "const_product_11040303": "Glass TV Stand",
    "const_product_15050203": "Glasses",
    "const_product_15050204": "Glasses Case & Pouch",
    "const_product_15050205": "Glasses Cloth",
    "const_product_15050206": "Glasses Frames",
    "const_product_15050207": "Glasses Lens",
    "const_product_15050208": "Glasses Parts",
    "const_product_150112": "Glassware",
    "const_product_8030104": "Glazed Tile",
    "const_product_240109": "Glistening Fabric",
    "const_product_19030102": "Globe",
    "const_product_13030310": "Globe Valve",
    "const_product_17020107": "Glove Making Machine",
    "const_product_20301": "Gloves & Mittens",
    "const_product_19020210": "Glue",
    "const_product_17120107": "Glue Dispensing System",
    "const_product_19020211": "Glue Stick",
    "const_product_20010404": "Gluing Machine",
    "const_product_230212": "Go Kart & Parts",
    "const_product_270407": "Go Kart & Parts",
    "const_product_3010304": "Gold & Platinum Jewelry",
    "const_product_230213": "Golf Cart",
    "const_product_27040205": "Golf Cart",
    "const_product_270408": "Golf Cart",
    "const_product_23010211": "Golf Equipment",
    "const_product_23040310": "Gong",
    "const_product_100401": "GPS",
    "const_product_10040207": "GPS",
    "const_product_10040101": "GPS Accessories",
    "const_product_10040102": "GPS Module",
    "const_product_10040103": "GPS Navigator",
    "const_product_4010406": "GPS Navigator",
    "const_product_10040104": "GPS Receiver",
    "const_product_10040105": "GPS Tracker",
    "const_product_23030103": "GPS Tracker",
    "const_product_4010407": "GPS Tracker",
    "const_product_8010106": "Grab Bar",
    "const_product_17040209": "Grader",
    "const_product_17110602": "Grading Machine",
    "const_product_2020306": "Graduation Dresses",
    "const_product_17010408": "Grain Processing Machinery",
    "const_product_17010409": "Grain Products Processing Machinery",
    "const_product_1050107": "Grain Silo",
    "const_product_15050106": "Grandfather Clock",
    "const_product_8020105": "Granite",
    "const_product_1030206": "Grape Seed Extract",
    "const_product_70305": "Graphic Card",
    "const_product_180206": "Graphite & Products",
    "const_product_15010608": "Grater",
    "const_product_17100103": "Gravity Casting",
    "const_product_25010406": "Grease Gun",
    "const_product_25010608": "Grease Gun",
    "const_product_1050108": "Greenhouse",
    "const_product_30204": "Greeting Cards",
    "const_product_240110": "Grey Fabric",
    "const_product_15010204": "Griddles",
    "const_product_25010106": "Grinder",
    "const_product_4030308": "Grinder",
    "const_product_17030107": "Grinder",
    "const_product_171111": "Grinder",
    "const_product_25010107": "Grinding Ball",
    "const_product_25010108": "Grinding Beads",
    "const_product_25010109": "Grinding Disc",
    "const_product_25010110": "Grinding Head",
    "const_product_17110405": "Grinding Machine",
    "const_product_25010111": "Grinding Powder",
    "const_product_25010112": "Grinding Rods",
    "const_product_25010113": "Grinding Segment",
    "const_product_25010114": "Grinding Wheel",
    "const_product_14010204": "Groundwater Detector",
    "const_product_130113": "Guide Rail",
    "const_product_17040302": "Guide Rail",
    "const_product_230402": "Guitar",
    "const_product_23040311": "Guitar",
    "const_product_23010212": "Gymnastics Equipment",
    "const_product_17040109": "Gypsum Powder Machine",
    "const_product_3010201": "Hair Bulk",
    "const_product_150203": "Hair Care Products",
    "const_product_15010502": "Hair Comb & Brush",
    "const_product_15020402": "Hair Comb & Brush",
    "const_product_15020301": "Hair Conditioner",
    "const_product_9030701": "Hair Curler",
    "const_product_9030702": "Hair Dryer",
    "const_product_15020302": "Hair Dye",
    "const_product_3010202": "Hair Extension",
    "const_product_15010503": "Hair Extension Tools",
    "const_product_15020403": "Hair Extension Tools",
    "const_product_15010504": "Hair Foil",
    "const_product_15020404": "Hair Foil",
    "const_product_9030703": "Hair Heating Cap",
    "const_product_15020303": "Hair Mask",
    "const_product_3010108": "Hair Ornaments",
    "const_product_9030704": "Hair Perm Machine",
    "const_product_30102": "Hair Products",
    "const_product_15020206": "Hair Products",
    "const_product_15010505": "Hair Roller",
    "const_product_15020405": "Hair Roller",
    "const_product_15010506": "Hair Scissors",
    "const_product_15020406": "Hair Scissors",
    "const_product_9030705": "Hair Steamer",
    "const_product_9030706": "Hair Straightener",
    "const_product_15020304": "Hair Styling Products",
    "const_product_150105": "Hair Tools & Accessories",
    "const_product_150204": "Hair Tools & Accessories",
    "const_product_90317": "Hair Tools & Accessories",
    "const_product_24030204": "Hair Towel",
    "const_product_15020305": "Hair Treatment Products",
    "const_product_9030707": "Hair Trimmer",
    "const_product_3010203": "Hair Weaving & Hair Weft",
    "const_product_15010507": "Hairdressing Cape & Gown",
    "const_product_15020407": "Hairdressing Cape & Gown",
    "const_product_3020204": "Halloween Gifts & Crafts",
    "const_product_110107": "Hallway Furniture",
    "const_product_16060102": "Halogen Light",
    "const_product_4010504": "Halogen Light",
    "const_product_25010407": "Hammer",
    "const_product_23020310": "Hammock",
    "const_product_11020108": "Hammock",
    "const_product_15021010": "Hand Cream",
    "const_product_90318": "Hand Dryer",
    "const_product_23010107": "Hand Grip",
    "const_product_25010408": "Hand Tool Set",
    "const_product_250104": "Hand Tools",
    "const_product_171112": "Hand Tools",
    "const_product_15020207": "Hand Warmer",
    "const_product_15040103": "Hand Washing Liquid",
    "const_product_3010109": "Handbags",
    "const_product_50101": "Handbags",
    "const_product_9020403": "Handheld Game Console",
    "const_product_12020303": "Handheld Massager",
    "const_product_14010205": "Handheld Metal Detector",
    "const_product_21020105": "Handheld Metal Detector",
    "const_product_120206": "Handicap Scooter",
    "const_product_27020306": "Handicap Scooter",
    "const_product_24030205": "Handkerchief",
    "const_product_15080406": "Hanger",
    "const_product_7040204": "Hard Disk",
    "const_product_14020304": "Hardness Tester",
    "const_product_2502": "Hardware & Accessories",
    "const_product_250202": "Hardware Accessories",
    "const_product_23040312": "Harmonica",
    "const_product_17010114": "Harvesting Machine",
    "const_product_20302": "Hat & Cap",
    "const_product_203": "Hat, Gloves, Scarf and Shoes",
    "const_product_7040203": "HDD Enclosure",
    "const_product_9010501": "HDMI Cable",
    "const_product_9010502": "HDMI Converter",
    "const_product_90105": "HDMI Equipment",
    "const_product_9010503": "HDMI Splitter",
    "const_product_9010504": "HDMI Switch",
    "const_product_12020304": "Head Massager",
    "const_product_9010406": "Headband Headphones",
    "const_product_4010505": "Headlight",
    "const_product_12": "Health & Medicine",
    "const_product_1202": "Health Care & Beauty Products",
    "const_product_120202": "Health Care Appliance",
    "const_product_12020201": "Health Care Cosmetics",
    "const_product_120207": "Health Food",
    "const_product_10311": "Health Food",
    "const_product_12020402": "Hearing Aid & Accessories",
    "const_product_13050301": "Heat Exchanger",
    "const_product_13050302": "Heat Exchanger Plate",
    "const_product_25010609": "Heat Gun",
    "const_product_20020309": "Heat Press Machine",
    "const_product_13050303": "Heat Pump",
    "const_product_10020201": "Heat Pump",
    "const_product_9030803": "Heat Pump Water Heater",
    "const_product_20010405": "Heat Shrink Packaging Machine",
    "const_product_20020310": "Heat Transfer Equipment",
    "const_product_13070104": "Heat Treatment",
    "const_product_17030108": "Heater & Heat Exchanger",
    "const_product_130503": "Heater & Heat Exchanger",
    "const_product_13050304": "Heating Element",
    "const_product_10020202": "Heating Element",
    "const_product_13050305": "Heating Equipment",
    "const_product_8080103": "Heating Radiator",
    "const_product_9030605": "Heating, Refrigeration & Temperature Control",
    "const_product_100202": "Heating, Refrigeration & Temperature Control",
    "const_product_140507": "Heating, Refrigeration & Temperature Control",
    "const_product_15060113": "Hedge Trimmer",
    "const_product_230205": "Helmet",
    "const_product_24010101": "Hemp Fabric",
    "const_product_1030207": "Herbal Extract",
    "const_product_6080102": "Herbicide & Weedicide",
    "const_product_8080808": "Hexagonal Wire Mesh",
    "const_product_9010602": "Hi-Fi Player",
    "const_product_7050102": "Hi-Fi Speaker",
    "const_product_16010203": "High Power LED",
    "const_product_13010208": "High Pressure Car Washer",
    "const_product_4030208": "High Pressure Car Washer",
    "const_product_13010209": "High Pressure Cleaner",
    "const_product_27040206": "High-altitude Operation Truck",
    "const_product_21040102": "Highway Guard Rail",
    "const_product_8080406": "Highway Guard Rail",
    "const_product_15010105": "Hip Flask",
    "const_product_13020205": "Hob Cutter",
    "const_product_5010105": "Hobo Bag",
    "const_product_23010213": "Hockey Equipment",
    "const_product_15060114": "Hoe",
    "const_product_17040303": "Hoist",
    "const_product_17080108": "Hole Puncher",
    "const_product_20020107": "Hole Puncher",
    "const_product_302": "Holiday Gifts & Decorations",
    "const_product_90303": "Home Appliance Parts",
    "const_product_2403": "Home Decor",
    "const_product_306": "Home Furnishings",
    "const_product_1101": "Home Furniture",
    "const_product_250203": "Home Hardware",
    "const_product_150810": "Home Hardware",
    "const_product_110108": "Home Office Furniture",
    "const_product_9030606": "Home Refrigerator & Freezer",
    "const_product_1503": "Home Textile",
    "const_product_9010102": "Home Theatre System",
    "const_product_2020307": "Homecoming Dresses",
    "const_product_10206": "Honey Products",
    "const_product_8050308": "Honeycomb Core",
    "const_product_8050309": "Honeycomb Panel",
    "const_product_17110406": "Honing Machine",
    "const_product_15080305": "Hookah",
    "const_product_25020208": "Hooks",
    "const_product_250109": "Horticulture & Gardening Products",
    "const_product_10503": "Horticulture & Gardening Products",
    "const_product_150601": "Horticulture & Gardening Products",
    "const_product_11031001": "Hospital Bed",
    "const_product_11031005": "Hospital Cabinet",
    "const_product_11031002": "Hospital Cart",
    "const_product_11031003": "Hospital Chair",
    "const_product_110310": "Hospital Furniture",
    "const_product_120306": "Hospital Furniture",
    "const_product_11031004": "Hospital Table",
    "const_product_15020208": "Hot Cold Therapy Bag",
    "const_product_17100104": "Hot Forging",
    "const_product_25010610": "Hot Knife",
    "const_product_25010611": "Hot Melt Glue Gun",
    "const_product_20010305": "Hot Stamping Foil",
    "const_product_20020108": "Hot Stamping Machine",
    "const_product_15020209": "Hot Water Bottle",
    "const_product_150811": "Hotel Amenities",
    "const_product_11030101": "Hotel Bed",
    "const_product_11030103": "Hotel Bedroom Furniture Sets",
    "const_product_110301": "Hotel Furniture",
    "const_product_11030105": "Hotel Lobby Furniture",
    "const_product_150401": "Household Chemicals",
    "const_product_15040104": "Household Disinfectant",
    "const_product_903": "Household Electrical Appliances",
    "const_product_12020202": "Household Health Test & Nursing Products",
    "const_product_15040202": "Household Plastic Containers",
    "const_product_150402": "Household Plastic Products",
    "const_product_61103": "Household Plastic Products",
    "const_product_150403": "Household Receptacle",
    "const_product_150812": "Household Rubber Products",
    "const_product_6010401": "Household Rubber Products",
    "const_product_1508": "Household Sundries",
    "const_product_90304": "Household Water Treatment Equipment",
    "const_product_7060102": "Hub",
    "const_product_9030105": "Humidifier",
    "const_product_9030304": "Humidifier Parts",
    "const_product_23020311": "Hunting Supplies",
    "const_product_80809": "HVAC System & Parts",
    "const_product_1303": "Hydraulic & Pneumatic",
    "const_product_25010501": "Hydraulic Accumulator",
    "const_product_25010502": "Hydraulic Cylinder",
    "const_product_25010503": "Hydraulic Fittings",
    "const_product_17110407": "Hydraulic Ironworker",
    "const_product_25010504": "Hydraulic Motor",
    "const_product_250105": "Hydraulic Parts",
    "const_product_25010505": "Hydraulic Pipe",
    "const_product_25010506": "Hydraulic Power Unit",
    "const_product_170110": "Hydraulic Press",
    "const_product_25010507": "Hydraulic Pump",
    "const_product_25010508": "Hydraulic Seal",
    "const_product_250110": "Hydraulic Tool",
    "const_product_25010509": "Hydraulic Valve",
    "const_product_13040306": "Hydro Generator",
    "const_product_6030108": "Hydrocarbon & Derivative",
    "const_product_1050109": "Hydroponics System",
    "const_product_17010115": "Hydroseeding Machine",
    "const_product_6030109": "Hydroxybenzene, Aether, Aldehyde & Ketone",
    "const_product_6040405": "Hypochlorite",
    "const_product_27040101": "IBC Tank",
    "const_product_10030401": "IC Card",
    "const_product_10312": "Ice & Preparation",
    "const_product_15010106": "Ice Bucket",
    "const_product_15010609": "Ice Cube Tray",
    "const_product_13050506": "Ice Machine",
    "const_product_170111": "Ice Machine",
    "const_product_13050507": "Ice Maker & Ice Crusher",
    "const_product_9030518": "Ice Maker & Ice Crusher",
    "const_product_4030209": "Ice Scraper",
    "const_product_4020207": "Ignition System",
    "const_product_10010708": "Image Sensor",
    "const_product_20020205": "Imagesetter",
    "const_product_9010407": "In-Ear Headphones",
    "const_product_16060103": "Incandescent Light",
    "const_product_16050103": "Indicator Light",
    "const_product_18010308": "Indium",
    "const_product_1602": "Indoor & Outdoor Lighting",
    "const_product_16010101": "Indoor LED Display",
    "const_product_23040103": "Indoor Playground",
    "const_product_9030519": "Induction Cooker",
    "const_product_10010502": "Inductor",
    "const_product_20020311": "Industrial 3D Printer",
    "const_product_130702": "Industrial 3D Printer",
    "const_product_130204": "Industrial Adhesive Products",
    "const_product_13050508": "Industrial Air Conditioner",
    "const_product_130306": "Industrial Brake",
    "const_product_25020503": "Industrial Caster",
    "const_product_13050509": "Industrial Chiller",
    "const_product_70204": "Industrial Computer & Accessories",
    "const_product_13050510": "Industrial Cooling System",
    "const_product_130701": "Industrial Customized Service",
    "const_product_13050401": "Industrial Dehumidifier",
    "const_product_13050402": "Industrial Dehumidifier Parts",
    "const_product_13": "Industrial Equipment & Components",
    "const_product_13020401": "Industrial Glue",
    "const_product_13050403": "Industrial Humidifier",
    "const_product_130504": "Industrial Humidifier & Dehumidifier & Parts",
    "const_product_13010702": "Industrial Laundry Dryer",
    "const_product_17020108": "Industrial Laundry Dryer",
    "const_product_8040307": "Industrial Lock",
    "const_product_20010209": "Industrial Plastic Containers",
    "const_product_27040102": "Industrial Plastic Containers",
    "const_product_170112": "Industrial Robot",
    "const_product_17020109": "Industrial Spin Dryer",
    "const_product_13010703": "Industrial Spin Dryer",
    "const_product_13020402": "Industrial Tape",
    "const_product_240111": "Industrial Textile",
    "const_product_4010705": "Industrial Tire",
    "const_product_13010210": "Industrial Ultrasonic Cleaner",
    "const_product_13010211": "Industrial Vacuum Cleaner",
    "const_product_13010704": "Industrial Washer",
    "const_product_17020110": "Industrial Washer",
    "const_product_13010411": "Industrial Water Filter",
    "const_product_4010104": "Inertial Sensor",
    "const_product_10010709": "Inertial Sensor",
    "const_product_20109": "Infant Apparel",
    "const_product_15070113": "Infant Apparel",
    "const_product_10313": "Infant Food",
    "const_product_15090108": "Inflatable Advertising",
    "const_product_150903": "Inflatable Advertising",
    "const_product_26040101": "Inflatable Ball",
    "const_product_26040102": "Inflatable Boat",
    "const_product_26040103": "Inflatable Bouncer",
    "const_product_26040104": "Inflatable Cartoon",
    "const_product_26040105": "Inflatable Castle",
    "const_product_6110101": "Inflatable Core Mold",
    "const_product_110805": "Inflatable Furniture",
    "const_product_26040106": "Inflatable Pool",
    "const_product_26040107": "Inflatable Slide",
    "const_product_230408": "Inflatable Toys",
    "const_product_260401": "Inflatable Toys",
    "const_product_2202": "Information Services",
    "const_product_9030520": "Infrared Cooker",
    "const_product_16060104": "Infrared Lamp",
    "const_product_10010710": "Infrared Sensor",
    "const_product_18020103": "Injection Ferrite Magnet",
    "const_product_17070106": "Injection Molding Machine",
    "const_product_6010301": "Injection Molding Parts",
    "const_product_19010109": "Ink Cartridge",
    "const_product_19010110": "Inkjet Printer",
    "const_product_20020206": "Inkjet System",
    "const_product_13030311": "Inlet Valve",
    "const_product_604": "Inorganic Chemicals",
    "const_product_60402": "Inorganic Chemicals",
    "const_product_60403": "Inorganic Fiber",
    "const_product_6020205": "Inorganic Pigment",
    "const_product_60404": "Inorganic Salt",
    "const_product_1050110": "Insect Net",
    "const_product_10314": "Instant Food",
    "const_product_50507": "Instrument Bag & Case",
    "const_product_100505": "Instrument Box",
    "const_product_140508": "Instrument Box",
    "const_product_10020505": "Instrument Transformer",
    "const_product_14": "Instruments & Meters",
    "const_product_23040313": "Instruments Parts & Accessories",
    "const_product_8050103": "Insulated Glass",
    "const_product_100112": "Insulation",
    "const_product_10020506": "Insulator",
    "const_product_100113": "Integrated Circuit",
    "const_product_260302": "Intellectual & Educational Toys",
    "const_product_160201": "Interior Lighting",
    "const_product_2040108": "Interlining & Lining",
    "const_product_220305": "Intermediary",
    "const_product_100203": "Inverter",
    "const_product_180106": "Iron & Products",
    "const_product_8080304": "Iron Pipe & Tube",
    "const_product_18010203": "Iron Scrap",
    "const_product_15080407": "Ironing Board",
    "const_product_17020111": "Ironing Machine",
    "const_product_13010705": "Ironing Machine",
    "const_product_2020105": "Isolation Gown",
    "const_product_10020507": "Isolation Switch",
    "const_product_10030605": "Isolation Switch",
    "const_product_11050401": "Italian Bed",
    "const_product_11050402": "Italian Cabinet",
    "const_product_11050403": "Italian Chair",
    "const_product_110504": "Italian Furniture",
    "const_product_11050404": "Italian Sofa",
    "const_product_11050405": "Italian Table & Desk",
    "const_product_120602": "IVD",
    "const_product_10010307": "Jack",
    "const_product_20110": "Jacket",
    "const_product_30306": "Jade & Stone Crafts",
    "const_product_3010305": "Jade Jewelry",
    "const_product_20111": "Jeans",
    "const_product_13030205": "Jet Pump",
    "const_product_23020603": "Jet Ski",
    "const_product_30103": "Jewelry & Accessories",
    "const_product_3040104": "Jewelry Box",
    "const_product_50508": "Jewelry Box",
    "const_product_3010306": "Jewelry Parts",
    "const_product_3010110": "Jewelry Set",
    "const_product_3010307": "Jewelry Tools",
    "const_product_17010304": "Jib Crane",
    "const_product_9020404": "Joystick & Game Controller",
    "const_product_9030521": "Juicer",
    "const_product_23010108": "Jump Rope",
    "const_product_260405": "Jump Rope",
    "const_product_4030309": "Jump Starter",
    "const_product_10020403": "Junction Box",
    "const_product_24010102": "Jute Fabric",
    "const_product_18020207": "Kaolin",
    "const_product_9010103": "Karaoke Player",
    "const_product_23020604": "Kayak",
    "const_product_15010205": "Kettles",
    "const_product_15010403": "Kettles",
    "const_product_5010202": "Key Case",
    "const_product_30403": "Key Chain",
    "const_product_3040406": "Key Chain",
    "const_product_70506": "Keyboard",
    "const_product_23020702": "Kick Scooter & Surfing Scooter",
    "const_product_260406": "Kick Scooter & Surfing Scooter",
    "const_product_270104": "Kick Scooter & Surfing Scooter",
    "const_product_11010102": "Kids Bed",
    "const_product_230214": "Kids Bike",
    "const_product_260501": "Kids Bike",
    "const_product_27020204": "Kids Bike",
    "const_product_11010104": "Kids Cabinet",
    "const_product_11010106": "Kids Chair",
    "const_product_11010105": "Kids Dining Chair",
    "const_product_11010110": "Kids Furniture Sets",
    "const_product_11010107": "Kids Sofa",
    "const_product_11010108": "Kids Table",
    "const_product_11010103": "Kids Wardrobe",
    "const_product_1501": "Kitchen & Dining Products",
    "const_product_80113": "Kitchen Appliance",
    "const_product_11010401": "Kitchen Cabinets",
    "const_product_11010403": "Kitchen Cart",
    "const_product_150113": "Kitchen Electronics",
    "const_product_90305": "Kitchen Electronics",
    "const_product_110104": "Kitchen Furniture",
    "const_product_150106": "Kitchen Implements",
    "const_product_11010402": "Kitchen Island",
    "const_product_15010610": "Kitchen Knives",
    "const_product_15010611": "Kitchen Mill",
    "const_product_8010204": "Kitchen Mixer & Faucet",
    "const_product_15010612": "Kitchen Racks",
    "const_product_14020408": "Kitchen Scale",
    "const_product_15010613": "Kitchen Scale",
    "const_product_8010602": "Kitchen Sink",
    "const_product_150305": "Kitchen Textile",
    "const_product_240112": "Kitchen Textile",
    "const_product_15010614": "Kitchen Tongs",
    "const_product_15010615": "Kitchenware Set",
    "const_product_23010403": "Knee Pad",
    "const_product_25010409": "Knife",
    "const_product_25020301": "Knife",
    "const_product_25010410": "Knife & Scissors",
    "const_product_19020212": "Knife & Scissors",
    "const_product_17110103": "Knife Cutting Machine",
    "const_product_15010616": "Knife Sharpener",
    "const_product_240113": "Knitting & Crocheting Fabric",
    "const_product_20112": "Knitting Apparel",
    "const_product_17020203": "Knitting Machine",
    "const_product_20010306": "Kraft Paper",
    "const_product_11030607": "Lab Accessories",
    "const_product_14040201": "Lab Balance",
    "const_product_11030601": "Lab Bench",
    "const_product_11030603": "Lab Cabinet",
    "const_product_14040108": "Lab Clamp",
    "const_product_14040202": "Lab Drying Machine",
    "const_product_13050209": "Lab Drying Machine",
    "const_product_14040203": "Lab Facilities",
    "const_product_8010205": "Lab Faucet",
    "const_product_110306": "Lab Furniture",
    "const_product_8010603": "Lab Sink",
    "const_product_11030604": "Lab Stool & Chair",
    "const_product_1404": "Lab Supplies",
    "const_product_140401": "Lab Utensil and Disposable",
    "const_product_20030107": "Label & Sticker Printing",
    "const_product_20020312": "Label Printing Machine",
    "const_product_20010406": "Labeling Machine",
    "const_product_150107": "Labels",
    "const_product_21050108": "Labor Protection Mask",
    "const_product_14040204": "Laboratory Centrifuge",
    "const_product_14040109": "Laboratory Cylinder",
    "const_product_14040205": "Laboratory Filtration Equipment",
    "const_product_14040206": "Laboratory Heating Equipment",
    "const_product_140402": "Laboratory Instruments",
    "const_product_61203": "Laboratory Instruments",
    "const_product_190305": "Laboratory Instruments",
    "const_product_14040207": "Laboratory Refrigeration Equipment",
    "const_product_14040208": "Laboratory Thermostat",
    "const_product_2040109": "Lace & Purfle",
    "const_product_240114": "Lace & Purfle",
    "const_product_17020112": "Lace Machine",
    "const_product_30307": "Lacquer Crafts",
    "const_product_150813": "Ladder",
    "const_product_80508": "Ladder",
    "const_product_8030206": "Laminate Flooring",
    "const_product_8050104": "Laminated Glass",
    "const_product_17050303": "Laminating Machine",
    "const_product_20010407": "Laminating Machine",
    "const_product_190109": "Laminator",
    "const_product_16070110": "Lamp Base & Light Socket",
    "const_product_16010206": "Lamp LED",
    "const_product_16070111": "Lamp Shade",
    "const_product_16040103": "Lantern",
    "const_product_3040407": "Lanyard",
    "const_product_190110": "Laptop",
    "const_product_70103": "Laptop",
    "const_product_90210": "Laptop",
    "const_product_70104": "Laptop Stand",
    "const_product_701": "Laptops & Accessories",
    "const_product_17110301": "Laser Cleaning Machine",
    "const_product_17050107": "Laser Cutting Machine",
    "const_product_17110104": "Laser Cutting Machine",
    "const_product_17110302": "Laser Cutting Machine",
    "const_product_10010401": "Laser Diode",
    "const_product_17110201": "Laser Engraving Machine",
    "const_product_17110303": "Laser Engraving Machine",
    "const_product_171103": "Laser Equipment",
    "const_product_17110304": "Laser Equipment Parts",
    "const_product_20010307": "Laser Film",
    "const_product_17110305": "Laser Heat Treatment Machine",
    "const_product_14030101": "Laser Level",
    "const_product_16030203": "Laser Light",
    "const_product_17010501": "Laser Marking Machine",
    "const_product_17110306": "Laser Marking Machine",
    "const_product_14010106": "Laser Particle Size Analyzer",
    "const_product_17110307": "Laser Plotter",
    "const_product_19030301": "Laser Pointer",
    "const_product_17110308": "Laser Scribing Machine",
    "const_product_17110309": "Laser Welding Machine",
    "const_product_13060206": "Laser Welding Machine",
    "const_product_17110408": "Lathe",
    "const_product_150114": "Laundry Bag",
    "const_product_50509": "Laundry Bag",
    "const_product_15040105": "Laundry Ball",
    "const_product_150405": "Laundry Basket",
    "const_product_15040106": "Laundry Detergent",
    "const_product_17020113": "Laundry Folding Machine",
    "const_product_13010706": "Laundry Folding Machine",
    "const_product_15040107": "Laundry Soap",
    "const_product_16020203": "Lawn Light",
    "const_product_15060115": "Lawn Mower",
    "const_product_25010612": "Lawn Mower",
    "const_product_9010303": "LCD Display",
    "const_product_9010304": "LCD Module",
    "const_product_100114": "LCD Module",
    "const_product_9010703": "LCD TV",
    "const_product_17060203": "Leaching Tank",
    "const_product_18010309": "Lead",
    "const_product_13020501": "Lead Seal",
    "const_product_130207": "Lean Pipe & Pipe Rack System",
    "const_product_250206": "Lean Pipe & Pipe Rack System",
    "const_product_190306": "Learning Machine",
    "const_product_90211": "Learning Machine",
    "const_product_20113": "Leather & Fur",
    "const_product_240201": "Leather & Leatheroid",
    "const_product_11040401": "Leather Bed",
    "const_product_2010103": "Leather Belt",
    "const_product_11040402": "Leather Chair",
    "const_product_30308": "Leather Crafts",
    "const_product_110404": "Leather Furniture",
    "const_product_5050202": "Leather Gift Box & Bag",
    "const_product_3040303": "Leather Key Chain",
    "const_product_170203": "Leather Machinery",
    "const_product_240603": "Leather Machinery",
    "const_product_20020313": "Leather Printing Machine",
    "const_product_2030406": "Leather Shoes",
    "const_product_11040403": "Leather Sofa",
    "const_product_15050108": "Leather Strap Watch",
    "const_product_2402": "Leather, Fur & Feather",
    "const_product_11030503": "Lectern",
    "const_product_16070106": "LED Aluminum Profile",
    "const_product_16010601": "LED Aquarium Light",
    "const_product_23030104": "LED Aquarium Light",
    "const_product_16010303": "LED Bulb Light",
    "const_product_16010602": "LED Cabinet Light",
    "const_product_16010304": "LED Ceiling Light",
    "const_product_16010603": "LED Conversion Kit",
    "const_product_10020404": "LED Conversion Kit",
    "const_product_16010305": "LED Cup",
    "const_product_160101": "LED Display",
    "const_product_23010306": "LED Display",
    "const_product_9010305": "LED Display",
    "const_product_100506": "LED Display",
    "const_product_16010204": "LED Dot Matrix",
    "const_product_16010306": "LED Down Light",
    "const_product_16010604": "LED Emergency Light",
    "const_product_16050104": "LED Emergency Light",
    "const_product_160102": "LED Encapsulation Series",
    "const_product_16010605": "LED Explosion-proof Light",
    "const_product_16010606": "LED Fishing Light",
    "const_product_16010607": "LED Flashlight",
    "const_product_16010501": "LED Floodlight",
    "const_product_16030202": "LED Floor",
    "const_product_16010608": "LED Fresh Light",
    "const_product_110806": "LED Furniture",
    "const_product_16010502": "LED Garden Light",
    "const_product_16010307": "LED Grille Light",
    "const_product_16010609": "LED Grow Light",
    "const_product_16010610": "LED Headlamp",
    "const_product_16070107": "LED Heatsink",
    "const_product_16010308": "LED High Bay Light",
    "const_product_16010503": "LED High Mast Light",
    "const_product_160103": "LED Interior Lighting",
    "const_product_3040302": "LED Key Chain",
    "const_product_16010504": "LED Lawn Light",
    "const_product_16010401": "LED Light Bar",
    "const_product_4010506": "LED Light Bar",
    "const_product_16010611": "LED Light Box",
    "const_product_16010612": "LED Light for Animal Husbandry",
    "const_product_1050111": "LED Light for Animal Husbandry",
    "const_product_1601": "LED Lighting & Display",
    "const_product_160104": "LED Lighting Decoration",
    "const_product_16010309": "LED Linear Light",
    "const_product_16010613": "LED Mirror Lamp",
    "const_product_16010205": "LED Module",
    "const_product_100115": "LED Module",
    "const_product_160105": "LED Outdoor Lighting",
    "const_product_16010505": "LED Outdoor Wall Light",
    "const_product_16010310": "LED Panel Light",
    "const_product_16010614": "LED PAR Light",
    "const_product_16010311": "LED Pendant Light",
    "const_product_16010506": "LED Point Light",
    "const_product_16070108": "LED Power Supply",
    "const_product_10020405": "LED Power Supply",
    "const_product_160106": "LED Professional Lighting",
    "const_product_16070109": "LED Retrofit Kit",
    "const_product_16010312": "LED Spotlight",
    "const_product_16010507": "LED Street Light",
    "const_product_16010402": "LED String Light",
    "const_product_16010403": "LED Strip Light",
    "const_product_16010313": "LED Table Lamp & Book Light",
    "const_product_16010314": "LED Track Light",
    "const_product_16010615": "LED Traffic Light",
    "const_product_16010616": "LED Tri-proof Light",
    "const_product_16010315": "LED Tube",
    "const_product_16010508": "LED Tunnel Light",
    "const_product_16010509": "LED Underground Light",
    "const_product_16010617": "LED Underwater Light",
    "const_product_100116": "LED Video Processor",
    "const_product_16010316": "LED Wall Light",
    "const_product_16010510": "LED Wall Washer",
    "const_product_15050107": "LED Watch",
    "const_product_16010618": "LED Work Light",
    "const_product_4010507": "LED Work Light",
    "const_product_2030407": "Leisure & Comfort Shoes",
    "const_product_20114": "Leisure Apparel",
    "const_product_2030201": "Leisure Caps",
    "const_product_150406": "Lens Cleaner",
    "const_product_15050209": "Lens Cleaner",
    "const_product_9020501": "Lens Cleaner",
    "const_product_9020609": "Lens Cleaner",
    "const_product_11030701": "Library Bookshelf",
    "const_product_110307": "Library Furniture",
    "const_product_4030111": "License Plate Frame",
    "const_product_4010508": "License Plate Light",
    "const_product_11070109": "Lid Stay",
    "const_product_25020209": "Lid Stay",
    "const_product_20010210": "Lids, Bottle Caps & Closers",
    "const_product_14010206": "Life Detector",
    "const_product_210403": "Lifesaving",
    "const_product_170403": "Lifting Equipment",
    "const_product_17040304": "Lifting Jack",
    "const_product_4030310": "Lifting Jack",
    "const_product_17040305": "Lifting Magnet",
    "const_product_17040306": "Lifting Sling",
    "const_product_17040307": "Lifting Table",
    "const_product_8080204": "Lifting Table",
    "const_product_17040308": "Lifting Tackle",
    "const_product_160504": "Light Box",
    "const_product_15090109": "Light Box",
    "const_product_16070112": "Light Bracket",
    "const_product_16070113": "Light Emitting Diode",
    "const_product_10010402": "Light Emitting Diode",
    "const_product_15": "Light Industry & Daily Use",
    "const_product_14010107": "Light Meter",
    "const_product_19020105": "Light Pen",
    "const_product_16070114": "Light Pole",
    "const_product_10010711": "Light Sensor",
    "const_product_160801": "Light Stocks",
    "const_product_15080306": "Lighter",
    "const_product_1607": "Lighting Accessories",
    "const_product_1606": "Lighting Bulbs & Tubes",
    "const_product_1604": "Lighting Decoration",
    "const_product_160401": "Lighting Decoration",
    "const_product_30205": "Lighting Decoration",
    "const_product_160701": "Lighting Fixtures",
    "const_product_10020106": "Lightning Receiver",
    "const_product_16": "Lights & Lighting",
    "const_product_8020106": "Limestone",
    "const_product_10030606": "Limit Switch",
    "const_product_100309": "Linear Actuator",
    "const_product_130405": "Linear Actuator",
    "const_product_13020104": "Linear Bearing",
    "const_product_13030105": "Linear Compressor",
    "const_product_24010103": "Linen & Flax Fabric",
    "const_product_15040408": "Lint Remover",
    "const_product_15020508": "Lip Balm",
    "const_product_15020509": "Lip Gloss",
    "const_product_15020510": "Lip Liner",
    "const_product_15021011": "Lip Mask",
    "const_product_15020511": "Lipstick",
    "const_product_14010108": "Liquid Analyzer",
    "const_product_14020203": "Liquid Level Meter",
    "const_product_10010712": "Liquid Level Sensor",
    "const_product_10020604": "Liquid Level Transmitter",
    "const_product_10030109": "Lithium Battery",
    "const_product_17010116": "Livestock Machinery",
    "const_product_11010611": "Living Room Cabinets",
    "const_product_11010606": "Living Room Chairs",
    "const_product_110106": "Living Room Furniture",
    "const_product_11010612": "Living Room Furniture Sets",
    "const_product_11010603": "Living Room Sofa",
    "const_product_14020409": "Load Cell",
    "const_product_10010713": "Load Cell",
    "const_product_17040210": "Loader",
    "const_product_210103": "Lock",
    "const_product_80403": "Lock",
    "const_product_25020105": "Lock",
    "const_product_8040308": "Lock Key & Accessories",
    "const_product_27040103": "Locker",
    "const_product_11030906": "Locker",
    "const_product_8040309": "Locksmith Tools",
    "const_product_27080401": "Locomotive",
    "const_product_8080705": "Log and Veneer",
    "const_product_220306": "Logistics Services",
    "const_product_17100105": "Lost Wax Casting",
    "const_product_27080205": "LPG Filling Station",
    "const_product_180304": "Lubricant",
    "const_product_4030210": "Lubricant",
    "const_product_60602": "Lubricant",
    "const_product_170302": "Lubricating Equipment",
    "const_product_4020208": "Lubrication System",
    "const_product_503": "Luggage & Travel Bags",
    "const_product_5060105": "Luggage Bar",
    "const_product_11030104": "Luggage Cart",
    "const_product_5060106": "Luggage Cover",
    "const_product_11030102": "Luggage Rack",
    "const_product_5060107": "Luggage Strap",
    "const_product_3040408": "Luggage Tag",
    "const_product_5060108": "Luggage Tag",
    "const_product_5060109": "Luggage Wheel",
    "const_product_25020504": "Luggage Wheel",
    "const_product_30206": "Luminous & Fluorescent Items",
    "const_product_15010908": "Lunch Box",
    "const_product_250204": "Machine Hardware",
    "const_product_13010412": "Machine Oil Purifier",
    "const_product_171104": "Machine Tools",
    "const_product_17110409": "Machine Tools Accessories",
    "const_product_170104": "Machinery for Food, Beverage & Cereal",
    "const_product_10504": "Machinery for Food, Beverage & Cereal",
    "const_product_20502": "Machinery for Garment, Shoes & Accessories",
    "const_product_170201": "Machinery for Garment, Shoes & Accessories",
    "const_product_170901": "Machinery for Packaging Supplies",
    "const_product_200101": "Machinery for Packaging Supplies",
    "const_product_130703": "Machinery Stocks",
    "const_product_1307": "Machinery Stocks & Others",
    "const_product_13070105": "Machining Service",
    "const_product_15080408": "Magazine & Newspaper Rack",
    "const_product_11030411": "Magazine & Newspaper Rack",
    "const_product_11030702": "Magazine & Newspaper Rack",
    "const_product_260303": "Magical Toys",
    "const_product_18010310": "Magnesium",
    "const_product_10030402": "Magnetic Card",
    "const_product_12020203": "Magnetic Health Care Products",
    "const_product_180201": "Magnetic Material",
    "const_product_100117": "Magnetic Material",
    "const_product_14030102": "Magnifier",
    "const_product_80810": "Mailbox",
    "const_product_11020116": "Mailbox",
    "const_product_70306": "Mainboard",
    "const_product_220205": "Maintenance Training",
    "const_product_150205": "Makeup",
    "const_product_15020512": "Makeup Base",
    "const_product_15020611": "Makeup Brushes",
    "const_product_15020612": "Makeup Mirror",
    "const_product_11080204": "Makeup Mirror",
    "const_product_8050404": "Makeup Mirror",
    "const_product_15020513": "Makeup Remover",
    "const_product_15020613": "Makeup Scissors",
    "const_product_15020514": "Makeup Set",
    "const_product_15020614": "Makeup Tool Kits",
    "const_product_150206": "Makeup Tools",
    "const_product_24010201": "Man-made Fiber",
    "const_product_23040314": "Mandolin",
    "const_product_27070101": "Manhole Cover",
    "const_product_15020702": "Manicure & Pedicure Set",
    "const_product_130307": "Manipulator",
    "const_product_170703": "Manipulator",
    "const_product_20405": "Mannequin",
    "const_product_150904": "Mannequin",
    "const_product_8020107": "Mantel",
    "const_product_17": "Manufacturing & Processing Machinery",
    "const_product_171201": "Manufacturing Equipment for Electrical & Electronic Product",
    "const_product_100507": "Manufacturing Equipment for Electrical & Electronic Product",
    "const_product_8020108": "Marble",
    "const_product_2705": "Marine & Aviation",
    "const_product_27050206": "Marine Airbags",
    "const_product_6110102": "Marine Airbags",
    "const_product_17010305": "Marine Crane",
    "const_product_27050207": "Marine Door",
    "const_product_27050208": "Marine Engines",
    "const_product_27050209": "Marine Hardware",
    "const_product_270502": "Marine Parts",
    "const_product_27050210": "Marine Pumps",
    "const_product_27050211": "Marine Windows",
    "const_product_19020106": "Marker & Highlighter",
    "const_product_19030103": "Marker & Highlighter",
    "const_product_170105": "Marking Machinery",
    "const_product_23010214": "Martial Arts & Boxing Supplies",
    "const_product_15020515": "Mascara",
    "const_product_130105": "Mass Transfer and Separation Equipment",
    "const_product_13010501": "Mass Transfer Equipment",
    "const_product_17030109": "Mass Transfer Equipment",
    "const_product_13010502": "Mass Transfer Equipment Parts",
    "const_product_12020305": "Massage Belt",
    "const_product_11030805": "Massage Chair",
    "const_product_12020306": "Massage Chair",
    "const_product_12020307": "Massage Cushion",
    "const_product_12020308": "Massage Pillow",
    "const_product_11030806": "Massage Table & Bed",
    "const_product_12020309": "Massage Table & Bed",
    "const_product_120203": "Massager",
    "const_product_230409": "Massager",
    "const_product_11030804": "Master Chair",
    "const_product_15080307": "Match",
    "const_product_150803": "Match, Lighter & Smoking Set",
    "const_product_240115": "Materials for Cap & Shoes",
    "const_product_20206": "Maternity Dress",
    "const_product_24060104": "Maternity Pillow",
    "const_product_15030108": "Matting",
    "const_product_15030109": "Mattress",
    "const_product_11010202": "Mattress",
    "const_product_15030110": "Mattress Cover",
    "const_product_171202": "Mattress Machine",
    "const_product_17120201": "Mattress Packing Machine",
    "const_product_17120202": "Mattress Sewing Machine",
    "const_product_17120203": "Mattress Spring Machine",
    "const_product_14020305": "Measuring Device",
    "const_product_14020410": "Measuring Device",
    "const_product_1402": "Measuring Instrument",
    "const_product_15010617": "Meat & Poultry Tools",
    "const_product_10207": "Meat & Preparation",
    "const_product_17010410": "Meat Processing Machinery",
    "const_product_17110202": "Mechanical Engraving Machine",
    "const_product_13020502": "Mechanical Seal",
    "const_product_19030104": "Mechatronics Training Equipment",
    "const_product_30408": "Medal",
    "const_product_12030204": "Medical Bed & Table",
    "const_product_12030405": "Medical Cap & Medical Shoe Cover",
    "const_product_220404": "Medical Care",
    "const_product_25020505": "Medical Caster",
    "const_product_12030205": "Medical Cryogenic Equipment",
    "const_product_12030206": "Medical Diagnosis Equipment",
    "const_product_140106": "Medical Diagnosis Equipment",
    "const_product_12030406": "Medical Disinfection Products",
    "const_product_120302": "Medical Equipment",
    "const_product_12030407": "Medical Gloves",
    "const_product_12030408": "Medical Goggles",
    "const_product_120303": "Medical Implement",
    "const_product_12030409": "Medical Mask",
    "const_product_12030410": "Medical Protective Clothing",
    "const_product_1203": "Medical Supplies & Devices",
    "const_product_120304": "Medical Supply",
    "const_product_27040207": "Medical Vehicle",
    "const_product_21050109": "Medical-Ray Protective Products",
    "const_product_120307": "Medical-Ray Protective Products",
    "const_product_1201": "Medicine",
    "const_product_8080706": "Melamine Board",
    "const_product_14010405": "Melting Point Apparatus",
    "const_product_10030607": "Membrane Switch",
    "const_product_19020213": "Memo & Note Pad",
    "const_product_70403": "Memory",
    "const_product_12020204": "Memory & Sleep Health Care Products",
    "const_product_7040205": "Memory Card",
    "const_product_9020610": "Memory Card",
    "const_product_2020308": "Men's Dress",
    "const_product_2030408": "Men's Shoes",
    "const_product_2010202": "Men's T-Shirts",
    "const_product_16060105": "Mercury Light",
    "const_product_5010106": "Messenger Bag",
    "const_product_1801": "Metal & Products",
    "const_product_20030108": "Metal & Tin Glass Printing",
    "const_product_11040501": "Metal Bed",
    "const_product_2010104": "Metal Belt",
    "const_product_11040502": "Metal Cabinet",
    "const_product_17100106": "Metal Casting",
    "const_product_17050108": "Metal Casting Machinery",
    "const_product_17100208": "Metal Casting Mould",
    "const_product_8050203": "Metal Ceiling",
    "const_product_11040503": "Metal Chair",
    "const_product_17050109": "Metal Coating Machinery",
    "const_product_30309": "Metal Crafts",
    "const_product_14010207": "Metal Detector for Food",
    "const_product_8040105": "Metal Door",
    "const_product_17050110": "Metal Drawing Machinery",
    "const_product_17050111": "Metal Electroplating Machine",
    "const_product_17050112": "Metal Engraving Machinery",
    "const_product_17110203": "Metal Engraving Machinery",
    "const_product_17050113": "Metal Forging Machinery",
    "const_product_110405": "Metal Furniture",
    "const_product_5050203": "Metal Gift Bag",
    "const_product_5050204": "Metal Gift Box",
    "const_product_16060106": "Metal Halide Lamp",
    "const_product_3040304": "Metal Key Chain",
    "const_product_16040104": "Metal Light",
    "const_product_17050114": "Metal Polishing Machine",
    "const_product_17050115": "Metal Processing Machinery Parts",
    "const_product_17050116": "Metal Recycling Machine",
    "const_product_17110502": "Metal Recycling Machine",
    "const_product_13010303": "Metal Recycling Machine",
    "const_product_180102": "Metal Scrap",
    "const_product_11040504": "Metal Shelf",
    "const_product_17050117": "Metal Spinning Machinery",
    "const_product_180107": "Metal Stocks",
    "const_product_17050118": "Metal Straightening Machinery",
    "const_product_11040505": "Metal Table",
    "const_product_20010501": "Metal Tag & Sign",
    "const_product_8040402": "Metal Window",
    "const_product_8080809": "Metal Wire",
    "const_product_17050119": "Metal Wire Forming Machine",
    "const_product_1705": "Metal, Woodworking & Stone Processing Machinery",
    "const_product_170501": "Metallic Processing Machinery",
    "const_product_180402": "Metallic Processing Machinery",
    "const_product_20010308": "Metallized Film",
    "const_product_170605": "Metallurgy Machinery",
    "const_product_180403": "Metallurgy Machinery",
    "const_product_18": "Metallurgy, Mineral & Energy",
    "const_product_100508": "Meter for Electricity",
    "const_product_140201": "Meter for Electricity",
    "const_product_140202": "Meter for Liquid & Gas",
    "const_product_23040315": "Metronome",
    "const_product_18020208": "Mica",
    "const_product_10030205": "Micro Motor",
    "const_product_10030608": "Micro Switch",
    "const_product_9010104": "Microphone",
    "const_product_14030103": "Microscope",
    "const_product_9030522": "Microwave Oven",
    "const_product_13060207": "MIG Welder",
    "const_product_21030102": "Military & Police Apparel & Accessories",
    "const_product_21030103": "Military & Police Bag",
    "const_product_50510": "Military & Police Bag",
    "const_product_21030104": "Military & Police Communication Supply",
    "const_product_21030105": "Military & Police Material",
    "const_product_240116": "Military & Police Material",
    "const_product_21030106": "Military & Police Optical Instrument",
    "const_product_21030107": "Military & Police Outdoor Supply",
    "const_product_21030108": "Military & Police Protective Equipment",
    "const_product_210301": "Military & Police Supply",
    "const_product_21030109": "Military & Police Vehicle",
    "const_product_210302": "Military Style Apparel",
    "const_product_20207": "Military Style Apparel",
    "const_product_2103": "Military Supplies",
    "const_product_13020206": "Milling Cutter",
    "const_product_17110410": "Milling Machine",
    "const_product_17060103": "Mine Drilling Rig",
    "const_product_17060204": "Mine Drilling Rig",
    "const_product_17100107": "Mineral Casting",
    "const_product_17060205": "Mineral Separator",
    "const_product_17110603": "Mineral Separator",
    "const_product_8050204": "Mineral Wool Ceiling",
    "const_product_13030312": "Mini Pressure Valve",
    "const_product_7050103": "Mini Speaker",
    "const_product_1706": "Mining & Metallurgy Machinery",
    "const_product_1804": "Mining & Metallurgy Machinery",
    "const_product_17060206": "Mining Conveyor",
    "const_product_17060207": "Mining Crusher",
    "const_product_17060208": "Mining Dryer",
    "const_product_170602": "Mining Machinery",
    "const_product_180404": "Mining Machinery",
    "const_product_17060209": "Mining Machinery Parts",
    "const_product_17060210": "Mining Mill",
    "const_product_17060211": "Mining Thickener",
    "const_product_80504": "Mirror",
    "const_product_110802": "Mirror",
    "const_product_3060102": "Mirror Frame",
    "const_product_180108": "Misc Ore",
    "const_product_27040208": "Mixer Truck",
    "const_product_170405": "Mixing Equipment",
    "const_product_9020502": "Mobile Phone",
    "const_product_90205": "Mobile Phone & Accessories",
    "const_product_9020503": "Mobile Phone Battery",
    "const_product_10030110": "Mobile Phone Battery",
    "const_product_9020504": "Mobile Phone Cable",
    "const_product_9020505": "Mobile Phone Case",
    "const_product_50204": "Mobile Phone Case",
    "const_product_9020506": "Mobile Phone Charger",
    "const_product_10030111": "Mobile Phone Charger",
    "const_product_9020507": "Mobile Phone Decoration",
    "const_product_30409": "Mobile Phone Decoration",
    "const_product_9010408": "Mobile Phone Earphone",
    "const_product_9020508": "Mobile Phone Earphone",
    "const_product_9020509": "Mobile Phone Flex Cables",
    "const_product_9020510": "Mobile Phone Holder",
    "const_product_9020511": "Mobile Phone Housing",
    "const_product_9020512": "Mobile Phone Keypad",
    "const_product_9020513": "Mobile Phone LCD",
    "const_product_260304": "Model & Miniature Toys",
    "const_product_7060103": "Modem",
    "const_product_11050201": "Modern Bed",
    "const_product_11050202": "Modern Cabinet",
    "const_product_11050203": "Modern Chair",
    "const_product_110502": "Modern Furniture",
    "const_product_11050204": "Modern Shelf & Rack",
    "const_product_11050205": "Modern Sofa",
    "const_product_11050206": "Modern Stool",
    "const_product_11050207": "Modern Table & Desk",
    "const_product_14010109": "Moisture Meter",
    "const_product_18010311": "Molybdenum",
    "const_product_3040105": "Money Box",
    "const_product_9040105": "Money Counter",
    "const_product_21010205": "Monitor",
    "const_product_9010306": "Monitor Stand",
    "const_product_21010206": "Monitoring System & Software",
    "const_product_15040409": "Mop",
    "const_product_17040211": "Mortar Spray Machine",
    "const_product_17050304": "Mortising Machine",
    "const_product_8030105": "Mosaic Tile",
    "const_product_15030111": "Mosquito Net",
    "const_product_2020309": "Mother of the Bride Dress",
    "const_product_16010404": "Motif Light",
    "const_product_16040105": "Motif Light",
    "const_product_27020402": "Motor Trike",
    "const_product_2706": "Motorcycle",
    "const_product_270601": "Motorcycle",
    "const_product_40503": "Motorcycle",
    "const_product_4050101": "Motorcycle Battery",
    "const_product_10030112": "Motorcycle Battery",
    "const_product_4050102": "Motorcycle Body Parts",
    "const_product_4050103": "Motorcycle Electronics",
    "const_product_4050104": "Motorcycle Engine",
    "const_product_4050105": "Motorcycle Helmets",
    "const_product_23020502": "Motorcycle Helmets",
    "const_product_23020803": "Motorcycle Helmets",
    "const_product_4050106": "Motorcycle Luggage",
    "const_product_40501": "Motorcycle Parts & Accessories",
    "const_product_270602": "Motorcycle Parts & Accessories",
    "const_product_405": "Motorcycle Parts & Stocks",
    "const_product_4050107": "Motorcycle Shock Absorber",
    "const_product_4050108": "Motorcycle Steering & Transmission System",
    "const_product_4010706": "Motorcycle Tire",
    "const_product_27040407": "Motorcycle Trailer",
    "const_product_27080301": "Motorhome",
    "const_product_25020404": "Mould",
    "const_product_171002": "Mould",
    "const_product_17100209": "Mould Parts",
    "const_product_8050310": "Moulding",
    "const_product_27020205": "Mountain Bicycle",
    "const_product_70507": "Mouse",
    "const_product_70508": "Mousepad",
    "const_product_15020803": "Mouth Wash",
    "const_product_16030204": "Moving Head Light",
    "const_product_27080104": "Moving Sidewalk",
    "const_product_9010603": "MP3 Player",
    "const_product_9010604": "MP4 Player",
    "const_product_9010803": "MP4 Player",
    "const_product_17060303": "Mud Circulating System",
    "const_product_4020209": "Muffler",
    "const_product_20010408": "Multi-Function Packing Machine",
    "const_product_10010204": "Multilayer PCB",
    "const_product_14020103": "Multimeter",
    "const_product_1030208": "Mushroom Extract",
    "const_product_3040106": "Music Box",
    "const_product_50511": "Music Box",
    "const_product_150606": "Musical Instrument",
    "const_product_230403": "Musical Instrument",
    "const_product_260305": "Musical Toys",
    "const_product_13020306": "Nail",
    "const_product_15020703": "Nail Acrylic Powder",
    "const_product_150207": "Nail Beauty Products",
    "const_product_15020704": "Nail Brush",
    "const_product_15020705": "Nail Clippers",
    "const_product_15020706": "Nail Decoration",
    "const_product_15020707": "Nail Dryer",
    "const_product_15020708": "Nail File",
    "const_product_15020709": "Nail Glue",
    "const_product_15020710": "Nail Lamp",
    "const_product_17050120": "Nail Making Machine",
    "const_product_15020711": "Nail Polish",
    "const_product_15020712": "Nail Polish Remover",
    "const_product_15020713": "Nail Printer",
    "const_product_11030807": "Nail Table",
    "const_product_18020104": "Nanocrystalline Core",
    "const_product_15070114": "Nasal Aspirator",
    "const_product_10315": "Native Products",
    "const_product_30310": "Natural Crafts",
    "const_product_180305": "Natural Gas & Coal Gas",
    "const_product_6010103": "NBR Foam",
    "const_product_18020105": "NdFeB Magnet",
    "const_product_15021012": "Neck Mask",
    "const_product_9010409": "Neckband Headphones",
    "const_product_3010111": "Necklace",
    "const_product_2040110": "Neckline",
    "const_product_20406": "Necktie & Bow Tie",
    "const_product_13020105": "Needle Bearing",
    "const_product_14010208": "Needle Detector",
    "const_product_17020204": "Needle Detector",
    "const_product_13030313": "Needle Valve",
    "const_product_16060107": "Neon Bulb & Tube",
    "const_product_16040106": "Neon Light",
    "const_product_17020205": "Net Machine",
    "const_product_24040101": "Netting",
    "const_product_10040208": "Network Cabinet",
    "const_product_7060104": "Network Card",
    "const_product_70601": "Network Hardware & Parts",
    "const_product_220206": "Network Information Services",
    "const_product_7060105": "Network Switch",
    "const_product_706": "Networking Devices",
    "const_product_61204": "New-type Chemical Material",
    "const_product_10316": "New-type Food",
    "const_product_20020404": "Newsprint",
    "const_product_18010312": "Nickel",
    "const_product_14030104": "Night Vision",
    "const_product_11010203": "Nightstands",
    "const_product_18010313": "Niobium",
    "const_product_8080607": "Nipple",
    "const_product_6040406": "Nitrate",
    "const_product_1040103": "Nitrogen Fertilizer",
    "const_product_6080202": "Nitrogen Fertilizer",
    "const_product_13010304": "Noise Reduction Device",
    "const_product_5010107": "Non Woven Bag",
    "const_product_50512": "Non Woven Bag",
    "const_product_200108": "Non Woven Bag",
    "const_product_150814": "Non Woven Bag",
    "const_product_240117": "Non Woven Fabric",
    "const_product_180103": "Non-ferrous Metal & Products",
    "const_product_1802": "Non-Metallic Materials",
    "const_product_25010115": "Non-Woven Abrasives",
    "const_product_5050302": "Non-woven Shopping Bag",
    "const_product_180202": "Nonmetallic Minerals",
    "const_product_20010502": "Nonmetallic Tag & Sign",
    "const_product_17020206": "Nonwoven Machine",
    "const_product_19020214": "Notebook",
    "const_product_260803": "Novelty Toys",
    "const_product_15050109": "Nurse Watch",
    "const_product_12030207": "Nursing Equipment",
    "const_product_13020307": "Nut",
    "const_product_10111": "Nut & Seed",
    "const_product_1030114": "Nutrition Enhancers",
    "const_product_21010207": "NVR",
    "const_product_240118": "Nylon Fabric",
    "const_product_4010708": "Off Road Tire",
    "const_product_190111": "Office & Education Appliance Stocks",
    "const_product_19020215": "Office Adhesive Tape",
    "const_product_15090202": "Office Adhesive Tape",
    "const_product_11030410": "Office Bookcase",
    "const_product_11030407": "Office Chair",
    "const_product_11030405": "Office Desk",
    "const_product_190112": "Office Electronics",
    "const_product_90408": "Office Electronics",
    "const_product_30610": "Office Electronics",
    "const_product_1901": "Office Equipment",
    "const_product_190113": "Office Furniture",
    "const_product_110304": "Office Furniture",
    "const_product_190114": "Office Gifts & Decoration",
    "const_product_30611": "Office Gifts & Decoration",
    "const_product_19020216": "Office Hole Punch",
    "const_product_19010111": "Office Paper",
    "const_product_19020217": "Office Paper",
    "const_product_11030412": "Office Partition",
    "const_product_8050502": "Office Partition",
    "const_product_11030408": "Office Sofa",
    "const_product_19020218": "Office Staples",
    "const_product_19": "Office Supplies",
    "const_product_20020314": "Offset Printing Machine",
    "const_product_13010305": "Oil Boom & Oil Absorbent",
    "const_product_13010413": "Oil Filter",
    "const_product_3060204": "Oil Painting",
    "const_product_4020409": "Oil Pan",
    "const_product_17010117": "Oil Presser",
    "const_product_13020503": "Oil Seal",
    "const_product_27080206": "Oil Tank",
    "const_product_17060104": "Oilfield Drilling Rig",
    "const_product_17060304": "Oilfield Drilling Rig",
    "const_product_17060305": "Oilfield Shale Shaker",
    "const_product_9010307": "OLED Display",
    "const_product_1010106": "Onion",
    "const_product_70602": "Optical Fiber",
    "const_product_10030309": "Optical Fiber",
    "const_product_100303": "Optical Fiber, Cable & Wire",
    "const_product_14030105": "Optical Filter",
    "const_product_12020205": "Optical Health Care Products",
    "const_product_1403": "Optical Instrument",
    "const_product_14030106": "Optical Lens",
    "const_product_140301": "Optical Lens & Instrument",
    "const_product_190307": "Optical Lens & Instrument",
    "const_product_14030107": "Optical Mirror",
    "const_product_17110604": "Optical Sorting Machine",
    "const_product_21010208": "Optical Transmitter & Receiver",
    "const_product_150208": "Oral Care",
    "const_product_12030108": "Oral Care",
    "const_product_60301": "Organic Chemicals",
    "const_product_1040104": "Organic Fertilizer",
    "const_product_6030110": "Organic Intermediate",
    "const_product_6020206": "Organic Pigment",
    "const_product_12020403": "Orthopedic & Immobilization Products",
    "const_product_100118": "Oscillator",
    "const_product_14010305": "Oscilloscope",
    "const_product_110801": "Other",
    "const_product_25010121": "Other Abrasive & Grinding Tools",
    "const_product_21010114": "Other Access Control Systems",
    "const_product_6050106": "Other Additives",
    "const_product_17010125": "Other Agricultural Machinery",
    "const_product_106": "Other Agriculture",
    "const_product_10601": "Other Agriculture",
    "const_product_13010106": "Other Air Clean Equipments",
    "const_product_6110105": "Other Airbags",
    "const_product_21020111": "Other Alarm & Security Systems",
    "const_product_18010110": "Other Aluminum",
    "const_product_23040107": "Other Amusement Park Facilities",
    "const_product_14010112": "Other Analysis Instruments",
    "const_product_11050104": "Other Antique & Reproduction Furniture",
    "const_product_20128": "Other Apparel",
    "const_product_3040107": "Other Art Boxes",
    "const_product_3060105": "Other Art Frames",
    "const_product_8080710": "Other Artificial Wood",
    "const_product_9010107": "Other Audio & Sets",
    "const_product_4020108": "Other Auto Electrical System",
    "const_product_4020213": "Other Auto Engine Structure",
    "const_product_4020316": "Other Auto Parts",
    "const_product_4030318": "Other Auto Repair Equipment & Tools",
    "const_product_4010107": "Other Auto Sensors",
    "const_product_15070117": "Other Baby Products",
    "const_product_5060111": "Other Bag Parts & Accessories",
    "const_product_506": "Other Bags & Accessories",
    "const_product_50602": "Other Bags & Cases",
    "const_product_200113": "Other Bags & Cases",
    "const_product_11040105": "Other Bamboo Furniture",
    "const_product_11030206": "Other Bar Furniture",
    "const_product_24010105": "Other Bast Fiber Fabrics",
    "const_product_15020107": "Other Bath Supplies",
    "const_product_8010115": "Other Bathroom Fittings & Accessories",
    "const_product_11010303": "Other Bathroom Furniture",
    "const_product_10030120": "Other Battery, Storage Battery & Charger",
    "const_product_13020112": "Other Bearing",
    "const_product_12020106": "Other Beauty Equipment",
    "const_product_15030115": "Other Bedding Textile",
    "const_product_11010210": "Other Bedroom Furniture",
    "const_product_11080101": "Other Beds",
    "const_product_2010108": "Other Belts",
    "const_product_27020125": "Other Bicycle Parts",
    "const_product_27020210": "Other Bikes",
    "const_product_9020204": "Other Bluetooth Products",
    "const_product_19030204": "Other Boards",
    "const_product_27050108": "Other Boats & Ships",
    "const_product_10020107": "Other Breaker & Protector",
    "const_product_8030113": "Other Brick, Tile & Accessories",
    "const_product_8060103": "Other Bridge Structures",
    "const_product_80811": "Other Building & Decoration Materials",
    "const_product_8050112": "Other Building Glass",
    "const_product_25020110": "Other Building Hardware",
    "const_product_8080104": "Other Building Heater",
    "const_product_17040117": "Other Building Material Making Machinery",
    "const_product_8060212": "Other Building Steel & Structures",
    "const_product_27030105": "Other Buses",
    "const_product_11080102": "Other Cabinets",
    "const_product_10010112": "Other Cable Parts",
    "const_product_23020319": "Other Camping & Outdoor Gear",
    "const_product_4010213": "Other Car Accessories",
    "const_product_4010306": "Other Car Air Conditioning System",
    "const_product_4030114": "Other Car Decoration",
    "const_product_4060106": "Other Car Electrical Appliances",
    "const_product_4010408": "Other Car Electronics",
    "const_product_4010515": "Other Car Lights & Auto Mirrors",
    "const_product_4030214": "Other Car Maintenance",
    "const_product_4040108": "Other Car Safety",
    "const_product_27040109": "Other Cargo & Storage Equipment",
    "const_product_17100110": "Other Casting & Forging",
    "const_product_8050208": "Other Ceilings",
    "const_product_2020314": "Other Ceremonial Clothing",
    "const_product_11080103": "Other Chairs",
    "const_product_17030112": "Other Chemical Equipment & Machinery",
    "const_product_24010203": "Other Chemical Fiber",
    "const_product_612": "Other Chemicals",
    "const_product_61206": "Other Chemicals",
    "const_product_11010111": "Other Children & Baby Furniture",
    "const_product_10010209": "Other Circuit Board",
    "const_product_11050306": "Other Classic Furniture",
    "const_product_13010214": "Other Cleaning Machines",
    "const_product_15050117": "Other Clocks & Watches",
    "const_product_2040118": "Other Clothing Accessories",
    "const_product_9040112": "Other Commerce & Finance Electronics",
    "const_product_17010207": "Other Commodity Making Machines",
    "const_product_10040218": "Other Communication Products",
    "const_product_220105": "Other Communication Services",
    "const_product_13030110": "Other Compressors",
    "const_product_10010317": "Other Connectors",
    "const_product_8080210": "Other Construction Equipment & Tools",
    "const_product_905": "Other Consumer Electronics",
    "const_product_90504": "Other Consumer Electronics",
    "const_product_13040107": "Other Conveyors & Parts",
    "const_product_15010212": "Other Cookware",
    "const_product_30702": "Other Crafts",
    "const_product_17010310": "Other Cranes",
    "const_product_4020413": "Other Crank Mechanisms",
    "const_product_15010311": "Other Cups & Mugs",
    "const_product_8060304": "Other Curtain Wall Accessories",
    "const_product_13070111": "Other Custom Fabrication Service",
    "const_product_13020212": "Other Cutters & Tongs",
    "const_product_17110109": "Other Cutting Machines",
    "const_product_8050317": "Other Decoration Materials",
    "const_product_16040109": "Other Decorative Lights",
    "const_product_19030107": "Other Demonstrational & Teaching Utensils",
    "const_product_12030110": "Other Dental Equipment & Supplies",
    "const_product_14010213": "Other Detectors",
    "const_product_150822": "Other Digital Products Bags",
    "const_product_50206": "Other Digital Products Bags",
    "const_product_70712": "Other Digital Products Bags",
    "const_product_11010505": "Other Dining Room Furniture",
    "const_product_12020408": "Other Disabilities",
    "const_product_9010206": "Other Disks & Tapes",
    "const_product_9010310": "Other Displays",
    "const_product_15080209": "Other Disposable Products",
    "const_product_8040220": "Other Door & Window Hardware",
    "const_product_8040109": "Other Doors",
    "const_product_17060106": "Other Drilling Rigs",
    "const_product_25010310": "Other Drilling Tools",
    "const_product_15010406": "Other Drinkware",
    "const_product_7040105": "Other Drives",
    "const_product_13050213": "Other Drying Machines",
    "const_product_9010410": "Other Earphones & Headphones",
    "const_product_27020309": "Other Electric Vehicles",
    "const_product_1005": "Other Electrical & Electronics",
    "const_product_14010308": "Other Electronic Instruments",
    "const_product_10010407": "Other Electronic Tubes & Transistors",
    "const_product_100510": "Other Electronics",
    "const_product_27080109": "Other Elevators & Funicular Cars",
    "const_product_16050106": "Other Emergency & Indicator Lights",
    "const_product_17040217": "Other Engineering & Construction Machinery",
    "const_product_9020405": "Other Entertainment Electronics",
    "const_product_13010316": "Other Environment Protection Device",
    "const_product_130704": "Other Equipment & Components",
    "const_product_11050505": "Other European Furniture",
    "const_product_15090114": "Other Exhibition and Advertising Equipment",
    "const_product_15050215": "Other Eyewear",
    "const_product_11040203": "Other Fabric Furniture",
    "const_product_1050113": "Other Farm Facilities & Equipment",
    "const_product_3010115": "Other Fashion Accessories",
    "const_product_13020315": "Other Fastener & Fitting",
    "const_product_8010208": "Other Faucets, Taps & Mixers",
    "const_product_8080413": "Other Fences",
    "const_product_6080206": "Other Fertilizers",
    "const_product_6040107": "Other Fiberglass Products",
    "const_product_13010415": "Other Filtration Equipment & Parts",
    "const_product_21020216": "Other Fire Control Equipment",
    "const_product_23020413": "Other Fishing Tackle",
    "const_product_23010118": "Other Fitness Equipment & Body Building",
    "const_product_8030212": "Other Floors",
    "const_product_6010106": "Other Foam",
    "const_product_1030115": "Other Food Additives",
    "const_product_3040207": "Other Funeral Products",
    "const_product_1108": "Other Furniture",
    "const_product_11080109": "Other Furniture",
    "const_product_24030104": "Other Furniture Covers",
    "const_product_11070108": "Other Furniture Hardware",
    "const_product_11070207": "Other Furniture Parts & Accessories",
    "const_product_11080108": "Other Furniture Sets",
    "const_product_27080207": "Other Gas Station Equipments",
    "const_product_13040310": "Other Generators",
    "const_product_8080508": "Other Geosynthetics Products",
    "const_product_5050209": "Other Gift Boxes & Bags",
    "const_product_307": "Other Gifts & Crafts",
    "const_product_11040304": "Other Glass Furniture",
    "const_product_2030104": "Other Gloves & Mittens",
    "const_product_10040106": "Other GPS",
    "const_product_23040206": "Other Guitars",
    "const_product_15020308": "Other Hair Care Products",
    "const_product_3010206": "Other Hair Products",
    "const_product_15010508": "Other Hair Tools & Accessories",
    "const_product_15020408": "Other Hair Tools & Accessories",
    "const_product_11010706": "Other Hallway Furniture",
    "const_product_25010420": "Other Hand Tools",
    "const_product_5010112": "Other Handbags",
    "const_product_25020215": "Other Hardware Accessories",
    "const_product_2030205": "Other Hats & Caps",
    "const_product_9010505": "Other HDMI Equipment",
    "const_product_12020207": "Other Health Care Products",
    "const_product_13050306": "Other Heaters & Heat Exchangers",
    "const_product_10020205": "Other Heating, Refrigeration & Temperature Control",
    "const_product_23020505": "Other Helmets",
    "const_product_3020207": "Other Holiday Gifts & Decorations",
    "const_product_25020304": "Other Home Hardware",
    "const_product_15060122": "Other Horticulture & Gardening Products",
    "const_product_11031006": "Other Hospital Furniture",
    "const_product_11030106": "Other Hotel Furniture",
    "const_product_15040109": "Other Household Chemicals",
    "const_product_15040208": "Other Household Plastic Products",
    "const_product_15040309": "Other Household Receptacle",
    "const_product_9030405": "Other Household Water Treatment Equipment",
    "const_product_25010510": "Other Hydraulic Parts",
    "const_product_16020107": "Other Indoor Lighting",
    "const_product_13020403": "Other Industrial Adhesive Products",
    "const_product_26040108": "Other Inflatable Toys",
    "const_product_6040304": "Other Inorganic Fiber",
    "const_product_6040411": "Other Inorganic Salts",
    "const_product_140511": "Other Instruments & Meters",
    "const_product_10020303": "Other Inverters",
    "const_product_11050406": "Other Italian Furniture",
    "const_product_3010310": "Other Jewelry",
    "const_product_3040307": "Other Key Chains",
    "const_product_9030533": "Other Kitchen Electronics",
    "const_product_11010405": "Other Kitchen Furniture",
    "const_product_15010624": "Other Kitchen Implements",
    "const_product_11030605": "Other Lab Furniture",
    "const_product_14040209": "Other Lab Instruments",
    "const_product_14040117": "Other Lab Utensils and Disposables",
    "const_product_15010707": "Other Label",
    "const_product_17110310": "Other Laser Equipments",
    "const_product_11040404": "Other Leather Furniture",
    "const_product_16010209": "Other LED Encapsulation Series",
    "const_product_16010317": "Other LED Interior Lighting",
    "const_product_16010406": "Other LED Lighting Decoration",
    "const_product_16010511": "Other LED Outdoor Lighting",
    "const_product_16010619": "Other LED Professional Lighting",
    "const_product_11030704": "Other Library Furniture",
    "const_product_17040312": "Other Lifting Equipment",
    "const_product_16060111": "Other Light Bulb & Tube",
    "const_product_16070117": "Other Lighting Fixtures",
    "const_product_160802": "Other Lights & Lighting",
    "const_product_1608": "Other Lights & Lighting Products",
    "const_product_11010613": "Other Living Room Furniture",
    "const_product_8040312": "Other Locks",
    "const_product_25020407": "Other Machine Hardware",
    "const_product_17110415": "Other Machine Tools",
    "const_product_1712": "Other Machinery",
    "const_product_17010414": "Other Machinery for Food, Beverage & Cereal",
    "const_product_17020123": "Other Machinery for Garment, Shoes & Accessories",
    "const_product_18020110": "Other Magnetic Material",
    "const_product_15020516": "Other Makeup Products",
    "const_product_15020616": "Other Makeup Tools",
    "const_product_171205": "Other Manufacturing & Processing Machinery",
    "const_product_17120112": "Other Manufacturing Equipments for Electrical & Electronic Product",
    "const_product_27050212": "Other Marine Parts",
    "const_product_17010503": "Other Marking Machinery",
    "const_product_12020312": "Other Massagers",
    "const_product_17120205": "Other Mattress Machinery",
    "const_product_12030213": "Other Medical Equipment",
    "const_product_12030305": "Other Medical Implement",
    "const_product_12030417": "Other Medical Supplies",
    "const_product_11040506": "Other Metal Furniture",
    "const_product_17050127": "Other Metal Processing Machinery",
    "const_product_18010206": "Other Metal Scrap",
    "const_product_14020106": "Other Meters for Electricity",
    "const_product_14020207": "Other Meters for Liquid & Gas",
    "const_product_21030110": "Other Military & Police Supply",
    "const_product_1805": "Other Minerals",
    "const_product_180502": "Other Minerals",
    "const_product_17060215": "Other Mining Machinery",
    "const_product_11080205": "Other Mirrors",
    "const_product_8050405": "Other Mirrors",
    "const_product_9020517": "Other Mobile Phone Accessories",
    "const_product_11050208": "Other Modern Furniture",
    "const_product_4050110": "Other Motorcycle Parts & Accessories",
    "const_product_10030208": "Other Motors",
    "const_product_17100214": "Other Moulds",
    "const_product_23040324": "Other Musical Instruments",
    "const_product_15020714": "Other Nail Beauty Products",
    "const_product_7060107": "Other Network Hardware & Parts",
    "const_product_18010321": "Other Non-ferrous Metal & Products",
    "const_product_18020216": "Other Nonmetallic Minerals",
    "const_product_11030414": "Other Office Furniture",
    "const_product_1904": "Other Office Supplies",
    "const_product_190401": "Other Office Supplies",
    "const_product_14030115": "Other Optical Lens & Instruments",
    "const_product_15020808": "Other Oral Care",
    "const_product_11020118": "Other Outdoor Furniture",
    "const_product_16020208": "Other Outdoor Lighting",
    "const_product_20010236": "Other Package & Conveyance",
    "const_product_20030113": "Other Package & Printing Service",
    "const_product_20010109": "Other Packaging Machinery",
    "const_product_20010314": "Other Packaging Materials",
    "const_product_20010418": "Other Packing Machinery",
    "const_product_6020111": "Other Paint & Coating",
    "const_product_3060205": "Other Paintings & Calligraphies",
    "const_product_15020911": "Other Paper for Household & Sanitation",
    "const_product_17080125": "Other Paper Machinery",
    "const_product_8050504": "Other Partitions",
    "const_product_40505": "Other Parts & Accessories",
    "const_product_10010506": "Other Passive Components",
    "const_product_19020112": "Other Pens",
    "const_product_15020215": "Other Personal Care Appliances",
    "const_product_23030117": "Other Pet Supplies",
    "const_product_26070103": "Other Pet Toys",
    "const_product_17060309": "Other Petroleum Equipments & Machinery",
    "const_product_14020308": "Other Physical Measuring Meters",
    "const_product_6020211": "Other Pigment & Dye",
    "const_product_8080612": "Other Pipe Fittings",
    "const_product_8080307": "Other Pipes & Tubes",
    "const_product_1030211": "Other Plant Extract",
    "const_product_11040603": "Other Plastic Furniture",
    "const_product_17070124": "Other Plastic Machinery",
    "const_product_6010310": "Other Plastic Products",
    "const_product_13010609": "Other Pneumatic Components",
    "const_product_23040405": "Other Pool Accessories",
    "const_product_9010606": "Other Portable Audio Appliances",
    "const_product_20020112": "Other Post-Press Equipment",
    "const_product_10020412": "Other Power Supply & Distribution",
    "const_product_25010619": "Other Power Tools",
    "const_product_10020512": "Other Power Transmission & Transformer",
    "const_product_13040413": "Other Power Transmission Parts",
    "const_product_20020213": "Other Pre-Press Equipment",
    "const_product_19010117": "Other Printers & Printer Supplies",
    "const_product_20020322": "Other Printing Machinery",
    "const_product_20020415": "Other Printing Materials",
    "const_product_19030305": "Other Projector & Equipment",
    "const_product_3040412": "Other Promotional Gifts",
    "const_product_11031106": "Other Public Furniture",
    "const_product_13030216": "Other Pump & Vacuum Equipment",
    "const_product_9030108": "Other Purifiers & Humidifiers",
    "const_product_15080414": "Other Racks & Shelf",
    "const_product_15080105": "Other Rain Gear",
    "const_product_11040706": "Other Rattan & Wicker Furniture",
    "const_product_23020607": "Other Recreational Boats",
    "const_product_17110505": "Other Recycling Machines",
    "const_product_13050511": "Other Refrigeration Equipment & Parts",
    "const_product_9030607": "Other Refrigerators & Freezers",
    "const_product_11030304": "Other Restaurant Furniture",
    "const_product_15010802": "Other Restaurant Supplies",
    "const_product_27070103": "Other Roadway Facilities",
    "const_product_21040111": "Other Roadway Safety",
    "const_product_17070205": "Other Rubber Machinery",
    "const_product_6010410": "Other Rubber Products",
    "const_product_2020108": "Other Safety & Protective Apparels",
    "const_product_210601": "Other Safety Products",
    "const_product_9030708": "Other Salon Equipments",
    "const_product_11030809": "Other Salon Furniture",
    "const_product_15040415": "Other Sanitary Utensils",
    "const_product_8010305": "Other Sauna Room & Accessories",
    "const_product_11030506": "Other School Furniture",
    "const_product_13020507": "Other Seals",
    "const_product_2106": "Other Security & Protection Products",
    "const_product_10010722": "Other Sensors",
    "const_product_2206": "Other Services",
    "const_product_8060405": "Other Shaped Building Material",
    "const_product_11080107": "Other Shelves & Racks",
    "const_product_5050306": "Other Shopping Bags",
    "const_product_8010406": "Other Shower Heads & Shower Sets",
    "const_product_8010507": "Other Shower Room, Shower Enclosure & Accessories",
    "const_product_8010604": "Other Sink & Basin",
    "const_product_15021018": "Other Skin Care",
    "const_product_10030407": "Other Smart Card",
    "const_product_15080311": "Other Smoking Sets",
    "const_product_10030510": "Other Sockets, Outlets & Accessories",
    "const_product_11080104": "Other Sofas",
    "const_product_18030113": "Other Solar & Renewable Energy",
    "const_product_17110606": "Other Sorting Machines",
    "const_product_7050108": "Other Speakers & Sound Boxes",
    "const_product_27040215": "Other Specialized Vehicles",
    "const_product_23010226": "Other Sporting Goods",
    "const_product_230503": "Other Sporting Goods & Recreation",
    "const_product_23010312": "Other Sports Field Equipment & Facilities",
    "const_product_23020809": "Other Sports Gear",
    "const_product_23010409": "Other Sports Safety",
    "const_product_16030105": "Other Stage Equipment",
    "const_product_16030206": "Other Stage Lights",
    "const_product_19020228": "Other Stationery",
    "const_product_18010419": "Other Steel & Products",
    "const_product_17050207": "Other Stone Processing Machinery",
    "const_product_8020115": "Other Stones",
    "const_product_11080105": "Other Stools",
    "const_product_7040207": "Other Storage Devices",
    "const_product_11030908": "Other Store & Supermarket Furniture",
    "const_product_21010210": "Other Surveillance, Control & Protection",
    "const_product_10030620": "Other Switches",
    "const_product_11080106": "Other Tables & Desks",
    "const_product_15010913": "Other Tablewares",
    "const_product_15090204": "Other Tape & Adhesive",
    "const_product_14010408": "Other Test Instrument",
    "const_product_17020214": "Other Textile Machinery",
    "const_product_240408": "Other Textiles",
    "const_product_4010712": "Other Tires",
    "const_product_8010709": "Other Toilet & Accessories",
    "const_product_250302": "Other Tools & Hardware",
    "const_product_24030208": "Other Towels",
    "const_product_2609": "Other Toys",
    "const_product_260903": "Other Toys",
    "const_product_27040410": "Other Trailers",
    "const_product_10020609": "Other Transmitters",
    "const_product_270809": "Other Transportation Equipment",
    "const_product_27040507": "Other Trucks",
    "const_product_9010709": "Other TVs",
    "const_product_2010307": "Other Underwear",
    "const_product_13030319": "Other Valves & Parts",
    "const_product_1010109": "Other Vegetable",
    "const_product_13050609": "Other Ventilation Equipment & Parts",
    "const_product_9010809": "Other Videos",
    "const_product_5010204": "Other Wallets",
    "const_product_13010709": "Other Washing Equipment",
    "const_product_9030806": "Other Water Heaters",
    "const_product_9020705": "Other Wearable Devices",
    "const_product_14020415": "Other Weighing & Measuring Apparatus",
    "const_product_13060107": "Other Welding & Soldering Supplies",
    "const_product_13060219": "Other Welding Equipments",
    "const_product_25020508": "Other Wheels & Casters",
    "const_product_8040405": "Other Windows",
    "const_product_10030314": "Other Wire & Cable",
    "const_product_8080815": "Other Wire Meshes",
    "const_product_11040805": "Other Wooden Furniture",
    "const_product_17050324": "Other Woodworking Machinery",
    "const_product_17040212": "OTR Tire",
    "const_product_4010707": "OTR Tire",
    "const_product_11010604": "Ottoman",
    "const_product_1102": "Outdoor & Garden Furniture",
    "const_product_504": "Outdoor & Sports Bags",
    "const_product_11020104": "Outdoor Bar Stools",
    "const_product_11020103": "Outdoor Chaise Lounge",
    "const_product_11020101": "Outdoor Daybed",
    "const_product_23010109": "Outdoor Fitness Equipment",
    "const_product_110201": "Outdoor Furniture",
    "const_product_16010102": "Outdoor LED Display",
    "const_product_160202": "Outdoor Lighting",
    "const_product_24060105": "Outdoor Pillow & Cushion",
    "const_product_23040104": "Outdoor Playground",
    "const_product_11020106": "Outdoor Sofa",
    "const_product_2302": "Outdoor Sports Goods & Gears",
    "const_product_11020113": "Outdoor Table",
    "const_product_2604": "Outdoor Toys",
    "const_product_20115": "Outer Wear",
    "const_product_11031008": "Overbed Table",
    "const_product_17010306": "Overhead Crane",
    "const_product_17020114": "Overlock Machine",
    "const_product_6040205": "Oxide",
    "const_product_12030208": "Oxygen Concentrator",
    "const_product_13010306": "Oxygen Generator",
    "const_product_13010307": "Ozone Generator",
    "const_product_7050104": "PA Speaker",
    "const_product_200102": "Package & Conveyance",
    "const_product_240502": "Package & Conveyance",
    "const_product_150905": "Package & Conveyance",
    "const_product_150906": "Package & Printing Service",
    "const_product_240503": "Package & Printing Service",
    "const_product_200301": "Package & Printing Service",
    "const_product_120502": "Package & Printing Service",
    "const_product_20": "Packaging & Printing",
    "const_product_1709": "Packaging & Printing Machinery",
    "const_product_610": "Packaging & Printing Materials",
    "const_product_20010211": "Packaging Bags",
    "const_product_20010212": "Packaging Barrels & Buckets",
    "const_product_20010213": "Packaging Bottles",
    "const_product_20010214": "Packaging Bowls",
    "const_product_20010215": "Packaging Boxes",
    "const_product_20010216": "Packaging Cans & Jars",
    "const_product_20010217": "Packaging Cartons",
    "const_product_20010218": "Packaging Cups",
    "const_product_20010219": "Packaging Labels",
    "const_product_15010703": "Packaging Labels",
    "const_product_200103": "Packaging Materials",
    "const_product_61001": "Packaging Materials",
    "const_product_20010220": "Packaging Paper",
    "const_product_20010221": "Packaging Ropes & Strappings",
    "const_product_20010222": "Packaging Tray",
    "const_product_20010223": "Packaging Tubes",
    "const_product_2001": "Packing",
    "const_product_20010409": "Packing Line",
    "const_product_170902": "Packing Machinery",
    "const_product_200104": "Packing Machinery",
    "const_product_20010410": "Packing Machinery Parts",
    "const_product_20010224": "Packing Tape",
    "const_product_15090203": "Packing Tape",
    "const_product_20020315": "Pad Printer",
    "const_product_8040310": "Padlock",
    "const_product_80509": "Paint & Coating",
    "const_product_60201": "Paint & Coating",
    "const_product_19020107": "Paint Brush",
    "const_product_4030211": "Paint Spray Gun",
    "const_product_602": "Paint, Coating, Pigment & Dyestuff",
    "const_product_30602": "Painting & Calligraphy",
    "const_product_3060103": "Painting Frame",
    "const_product_20116": "Pajamas & Bathrobe",
    "const_product_20010411": "Palletizer",
    "const_product_20010225": "Pallets",
    "const_product_27040104": "Pallets",
    "const_product_190115": "Palm Computer, Pocket PC & PDA",
    "const_product_70105": "Palm Computer, Pocket PC & PDA",
    "const_product_90212": "Palm Computer, Pocket PC & PDA",
    "const_product_15010206": "Pans",
    "const_product_20117": "Pants & Trousers",
    "const_product_20030109": "Paper & Paperboard Printing",
    "const_product_17080109": "Paper Bag Machine",
    "const_product_20010106": "Paper Bag Machine",
    "const_product_17080110": "Paper Bowl Machine",
    "const_product_6050205": "Paper Chemical",
    "const_product_6020106": "Paper Coating",
    "const_product_17080111": "Paper Core & Tube Machine",
    "const_product_30311": "Paper Crafts",
    "const_product_17080112": "Paper Cup Machine",
    "const_product_17080113": "Paper Cutter",
    "const_product_20020109": "Paper Cutter",
    "const_product_150209": "Paper for Household & Sanitation",
    "const_product_110409": "Paper Furniture",
    "const_product_5050205": "Paper Gift Bag",
    "const_product_5050206": "Paper Gift Box",
    "const_product_8010107": "Paper Holder",
    "const_product_17080114": "Paper Honeycomb Core Machine",
    "const_product_17080115": "Paper Lunch Box Machine",
    "const_product_170801": "Paper Machinery",
    "const_product_17080116": "Paper Machinery Parts",
    "const_product_17080117": "Paper Making Machine",
    "const_product_150907": "Paper Material",
    "const_product_17080118": "Paper Plate Machine",
    "const_product_17080119": "Paper Pleating Machine",
    "const_product_5050303": "Paper Shopping Bag",
    "const_product_190116": "Paper Shredder",
    "const_product_90409": "Paper Shredder",
    "const_product_15020906": "Paper Towel",
    "const_product_190117": "Paper Trimmer",
    "const_product_1708": "Paper, Glass & Ceramics Machinery",
    "const_product_11020107": "Park Bench",
    "const_product_40605": "Parking",
    "const_product_270806": "Parking",
    "const_product_8080707": "Particle Board",
    "const_product_80505": "Partition",
    "const_product_27080105": "Parts for Elevator & Funicular Car",
    "const_product_27020307": "Parts of Electric Bike",
    "const_product_27040301": "Parts of Tractor",
    "const_product_27020403": "Parts of Tricycle",
    "const_product_3020205": "Party Supplies",
    "const_product_13010105": "Pass Box",
    "const_product_27080106": "Passenger Elevator",
    "const_product_27050103": "Passenger Ship",
    "const_product_100105": "Passive Components",
    "const_product_15010618": "Pasta Tools",
    "const_product_2040111": "Patch",
    "const_product_8040216": "Patch Fitting",
    "const_product_10010109": "Patch Panel",
    "const_product_11020114": "Patio Umbrella",
    "const_product_11070206": "Patio Umbrella Base",
    "const_product_25020210": "Patio Umbrella Base",
    "const_product_8050105": "Patterned Glass",
    "const_product_17040213": "Paving Machinery",
    "const_product_8020109": "Paving Stone",
    "const_product_9040107": "Payment Kiosk",
    "const_product_10040209": "PBX",
    "const_product_70509": "PC Camera",
    "const_product_9010804": "PC Camera",
    "const_product_705": "PC Peripherals",
    "const_product_10010308": "PCB Connector",
    "const_product_10010205": "PCBA",
    "const_product_10030505": "PDU",
    "const_product_3010308": "Pearl Jewelry",
    "const_product_11030810": "Pedicure Chair",
    "const_product_12020206": "Pedometer",
    "const_product_23010110": "Pedometer",
    "const_product_14010306": "Pedometer",
    "const_product_15010619": "Peeler",
    "const_product_19020219": "Pen Holder",
    "const_product_19020108": "Pen Refill",
    "const_product_190201": "Pen, Pencil & Brush",
    "const_product_19020109": "Pencil",
    "const_product_19020220": "Pencil Bags & Cases",
    "const_product_50513": "Pencil Bags & Cases",
    "const_product_19020221": "Pencil Sharpener",
    "const_product_3010112": "Pendant",
    "const_product_16020104": "Pendant Light",
    "const_product_8080810": "Perforated Metal",
    "const_product_15020210": "Perfume",
    "const_product_13030206": "Peristaltic Pump",
    "const_product_18020209": "Perlite",
    "const_product_15020306": "Perm Solution",
    "const_product_2204": "Personal Consumption Service",
    "const_product_15020211": "Personal Grooming",
    "const_product_150407": "Pest Control",
    "const_product_90319": "Pest Control",
    "const_product_150815": "Pesticide & Insecticides",
    "const_product_6080103": "Pesticide & Insecticides",
    "const_product_10208": "Pet",
    "const_product_23030105": "Pet",
    "const_product_23030106": "Pet Bed",
    "const_product_23030107": "Pet Carrier",
    "const_product_50105": "Pet Carrier",
    "const_product_23030108": "Pet Cleaning and Beauty Appliance",
    "const_product_23030109": "Pet Clothing & Accessories",
    "const_product_23030110": "Pet Collar",
    "const_product_23030111": "Pet Food",
    "const_product_17010411": "Pet Food Processing Machinery",
    "const_product_23030112": "Pet Harness",
    "const_product_23030113": "Pet House",
    "const_product_23030114": "Pet Leash & Lead",
    "const_product_2303": "Pet Supplies",
    "const_product_230301": "Pet Supplies",
    "const_product_150607": "Pet Supplies",
    "const_product_23030115": "Pet Toys",
    "const_product_260701": "Pet Toys",
    "const_product_23030116": "Pet Training Supplies",
    "const_product_14040110": "Petri Dish",
    "const_product_60603": "Petrochemical Refining",
    "const_product_180306": "Petrochemical Refining",
    "const_product_606": "Petrochemicals",
    "const_product_180307": "Petroleum & Products",
    "const_product_6050105": "Petroleum Additive",
    "const_product_170603": "Petroleum Equipment & Machinery",
    "const_product_180405": "Petroleum Equipment & Machinery",
    "const_product_17060306": "Petroleum Machinery Parts",
    "const_product_14010111": "pH Meter",
    "const_product_120102": "Pharmaceutical Chemicals",
    "const_product_609": "Pharmaceutical Chemicals",
    "const_product_60902": "Pharmaceutical Chemicals",
    "const_product_170303": "Pharmaceutical Equipment & Machinery",
    "const_product_120603": "Pharmaceutical Equipment & Machinery",
    "const_product_120103": "Pharmaceutical Intermediate",
    "const_product_60903": "Pharmaceutical Intermediate",
    "const_product_1206": "Pharmaceutical Machinery",
    "const_product_1205": "Pharmaceutical Packaging",
    "const_product_120503": "Pharmaceutical Packaging",
    "const_product_150908": "Pharmaceutical Packaging",
    "const_product_20010226": "Pharmaceutical Packaging",
    "const_product_240504": "Pharmaceutical Packaging",
    "const_product_120104": "Pharmacy Stocks",
    "const_product_6040407": "Phosphate",
    "const_product_1040105": "Phosphate Fertilizer",
    "const_product_6080203": "Phosphate Fertilizer",
    "const_product_30410": "Photo Album",
    "const_product_3060104": "Photo Frame & Picture Frame",
    "const_product_10010714": "Photoelectric Sensor",
    "const_product_61205": "Photographic & Sensitive Product",
    "const_product_9020611": "Photographic Accessories",
    "const_product_90206": "Photographic Apparatus",
    "const_product_190118": "Photographic Apparatus",
    "const_product_20020405": "Photographic Film",
    "const_product_140203": "Physical Measuring Meter",
    "const_product_12030209": "Physical Therapy Equipment",
    "const_product_23040316": "Piano",
    "const_product_23040317": "Piccolo",
    "const_product_15060116": "Pickaxe",
    "const_product_150816": "Picnic Bags",
    "const_product_23020312": "Picnic Bags",
    "const_product_50403": "Picnic Bags",
    "const_product_60202": "Pigment & Dye",
    "const_product_17040214": "Pile Driver",
    "const_product_8020110": "Pillar",
    "const_product_240601": "Pillow & Cushion",
    "const_product_13020106": "Pillow Block Bearing",
    "const_product_24060106": "Pillow Case",
    "const_product_15030112": "Pillow Case",
    "const_product_13020308": "Pin",
    "const_product_130208": "Pipe & Tube System",
    "const_product_17040402": "Pipe and Tube Bending Machine",
    "const_product_17040401": "Pipe Cutting Machine",
    "const_product_80806": "Pipe Fittings",
    "const_product_250207": "Pipe Fittings",
    "const_product_17040215": "Pipe Jacking Machine",
    "const_product_170404": "Pipe Machine",
    "const_product_17040110": "Pipe Making Machine",
    "const_product_25010613": "Pipe Threading Machine",
    "const_product_6020107": "Pipeline Coating",
    "const_product_14040111": "Pipette",
    "const_product_4020410": "Piston",
    "const_product_4020411": "Piston Pin",
    "const_product_13030207": "Piston Pump",
    "const_product_4020412": "Piston Ring",
    "const_product_13020107": "Plain Bearing",
    "const_product_17110411": "Planer",
    "const_product_6080104": "Plant Growth Regulator",
    "const_product_30612": "Plant Plaiting",
    "const_product_17010118": "Planting & Fertilizing Machine",
    "const_product_17050121": "Plasma Cutting Machine",
    "const_product_17110105": "Plasma Cutting Machine",
    "const_product_9010308": "Plasma Display",
    "const_product_9010704": "Plasma TV",
    "const_product_13060208": "Plasma Welder",
    "const_product_8080205": "Plaster Trowel",
    "const_product_8050206": "Plasterboard Ceiling",
    "const_product_60102": "Plastic & Polymer",
    "const_product_30301": "Plastic & Resin Crafts",
    "const_product_601": "Plastic & Rubber",
    "const_product_1707": "Plastic & Rubber Machinery",
    "const_product_6050206": "Plastic Auxiliary Agent",
    "const_product_2010106": "Plastic Belt",
    "const_product_6010302": "Plastic Card",
    "const_product_11040601": "Plastic Chair",
    "const_product_8080811": "Plastic Coated Wire Mesh",
    "const_product_6020108": "Plastic Coating",
    "const_product_3030101": "Plastic Craft",
    "const_product_17070107": "Plastic Crusher",
    "const_product_15010306": "Plastic Cup & Mug",
    "const_product_8040106": "Plastic Door",
    "const_product_17070108": "Plastic Drawing Machine",
    "const_product_17070109": "Plastic Drying Machine",
    "const_product_17070110": "Plastic Extruder",
    "const_product_20010309": "Plastic Film",
    "const_product_6010303": "Plastic Film",
    "const_product_8030207": "Plastic Floor",
    "const_product_15040203": "Plastic Fresh Bag",
    "const_product_110406": "Plastic Furniture",
    "const_product_5050207": "Plastic Gift Box & Bag",
    "const_product_17070111": "Plastic Granulator",
    "const_product_17070112": "Plastic Haul-Off Machine",
    "const_product_3040305": "Plastic Key Chain",
    "const_product_17070113": "Plastic Laminating Machine",
    "const_product_17070114": "Plastic Loader",
    "const_product_170701": "Plastic Machinery",
    "const_product_61104": "Plastic Machinery",
    "const_product_17070115": "Plastic Machinery Parts",
    "const_product_6010202": "Plastic Masterbatch",
    "const_product_6010203": "Plastic Materials",
    "const_product_17070116": "Plastic Mixer",
    "const_product_13070106": "Plastic Molding",
    "const_product_17100210": "Plastic Mould",
    "const_product_6010304": "Plastic Mould",
    "const_product_6010305": "Plastic Pipe & Tube",
    "const_product_8080305": "Plastic Pipe & Tube",
    "const_product_17070117": "Plastic Pipe Machinery",
    "const_product_17070118": "Plastic Plate Machinery",
    "const_product_20010227": "Plastic Preform",
    "const_product_20030110": "Plastic Printing",
    "const_product_20020316": "Plastic Printing Machine",
    "const_product_60103": "Plastic Products",
    "const_product_6010306": "Plastic Profile",
    "const_product_8060404": "Plastic Profile",
    "const_product_17070119": "Plastic Recycling Machine",
    "const_product_17110503": "Plastic Recycling Machine",
    "const_product_13010308": "Plastic Recycling Machine",
    "const_product_6010307": "Plastic Rod",
    "const_product_17070120": "Plastic Rolling Machinery",
    "const_product_15040204": "Plastic Rope & Net",
    "const_product_20010310": "Plastic Sheet, Board & Panel",
    "const_product_8050311": "Plastic Sheet, Board & Panel",
    "const_product_6010308": "Plastic Sheet, Board & Panel",
    "const_product_5050304": "Plastic Shopping Bag",
    "const_product_6010309": "Plastic Strap & Tape",
    "const_product_12030411": "Plastic Surgery Implants",
    "const_product_11040602": "Plastic Table",
    "const_product_15040205": "Plastic Tableware",
    "const_product_260604": "Plastic Toys",
    "const_product_15040206": "Plastic Tube, Pipe & Hose",
    "const_product_13060209": "Plastic Welder",
    "const_product_8040403": "Plastic Window",
    "const_product_20020207": "Plate Drying Oven",
    "const_product_20020208": "Plate Machine",
    "const_product_17110106": "Plate Shear",
    "const_product_15010909": "Plates",
    "const_product_14020411": "Platform Scale",
    "const_product_25010411": "Pliers",
    "const_product_80701": "Plug",
    "const_product_100310": "Plug",
    "const_product_16070115": "Plug",
    "const_product_13030314": "Plug Valve",
    "const_product_6110103": "Plugging Airbag",
    "const_product_80609": "Plumbing Hardware",
    "const_product_25020106": "Plumbing Hardware",
    "const_product_13030208": "Plunger Pump",
    "const_product_260202": "Plush & Stuffed Toy",
    "const_product_8080708": "Plywood",
    "const_product_13010602": "Pneumatic Actuator",
    "const_product_130106": "Pneumatic Component",
    "const_product_13010603": "Pneumatic Filter",
    "const_product_13010604": "Pneumatic Fittings",
    "const_product_17010502": "Pneumatic Marking Machine",
    "const_product_13010605": "Pneumatic Pipe",
    "const_product_13010606": "Pneumatic Seal",
    "const_product_250111": "Pneumatic Tools",
    "const_product_171113": "Pneumatic Tools",
    "const_product_13010607": "Pneumatic Valve",
    "const_product_40504": "Pocket Bike",
    "const_product_230215": "Pocket Bike",
    "const_product_270206": "Pocket Bike",
    "const_product_15050110": "Pocket Watch",
    "const_product_8030106": "Polished Tile",
    "const_product_20020110": "Polishing / Glazing Machine",
    "const_product_25010116": "Polishing Pad",
    "const_product_240119": "Polyester Fabric",
    "const_product_6010204": "Polymer & Resin",
    "const_product_8050207": "Polystyrene Ceiling",
    "const_product_230404": "Pool & Accessories",
    "const_product_9030523": "Popcorn Machine",
    "const_product_30312": "Porcelain & Ceramics",
    "const_product_15010307": "Porcelain Cup & Mug",
    "const_product_90106": "Portable Audio Appliance",
    "const_product_9010605": "Portable Audio Recorder",
    "const_product_160505": "Portable Lighting",
    "const_product_7050105": "Portable Speaker",
    "const_product_17010307": "Portal Crane",
    "const_product_9040106": "POS Terminal & Cash Register",
    "const_product_170903": "Post-Press Equipment",
    "const_product_200201": "Post-Press Equipment",
    "const_product_220103": "Postal Services",
    "const_product_15040303": "Pot & Kettle",
    "const_product_1040106": "Potassium Fertilizer",
    "const_product_6080204": "Potassium Fertilizer",
    "const_product_1010107": "Potato",
    "const_product_30313": "Pottery",
    "const_product_15070115": "Potty",
    "const_product_10209": "Poultry, Livestock & Breed Animal",
    "const_product_13070107": "Powder Injection Molding",
    "const_product_13070108": "Powder Metallurgy",
    "const_product_15020615": "Powder Puff",
    "const_product_130403": "Power & Generating Sets",
    "const_product_1304": "Power & Transmission",
    "const_product_10020406": "Power Adaptor",
    "const_product_70106": "Power Bank",
    "const_product_9020514": "Power Bank",
    "const_product_10030113": "Power Bank",
    "const_product_10030311": "Power Cable",
    "const_product_10020508": "Power Cable",
    "const_product_10030312": "Power Cord & Extension Cord",
    "const_product_10020407": "Power Distribution Cabinet & Box",
    "const_product_180308": "Power Generation & Distribution",
    "const_product_10020301": "Power Inverter",
    "const_product_70307": "Power Supply",
    "const_product_100204": "Power Supply & Distribution",
    "const_product_25010614": "Power Tool Accessories",
    "const_product_25010615": "Power Tool Set",
    "const_product_250106": "Power Tools",
    "const_product_171114": "Power Tools",
    "const_product_100205": "Power Transmission & Transformer",
    "const_product_130404": "Power Transmission Parts",
    "const_product_170904": "Pre-Press Equipment",
    "const_product_200202": "Pre-Press Equipment",
    "const_product_80610": "Prefabricated Building",
    "const_product_17100211": "Preform Mould",
    "const_product_12030412": "Prep Pad & Swab",
    "const_product_120105": "Prepared Chinese Medicine",
    "const_product_20020209": "Prepress Auxiliary Equipment",
    "const_product_19030105": "Presentation & Bulletin Board",
    "const_product_190302": "Presentation & Bulletin Board",
    "const_product_10112": "Preserved Fruit",
    "const_product_13060210": "Press Welder",
    "const_product_20020111": "Pressing Machine",
    "const_product_15010207": "Pressure Cookers",
    "const_product_20010412": "Pressure Filling Machine",
    "const_product_14020204": "Pressure Gauge",
    "const_product_13030209": "Pressure Pump",
    "const_product_13030315": "Pressure Reducing Valve",
    "const_product_4010105": "Pressure Sensor",
    "const_product_10010715": "Pressure Sensor",
    "const_product_10030609": "Pressure Switch",
    "const_product_10020605": "Pressure Transmitter",
    "const_product_17030110": "Pressure Vessel",
    "const_product_20010228": "Pressure Vessel",
    "const_product_10030114": "Primary & Dry Battery",
    "const_product_9020102": "Primary & Dry Battery",
    "const_product_240120": "Printed & Color-woven Cloth",
    "const_product_24050101": "Printed Labels",
    "const_product_20010229": "Printed Labels",
    "const_product_15010704": "Printed Labels",
    "const_product_190101": "Printer & Printer Supplies",
    "const_product_70708": "Printer & Printer Supplies",
    "const_product_90410": "Printer & Printer Supplies",
    "const_product_19010112": "Printer Chip",
    "const_product_19010113": "Printer Ink",
    "const_product_19010114": "Printer Ribbon",
    "const_product_2002": "Printing",
    "const_product_20020406": "Printing Inks",
    "const_product_170905": "Printing Machinery",
    "const_product_200203": "Printing Machinery",
    "const_product_20020317": "Printing Machinery Parts",
    "const_product_200204": "Printing Materials",
    "const_product_61002": "Printing Materials",
    "const_product_20020407": "Printing Mesh",
    "const_product_20020408": "Printing Plate",
    "const_product_17020207": "Printing, Dyeing and Finishing Machinery",
    "const_product_14030108": "Prism",
    "const_product_21020106": "Probe, Sensor",
    "const_product_2203": "Production and Marketing Services",
    "const_product_16030102": "Professional Audio",
    "const_product_9010105": "Professional Audio",
    "const_product_1605": "Professional Lighting",
    "const_product_160506": "Professional Lighting",
    "const_product_17070121": "Profile Production Line",
    "const_product_19030302": "Projection Screen",
    "const_product_190303": "Projector & Equipment",
    "const_product_90411": "Projector & Equipment",
    "const_product_19030303": "Projectors",
    "const_product_2020310": "Prom Dresses",
    "const_product_30404": "Promotion Gifts",
    "const_product_11030902": "Promotion Table",
    "const_product_3040409": "Promotional Bags",
    "const_product_50514": "Promotional Bags",
    "const_product_3040410": "Promotional Bracelets & Wristbands",
    "const_product_3040411": "Promotional Pen",
    "const_product_19020110": "Promotional Pen",
    "const_product_19020222": "Promotional Pen",
    "const_product_20020210": "Proofing Machine / Proofer",
    "const_product_20010230": "Protective & Cushioning Material",
    "const_product_21050110": "Protective Face Mask",
    "const_product_2010105": "PU Belt",
    "const_product_6010104": "PU Foam",
    "const_product_110311": "Public Furniture",
    "const_product_220502": "Public Health",
    "const_product_220503": "Public Management Services",
    "const_product_2205": "Public Services",
    "const_product_220504": "Public Welfare Information Service",
    "const_product_17080120": "Pulping Machine",
    "const_product_130302": "Pump & Vacuum Equipment",
    "const_product_13030210": "Pump Parts",
    "const_product_17060307": "Pumping Unit",
    "const_product_17110412": "Punching Machine",
    "const_product_17040111": "Purlin Machine",
    "const_product_10030610": "Push Button Switch",
    "const_product_8080206": "Putty Knife",
    "const_product_10030310": "PV Cable",
    "const_product_8050205": "PVC Ceiling",
    "const_product_6010105": "PVC Foam",
    "const_product_23020102": "Quad & ATV Parts",
    "const_product_18020210": "Quartz",
    "const_product_8020111": "Quartz Stone",
    "const_product_15030113": "Quilt",
    "const_product_15030114": "Quilt Cover",
    "const_product_17020208": "Quilting Machine",
    "const_product_2020311": "Quinceanera Dresses",
    "const_product_150804": "Rack, Shelf, Holder & Hanger",
    "const_product_90109": "Radio",
    "const_product_100406": "Radio",
    "const_product_90110": "Radio & Cassette Recorders",
    "const_product_9010705": "Radio & TV Broadcasting",
    "const_product_10040210": "Radio & TV Broadcasting",
    "const_product_8080407": "Railway Fence",
    "const_product_27080402": "Railway Supplies",
    "const_product_15080102": "Rainboots",
    "const_product_2030409": "Rainboots",
    "const_product_15080103": "Raincoat",
    "const_product_15060117": "Rake",
    "const_product_24010104": "Ramie Fabric",
    "const_product_9030524": "Range Hood",
    "const_product_14030109": "Rangefinder",
    "const_product_180109": "Rare Earth & Products",
    "const_product_4010601": "Ratchet Buckle",
    "const_product_25020211": "Ratchet Buckle",
    "const_product_20010231": "Ratchet Straps",
    "const_product_4010602": "Ratchet Straps",
    "const_product_40106": "Ratchet Straps & Buckle",
    "const_product_11040701": "Rattan & Wicker Bed",
    "const_product_11040702": "Rattan & Wicker Chair",
    "const_product_110407": "Rattan & Wicker Furniture",
    "const_product_11040705": "Rattan & Wicker Furniture Sets",
    "const_product_11040704": "Rattan & Wicker Sofa",
    "const_product_11040703": "Rattan & Wicker Table",
    "const_product_25010412": "Razor Scraper",
    "const_product_15040410": "Razor Scraper",
    "const_product_6020207": "Reactive Dye",
    "const_product_15050210": "Reading Glasses",
    "const_product_11030703": "Reading Table",
    "const_product_220405": "Real Estate",
    "const_product_13020207": "Reamer",
    "const_product_27020124": "Rear Bike Rack",
    "const_product_4010509": "Rear Light",
    "const_product_4010510": "Rear View Mirror",
    "const_product_4040107": "Rear View Mirror",
    "const_product_4010305": "Receiver Drier",
    "const_product_11030413": "Reception Desk",
    "const_product_10030115": "Rechargeable Battery & Charger",
    "const_product_9020103": "Rechargeable Battery & Charger",
    "const_product_13030106": "Reciprocating Compressor",
    "const_product_230206": "Recreational Boat",
    "const_product_27050104": "Recreational Boat",
    "const_product_230216": "Recreational Boat Parts",
    "const_product_270506": "Recreational Boat Parts",
    "const_product_2304": "Recreational Goods",
    "const_product_27080302": "Recreational Vehicle Parts & Accessories",
    "const_product_230107": "Recreational Vehicles",
    "const_product_270803": "Recreational Vehicles",
    "const_product_10010310": "Rectangular Connector",
    "const_product_10010403": "Rectifier Diode",
    "const_product_5050305": "Recycled Shopping Bag",
    "const_product_171105": "Recycling Machine",
    "const_product_8080608": "Reducer",
    "const_product_30411": "Reed Diffuser",
    "const_product_10030611": "Reed Switch",
    "const_product_21040103": "Reflective Material",
    "const_product_21050111": "Reflective Safety Clothing",
    "const_product_2020106": "Reflective Safety Clothing",
    "const_product_17120108": "Reflow Oven",
    "const_product_14030110": "Refractometer",
    "const_product_180207": "Refractory",
    "const_product_27040209": "Refrigerated Truck",
    "const_product_130505": "Refrigeration Equipment",
    "const_product_130507": "Refrigerator, Freezer & Parts",
    "const_product_90306": "Refrigerator, Freezer & Parts",
    "const_product_120204": "Rehabilitation Therapy Supplies",
    "const_product_120308": "Related Chinese Medical",
    "const_product_105": "Related Machinery & Equipment",
    "const_product_406": "Related Products",
    "const_product_2503": "Related Products",
    "const_product_2708": "Related Products",
    "const_product_1405": "Related Products",
    "const_product_707": "Related Products",
    "const_product_808": "Related Products",
    "const_product_2003": "Related Service",
    "const_product_10010602": "Relay",
    "const_product_100106": "Relay & Contactor",
    "const_product_10020509": "Relay & Contactor",
    "const_product_20020318": "Relief Printing Machine",
    "const_product_30508": "Religion Crafts",
    "const_product_100311": "Remote Control",
    "const_product_90111": "Remote Control",
    "const_product_10030612": "Remote Control Switch",
    "const_product_260502": "Remote Control Toys",
    "const_product_17040216": "Rendering Machine",
    "const_product_4030311": "Repair Tools",
    "const_product_10040211": "Repeater",
    "const_product_3030102": "Resin Craft",
    "const_product_14020104": "Resistance Meter",
    "const_product_13060211": "Resistance Welder",
    "const_product_10010503": "Resistor & Potentiometer",
    "const_product_10010504": "Resonator",
    "const_product_220406": "Restaurant",
    "const_product_11030301": "Restaurant Chairs",
    "const_product_110303": "Restaurant Furniture",
    "const_product_11030303": "Restaurant Furniture Sets",
    "const_product_150108": "Restaurant Supplies",
    "const_product_11030302": "Restaurant Table",
    "const_product_220407": "Retail",
    "const_product_4010511": "Reverse Light",
    "const_product_13010414": "Reverse Osmosis System",
    "const_product_9030401": "Reverse Osmosis System",
    "const_product_10010309": "RF Connector",
    "const_product_10030403": "RFID Card",
    "const_product_10030404": "RFID Tag",
    "const_product_15010705": "RFID Tag",
    "const_product_10030405": "RFID Wristband",
    "const_product_18010314": "Rhenium",
    "const_product_2040112": "Rhinestone",
    "const_product_17020115": "Rhinestone Machine",
    "const_product_20407": "Ribbon",
    "const_product_30412": "Ribbon",
    "const_product_240308": "Ribbon",
    "const_product_9030525": "Rice Cooker",
    "const_product_27020404": "Rickshaw",
    "const_product_10010206": "Rigid PCB",
    "const_product_10010207": "Rigid-Flex PCB",
    "const_product_3010113": "Ring",
    "const_product_13020309": "Rivet",
    "const_product_250112": "Riveter",
    "const_product_17050122": "Riveting Machine",
    "const_product_27020206": "Road Bicycle",
    "const_product_6020109": "Road Marking Paint",
    "const_product_21040104": "Road Stud",
    "const_product_2104": "Roadway & Water Safety Products",
    "const_product_270701": "Roadway Facility",
    "const_product_2707": "Roadway Facility & Safety",
    "const_product_210401": "Roadway Safety",
    "const_product_27070102": "Roadway Safety",
    "const_product_40403": "Roadway Safety",
    "const_product_17060212": "Rock Splitter",
    "const_product_10030613": "Rocker Switch",
    "const_product_15090110": "Roll up Display",
    "const_product_12020404": "Rollator Walker",
    "const_product_19020111": "Roller Ball Pen",
    "const_product_13020108": "Roller Bearing",
    "const_product_17050123": "Rolling Mill",
    "const_product_8030107": "Roof Tile",
    "const_product_4010512": "Rope Light",
    "const_product_16010405": "Rope Light",
    "const_product_16040107": "Rope Light",
    "const_product_60803": "Rosin & Forest Chemical",
    "const_product_13030107": "Rotary Compressor",
    "const_product_13050210": "Rotary Drying Machine",
    "const_product_25010616": "Rotary Hammer",
    "const_product_10030614": "Rotary Switch",
    "const_product_13020208": "Rotary Table",
    "const_product_17070122": "Rotational Molding Machine",
    "const_product_20020319": "Rotogravure Printing Machine",
    "const_product_7060106": "Router",
    "const_product_23010111": "Rowing Machine",
    "const_product_60104": "Rubber & Rubber Products",
    "const_product_6050207": "Rubber Auxiliary Agent",
    "const_product_6010402": "Rubber Belt",
    "const_product_6020110": "Rubber Coating",
    "const_product_8030208": "Rubber Floor",
    "const_product_17070201": "Rubber Machine Parts",
    "const_product_170702": "Rubber Machinery",
    "const_product_61105": "Rubber Machinery",
    "const_product_18020106": "Rubber Magnet",
    "const_product_6010403": "Rubber Materials",
    "const_product_6010404": "Rubber Mould",
    "const_product_17100212": "Rubber Mould",
    "const_product_17070202": "Rubber Product Making Machinery",
    "const_product_17070203": "Rubber Raw Material Machinery",
    "const_product_17070204": "Rubber Recycling Machinery",
    "const_product_17110504": "Rubber Recycling Machinery",
    "const_product_13010309": "Rubber Recycling Machinery",
    "const_product_6010405": "Rubber Roller",
    "const_product_6010406": "Rubber Seal",
    "const_product_6010407": "Rubber Sheet & Mat",
    "const_product_6010408": "Rubber Tube, Pipe & Hose",
    "const_product_19020223": "Ruler",
    "const_product_8030108": "Rustic Tile",
    "const_product_20010232": "Sack & Bag for Packing",
    "const_product_150909": "Sack & Bag for Packing",
    "const_product_120504": "Sack & Bag for Packing",
    "const_product_240505": "Sack & Bag for Packing",
    "const_product_210504": "Safe",
    "const_product_9040108": "Safe",
    "const_product_21050112": "Safety & Protective Apparel",
    "const_product_20201": "Safety & Protective Apparel",
    "const_product_21050113": "Safety Belt & Rope",
    "const_product_23020804": "Safety Belt & Rope",
    "const_product_21050114": "Safety Glasses",
    "const_product_15050211": "Safety Glasses",
    "const_product_21050115": "Safety Helmet",
    "const_product_23020503": "Safety Helmet",
    "const_product_210501": "Safety Products & Supplies",
    "const_product_13030316": "Safety Valve",
    "const_product_23020605": "Sailboat",
    "const_product_15010620": "Salad Tools",
    "const_product_150115": "Salon Equipment",
    "const_product_90307": "Salon Equipment",
    "const_product_12020103": "Salon Equipment",
    "const_product_30104": "Salon Equipment",
    "const_product_110308": "Salon Furniture",
    "const_product_11030808": "Salon Trolley",
    "const_product_14010110": "Sample Processor",
    "const_product_18020211": "Sand",
    "const_product_802": "Sand & Stone",
    "const_product_17100108": "Sand Casting",
    "const_product_17040112": "Sand Making Machine",
    "const_product_17060213": "Sand Washer",
    "const_product_80203": "Sand, Lime & Plaster",
    "const_product_2030410": "Sandals",
    "const_product_13010212": "Sandblaster",
    "const_product_25010617": "Sander",
    "const_product_25010117": "Sanding Belt",
    "const_product_25010118": "Sanding Paper",
    "const_product_8020112": "Sandstone, Cobble & Pebble",
    "const_product_9030526": "Sandwich Maker",
    "const_product_8050312": "Sandwich Panel",
    "const_product_17080121": "Sanitary Napkin Machine",
    "const_product_15020907": "Sanitary Napkins",
    "const_product_150404": "Sanitary Utensil",
    "const_product_90320": "Sanitary Utensil",
    "const_product_10302": "Sap & Extract",
    "const_product_120106": "Sap & Extract",
    "const_product_5010108": "Satchel Bag",
    "const_product_10040212": "Satellite",
    "const_product_15010208": "Saucepans",
    "const_product_8010302": "Sauna Heater",
    "const_product_80103": "Sauna Room",
    "const_product_25010413": "Saw",
    "const_product_17110413": "Saw Bench",
    "const_product_13020209": "Saw Blade",
    "const_product_23040318": "Saxophone",
    "const_product_8080207": "Scaffolding",
    "const_product_8080208": "Scaffolding Net",
    "const_product_21050116": "Scaffolding Net",
    "const_product_70709": "Scanner",
    "const_product_90412": "Scanner",
    "const_product_190119": "Scanner",
    "const_product_20020211": "Scanner",
    "const_product_2030302": "Scarf",
    "const_product_20303": "Scarf & Warm",
    "const_product_9030106": "Scent Diffuser",
    "const_product_50515": "School Bags",
    "const_product_27030104": "School Bus",
    "const_product_190308": "School Furniture",
    "const_product_110305": "School Furniture",
    "const_product_11030505": "School Furniture Sets",
    "const_product_25010414": "Scissors",
    "const_product_25020302": "Scissors",
    "const_product_15010621": "Scoop, Ladle",
    "const_product_23020703": "Scooter Parts & Accessories",
    "const_product_230207": "Scooters & Parts",
    "const_product_23010215": "Scope & Accessories",
    "const_product_23010307": "Scoreboard",
    "const_product_20020320": "Screen Printer",
    "const_product_70510": "Screen Protector",
    "const_product_90213": "Screen Protector",
    "const_product_13020310": "Screw",
    "const_product_13030108": "Screw Compressor",
    "const_product_13030211": "Screw Pump",
    "const_product_25010415": "Screwdriver",
    "const_product_13030109": "Scroll Compressor",
    "const_product_230217": "Sea Scooter",
    "const_product_270105": "Sea Scooter",
    "const_product_13020504": "Seal Packing",
    "const_product_13020505": "Seal Ring",
    "const_product_130205": "Sealing & Gaskets",
    "const_product_25020405": "Sealing & Gaskets",
    "const_product_20010413": "Sealing Machine",
    "const_product_13020506": "Sealing Strip",
    "const_product_17020116": "Seam Sealing Machine",
    "const_product_13060212": "Seam Welder",
    "const_product_17010412": "Seasoning Processing Machinery",
    "const_product_24060107": "Seat Cushion",
    "const_product_11060203": "Seating and Table Sets",
    "const_product_110602": "Seating, Table & Furniture",
    "const_product_1106": "Seating, Tables & Racks",
    "const_product_70710": "Secondhand Computer Devices",
    "const_product_90502": "Secondhand Consumer Electronics",
    "const_product_110807": "Secondhand Furniture & Stocks",
    "const_product_171203": "Secondhand Machinery",
    "const_product_190120": "Secondhand Office Equipment",
    "const_product_230501": "Secondhand Sporting Goods & Recreation",
    "const_product_270807": "Secondhand Vehicle & Stocks",
    "const_product_40606": "Secondhand Vehicle & Stocks",
    "const_product_21": "Security & Protection",
    "const_product_21020107": "Security Display Stand",
    "const_product_15090111": "Security Display Stand",
    "const_product_8040107": "Security Door",
    "const_product_8080408": "Security Fence",
    "const_product_210505": "Security Guard",
    "const_product_10113": "Seed for Sowing",
    "const_product_17010119": "Seed Processing Machine",
    "const_product_230218": "Self Balancing Electric Scooter",
    "const_product_27020308": "Self Balancing Electric Scooter",
    "const_product_13030212": "Self-Priming Pump",
    "const_product_13020311": "Self-tapping Screws",
    "const_product_9020515": "Selfie Stick",
    "const_product_16010103": "Semi-outdoor LED Display",
    "const_product_100119": "Semiconductor",
    "const_product_21020108": "Sender, Receiver",
    "const_product_140107": "Sensor",
    "const_product_100107": "Sensor",
    "const_product_160507": "Sensor Light",
    "const_product_10030615": "Sensor Switch",
    "const_product_13010503": "Separation Equipment",
    "const_product_17030111": "Separation Equipment",
    "const_product_13010504": "Separation Equipment Parts",
    "const_product_18020212": "Sepiolite",
    "const_product_2040113": "Sequins",
    "const_product_70205": "Server & Workstation",
    "const_product_22": "Services",
    "const_product_10040213": "Set Top Box",
    "const_product_9010706": "Set Top Box",
    "const_product_27040210": "Sewage & Fecal Suction Truck",
    "const_product_13010310": "Sewage Treatment Equipment",
    "const_product_17020117": "Sewing Machine",
    "const_product_190121": "Sewing Thread & Tape",
    "const_product_20010311": "Sewing Thread & Tape",
    "const_product_2040114": "Sewing Thread & Tape",
    "const_product_240404": "Sewing Thread & Tape",
    "const_product_120208": "Sex Product",
    "const_product_2010303": "Sexy Lingerie",
    "const_product_25020212": "Shackles",
    "const_product_130209": "Shaft",
    "const_product_13040404": "Shaft",
    "const_product_13040405": "Shaft Coupling",
    "const_product_15020307": "Shampoo",
    "const_product_11030803": "Shampoo Chair & Bed",
    "const_product_80604": "Shaped Building Material",
    "const_product_2010304": "Shapers",
    "const_product_25010119": "Sharpening Stone",
    "const_product_90321": "Shaver",
    "const_product_2030303": "Shawl",
    "const_product_25020303": "Shears",
    "const_product_15060118": "Shears",
    "const_product_13070109": "Sheet Metal Fabrication",
    "const_product_110603": "Shelf & Rack",
    "const_product_5010109": "Shell Bag",
    "const_product_17010120": "Sheller",
    "const_product_4010208": "Shift Knob",
    "const_product_23010404": "Shin Guards",
    "const_product_20118": "Shirt & Blouse",
    "const_product_4020312": "Shock Absorber",
    "const_product_25010309": "Shock Absorber",
    "const_product_11010702": "Shoe Cabinet & Shoe Rack",
    "const_product_15040411": "Shoe Covers & Shoe Covers Dispenser",
    "const_product_17020118": "Shoe Making Machine",
    "const_product_17020119": "Shoe Repairing Equipment",
    "const_product_20304": "Shoes",
    "const_product_11010703": "Shoes Changing Stool",
    "const_product_2030411": "Shoes Parts & Accessories",
    "const_product_50503": "Shopping Bags",
    "const_product_150817": "Shopping Bags",
    "const_product_200109": "Shopping Bags",
    "const_product_11030907": "Shopping Basket & Cart",
    "const_product_12020310": "Shoulder & Neck Massager",
    "const_product_5010110": "Shoulder Bag",
    "const_product_2040115": "Shoulder Pads",
    "const_product_23010405": "Shoulder Support",
    "const_product_15060119": "Shovel, Spade",
    "const_product_11030904": "Showcase",
    "const_product_8010502": "Shower Base",
    "const_product_15020104": "Shower Cap",
    "const_product_12020405": "Shower Chair",
    "const_product_8010503": "Shower Doors",
    "const_product_8010206": "Shower Faucet",
    "const_product_8010401": "Shower Faucet",
    "const_product_15020105": "Shower Gel",
    "const_product_8010402": "Shower Head",
    "const_product_80104": "Shower Head & Shower Set",
    "const_product_8010403": "Shower Hose",
    "const_product_8010404": "Shower Panel",
    "const_product_80105": "Shower Room & Shower Enclosure",
    "const_product_8010504": "Shower Screen",
    "const_product_8010405": "Shower Set",
    "const_product_20010312": "Shrink Film",
    "const_product_15040207": "Shrink Wrap",
    "const_product_25010416": "Sickle",
    "const_product_4010209": "Side Step",
    "const_product_11010608": "Side Table",
    "const_product_11010503": "Sideboard",
    "const_product_13030401": "Sight Glass",
    "const_product_8080609": "Sight Glass",
    "const_product_27040211": "Sightseeing Bus & Car",
    "const_product_27080107": "Sightseeing Elevator",
    "const_product_10010505": "Signal Filter",
    "const_product_14010307": "Signal Generator",
    "const_product_10040214": "Signal Jammer",
    "const_product_6040408": "Silicate",
    "const_product_18010403": "Silicon Steel",
    "const_product_18020107": "Silicon Steel",
    "const_product_60105": "Silicone & Silicone Products",
    "const_product_15010308": "Silicone Cup & Mug",
    "const_product_3040306": "Silicone Keychain",
    "const_product_15050111": "Silicone Watch",
    "const_product_240121": "Silk",
    "const_product_30314": "Silk & Embroidery",
    "const_product_20119": "Silk Apparel",
    "const_product_8010505": "Simple Shower Room",
    "const_product_11060101": "Single Sofa",
    "const_product_10010208": "Single-Sided PCB",
    "const_product_80106": "Sink & Basin",
    "const_product_18020108": "Sintered Ferrite Magnet",
    "const_product_8030109": "Sintered Slab",
    "const_product_230219": "Skateboard",
    "const_product_23010216": "Skiing & Skating Equipment",
    "const_product_12020104": "Skin Analyzer",
    "const_product_12020105": "Skin Beauty Equipment",
    "const_product_150210": "Skin Care",
    "const_product_15021013": "Skin Care Essence",
    "const_product_15021014": "Skin Care Set",
    "const_product_15020212": "Skin Cleaning Tools",
    "const_product_15021015": "Skin Toner",
    "const_product_20120": "Skirt",
    "const_product_8050313": "Skirting",
    "const_product_8020113": "Slate",
    "const_product_80201": "Slate, Marble, Granite & Stone",
    "const_product_17010121": "Slaughtering Equipment",
    "const_product_15020213": "Sleep Mask",
    "const_product_23020313": "Sleeping Bag",
    "const_product_13020109": "Slewing Bearing",
    "const_product_13040406": "Slewing Drive",
    "const_product_23040105": "Slide",
    "const_product_14040112": "Slide Glass",
    "const_product_10030616": "Slide Switch",
    "const_product_10010311": "Slip Ring",
    "const_product_20010233": "Slip Sheet",
    "const_product_2030412": "Slippers",
    "const_product_17080122": "Slitting Machine",
    "const_product_20010107": "Slitting Machine",
    "const_product_9030527": "Slow Cookers and Sous Vide",
    "const_product_9020702": "Smart Bracelet",
    "const_product_90413": "Smart Card & Card Reader",
    "const_product_210104": "Smart Card & Card Reader",
    "const_product_100304": "Smart Card & Card Reader",
    "const_product_171204": "Smart Card Making Machine",
    "const_product_10030406": "Smart Card Reader",
    "const_product_9020703": "Smart Glasses",
    "const_product_9020704": "Smartwatch",
    "const_product_18020109": "SmCo Magnet",
    "const_product_16010207": "SMD LED",
    "const_product_16030103": "Smoke Machine",
    "const_product_15080308": "Smoking Accessories",
    "const_product_15080309": "Smoking Pipe",
    "const_product_15080310": "Smoking Set",
    "const_product_30613": "Smoking Set",
    "const_product_10317": "Snack",
    "const_product_17010413": "Snack Machinery",
    "const_product_13010213": "Snow Blower",
    "const_product_16030104": "Snow Machine",
    "const_product_230220": "Snow Scooter",
    "const_product_270106": "Snow Scooter",
    "const_product_8010108": "Soap Dish",
    "const_product_8010109": "Soap Dispenser",
    "const_product_23010217": "Soccer Table",
    "const_product_20121": "Sock & Stocking",
    "const_product_80702": "Socket & Outlet",
    "const_product_100305": "Socket & Outlet",
    "const_product_16060108": "Sodium Lamp",
    "const_product_110601": "Sofa",
    "const_product_11010602": "Sofa Bed",
    "const_product_24030102": "Sofa Cover",
    "const_product_23010218": "Softball, Squash & Handball Equipment",
    "const_product_70711": "Software",
    "const_product_90322": "Solar & Renewable Energy",
    "const_product_180301": "Solar & Renewable Energy",
    "const_product_18030101": "Solar & Wind Inverter",
    "const_product_10020302": "Solar & Wind Inverter",
    "const_product_18030102": "Solar Brackets",
    "const_product_18030103": "Solar Cell",
    "const_product_18030104": "Solar Charger",
    "const_product_10030116": "Solar Charger",
    "const_product_18030105": "Solar Collector",
    "const_product_18030106": "Solar Controller",
    "const_product_18030107": "Solar Energy System",
    "const_product_13040307": "Solar Generator",
    "const_product_18030108": "Solar Glass",
    "const_product_8050106": "Solar Glass",
    "const_product_18030109": "Solar Light",
    "const_product_16020204": "Solar Light",
    "const_product_18030110": "Solar Panels",
    "const_product_10030117": "Solar Panels",
    "const_product_18030111": "Solar Vacuum Tube",
    "const_product_18030112": "Solar Water Heater",
    "const_product_9030804": "Solar Water Heater",
    "const_product_13060213": "Soldering Iron Tip",
    "const_product_13060214": "Soldering Machine",
    "const_product_13030317": "Solenoid Valve",
    "const_product_8080709": "Solid Wood Board",
    "const_product_6020208": "Solvent Dye",
    "const_product_171106": "Sorting Machine",
    "const_product_130114": "Sound & Heat Insulation",
    "const_product_80611": "Sound & Heat Insulation",
    "const_product_80612": "Sound Absorption Materials",
    "const_product_70308": "Sound Card",
    "const_product_100120": "Sound Module",
    "const_product_60508": "Soundless Cracking Agent",
    "const_product_8080409": "Soundproof Fence",
    "const_product_13010608": "Source Treatment Unit",
    "const_product_30413": "Souvenir",
    "const_product_1030209": "Soybean Extract",
    "const_product_10010312": "Spacer Support",
    "const_product_70501": "Speaker & Sound Box",
    "const_product_9010106": "Speaker & Sound Box",
    "const_product_100121": "Speaker,Trumpet & Buzzer",
    "const_product_202": "Special Apparel",
    "const_product_13020110": "Special Bearing",
    "const_product_80613": "Special Building Materials",
    "const_product_27080108": "Special Elevator",
    "const_product_8050107": "Special Glass",
    "const_product_21050117": "Special Labor Protection Mask",
    "const_product_17110414": "Special Machine Tools",
    "const_product_20020409": "Special Paper",
    "const_product_505": "Special Purpose Bags & Cases",
    "const_product_18010404": "Special Steel",
    "const_product_16060201": "Special Tube Compact Bulb & Lamp",
    "const_product_150818": "Specialized Case & Box",
    "const_product_200110": "Specialized Case & Box",
    "const_product_50516": "Specialized Case & Box",
    "const_product_100208": "Specialized Electrical Equipment",
    "const_product_60509": "Specialized Preparation",
    "const_product_40607": "Specialized Vehicle",
    "const_product_270402": "Specialized Vehicle",
    "const_product_14030111": "Spectrometer",
    "const_product_27050105": "Speed Boat",
    "const_product_21040105": "Speed Bump",
    "const_product_14020306": "Speed Measuring Meter",
    "const_product_13040407": "Speed Reducer",
    "const_product_13020111": "Spherical Plain Bearing",
    "const_product_10318": "Spice",
    "const_product_17020209": "Spinning Machine",
    "const_product_16060202": "Spiral Compact Bulb & Lamp",
    "const_product_4010210": "Spoiler",
    "const_product_15010910": "Spoon",
    "const_product_150608": "Sporting Goods",
    "const_product_230102": "Sporting Goods",
    "const_product_2301": "Sporting Goods & Fitness Equipment",
    "const_product_23": "Sporting Goods & Recreation",
    "const_product_230502": "Sporting Goods & Recreation Stocks",
    "const_product_23010219": "Sports & Fitness",
    "const_product_260407": "Sports & Fitness",
    "const_product_50404": "Sports Bag",
    "const_product_2030202": "Sports Caps",
    "const_product_23020805": "Sports Caps",
    "const_product_230103": "Sports Field Equipment & Facilities",
    "const_product_23010308": "Sports Floor",
    "const_product_8030209": "Sports Floor",
    "const_product_230208": "Sports Gear",
    "const_product_23020806": "Sports Glasses",
    "const_product_15050212": "Sports Glasses",
    "const_product_2030101": "Sports Gloves",
    "const_product_23020504": "Sports Helmet",
    "const_product_23020807": "Sports Helmet",
    "const_product_21050118": "Sports Protection",
    "const_product_230104": "Sports Protection",
    "const_product_4050109": "Sports Protection",
    "const_product_24030206": "Sports Towel",
    "const_product_15050112": "Sports Watch",
    "const_product_23020808": "Sports Wear",
    "const_product_20122": "Sports Wear",
    "const_product_13060215": "Spot Welder",
    "const_product_16020205": "Spotlight",
    "const_product_13050211": "Spray Drying Machine",
    "const_product_15040304": "Sprayer Bottle",
    "const_product_15040305": "Sprayer Head",
    "const_product_171115": "Spraying Machinery & Spreading Equipment",
    "const_product_25020213": "Spring",
    "const_product_17050124": "Spring Coiling Machine",
    "const_product_13040408": "Sprocket",
    "const_product_8080812": "Square Mesh",
    "const_product_8060201": "Square Steel",
    "const_product_18010405": "Square Steel",
    "const_product_8010703": "Squat Pan",
    "const_product_15040412": "Squeegee",
    "const_product_2020107": "Stab-resistant Clothing",
    "const_product_17040309": "Stacker & Reclaimer",
    "const_product_8080410": "Stadium Fence",
    "const_product_160301": "Stage Equipment",
    "const_product_160302": "Stage Lighting",
    "const_product_1603": "Stage Lighting & Equipment",
    "const_product_8050108": "Stained Glass",
    "const_product_18010406": "Stainless Steel",
    "const_product_15010309": "Stainless Steel Cup & Mug",
    "const_product_18010204": "Stainless Steel Scrap",
    "const_product_8080813": "Stainless Steel Wire Mesh",
    "const_product_8080411": "Stair Fence",
    "const_product_8050601": "Stair Parts",
    "const_product_25020107": "Stair Parts",
    "const_product_8050602": "Stairs",
    "const_product_80506": "Stairs & Stair Parts",
    "const_product_171003": "Stamping",
    "const_product_17100213": "Stamping Die",
    "const_product_19020224": "Staple Remover",
    "const_product_19020225": "Stapler",
    "const_product_10319": "Starch",
    "const_product_4020210": "Starter Motor",
    "const_product_1902": "Stationery",
    "const_product_190202": "Stationery",
    "const_product_150819": "Stationery",
    "const_product_19020226": "Stationery Set",
    "const_product_90323": "Steam Cleaner",
    "const_product_8010303": "Steam Generator",
    "const_product_8010506": "Steam Shower Room",
    "const_product_13030318": "Steam Trap",
    "const_product_13040308": "Steam Turbine Generator",
    "const_product_15010209": "Steamers",
    "const_product_80614": "Steel & Products",
    "const_product_180104": "Steel & Products",
    "const_product_18010407": "Steel Angle",
    "const_product_8060202": "Steel Angle",
    "const_product_8060203": "Steel Billet",
    "const_product_18010408": "Steel Billet",
    "const_product_18010409": "Steel Channel",
    "const_product_8060204": "Steel Channel",
    "const_product_8060205": "Steel Coil & Strip",
    "const_product_18010410": "Steel Coil & Strip",
    "const_product_17040113": "Steel Frame Machine",
    "const_product_8060206": "Steel Grating",
    "const_product_18010411": "Steel H Beam",
    "const_product_18010412": "Steel I Beam",
    "const_product_18010413": "Steel Pipe & Tube",
    "const_product_8080306": "Steel Pipe & Tube",
    "const_product_8060207": "Steel Rebar",
    "const_product_18010414": "Steel Rebar",
    "const_product_18010415": "Steel Round Bar",
    "const_product_8060208": "Steel Round Bar",
    "const_product_18010205": "Steel Scrap",
    "const_product_18010416": "Steel Scrap",
    "const_product_18010417": "Steel Sheet & Plate",
    "const_product_8060209": "Steel Sheet & Plate",
    "const_product_8060210": "Steel Structure",
    "const_product_80615": "Steel Structure",
    "const_product_15050113": "Steel Watch",
    "const_product_18010418": "Steel Wire",
    "const_product_4030112": "Steering Wheel Covers",
    "const_product_200205": "Stencil",
    "const_product_11010404": "Step Stool",
    "const_product_23010112": "Stepper",
    "const_product_10030206": "Stepper Motor",
    "const_product_3010309": "Sterling Silver Jewelry",
    "const_product_30414": "Sticker",
    "const_product_15040413": "Sticky Roller",
    "const_product_15010210": "Stock Pots",
    "const_product_2305": "Stocks, Secondhand & Others",
    "const_product_17050202": "Stone Carving Machine",
    "const_product_17110204": "Stone Carving Machine",
    "const_product_17050203": "Stone Crusher",
    "const_product_17050204": "Stone Cutting Machine",
    "const_product_17110107": "Stone Cutting Machine",
    "const_product_17050205": "Stone Drilling Machine",
    "const_product_17050206": "Stone Polishing & Grinding Machine",
    "const_product_17040114": "Stone Processing Machinery",
    "const_product_170502": "Stone Processing Machinery",
    "const_product_11010605": "Stool",
    "const_product_15040306": "Storage & Organization",
    "const_product_10030118": "Storage Battery",
    "const_product_10030119": "Storage Battery Charger",
    "const_product_27040105": "Storage Cage",
    "const_product_70402": "Storage Device",
    "const_product_704": "Storage Devices & Drive",
    "const_product_27040106": "Storage Racks",
    "const_product_110309": "Store & Supermarket Furniture",
    "const_product_16060203": "Straight Tube Compact Bulb & Lamp",
    "const_product_15010622": "Strainer",
    "const_product_17120109": "Stranding Machine",
    "const_product_20010414": "Strapping Machine",
    "const_product_17010204": "Straw Making Machine",
    "const_product_16020206": "Street Light",
    "const_product_23010113": "Strength Fitness Equipment",
    "const_product_20010313": "Stretch Film",
    "const_product_11031007": "Stretcher",
    "const_product_17120110": "Stripping Machine",
    "const_product_21020109": "Stroboscopic Lamp",
    "const_product_15070116": "Strollers, Walkers & Carriers",
    "const_product_806": "Structural Building Materials",
    "const_product_13020312": "Stud",
    "const_product_11030502": "Student Chair",
    "const_product_11030501": "Student Desk",
    "const_product_70107": "Stylus",
    "const_product_9020516": "Stylus",
    "const_product_27050106": "Submarine",
    "const_product_10320": "Sugar & Confection",
    "const_product_20123": "Suit",
    "const_product_6040409": "Sulfate",
    "const_product_6040410": "Sulfide",
    "const_product_180208": "Sulfur & Phosphor Mineral",
    "const_product_6020209": "Sulphur Dye",
    "const_product_14010406": "Sun Simulator",
    "const_product_15050213": "Sunglasses",
    "const_product_3010114": "Sunglasses",
    "const_product_15021016": "Sunscreen",
    "const_product_11030606": "Super Clean Bench",
    "const_product_16010208": "Super Flux LED",
    "const_product_25010120": "Superhard Materials",
    "const_product_25020506": "Supermarket Caster",
    "const_product_11030905": "Supermarket Shelf",
    "const_product_6050208": "Surface Disposal Agent",
    "const_product_23010220": "Surfing Equipment",
    "const_product_12030210": "Surgical Equipment",
    "const_product_12030302": "Surgical Instrument",
    "const_product_12030413": "Surgical Supplies",
    "const_product_12030414": "Surgical Tape",
    "const_product_7050106": "Surround Sound Speaker",
    "const_product_9010805": "Surveillance, Control & Protection",
    "const_product_210102": "Surveillance, Control & Protection",
    "const_product_15010623": "Sushi Tools",
    "const_product_8080209": "Suspended Platform",
    "const_product_2010107": "Suspenders",
    "const_product_4020313": "Suspension System",
    "const_product_23010406": "Sweatband",
    "const_product_20124": "Sweater",
    "const_product_27040212": "Sweep Truck",
    "const_product_23040401": "Swimming Pool",
    "const_product_23040402": "Swimming Pool Ladder",
    "const_product_23040403": "Swimming Pool Pump",
    "const_product_23040404": "Swimming Pool Sand Filter",
    "const_product_23010221": "Swimming Supplies",
    "const_product_20208": "Swimwear",
    "const_product_80703": "Switch",
    "const_product_100306": "Switch",
    "const_product_10020408": "Switchgear",
    "const_product_10010404": "Switching Diode",
    "const_product_10020409": "Switching Power Supply",
    "const_product_11070107": "Swivel Plate",
    "const_product_260102": "Sword & Gun Toys",
    "const_product_10030207": "Synchronous Motor",
    "const_product_6010205": "Synthetic Fibers",
    "const_product_24010202": "Synthetic Fibers",
    "const_product_24020102": "Synthetic Leather",
    "const_product_12030303": "Syringe",
    "const_product_14040113": "Syringe Filter",
    "const_product_220207": "System Integration",
    "const_product_20102": "T-Shirt",
    "const_product_11060202": "Table & Desk",
    "const_product_150306": "Table Cloth",
    "const_product_24030103": "Table Cloth",
    "const_product_240309": "Table Cloth",
    "const_product_16020105": "Table Lamp & Reading Light",
    "const_product_23010222": "Table Tennis Equipment",
    "const_product_70108": "Tablet Case & Cover",
    "const_product_90214": "Tablet Case & Cover",
    "const_product_50205": "Tablet Case & Cover",
    "const_product_70109": "Tablet PC",
    "const_product_90215": "Tablet PC",
    "const_product_150109": "Tableware",
    "const_product_10030617": "Tact Switch",
    "const_product_200105": "Tag & Sign",
    "const_product_200111": "Tag Gun",
    "const_product_25010417": "Tag Gun",
    "const_product_4010211": "Tail Lift",
    "const_product_18020213": "Talc",
    "const_product_27020207": "Tandem Bicycle",
    "const_product_20125": "Tank Top",
    "const_product_27040504": "Tank Truck",
    "const_product_15021017": "Tanning Lotion",
    "const_product_18010315": "Tantalum",
    "const_product_25010418": "Tap & Die",
    "const_product_150902": "Tape & Adhesive",
    "const_product_19020227": "Tape Dispenser",
    "const_product_17120204": "Tape Edge Machine",
    "const_product_180110": "Target Material",
    "const_product_10114": "Tea & Tea Leaf",
    "const_product_1030210": "Tea Extract",
    "const_product_1903": "Teaching Equipment",
    "const_product_19030106": "Teaching Model",
    "const_product_8080610": "Tee",
    "const_product_10040215": "Telecom Tower",
    "const_product_100402": "Telecommunication & Broadcasting",
    "const_product_1004": "Telecommunication Equipment",
    "const_product_220104": "Telecommunication Services",
    "const_product_90216": "Telephone",
    "const_product_190122": "Telephone",
    "const_product_10030507": "Telephone Socket",
    "const_product_14030112": "Telescope & Binoculars",
    "const_product_230221": "Telescope & Binoculars",
    "const_product_9010708": "Television Parts",
    "const_product_9030305": "Television Parts",
    "const_product_1305": "Temperature & Humidity Control Equipment",
    "const_product_10010716": "Temperature & Humidity Sensor",
    "const_product_10020606": "Temperature & Humidity Transmitter",
    "const_product_130115": "Temperature Measuring Apparatus",
    "const_product_90503": "Temperature Measuring Apparatus",
    "const_product_8050109": "Tempered Glass",
    "const_product_23010223": "Tennis Equipment",
    "const_product_23020314": "Tent",
    "const_product_15090112": "Tent",
    "const_product_10010313": "Terminal Blocks",
    "const_product_10010314": "Terminals",
    "const_product_140104": "Test Instrument",
    "const_product_14010407": "Test Machine",
    "const_product_14040114": "Test Paper",
    "const_product_14010209": "Test Probe",
    "const_product_14040115": "Test Tube",
    "const_product_24": "Textile",
    "const_product_2406": "Textile & Leather Machinery",
    "const_product_30315": "Textile & Weaving Crafts",
    "const_product_6050209": "Textile Auxiliary Agent",
    "const_product_2405": "Textile for Package",
    "const_product_17020210": "Textile Instrument",
    "const_product_170202": "Textile Machinery & Parts",
    "const_product_240604": "Textile Machinery & Parts",
    "const_product_17020211": "Textile Machinery Parts & Accessories",
    "const_product_20030111": "Textile Printing",
    "const_product_20020321": "Textile Printing Machine",
    "const_product_2404": "Textile Related Products",
    "const_product_240405": "Textile Stocks",
    "const_product_240406": "Textile Waste",
    "const_product_20209": "Theatrical Costume",
    "const_product_14030113": "Theodolite",
    "const_product_19010115": "Thermal Printer",
    "const_product_20020212": "Thermal Printer",
    "const_product_9040109": "Thermal Printer",
    "const_product_10020203": "Thermoelectric Cooler Module",
    "const_product_17070123": "Thermoforming Machine",
    "const_product_14020205": "Thermometer & Hygrometer",
    "const_product_10020204": "Thermostat & Temperature Controller",
    "const_product_10010717": "Throttle Position Sensor",
    "const_product_4010106": "Throttle Position Sensor",
    "const_product_10010110": "Tie Mount",
    "const_product_16040108": "Tiffany Lamp",
    "const_product_17040115": "Tile Making Machine",
    "const_product_8030110": "Tile Spacers",
    "const_product_8030111": "Tile Trim",
    "const_product_803": "Tiles & Flooring",
    "const_product_17010122": "Tiller",
    "const_product_80807": "Timber & Plywood",
    "const_product_9040110": "Time & Attendance",
    "const_product_190123": "Time & Attendance",
    "const_product_210105": "Time & Attendance",
    "const_product_10030618": "Time Switch",
    "const_product_100509": "Timer & Counter",
    "const_product_190309": "Timer & Counter",
    "const_product_140509": "Timer & Counter",
    "const_product_90414": "Timer & Counter",
    "const_product_18010316": "Tin",
    "const_product_8050110": "Tinted Glass",
    "const_product_40107": "Tire & Accessories",
    "const_product_6010409": "Tire & Accessories",
    "const_product_270808": "Tire & Accessories",
    "const_product_4010709": "Tire Chain",
    "const_product_4030312": "Tire Inflator",
    "const_product_4010710": "Tire Valve",
    "const_product_15020908": "Tissue Box",
    "const_product_15040307": "Tissue Box",
    "const_product_17080123": "Tissue Machine",
    "const_product_18010317": "Titanium",
    "const_product_9030528": "Toaster",
    "const_product_10030619": "Toggle Switch",
    "const_product_8010704": "Toilet",
    "const_product_80107": "Toilet & Accessories",
    "const_product_8010110": "Toilet Brush Holder",
    "const_product_15080409": "Toilet Brush Holder",
    "const_product_15040108": "Toilet Cleaner",
    "const_product_15020909": "Toilet Paper",
    "const_product_17080124": "Toilet Paper Machine",
    "const_product_8050503": "Toilet Partitions",
    "const_product_8010705": "Toilet Seat",
    "const_product_15020106": "Toilet Soap",
    "const_product_8010706": "Toilet Tank",
    "const_product_8010707": "Toilet Tank Parts",
    "const_product_1010108": "Tomato",
    "const_product_3040205": "Tombstone & Monument",
    "const_product_8020114": "Tombstone & Monument",
    "const_product_19010116": "Toner Powder",
    "const_product_20010601": "Tool Bag",
    "const_product_50517": "Tool Bag",
    "const_product_50106": "Tool Box & Case",
    "const_product_20010602": "Tool Box & Case",
    "const_product_20010603": "Tool Cabinet",
    "const_product_200106": "Tool Packaging",
    "const_product_250113": "Tool Packaging",
    "const_product_2501": "Tools & Equipment",
    "const_product_25": "Tools & Hardware",
    "const_product_250301": "Tools & Hardware Stocks",
    "const_product_15020804": "Tooth Powder",
    "const_product_15020805": "Toothbrush",
    "const_product_15080410": "Toothbrush Holder",
    "const_product_8010111": "Toothbrush Holder",
    "const_product_15020806": "Toothpaste",
    "const_product_15020807": "Toothpick",
    "const_product_17010205": "Toothpick Machine",
    "const_product_160508": "Torch & Flashlight",
    "const_product_230222": "Torch & Flashlight",
    "const_product_10010718": "Torque Sensor",
    "const_product_5010111": "Tote Bag",
    "const_product_9010309": "Touch Screen",
    "const_product_3010204": "Toupee",
    "const_product_12030415": "Tourniquet",
    "const_product_150307": "Towel & Handkerchief",
    "const_product_240302": "Towel & Handkerchief",
    "const_product_15080411": "Towel Bar",
    "const_product_8010112": "Towel Bar",
    "const_product_8010113": "Towel Ring",
    "const_product_15080412": "Towel Ring",
    "const_product_24030207": "Towel Set",
    "const_product_17010308": "Tower Crane",
    "const_product_260901": "Toy Accessories",
    "const_product_260902": "Toy Stocks",
    "const_product_2605": "Toy Vehicles",
    "const_product_26": "Toys",
    "const_product_2606": "Toys By Material",
    "const_product_2608": "Toys By Style",
    "const_product_2607": "Toys By User",
    "const_product_1040107": "Trace Element Fertilizer",
    "const_product_6080205": "Trace Element Fertilizer",
    "const_product_23010224": "Track & Field Equipment",
    "const_product_27020208": "Track Bicycle",
    "const_product_27040302": "Tractor",
    "const_product_270403": "Tractor & Parts",
    "const_product_17010123": "Tractor & Parts",
    "const_product_27040505": "Tractor Truck",
    "const_product_21040106": "Traffic Barrier",
    "const_product_8080412": "Traffic Barrier",
    "const_product_21040107": "Traffic Cone",
    "const_product_21040108": "Traffic Light",
    "const_product_21040109": "Traffic Sign",
    "const_product_270404": "Trailer",
    "const_product_27040408": "Trailer Parts",
    "const_product_270804": "Train & Railway",
    "const_product_27080403": "Train Parts",
    "const_product_11030406": "Training Chair",
    "const_product_11030404": "Training Desk",
    "const_product_23010309": "Training Equipment",
    "const_product_23010114": "Trampoline",
    "const_product_27040107": "Transfer Cart",
    "const_product_20020410": "Transfer Film",
    "const_product_20020411": "Transfer Paper",
    "const_product_20030112": "Transfer Printing",
    "const_product_10020510": "Transformer",
    "const_product_13040409": "Transmission Belt",
    "const_product_13040410": "Transmission Chain",
    "const_product_13040411": "Transmission Pulley",
    "const_product_10020511": "Transmission Tower",
    "const_product_100206": "Transmitter",
    "const_product_140108": "Transmitter",
    "const_product_27": "Transportation",
    "const_product_220408": "Travel",
    "const_product_23020315": "Travel Bag",
    "const_product_50302": "Travel Bag",
    "const_product_23020316": "Travel Mug",
    "const_product_15010310": "Travel Mug",
    "const_product_24060108": "Travel Pillow",
    "const_product_27080303": "Travel Trailer",
    "const_product_15010911": "Tray",
    "const_product_23010115": "Treadmill",
    "const_product_23020317": "Trekking Pole",
    "const_product_17010124": "Trencher",
    "const_product_270204": "Tricycle & Parts",
    "const_product_40608": "Tricycle & Parts",
    "const_product_10010405": "Triode",
    "const_product_50303": "Trolley & Luggage",
    "const_product_23040319": "Trombone",
    "const_product_270405": "Truck",
    "const_product_40609": "Truck",
    "const_product_4010711": "Truck & Bus Tire",
    "const_product_17010309": "Truck Crane",
    "const_product_27040506": "Truck Parts",
    "const_product_14020412": "Truck Scale",
    "const_product_27040409": "Truck Trailer",
    "const_product_23040320": "Trumpet",
    "const_product_8060211": "Truss",
    "const_product_15090113": "Truss",
    "const_product_8010207": "Tub Faucet",
    "const_product_23040321": "Tuba",
    "const_product_17040403": "Tube End Forming Machine",
    "const_product_13060216": "Tube Welder",
    "const_product_27050107": "Tugboat",
    "const_product_8010114": "Tumbler Holder",
    "const_product_18010318": "Tungsten",
    "const_product_4020211": "Turbocharger",
    "const_product_15010912": "Tureen",
    "const_product_4010513": "Turn Light",
    "const_product_25020214": "Turnbuckle",
    "const_product_13020210": "Turning Tool",
    "const_product_21010112": "Turnstile",
    "const_product_90107": "TV & Parts",
    "const_product_9010707": "TV Mount",
    "const_product_10030506": "TV Socket",
    "const_product_11010609": "TV Stand",
    "const_product_23020318": "Twine & Rope",
    "const_product_24040102": "Twine & Rope",
    "const_product_240401": "Twine, Rope & Netting",
    "const_product_150820": "Twine, Rope & Netting",
    "const_product_90217": "Two Way Radio",
    "const_product_20020412": "Two-Sided Offset Paper",
    "const_product_4030313": "Tyre Changer",
    "const_product_16060204": "U-Tube Compact Bulb & Lamp",
    "const_product_90218": "UGV",
    "const_product_23040205": "Ukulele",
    "const_product_90324": "Ultrasonic Cleaner",
    "const_product_12030109": "Ultrasonic Scaler",
    "const_product_10010719": "Ultrasonic Sensor",
    "const_product_12030211": "Ultrasonic, Optical, Electronic Equipment",
    "const_product_16060109": "Ultraviolet Lamp",
    "const_product_15080104": "Umbrella",
    "const_product_11010704": "Umbrella Stand",
    "const_product_23010310": "Umpire Chair",
    "const_product_14010210": "Underground Metal Detector",
    "const_product_15020910": "Underpad",
    "const_product_2010305": "Underpants",
    "const_product_16020207": "Underwater Light",
    "const_product_20103": "Underwear",
    "const_product_2010306": "Underwear Set",
    "const_product_2020201": "Uniform",
    "const_product_20202": "Uniform & Work Clothes",
    "const_product_8080611": "Union",
    "const_product_130210": "Universal Hardware Parts",
    "const_product_250208": "Universal Hardware Parts",
    "const_product_4020314": "Universal Joint",
    "const_product_12030304": "Universal Medical Implement",
    "const_product_140510": "Universal Medical Implement",
    "const_product_10020410": "UPS",
    "const_product_8010708": "Urinal",
    "const_product_3040206": "Urn",
    "const_product_7040206": "USB Flash Disk",
    "const_product_70511": "USB Products",
    "const_product_10030508": "USB Socket",
    "const_product_50107": "Used Bags",
    "const_product_20126": "Used Clothes",
    "const_product_2030413": "Used Shoes",
    "const_product_27020209": "Utility Bicycle",
    "const_product_270107": "UTV & Parts",
    "const_product_17100109": "Vacuum Casting",
    "const_product_4030212": "Vacuum Cleaner",
    "const_product_15040414": "Vacuum Cleaner",
    "const_product_150408": "Vacuum Cleaner",
    "const_product_90325": "Vacuum Cleaner",
    "const_product_9030306": "Vacuum Cleaner Parts",
    "const_product_13050212": "Vacuum Drying Machine",
    "const_product_20010415": "Vacuum Filling Machine",
    "const_product_15010404": "Vacuum Flask & Thermos",
    "const_product_15040308": "Vacuum Flask & Thermos",
    "const_product_17040310": "Vacuum Lifter",
    "const_product_20010416": "Vacuum Packing Machine",
    "const_product_9030529": "Vacuum Packing Machine",
    "const_product_13030213": "Vacuum Pump",
    "const_product_220208": "Value-added Network Services",
    "const_product_25020406": "Valve",
    "const_product_130303": "Valve",
    "const_product_13030402": "Valve Actuator",
    "const_product_13030403": "Valve Ball",
    "const_product_13030404": "Valve Body",
    "const_product_13030405": "Valve Cap",
    "const_product_13030406": "Valve Needle",
    "const_product_130304": "Valve Parts",
    "const_product_13030407": "Valve Seat",
    "const_product_13030408": "Valve Stem",
    "const_product_13030409": "Valve Strainer",
    "const_product_4020212": "Valve System",
    "const_product_13030214": "Vane Pump",
    "const_product_100209": "Variable-Frequency Drive",
    "const_product_30614": "Vase",
    "const_product_6020210": "Vat Dye",
    "const_product_9010806": "VCD & DVD Player",
    "const_product_20010234": "VCI Packaging",
    "const_product_9030530": "Vegetable & Fruit Purifier",
    "const_product_10101": "Vegetable & Preparation",
    "const_product_40303": "Vehicle Repair Equipment & Tools",
    "const_product_250114": "Vehicle Repair Equipment & Tools",
    "const_product_270305": "Vehicle Repair Equipment & Tools",
    "const_product_140109": "Vehicle Repair Equipment & Tools",
    "const_product_260503": "Vehicle Toys",
    "const_product_9040111": "Vending Machine",
    "const_product_13050607": "Ventilation Duct",
    "const_product_130506": "Ventilation Equipment",
    "const_product_13050608": "Ventilation Fan",
    "const_product_9030107": "Ventilation Fan",
    "const_product_12030212": "Ventilator",
    "const_product_18020214": "Vermiculite",
    "const_product_1050112": "Vertical Grow System",
    "const_product_20127": "Vest & Waistcoat",
    "const_product_120401": "Veterinary",
    "const_product_12040101": "Veterinary Instruments",
    "const_product_12040102": "Veterinary Medicine",
    "const_product_14040116": "Vial",
    "const_product_17060214": "Vibrating Screen",
    "const_product_17110605": "Vibrating Screen",
    "const_product_23010116": "Vibration Platform",
    "const_product_12020311": "Vibration Platform",
    "const_product_90108": "Video",
    "const_product_9010807": "Video Camera & Accessories",
    "const_product_21010113": "Video Door Phone",
    "const_product_9010808": "Video Glasses",
    "const_product_15050214": "Video Glasses",
    "const_product_14030114": "Video Measuring System",
    "const_product_23040322": "Viola",
    "const_product_23040323": "Violin",
    "const_product_14020307": "Viscometer, Density Meter",
    "const_product_13020211": "Vise",
    "const_product_19030304": "Visual Presenter",
    "const_product_10040216": "VoIP Products",
    "const_product_23010225": "Volleyball Equipment",
    "const_product_14020105": "Voltage Meter",
    "const_product_10020411": "Voltage Regulator",
    "const_product_10010720": "Voltage Sensor",
    "const_product_10020607": "Voltage Transmitter",
    "const_product_50108": "Waist Bag",
    "const_product_23010407": "Waist Support",
    "const_product_23010311": "Waiting Bench & Chair",
    "const_product_11031103": "Waiting Bench & Chair",
    "const_product_12020406": "Walking Stick",
    "const_product_21020110": "Walkthrough Metal Detector",
    "const_product_14010211": "Walkthrough Metal Detector",
    "const_product_25010618": "Wall Chaser",
    "const_product_15050114": "Wall Clock",
    "const_product_16020106": "Wall Light",
    "const_product_8050315": "Wall Panels",
    "const_product_10030509": "Wall Socket",
    "const_product_16070116": "Wall Socket",
    "const_product_8030112": "Wall Tile",
    "const_product_16030205": "Wall Washer Light",
    "const_product_5010203": "Wallet",
    "const_product_50102": "Wallet & Purses",
    "const_product_8050316": "Wallpaper",
    "const_product_11010205": "Wardrobe",
    "const_product_27040108": "Warehouse",
    "const_product_2030203": "Warm Caps",
    "const_product_2030102": "Warm Gloves",
    "const_product_15020214": "Warm Patch",
    "const_product_2030304": "Warm Set",
    "const_product_90326": "Warmer Appliance",
    "const_product_9030531": "Warming Tray",
    "const_product_16050105": "Warning Light",
    "const_product_21040110": "Warning Triangle",
    "const_product_4030314": "Warning Triangle",
    "const_product_90327": "Washing & Drying Machine & Parts",
    "const_product_17020120": "Washing Auxiliary Equipment & Apparatus",
    "const_product_13010707": "Washing Auxiliary Equipment & Apparatus",
    "const_product_130107": "Washing Equipment",
    "const_product_13010708": "Washing Equipment Parts",
    "const_product_17020121": "Washing Equipment Parts",
    "const_product_13010311": "Waste Treatment Equipment",
    "const_product_15050115": "Watch Box",
    "const_product_50518": "Watch Box",
    "const_product_200112": "Watch Box",
    "const_product_6110104": "Water Bladder Tank",
    "const_product_15010405": "Water Bottle",
    "const_product_13010312": "Water Disinfection System",
    "const_product_9030402": "Water Dispenser",
    "const_product_90308": "Water Heater & Components",
    "const_product_9030307": "Water Heater Parts",
    "const_product_9030805": "Water Heater Parts",
    "const_product_10010721": "Water Leak Sensor",
    "const_product_14020206": "Water Meter",
    "const_product_23040106": "Water Play Equipment",
    "const_product_13030215": "Water Pump",
    "const_product_13010313": "Water Purifier",
    "const_product_9030403": "Water Purifier",
    "const_product_9030404": "Water Softener",
    "const_product_13010314": "Water Tank",
    "const_product_13010315": "Water Treatment Chemical",
    "const_product_6050210": "Water Treatment Chemical",
    "const_product_27040213": "Water Truck",
    "const_product_60106": "Water Weight Bag",
    "const_product_17060105": "Water Well Drilling Rig",
    "const_product_15060120": "Watering Can",
    "const_product_17050125": "Waterjet Cutting Machine",
    "const_product_17110108": "Waterjet Cutting Machine",
    "const_product_80616": "Waterproof Building Materials",
    "const_product_17010206": "Wax & Candle Machine",
    "const_product_60604": "Wax & Fat",
    "const_product_180209": "Wear Resistant Material",
    "const_product_90207": "Wearable Devices",
    "const_product_17020212": "Weaving Machine",
    "const_product_2040116": "Webbing",
    "const_product_2020312": "Wedding Accessories",
    "const_product_2020313": "Wedding Dresses",
    "const_product_20203": "Wedding Dresses & Ceremonial Clothing",
    "const_product_3020206": "Wedding Gifts & Crafts",
    "const_product_15060121": "Weed Mat",
    "const_product_8080507": "Weed Mat",
    "const_product_140204": "Weighing & Measuring Apparatus",
    "const_product_150821": "Weighing & Measuring Apparatus",
    "const_product_14020413": "Weight Indicator",
    "const_product_120209": "Weight Management",
    "const_product_10020608": "Weight Transmitter",
    "const_product_14020414": "Weight Transmitter",
    "const_product_8080814": "Welded Wire Mesh",
    "const_product_1306": "Welding & Solder",
    "const_product_130601": "Welding & Soldering Supplies",
    "const_product_250115": "Welding & Soldering Supplies",
    "const_product_13060217": "Welding Auxiliary Equipment & Apparatus",
    "const_product_130602": "Welding Equipment",
    "const_product_4030315": "Welding Equipment",
    "const_product_13060102": "Welding Flux",
    "const_product_13060103": "Welding Helmet",
    "const_product_21050119": "Welding Helmet",
    "const_product_13060104": "Welding Rod",
    "const_product_13070110": "Welding Service",
    "const_product_13060218": "Welding Tips",
    "const_product_13060105": "Welding Torch",
    "const_product_13060106": "Welding Wire",
    "const_product_17060308": "Wellhead Tree",
    "const_product_120107": "Western Medicine",
    "const_product_8010304": "Wet Steam Room",
    "const_product_130211": "Wheel & Caster",
    "const_product_250205": "Wheel & Caster",
    "const_product_25020507": "Wheel & Caster Accessories",
    "const_product_4030316": "Wheel Alignment",
    "const_product_4030317": "Wheel Balancer",
    "const_product_4020315": "Wheel Hub, Rim & Spoke",
    "const_product_12020407": "Wheelchair",
    "const_product_20020413": "White Paperboard",
    "const_product_19030203": "Whiteboard",
    "const_product_220307": "Wholesale Services",
    "const_product_10040217": "WiFi Products",
    "const_product_3010205": "Wig",
    "const_product_17040311": "Winch",
    "const_product_13040309": "Wind Generator",
    "const_product_17020213": "Winding Machine",
    "const_product_80404": "Window",
    "const_product_17040116": "Window & Door Making Machine",
    "const_product_25020108": "Window Handle",
    "const_product_8040217": "Window Handle",
    "const_product_8040218": "Window Lock",
    "const_product_8040311": "Window Lock",
    "const_product_20010108": "Window Patching Machine",
    "const_product_8040219": "Window Screening",
    "const_product_4030113": "Window Visor",
    "const_product_15010107": "Wine Cup",
    "const_product_15080413": "Wine Rack",
    "const_product_11030204": "Wine Rack",
    "const_product_4010212": "Wiper Blade, Arm & Motor",
    "const_product_10030313": "Wire Harness",
    "const_product_171116": "Wire Mesh",
    "const_product_180111": "Wire Mesh",
    "const_product_80808": "Wire Mesh",
    "const_product_25020109": "Wire Mesh",
    "const_product_17050126": "Wire Mesh Machine",
    "const_product_17120111": "Wire Pay Off Machine",
    "const_product_13020313": "Wire Thread Insert",
    "const_product_10010315": "Wire to Board Connector",
    "const_product_10010316": "Wire to Wire Connector",
    "const_product_8050111": "Wired Glass",
    "const_product_90415": "Wireless Paging System",
    "const_product_7050107": "Wireless Speaker",
    "const_product_21010209": "Wireless Transmission System",
    "const_product_10010111": "Wiring Duct",
    "const_product_15010211": "Woks",
    "const_product_18020215": "Wollastonite",
    "const_product_2030414": "Women's Shoes",
    "const_product_2010203": "Women's T-Shirts",
    "const_product_8030210": "Wood & Hardwood Floor",
    "const_product_17050305": "Wood Based Panel Machine",
    "const_product_17050306": "Wood Boring Machine",
    "const_product_17050307": "Wood Chip Machine",
    "const_product_17050308": "Wood Crusher",
    "const_product_17050309": "Wood Debarker",
    "const_product_17050310": "Wood Drilling Machine",
    "const_product_17050311": "Wood Edge Banding Machine",
    "const_product_17050312": "Wood Embossing Machine",
    "const_product_17050313": "Wood Lathe",
    "const_product_17050314": "Wood Pellet Mill",
    "const_product_17050315": "Wood Planer",
    "const_product_8030211": "Wood Plastic Composite Flooring",
    "const_product_17050316": "Wood Press",
    "const_product_17050317": "Wood Router",
    "const_product_17050318": "Wood Sander",
    "const_product_17050319": "Wood Saw Machine",
    "const_product_13020314": "Wood Screws",
    "const_product_17050320": "Wood Shaving Machine",
    "const_product_8040108": "Wooden & Timber Door",
    "const_product_11040801": "Wooden Bed",
    "const_product_11040802": "Wooden Cabinet",
    "const_product_11040803": "Wooden Chair",
    "const_product_30316": "Wooden Crafts",
    "const_product_110408": "Wooden Furniture",
    "const_product_5050208": "Wooden Gift Box",
    "const_product_11040804": "Wooden Table",
    "const_product_260605": "Wooden Toys",
    "const_product_15050116": "Wooden Watch",
    "const_product_8040404": "Wooden Window",
    "const_product_17050321": "Woodworking Composer",
    "const_product_17050322": "Woodworking Engraving Machine",
    "const_product_170503": "Woodworking Machinery",
    "const_product_17050323": "Woodworking Machinery Parts",
    "const_product_240204": "Wool & Animal Hair",
    "const_product_21050120": "Work & Labor Gloves",
    "const_product_2030103": "Work & Labor Gloves",
    "const_product_4030213": "Work & Labor Gloves",
    "const_product_2030415": "Work & Safety Shoes",
    "const_product_21050121": "Work & Safety Shoes",
    "const_product_2030204": "Work Caps",
    "const_product_2020202": "Work Clothes",
    "const_product_11030305": "Workbench",
    "const_product_2105": "Workplace Safety Supplies",
    "const_product_13040412": "Worm",
    "const_product_12030416": "Wound Dressing",
    "const_product_15010706": "Woven Label",
    "const_product_20010235": "Woven Label",
    "const_product_24050102": "Woven Label",
    "const_product_8050314": "WPC Board",
    "const_product_20010417": "Wrapping Machine",
    "const_product_27040214": "Wrecker",
    "const_product_25010419": "Wrench",
    "const_product_23010408": "Wrist Support",
    "const_product_20020414": "Writing Paper",
    "const_product_14010212": "X-Ray Detector",
    "const_product_16060110": "Xenon Light",
    "const_product_4010514": "Xenon Light",
    "const_product_23020606": "Yacht",
    "const_product_240407": "Yarn",
    "const_product_10321": "Yeast & Products",
    "const_product_23010117": "Yoga Supplies",
    "const_product_9030532": "Yogurt Maker",
    "const_product_10010406": "Zener Diode",
    "const_product_18010319": "Zinc",
    "const_product_2040117": "Zipper & Slider",
    "const_product_5060110": "Zipper & Slider",
    "const_product_17020122": "Zipper Making Machine",
    "const_product_18010320": "Zirconium"
};


export default texts;
