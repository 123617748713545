import React from 'react';
import { Result } from 'antd';
import usePageTitle from 'hooks/usePageTitle';

export const PageError404 = () => {
    usePageTitle("403 - 页面不存在");
    return (
        <div>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
            />
        </div>
    );
};

export default PageError404;

