import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Dropdown, Button } from "antd";
import { useFetchGetBemLogsList } from "hooks";
import { getTime, langError } from "tools";
import { Link } from "react-router-dom";

export const TableLogsList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);
	
	const [fetchGetBemLogsList] = useFetchGetBemLogsList();

	// const items = [
	// 	{
	// 		label: "转发",
	// 		key: "forward",
	// 	},
	// ];

	const typeMap = {
		1: <Tag color="green">正常</Tag>,
		9: <Tag color="red">禁用</Tag>,
	};

	const levelMap = {
		"PLATINUM": <Tag color="blue">PLATINUM</Tag>,
		"FREE": <Tag color="gray">FREE</Tag>,
	};

	const columns = [
		{
			title: "time_add",
			dataIndex: "time_add",
			render:(_,record) =>(
				<span>{getTime(record.time_add)}</span>
			)
		},
		{
			title: "user",
			dataIndex: "user",
			render:(_,record) =>(
				<Link target="_blank" to={`/bem/user/detail?uid=${record.user}&type=insight`}>{record.user}</Link>
			)
		},
		{
			title: "App",
			dataIndex: "app",

		},
		{
			title: "action",
			dataIndex: "action",
		},
		{
			title: "data",
			dataIndex: "data",
			render:(_,record) =>(
				<>
					{record.data && JSON.stringify(record.data)}
				</>
			)
		},
		// {
		// 	title: "from",
		// 	dataIndex: "from",
		// },
		{
			title: "obj",
			dataIndex: "obj",
		},
		// {
		// 	title: "priority",
		// 	dataIndex: "priority",
		// },
		// {
		// 	title: "realtime",
		// 	dataIndex: "realtime",
		// },
		// {
		// 	title: "status",
		// 	dataIndex: "status",
		// },
		// {
		// 	title: "type",
		// 	dataIndex: "type",
		// },
		// {
		// 	title: "操作",
		// 	dataIndex: "operate",
		// 	width:80,
		// 	render: (_, record) => (
		// 		<Dropdown
		// 			menu={{
		// 				items,
		// 				onClick: (item) => {
		// 					if (item.key === "forward") {
		// 						setCurrentRow(record);
		// 						setOpenModalForward(true);
		// 					}
		// 				},
		// 			}}
		// 		>
		// 			<a onClick={(e) => e.stopPropagation()}>
		// 				<Space>
		// 					<EllipsisOutlined style={{ fontSize: 24 }} />
		// 				</Space>
		// 			</a>
		// 		</Dropdown>
		// 	),
		// },
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData, app:"insight" };
			fetchGetBemLogsList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(data.list);
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							{/* <Button type="primary">操作</Button> */}
						</Row>
					</Space>
					
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={record => record.id}
						scroll={{x:"fit-content"}}
					/>
				</>
			)}
		</Fragment>
	);
};
