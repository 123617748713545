import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Space, Row, Col, Dropdown, Avatar, Spin } from "antd";
import {
	AppstoreOutlined,
	DownOutlined,
	UserOutlined,
	LogoutOutlined,
	SettingOutlined,
} from "@ant-design/icons";
import { LogoSm } from "components";
import { handleUnameInitial, unsetAuthLocal } from "tools";
import { useFetchGetUserInfo } from "hooks";

const { Header } = Layout;

const usersStyle = {
	color: "#ffffff",
	fontSize: "14px",
};

export const LayoutHeaderNav = () => {
	const user = useSelector((state) => state.user);
	const { id, name } = user?.info;

	const [fetchUser] = useFetchGetUserInfo();

	const navigate = useNavigate();

	const [items, setItems] = useState([]);

	useEffect(() => {
		setItems(() => {
			return user.apps.map((item) => {
				return {
					label: (
						<Link
							target="_blank"
							to={`/${item.default_path}`}
						>
							{item.name}
						</Link>
					),
					key: item.key
				};
			});
		});
	}, [user.apps]);

	useEffect(() => {
		if (!id) {
			fetchUser({
				error: () => {
					navigate("/login");
				},
			});
		}
	}, [id]);

	const itemsProfile = [
		// {
		//   label: "Account",
		//   key: "account",
		//   icon: <UserOutlined />,
		// },
		// {
		//   type: "divider",
		// },
		{
			label: (
				<a
					onClick={() => {
						unsetAuthLocal();
						window.location.href = "/login";
					}}
				>
					Logout
				</a>
			),
			key: "logout",
			icon: <LogoutOutlined />,
		},
	];

	const goToUser = () => {
		navigate("/user", {
			replace: false,
			// state: { a: 1, b: 2 },
		});
	};

	return (
		<Header
			style={{
				position: "sticky",
				// zIndex: 2,
				zIndex: 3,
				top: 0,
				width: "100%",
				height: "50px",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
			}}
		>
			<div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
				<Link to="/" style={{display:"inline-flex"}}>
					<LogoSm />
				</Link>
				<h3 style={{ color: "#fff", lineHeight: "50px", marginLeft:12 }}>内部系统</h3>
				{/* <Button
						type="text"
						icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
						onClick={onHandleCollapsed}
						style={{
							fontSize: '16px',
							width: 64,
							height: 64,
						}}
					/> */}
			</div>
			<div>
				{user?.is_super_admin === 1 && (
					<Link className="ardl-header-nav-link" to="/user" ><SettingOutlined style={usersStyle} /></Link>
				)}
				<Dropdown
					menu={{
						items
					}}
					trigger={["hover"]}
					placement="bottom"
				>
					<a className="ardl-header-nav-link" onClick={(e) => e.preventDefault()}>
						<AppstoreOutlined style={{ color: "#fff" }} />
					</a>
				</Dropdown>
				<Dropdown
					menu={{
						items: itemsProfile,
					}}
					trigger={["hover"]}
				>
					<a className="ardl-header-nav-link" onClick={(e) => e.preventDefault()}>
						{!name ? (
							<Spin />
						) : (
							<Space>
								<Avatar
									style={{
										backgroundColor: "#1677ff",
										verticalAlign: "middle",
									}}
									size="middle"
								>
									{handleUnameInitial(name)}
								</Avatar>
								<span>
									<span style={{ color: "#fff" }}>{name}</span>
								</span>
								<DownOutlined style={{ color: "#fff" }} />
							</Space>
						)}
					</a>
				</Dropdown>
			</div>
		</Header>
	);
};
