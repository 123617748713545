import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { EllipsisOutlined } from "@ant-design/icons";
import { useFetchGetAppListPermissions } from "hooks";
import { App, Button, Row, Space, Table, Typography, Dropdown, Tag, Skeleton } from "antd";
import { useFetchGetUserList } from "hooks";
import { langError } from "tools";
import { ModalAddUser } from "./ModalAddUser";
import { ModalEditApp } from "./ModalEditApp";
import { useNavigate } from "react-router-dom";

const items = [
	{
		label: "编辑用户",
		key: "edit_user",
	},
	{
		label: "分配App",
		key: "edit_app",
	},
];

export const TableUserList = () => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [openAddUser, setOpenUserModal] = useState(false); // 控制编辑user modal
	const [openEditApp, setOpenEditApp] = useState(false);   // 控制分配app modal
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [currentUser, setCurrentUser] = useState(null);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [appList, setAppList] = useState([]);
	const [forceList, setForceList] = useState(0);

	const [fetchAppList] = useFetchGetAppListPermissions();
	const [fetchList] = useFetchGetUserList();

	const navigate = useNavigate();

	useEffect(() => {
		if (id) {
			fetchAppList({}, {
				success: (data) => {
					setLoading(false);
					setAppList(data);
				},
				error: (error) => {
					setLoading(false);
					if (error.code === "ACCESS:NO_PERMISSION") {
						navigate("/403");
					} else {
						notification.error({ message: langError(error.code) });
					}
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const userStatusMap = {
		1: <Tag color="green">在职</Tag>,
		11: <Tag color="red">离职</Tag>,
	};

	const typeMap = {
		1: "全职",
		2: "试用期",
		3: "实习生",
		4: "兼职",
		5: "合作伙伴",
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "姓名",
			dataIndex: "name",
		},
		{
			title: "邮箱",
			dataIndex: "email",
		},
		{
			title: "状态",
			dataIndex: "status",
			render: (_, record) => (
				<>{userStatusMap[record.status]}</>
			)
		},
		{
			title: "类型",
			dataIndex: "type",
			render: (_, record) => (
				<>{typeMap[record.type]}</>
			)
		},
		{
			title: "操作",
			dataIndex: "operate",
			render: (_, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Dropdown
						menu={{
							items,
							onClick: (item) => {
								if (item.key === "edit_app") {
									setOpenEditApp(true);
									setCurrentUser(record);
								} else if (item.key === "edit_user") {
									setOpenUserModal(true);
									setCurrentUser(record);
								}
							},
						}}
					>
						<a>
							<Space>
								<EllipsisOutlined style={{ fontSize: 24 }} />
							</Space>
						</a>
					</Dropdown>
				</div>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			fetchList(
				{ page: 1 },
				{
					success: (data) => {
						setLoading(false);
						setListData(data.list);
						setDataTotal(data.count);
					},
					error: (error) => {
						setLoading(false);
						notification.error({ message: langError(error.code) });
					},
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	}

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={() => setOpenUserModal(true)}>
								添加用户
							</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={record => record.id}
					/>
				</>
			)}
			{openEditApp && (
				<ModalEditApp
					open={openEditApp}
					currentUser={currentUser}
					onCancel={() => {
						setOpenEditApp(false);
						setCurrentUser(null);
					}}
					appList={appList}
					onUpdateList={() => setForceList(forceList => forceList + 1)}
				/>
			)}
			{openAddUser && (
				<ModalAddUser
					open={openAddUser}
					currentUser={currentUser}
					onCancel={() => {
						setOpenUserModal(false);
						setCurrentUser(null);
					}}
				/>
			)}
		</Fragment>
	);
};
