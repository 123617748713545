import { createSlice } from '@reduxjs/toolkit';
import config from '../../config';

let token = localStorage.getItem(config.keyStorageToken);
if (!token || token === 'undefined') {
    token = '';
}

let api = localStorage.getItem(config.keyStorageAPI);
if (!api || api === 'undefined') {
    api = '';
}

const initValue = {
    auth: {
        token: token,
        api: api,
    },
    user:null
};

const initialSlice = createSlice({
    name: 'initial',
    initialState: initValue,
    reducers: {
        setAuth(state, action) {
            if (state.auth.api !== action.payload.api || state.auth.token !== action.payload.jwt) {
                state.auth.api = action.payload.api;
                state.auth.token = action.payload.jwt;
            }
        },
        unSetAuth(state, action) {
            return state
        },
    }
})

export const { setAuth, unSetAuth } = initialSlice.actions;
export default initialSlice.reducer;
