
const texts = {
    "label_bulk_no_permission_title": "Get Contacts by Domain or Company Names",
    "label_bulk_verifier_no_permission_title": "Decrease Hard Bounce Rate with Bulk Email Verification",
    "label_bulk_cleansing_no_permission_title": "Clean Up Your Contact Lists",
    "info_bulk_no_permission_content": "Bulk upload domain or company name to AroundDeal and we will automatically search and match contact information like email, phones, titles, work history and more.",
    "info_bulk_verifier_no_permission_content": "Easiest way to clean customer emails & databases and boost the ROI of your email marketing campaigns.",
    "info_bulk_cleansing_no_permission_content": "Use Bulk Cleansing to remove incorrect, incomplete, duplicate, and improperly formatted data.",
    "label_no_bulk_title":"No Bulk Task Yet",
    "info_no_bulk_content":"Use bulk finder to get contacts by company or domain names",
    "info_no_bulk_cleansing_content":"Clean Up Your Contact Lists",
    "info_no_bulk_verifier_content":"Decrease Hard Bounce Rate with Bulk Email Verification",
    "bulk_step_title_1":"1. Upload a CSV. file",
    "bulk_step_info_1":"Upload your targeted company names or domain names list.",
    "bulk_step_title_2":"2. Set Search Option",
    "bulk_step_info_2":"Choose search options to set up the necessary parameters and start a task.",
    "bulk_step_title_3":"3. View Results",
    "bulk_step_info_3":"Once your task starts, you can check the progress of the task. When your search is complete, you can view the results.",
    "bulk_verifier_step_title_1":"1. Upload a CSV. file",
    "bulk_verifier_step_info_1":"Upload your targeted email list with up to 10,000 contacts.",
    "bulk_verifier_step_title_2":"2. Download the results",
    "bulk_verifier_step_info_2":"You can get results within a few minutes.",
    "bulk_cleansing_step_title_1":"1. Upload a CSV. file",
    "bulk_cleansing_step_info_1":"Upload your targeted email list or phone list with up to 10,000 contacts.",
    "bulk_cleansing_step_title_2":"2. Download a Cleaned List",
    "bulk_cleansing_step_info_2":"You can get results within a few minutes.",
    "bulk_empty_local": "Upload your targeted company names or domain names list and get bulk results. Let's start~",
    "colunm_search_name": "Search Name",
    "colunm_action": "Action",
	"colunm_results": "Results",
    "colunm_created_date": "Created Date",
	"colunm_last_updated": "Last Updated",
	"prospector_not_found": "Prospector Not Found",
    "modal_add_to_list_title":"You are going to export {result} prospect(s)",
    "cleansing_modal_add_to_list_title":"You are going to export {result} record(s)",
    "verifier_modal_add_to_list_title":"You are going to export {result} record(s)",
    "modal_add_to_list_content":"The export will take {result} credit(s) from your balance( 1 credit per prospect).You currently have {credit} credit(s) in your balance.",
    "cleansing_modal_add_to_list_content":"The export will take {result} cleansing(s) from your balance( 1 cleansing per record ).You currently have {cleansing} cleansing(s) in your balance.",
    "verifier_modal_add_to_list_content":"The export will take {result} verification(s) from your balance( 1 verification per record ).You currently have {verification} verification(s) in your balance.",
    "modal_bulk_verifier_view_results":"Email verification ensures that you are capturing and/or sending to emails that are valid and will not result in a bounce.",
    "modal_bulk_verifier_view_results_footer":"Learn More about Result status values",
    "modal_bulk_cleansing_view_results":"Email cleansing ensures that you are capturing and/or sending to emails that are valid and will not result in a bounce.",
    "modal_bulk_verifier_title":"Verification Results",
    "modal_bulk_cleansing_title":"Cleansing Results",
    "bulk_domain_search_added_successfully": "Your bulk task added to list successfully.",
    "bulk_finder_title":"Get Contacts by Domains or Company Names",
    "bulk_cleansing_title":"Clean Up Your Contact Lists",
    "bulk_verifier_title":"Verify Email Addresses in Bulk",
    "bulk_finder_initial_list_name":"Bulk Finder - {time}",
    "bulk_finder_initial_list_name_required":"Please select your list name",
    "bulk_finder_initial_list_name_ph":"Enter a name...",
    "bulk_verifier_initial_list_name":"Bulk Verifier - {time}",
    "bulk_verifier_initial_list_name_required":"Please select your list name",
    "bulk_verifier_initial_list_name_ph":"Enter a name...",
    "bulk_cleansing_initial_list_name":"Bulk Cleansing - {time}",
    "bulk_cleansing_initial_list_name_required":"Please select your list name",
    "bulk_cleansing_initial_list_name_ph":"Enter a name...",
    "bulk_finder_step1": "1. Enter your list of domains or company names",
	"bulk_finder_step2": "2. Set Search Options",
	"bulk_finder_step3": "3. View Results",
    "bulk_cleansing_step1": "1. Enter your list of emails",
    "bulk_verifier_step1": "1. Enter your list of emails",
	"bulk_finder_option_list": "List",
	"bulk_finder_option_file": "File",
	"bulk_finder_form_item_type": "Type of input",
	"bulk_finder_form_item_type_required": "Please select your country!",
	"bulk_finder_form_item_select_domain": "Domain names",
	"bulk_finder_form_item_select_company": "Company names",
	"bulk_finder_form_item_domain_names": "Enter the domain names(one per line)",
	"bulk_finder_form_item_domain_names_required": "Please enter domain names!",
    "bulk_finder_form_item_names_ph":"The number by rows may not be greater than 10,000.",
    "bulk_finder_form_item_domain_names_example":"apple.com&#13;&#10;abb.com&#13;&#10;...",
    "bulk_finder_form_item_company_names":"Enter the company names(one per line)",
    "bulk_finder_form_item_company_names_required":"Please enter company names!",
    "bulk_finder_form_item_company_names_example":"Apple&#13;&#10;ABB&#13;&#10;...",
    "bulk_finder_form_item_emails": "Enter the emails(one per line)",
    "bulk_finder_form_item_domain_emails_required": "Please enter emails!",
    "bulk_finder_form_item_emails_ph":"The number by rows may not be greater than 10,000.",
    "bulk_finder_form_item_phones":"Enter the phones(one per line)",
    "bulk_finder_form_item_phones_required":"Please enter phones!",
    "bulk_finder_form_item_phones_example":"123&#13;&#10;456&#13;&#10;...",
    "bulk_finder_tips":"Maximum accept number of rows <b>10,000</b>.",
    "upload_csv_file": "Upload from file",
    "bulk_finder_options_file_info": "In general, your CSV file should contain at least \"Domain Name\" (recommend) or \"Company Name\". Please see the <a href=\"/data/sample.csv\" target=\"_blank\" ><u>sample file</u></a>.",
    "bulk_upload_intro_sub":"Bulk Finder tool can process up to 10,000 domains/company names per search.",
	"bulk_upload_intro_sub_last":"If you are looking for a tailor-made list of B2B sales leads collected specifically according to your ideal customer profile, we offer a Prospecting Service. Contact us to learn more: <a href=\"mailto:support@arounddeal.com\">support@arounddeal.com</a>",

    "bulk_cleansing_form_item_select_email": "Email",
    "bulk_cleansing_form_item_select_phone": "Phone",
    "bulk_verifier_form_item_select_email": "Email",
    "bulk_verifier_form_item_select_phone": "Phone",
    "bulk_cleansing_form_item_type_required": "Please select your type of input!",
    "bulk_cleansing_options_email_file_info": "In general, your CSV file should contain \"Email\". Please see the <a href=\"/data/sample.email.csv\" target=\"_blank\" ><u>sample file</u></a>.",
    "bulk_cleansing_options_phone_file_info": "In general, your CSV file should contain \"Phone\". Please see the <a href=\"/data/sample.phone.csv\" target=\"_blank\" ><u>sample file</u></a>.",
    "bulk_options_table_title": "File Preview",
	"bulk_options_form_title": "Set Search Options",
	"bulk_form_get_number": "Get",
	"bulk_form_numner_ph": "The count by domain may not be greater than 50.",
	"bulk_form_get_number_extra": "contacts per domain",
	"bulk_form_title": "Filter by Title",
	"bulk_form_title_ph": "e.g. Procurement Manager",
	"bulk_form_function": "Filter by department",
	"bulk_form_function_ph": "e.g. Purchasing",
	"bulk_form_joblevel": "Filter by job level",
	"bulk_form_joblevel_ph": "e.g. Manager",
	"bulk_form_country": "Filter by country",
	"bulk_form_country_ph": "e.g. United States",
	"bulk_form_exclude": "Exclude previously looked up contacts in list.",
	"column_company_name": "Company Name",
	"column_doamin_name": "Domain Name",
    "bulk_filter_by_title":"Filter by title",
	"bulk_filter_by_fn":"Filter by function and seniority level",
	"bulk_filter_by_fn_info":"You will get more results by this option, and this contains roughly 5% of errors.",
	"bulk_filter_label_num":"Set contacts per domain",
    "remove_successfully": "Remove successfully.",
    "bulk_finder_created": "Your bulk task created successfully.",
    "error_file_limit": "File must be smaller than 20MB.",
    "error_upload_file_went_wrong": "The file was loaded in the wrong encoding. Please try another file.",
    "bulk_finder_tool_content_info_1": "1.Any prospects, that you've previously added (viewed contact info) will be excluded from the generated list.",
    "bulk_finder_tool_content_info_2": "2.In any case, AroundDeal will never double charge credits for previous lookups.",
    "bulk_finder": "Bulk Finder",
    "create_a_search": "Create a Search",

	"button_start_search": "Start Search",
	"button_start_verify": "Start Verify",
	"button_start_cleanse": "Start Cleanse",
    "button_upgrade_your_account":"Upgrade Your Account",
    "button_new_bulk":"New Bulk Finder",
    "button_new_bulk_verifier":"New Bulk Verifier",
    "button_new_bulk_cleansing":"New Bulk Cleansing",
    "button_view_tutorial":"View Tutorial",
    "button_add_to_list":"Add to List",
    "button_go_to_list":"Go to List",
};


export default texts;
