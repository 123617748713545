import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Layout, Space } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { CardBasicInfo } from "./components";
import { useFetchRiskifyUserDetail } from "hooks";

const PageRiskifyUserDetails = () => {
    const [loading, setLoading] = useState(false);

    usePageTitle("用户详情 - Riskify插件管理后台");

    const [search] = useSearchParams();
    const id = search.get("uid");

    const [fetchUserDetail] = useFetchRiskifyUserDetail();
    const [userInfo, setUserInfo] = useState(null);
    const [forceFetch, setForceFetch] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetchUserDetail({
            key:id
        },
            {
                success: (data) => {
                    setUserInfo(data);
                    setLoading(false);
                },
                error: (error) => {
                    console.log(error);
                    setLoading(false);
                },
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, search, forceFetch]);

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            <CardBasicInfo loading={loading} userInfo={userInfo} onHandleUpdateInfo={() =>setForceFetch(forceFetch => forceFetch +1)} />
                        </Space>
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageRiskifyUserDetails;
