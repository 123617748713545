const texts = {

    "pp_field_company_industry": "Company Industry",
    "pp_field_company_industry_ph": "Select company industry",
    "pp_field_company_id": "Company ID",
    "pp_field_company_name": "Company Name",
    "pp_field_company_name_ph": "e.g. Apple, apple.com",
    "pp_field_company_employee_range": "Company Employees",
    "pp_field_company_employee_range_ph": "Select company employees",
    "pp_field_company_headcount": "Company Headcount",
    "pp_field_company_headcount_ph": "Select company headcount",
    "pp_field_company_type": "Company Type",
    "pp_field_company_type_ph": "Select company type",
    "pp_field_company_location_hq_country": "Company Headquarters",
    "pp_field_company_location_hq_country_ph": "e.g. United States",
    "pp_field_company_headquarters": "Company Headquarters",
    "pp_field_company_headquarters_ph": "e.g. United States",
    "pp_field_company_website": "Company Website",
    "pp_field_company_website_ph": "e.g. apple.com",
    "pp_field_name": "Contact Name",
    "pp_field_name_ph": "e.g. John Rees",
    "pp_field_is_distributor": "Is Distributor",
    "pp_field_is_distributor_ph": "Select distributor tag",
    "pp_field_add_list": "Add up to 100 ",

    "pp_field_modal_company_industry_ph": "Select company industry",
    "pp_field_modal_company_headquarters_ph": "United States",
    "pp_field_modal_company_headcount_ph": "Select company headcount",
    "pp_field_modal_company_type_ph": "Select company type",
    "pp_field_modal_company_specialties_ph": "Adobe\nAWS\nCoffee\nGlass\n",
    "pp_field_modal_company_name_ph": "Amazon\nIBM\napple.com\nsalesforce.com",
    "pp_field_modal_company_distributor_ph": "Select distributor tag",

    "pp_field_modal_location_ph": "United States",
    "pp_field_modal_function_ph": "Select function",
    "pp_field_modal_joblevel_ph": "Select level of seniority",
    "pp_field_modal_contact_ph": "Select contact detail",
    "pp_field_modal_skills_ph": "Supply Chain\nProgram Management",

    
    "pp_field_location_hq_country": "Contact Location",
    "pp_field_location_hq_country_ph": "e.g. United States",
    "pp_field_function": "Function",
    "pp_field_function_ph": "Select function",
    "pp_field_joblevel": "Seniority Level",
    "pp_field_joblevel_ph": "Select level of seniority",
    "pp_field_title": "Job Title",
    "pp_field_title_ph": "e.g. CEO",
    "pp_field_contact": "Contact Must Contain",
    "pp_field_contact_ph": "Select contact detail",
    "pp_field_linkedin_url": "Contact LinkedIn URL",
    "pp_field_linkedin_url_ph": "e.g. https://www.linkedin.com/in/arounddeal/",
    
    "pc_field_industry": "Industry",
    "pc_field_industry_ph": "Select industry",
    "pc_field_name": "Name",
    "pc_field_name_ph": "e.g. Apple",
    "pc_field_location_hq_country": "Headquarters",
    "pc_field_location_hq_country_ph": "e.g. United States",
    "pc_field_employee_range": "Headcount",
    "pc_field_employee_range_ph": "Select headcount",
    "pc_field_type": "Type",
    "pc_field_type_ph": "Select company type",
    "pp_field_company_specialty": "Company Keywords",
    "pp_field_specialty": "Keywords",
    "pp_field_company_specialty_ph": "e.g. AWS, Adobe, Glass, Coffee",
    "pp_field_specialty_ph": "e.g. AWS, Adobe, Glass, Coffee",
    
    "pp_field_skill": "Contact Skills",
    "pp_field_skill_ph": "e.g. Supply Chain, Program Management",
    "pc_field_keyword": "Keywords",
    "pc_field_keyword_ph": "Enter keywords",
    "pc_field_website_home_url": "Company Website",
    "pc_field_website": "Website",
    "pc_field_website_home_url_ph": "e.g. apple.com",
    "pc_field_linkedin_url": "LinkedIn URL",
    "pc_field_linkedin_url_ph": "e.g. https://www.linkedin.com/company/arounddeal/",
};

export default texts;
