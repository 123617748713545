import React, { useState } from "react";
import { Button, Card, Descriptions, Row, Spin, Table, Tag } from "antd";
import { getDateCN } from "tools";
import { ModalSetQuota } from "./ModalSetQuota";
export const CardBasicInfo = ({ loading, id, type, userInfo, listCombines, onHandleUpdateInfo }) => {
	const { key, name, category, time_create, time_update, time_expired, quota } = userInfo || {};
	const [openSetQuota, setOpenSetQuota] = useState(false);

	const dataSource = [
		{ key: "Key", value: key },
		{ key: "name", value: name },
		{ key: "category", value: category },
		{ key: "time_create", value: time_create ? getDateCN(time_create) : null },
		{ key: "time_expired", value: time_expired ? getDateCN(time_expired) : null },
		{ key: "time_update", value: time_update ? getDateCN(time_update) : null }
	];

	const formatData = dataSource.map((item) => {
		return {
			...item,
			value: item.value || "",
		};
	});

	const columnsQuota = [
		{
			title: "Key",
			dataIndex: "key",
		},
		{
			title: "Type",
			dataIndex: "type",
		},
		{
			title: "Quota",
			dataIndex: "quota",
		},
	];

	const columnsCombines = [
		{
			title: "id",
			dataIndex: "id",
		},
		{
			title: "pa",
			dataIndex: "pa",
		},
		{
			title: "pb",
			dataIndex: "pb",
		},
		{
			title: "pc",
			dataIndex: "pc",
		},
		{
			title: "weight",
			dataIndex: "weight",
		},
	];

	return (
		<>
			<Row align="middle" justify="space-between" style={{ marginBottom: "25px" }}>
				<div>
					<h2>
						package详情 - {!name ? <Spin /> : name}
					</h2>
				</div>
				<Button type="primary" onClick={() => {setOpenSetQuota(true)}}>设置额度</Button>
			</Row>
			<Card title="基础信息" loading={loading} style={{ marginBottom: 20 }}>
				<Descriptions
					bordered
					layout="horizontal"
					labelStyle={{ fontWeight: "bold" }}
				>
					{formatData.map((item, index) => (
						<Descriptions.Item label={item.key} key={index}>
							{item.value}
						</Descriptions.Item>
					))}
				</Descriptions>
			</Card>
			<Card title="quota信息" loading={loading} style={{ marginBottom: 20 }}>
				<Table
					dataSource={quota}
					columns={columnsQuota}
					pagination={false}
				/>
			</Card>
			<Card title="package组合规则" loading={loading}>
				<Table
					dataSource={listCombines}
					columns={columnsCombines}
					pagination={false}
					rowKey={record => record.id}
				/>
			</Card>
			{
				openSetQuota ? 
					<ModalSetQuota
						open={openSetQuota}
						currentApp={type} 
						currentKey={id}
						onCancel={() => setOpenSetQuota(false)}
						onHandleUpdateInfo={onHandleUpdateInfo}
					/>:null
			}
		</>
	);
};
