import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Skeleton } from "antd";
import { useFetchGetRiskifyPackageList } from "hooks";
import { langError, getDateCN } from "tools";

export const TablePackageList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);
	
	const [fetchGetRiskifyPackageList] = useFetchGetRiskifyPackageList();

	const columns = [
		{
			title: "类别",
			dataIndex: "category",
		},
		{
			title: "标识",
			dataIndex: "key",
		},
		{
			title: "Packgae Name",
			dataIndex: "name",
		},
		{
			title: "创建时间",
			dataIndex: "time_create",
			render:(_,record) =>(
				<span>{getDateCN(record.time_create)}</span>
			)
		}
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetRiskifyPackageList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(data.list);
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<div className="cursor">
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							{/* <Button type="primary">操作</Button> */}
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						onRow={(record, index) => {
							return {
								onClick: event => {
									window.open(`/bem/package/detail?uid=${record.key}&type=insight`);
								},
							};
						}}
					/>
				</div>
			)}
		</Fragment>
	);
};
