import React from "react";
import Chart from "react-apexcharts";

export const ChartRegisteredUser = ({
  registerUser = {},
  activeRegister = {},
  noActiveRegister = {},
}) => {
  const data = [
    { name: "注册人数", data: registerUser?.values || [] },
    { name: "已激活", data: activeRegister?.values || [] },
    { name: "未激活", data: noActiveRegister.values || [] },
  ];

  let options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },

    dataLabels: {
      enabled: false,
    },

    stroke: {
      width: [5, 7, 5],
      curve: "straight",
      dashArray: [0, 8, 5],
    },

    title: {
      text: "每日新增注册用户",
      align: "center",
    },

    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },

    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },

    xaxis: {
      categories: registerUser?.keys || [],
    },

    tooltip: {
      y: [
        {
          title: {
            formatter: (val) => val,
          },
        },
        {
          title: {
            formatter: (val) => val,
          },
        },
        {
          title: {
            formatter: (val) => val,
          },
        },
      ],
    },

    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <Chart
      options={options}
      series={data}
      type="line"
      width="100%"
      height="350"
    />
  );
};
