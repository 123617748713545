import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout, App, Breadcrumb, Form, Input, Row, Col, Button, Skeleton, Popover, Tooltip } from "antd";
import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    LayoutAuthRequired,
    LayoutHeaderNav,
} from "components";
import { LayoutPageSidebar } from "../components";
import { useFetchEDMCoverSave, useFetchEDMCoverDetail } from "hooks";
import usePageTitle from "hooks/usePageTitle";
import { langError } from "tools";
import { LayoutPageContentWithBreadcrumb } from "pages/ms/components";
import { useSelector } from "react-redux";

Quill.register('modules/imageResize', ImageResize);
Quill.register(Quill.import("attributors/style/align"), true);

// const Size = Quill.import('attributors/style/size');
// Size.whitelist = ['12', '14', '16', '18'];
// Quill.register(Size, true);

var Font = Quill.import('formats/font');
Font.whitelist = ['sansserif', 'serif', 'monospace', 'arial', 'gillsans', 'roboto', 'comic', 'verdana', 'franklin', 'impact'];
Quill.register(Font, true);

// var FontStyle = Quill.import('attributors/style/font');
// Quill.register(FontStyle, true);

var Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

const PageEDMCover = () => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const user = useSelector((state) => state.user);
    const { id } = user?.info;
    let [searchParams] = useSearchParams();
    const filterID = searchParams.get('id');
    const quillRef = useRef();

    const [bodyValue, setBodyValue] = useState('');
    const [showError, setShowError] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    // const [initialData, setInitialData] = useState({});
    const [loadingContent, setLoadingContent] = useState(false);

    usePageTitle("编辑Cover - EDM管理后台");

    const navigate = useNavigate();

    const [fetchSaveCover] = useFetchEDMCoverSave();
    const [fetchGetCover] = useFetchEDMCoverDetail();

    useEffect(() => {
        if (id && filterID) {
            setLoadingContent(true);
            fetchGetCover({ id: filterID }, {
                success: (data) => {
                    setLoadingContent(false);
                    form.setFieldsValue({ name: data.name });
                    setBodyValue(data && data.data && data.data.html);
                },
                error: (error) => {
                    setLoadingContent(false);
                    notification.error({ message: langError(error.code) });
                },
            });
        }
    }, [id, filterID]);

    const breadcrumbItems = [
        {
            title: <a href="/edm/cover">Cover 列表</a>,
        },
        {
            title: '编辑Cover',
        },
    ];

    const ImageUploader = () => {
        const [imageUrl, setImageUrl] = useState('');
        const [openPopover, setOpenPopover] = useState(false);

        const handleImageUpload = async () => {
            // 模拟图片上传过程并获取图片URL
            const imageUrl_ = await simulateImageUpload();

            // 获取编辑器实例
            const editor = quillRef.current.getEditor();

            // 确保编辑器有焦点
            editor.focus();

            // 获取当前光标位置
            let range = editor.getSelection();
            if (!range) {
                // 如果光标位置为null，手动设置为编辑器内容末尾
                range = { index: editor.getLength(), length: 0 };
            }

            // 插入图片到当前光标位置
            editor.insertEmbed(range.index, 'image', imageUrl_);

            // 调整光标位置
            editor.setSelection(range.index + 1);

            // 重新聚焦编辑器
            editor.focus();
        };

        const simulateImageUpload = async () => {
            // 模拟上传过程
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(imageUrl);
                }, 1000);
            });
        };

        const ImageUploaderContent = () => (
            <div>
                <Input
                    placeholder="请输入图片地址"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                />
                <div style={{ textAlign: "right", marginTop: 10 }}>
                    <Button size="small" type="primary" onClick={handleImageUpload}>确定</Button>
                </div>
            </div>
        )

        const handleOpenChange = (newOpen) => {
            setOpenPopover(newOpen);
        };

        return (
            <span className="iconfont">
                <Popover open={openPopover} onOpenChange={handleOpenChange} content={<ImageUploaderContent />} trigger="click">
                    <Tooltip title={"上传图片URL"}>
                        <UploadOutlined style={{ fontSize: 16 }} />
                    </Tooltip>
                </Popover>
            </span>
        );
    };

    const CustomToolbar = useCallback(() => (
        <div id="toolbar">
            <select
                className="ql-header"
                defaultValue={''}
                onChange={(e) => e.persist()}
            >
                <option value="1"></option>
                <option value="2"></option>
                <option value="3"></option>
                <option value="4"></option>
                <option value="5"></option>
                <option value="6"></option>
                <option selected></option>
            </select>
            {/* <select className="ql-size">
                <option value="12">12px</option>
                <option value="14">14px</option>
                <option value="16">16px</option>
                <option value="18">18px</option>
            </select> */}
            <select className="ql-font">
                <option value="sansserif" selected>Sans Serif</option>
                <option value="serif">Serif</option>
                <option value="monospace">Monospace</option>
                <option value="arial">Arial</option>
                <option value="gillsans">Gill Sans</option>
                <option value="roboto">Roboto</option>
                <option value="comic">Comic</option>
                <option value="verdana">Verdana</option>
                <option value="franklin">Franklin</option>
                <option value="impact">Impact</option>
            </select>
            <select className="ql-align">
                <option value="" selected></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
            </select>
            <button className="ql-underline"></button>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-strike" />
            <button className="ql-header" value="1" />
            <button className="ql-header" value="2" />
            <button className="ql-blockquote" />
            <select className="ql-color">
                <option value="rgb(0, 0, 0)"></option>
                <option value="rgb(230, 0, 0)"></option>
                <option value="rgb(255, 153, 0)"></option>
                <option value="rgb(255, 255, 0)"></option>
                <option value="rgb(0, 138, 0)"></option>
                <option value="rgb(0, 102, 204)"></option>
                <option value="rgb(153, 51, 255)"></option>
                <option value="rgb(255, 255, 255)"></option>
                <option value="rgb(250, 204, 204)"></option>
                <option value="rgb(255, 235, 204)"></option>
                <option value="rgb(255, 255, 204)"></option>
                <option value="rgb(204, 232, 204)"></option>
                <option value="rgb(204, 224, 245)"></option>
                <option value="rgb(235, 214, 255)"></option>
                <option value="rgb(187, 187, 187)"></option>
                <option value="rgb(240, 102, 102)"></option>
                <option value="rgb(255, 194, 102)"></option>
                <option value="rgb(255, 255, 102)"></option>
                <option value="rgb(102, 185, 102)"></option>
                <option value="rgb(102, 163, 224)"></option>
                <option value="rgb(194, 133, 255)"></option>
                <option value="rgb(136, 136, 136)"></option>
                <option value="rgb(161, 0, 0)"></option>
                <option value="rgb(178, 107, 0)"></option>
                <option value="rgb(178, 178, 0)"></option>
                <option value="rgb(0, 97, 0)"></option>
                <option value="rgb(0, 71, 178)"></option>
                <option value="rgb(107, 36, 178)"></option>
                <option value="rgb(68, 68, 68)"></option>
                <option value="rgb(92, 0, 0)"></option>
                <option value="rgb(102, 61, 0)"></option>
                <option value="rgb(102, 102, 0)"></option>
                <option value="rgb(0, 55, 0)"></option>
                <option value="rgb(0, 41, 102)"></option>
                <option value="rgb(61, 20, 102)"></option>
            </select>
            <select className="ql-background">
                <option value="rgb(0, 0, 0)"></option>
                <option value="rgb(230, 0, 0)"></option>
                <option value="rgb(255, 153, 0)"></option>
                <option value="rgb(255, 255, 0)"></option>
                <option value="rgb(0, 138, 0)"></option>
                <option value="rgb(0, 102, 204)"></option>
                <option value="rgb(153, 51, 255)"></option>
                <option value="rgb(255, 255, 255)"></option>
                <option value="rgb(250, 204, 204)"></option>
                <option value="rgb(255, 235, 204)"></option>
                <option value="rgb(255, 255, 204)"></option>
                <option value="rgb(204, 232, 204)"></option>
                <option value="rgb(204, 224, 245)"></option>
                <option value="rgb(235, 214, 255)"></option>
                <option value="rgb(187, 187, 187)"></option>
                <option value="rgb(240, 102, 102)"></option>
                <option value="rgb(255, 194, 102)"></option>
                <option value="rgb(255, 255, 102)"></option>
                <option value="rgb(102, 185, 102)"></option>
                <option value="rgb(102, 163, 224)"></option>
                <option value="rgb(194, 133, 255)"></option>
                <option value="rgb(136, 136, 136)"></option>
                <option value="rgb(161, 0, 0)"></option>
                <option value="rgb(178, 107, 0)"></option>
                <option value="rgb(178, 178, 0)"></option>
                <option value="rgb(0, 97, 0)"></option>
                <option value="rgb(0, 71, 178)"></option>
                <option value="rgb(107, 36, 178)"></option>
                <option value="rgb(68, 68, 68)"></option>
                <option value="rgb(92, 0, 0)"></option>
                <option value="rgb(102, 61, 0)"></option>
                <option value="rgb(102, 102, 0)"></option>
                <option value="rgb(0, 55, 0)"></option>
                <option value="rgb(0, 41, 102)"></option>
                <option value="rgb(61, 20, 102)"></option>
            </select>
            {/* <button className="ql-image" /> */}
            <button className="ql-code-block" />
            <button className="ql-link" />
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-image" value="image" />
            <button className="ql-insertStar" style={{ padding: 0 }}>
                <ImageUploader />
            </button>
        </div>
    ), []);

    const modules = {
        toolbar: {
            container: '#toolbar'
        },
        imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }
    };

    const handleChangeBodyValue = (value) => {
        setBodyValue(value);
        if (!value || value === '<div><br></div>') {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }

    const onFinish = (values) => {
        let formData = {};
        formData.name = values.name;
        formData.type = "html";
        formData.html = bodyValue;
        if (!bodyValue) {
            setShowError(true);
        } else {
            setSaveLoading(true);
            if (filterID) {
                //编辑情况
                formData.id_cover = filterID;
                fetchSaveCover({ ...formData }, {
                    success: (data) => {
                        setSaveLoading(false);
                        notification.success({ message: "保存成功！" });
                        navigate("/edm/cover");
                    },
                    error: (error) => {
                        setSaveLoading(false);
                        notification.error({ message: langError(error.code) });
                    },
                });
            } else {
                //创建情况
                fetchSaveCover({ ...formData }, {
                    success: (data) => {
                        setSaveLoading(false);
                        notification.success({ message: "创建成功！" });
                        navigate("/edm/cover");
                    },
                    error: (error) => {
                        setSaveLoading(false);
                        notification.error({ message: langError(error.code) });
                    },
                });
            }
        }
    }

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar selectedKey={["edm/cover"]} />
                    <LayoutPageContentWithBreadcrumb
                        breadcrumb={<Breadcrumb items={breadcrumbItems} />}
                        content={
                            (id && !loadingContent) ?
                                <Row>
                                    <Col span={12}>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            onFinish={onFinish}
                                        >
                                            <Form.Item
                                                name="name"
                                                label="Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            <Form.Item
                                                name="body"
                                                label={<div><span style={{ color: "#ff6b72", marginRight: 5 }}>*</span>Body</div>}
                                            >
                                                <div className="text-editor notranslate">
                                                    <ReactQuill
                                                        modules={modules}
                                                        value={bodyValue}
                                                        id="container"
                                                        ref={quillRef}
                                                        onChange={handleChangeBodyValue}
                                                        theme="snow"
                                                    />
                                                    <CustomToolbar />
                                                    {
                                                        showError ?
                                                            <div style={{ color: "red", marginTop: 10 }}>
                                                                请输入内容！
                                                            </div> : null
                                                    }
                                                </div>
                                            </Form.Item>
                                            <Form.Item
                                            >
                                                <Button loading={saveLoading} icon={<SaveOutlined />} type="primary" htmlType="submit">
                                                    保存
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row> : <Skeleton active />
                        }
                    >
                    </LayoutPageContentWithBreadcrumb>
                </Layout>
            </Layout>
        </LayoutAuthRequired >
    );
};
export default PageEDMCover;
