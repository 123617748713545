import React from "react";
import Chart from "react-apexcharts";

export const ChartRegisterWayAnalyse = ({ userEmailSource = {} }) => {
  let data = [];
  for (let key in userEmailSource.values) {
    data.push({ name: key, data: userEmailSource.values[key] });
  }

  let options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },
    title: {
      text: "注册方式分析",
      align: "center",
    },
    xaxis: {
      categories: userEmailSource?.keys || [],
    },
    legend: {
      position: "bottom",
      offsetY: 0,
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <Chart
      options={options}
      series={data}
      type="bar"
      width="100%"
      height="350"
    />
  );
};
