
import { Layout } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav, LayoutNavForms } from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableApiKeyList } from "./components";
import usePageTitle from "hooks/usePageTitle";
import { Fragment, useState } from "react";

const PageMsApiKey = () => {
	const [filterData, setFilterData] = useState({});
	usePageTitle("Api Key列表 - AroundDeal管理后台");

	const inputOptions = [{ key: "email", placeholder: "搜索Email" }];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/apikey"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableApiKeyList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsApiKey;
