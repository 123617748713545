import React, { useState } from 'react';
import { Modal, Form, Button, App, Select } from 'antd';
import { consoleLog, langError } from 'tools';
import { useFetchRiskifyFeedbackSetting } from "hooks";

export const ModalSettings = ({ open, currentRow, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

    const [fetchSave] = useFetchRiskifyFeedbackSetting();

    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                status: values.status,
                id: currentRow.id,
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"操作成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }
    return (
        <Modal
            destroyOnClose
            open={open}
            title={"设置Feedback状态"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="status"
                    // label={"状态"}
                >
                   <Select placeholder="请选择状态">
                        <Select.Option value="1">等待回复</Select.Option>
                        <Select.Option value="2">已回复</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
