import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Input, App, Skeleton } from "antd";
import { useFetchDataConfigSet, useFetchGetDataConfigDetail } from "hooks";
import { langError } from "tools";
import { useSelector } from "react-redux";

export const ModalSetConfig = ({ open, currentKey, type, sid, onCancel }) => {
	const { notification } = App.useApp();
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const [fetchCreate] = useFetchDataConfigSet();
	const [fetchDetail] = useFetchGetDataConfigDetail();

	const [form] = Form.useForm();

	useEffect(() => {
		if (id) {
			setLoading(true);
			fetchDetail({ type, sid, key: currentKey }, {
				success: (data) => {
					setLoading(false);
					if(data.length>0){
						form.setFieldsValue({ value: data.join('\n') });
					}
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
	}, [id]);


	const handleCreate = () => {
		form
			.validateFields()
			.then((values) => {
				values.value = values.value.split('\n');
				values.key = currentKey;
				values.type = type;
				values.sid = sid;
				setLoadingBtn(true);
				fetchCreate(values, {
					success: (data) => {
						setLoadingBtn(false);
						onCancel();
						notification.success({ message: "设置成功！" });
					},
					error: (error) => {
						setLoadingBtn(false);
						onCancel();
						notification.error({ message: langError(error.code) });
					},
				});
			})
			.catch((info) => { });
	};

	return (
		<Modal
			title={currentKey === "match_keywords" ? "设置matches" : "设置catches"}
			width={500}
			open={open}
			onCancel={onCancel}
			destroyOnClose={true}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					取消
				</Button>,
				<Button
					key="ok"
					type="primary"
					loading={loadingBtn}
					onClick={handleCreate}
				>
					确定
				</Button>,
			]}
		>
			{
				loading ? <Skeleton active /> :
					<Form
						name="basic"
						form={form}
						layout="vertical"
					>
						<Form.Item
							label="Value"
							name="value"
							style={{ margin: "10px 0" }}
							rules={[
								{
									required: true,
									message: "请填写value",
								},
							]}
						>
							<Input.TextArea rows={10} />
						</Form.Item>
					</Form>
			}
		</Modal>
	);
};
