import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutSidebar,
} from "components";
import { TableAppList } from "./components";

import usePageTitle from "hooks/usePageTitle";
import { LayoutPageContent } from "components/layout/LayoutPageContent";

const PageApp = () => {

	usePageTitle("App列表 - ADMIN");

	const inputOptions = [
		{ id: 0, tip: "搜索用户名" },
		{ id: 1, tip: "搜索标识" },
	];

	const selectOptions = [
		{
			id: 0,
			tip: "选择账号类型",
			options: [
				{ value: "Free", label: "Free" },
				{ value: "Lite", label: "Lite" },
				{ value: "Plus", label: "Plus" },
				{ value: "Pro", label: "Pro" },
				{ value: "Enterprise", label: "Enterprise" },
				{ value: "Basic", label: "Basic" },
				{ value: "Premium", label: "Premium" },
			],
		},
	];

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutSidebar selectedKey={["app"]} />
					<LayoutPageContent>
						<TableAppList />
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};

export default PageApp;
