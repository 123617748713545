
const texts = {
    "info_page_title_default": "内部系统",

    "label_arounddeal_logo": "AroundDeal",
    "label_login_welcome_back": "Welcome Back!",
    "info_login_have_no_account": "Don't have an account yet?",
    "button_start_free_trial": "Start Your Free Trial",
    "button_login_with_google": "Login with Google",
    "button_login_with_microsoft": "Login with Microsoft",
    "label_login": "Login",         

    "label_tag_new": "New",

    "info_login_review_1_content": "\"Complete and accurate info about possible leads\"",
    "info_login_review_1_auther": "Anca G.",
    "info_login_review_2_content": "\"We love AroundDeal and we are surprised how it does what it says.\"",
    "info_login_review_2_auther": "Ibrahim A.",
    "info_login_review_3_content": "\"Very easy to use and accurate. Time-saving app for finding contacts.\"",
    "info_login_review_3_auther": "Marko Z.",
    "label_email_address": "Email Address",
    "label_password": "Password",
    
    "label_login_review_awards": "AroundDeal Awards",


    "button_login": "Login", 
    "button_added": "Added", 
    "button_forget_password": "Forget Password",
    "button_submit": "Submit",
    "button_save_changes": "Save Changes",

    // Forget Password
    "label_forget_password":"Forget Password",
    "label_forget_password_title": "Forget Password",
    "label_forget_password_subtitle": "Enter your email to reset password.",
    "label_resent_password_title": "Password Reset Email Sent",
    "label_resent_password_subtitle": "We just sent an email to {email}. Check the inbox and click the reset link provided.",
    "label_wrong_email_address_reenter": "Wrong email address? Please <a href=\"/forget-password\">re-enter your email address</a>.",
    "button_sending":"sending",
    "button_resend_email":"Resend Email",

    // Reset Password
    "label_reset_password":"Reset Password",
    "label_reset_password_title":"Password Reset",
    "label_reset_password_subtitle":"Enter your new password for your AroundDeal account.",
    "label_confirm_new_password":"Confirm New Password",
    "label_reset_password_successfully": "Password reset successfully. Please login again.",
    "button_change_my_password":"Change My Password",

    "label_register": "Register",
    "label_register_title": "Start Your Free Trial",
    "info_already_have_account": "Already have an account?",
    "button_register_with_google": "Sign Up with Google",
    "button_register_with_microsoft": "Sign Up with Microsoft",
    "button_reister": "Sign Up",
    "info_register_agree": "By creating an AroundDeal account, you agree to our <a target=\"_blank\" rel=\"noreferrer\" href=\"https://www.arounddeal.com/terms/\">terms of service</a> and <a target=\"_blank\" rel=\"noreferrer\" href=\"https://www.arounddeal.com/privacy-center/privacy-policy/\">privacy policy</a>.",

    "label_register_right_title": "Find targeted leads now",
    "info_register_right_1": "Unlimited people & company search",
    "info_register_right_2": "Get access to 100M+ contacts",
    "info_register_right_3": "Easy to export contacts to csv",
    "info_register_right_4": "Enrich your list with 50+ fields",




    "label_logo_alt": "AroundDeal Logo",
    "label_logo_alt_logo": "Logo",
    "button_upgrade": "Upgrade",
    "button_change_your_plan": "Change Your Plan",
    "button_knowledge_base": "Knowledge Base",
    "button_submit_request": "Submit a Request",
    "button_account": "Account",
    "button_team": "Team",
    "button_get_free_credits": "Get Free Credits",
    "button_sign_out": "Sign Out",
    "nav_home": "Home",
    "nav_prospector": "Prospector",
    "nav_bulks": "Bulks",
    "nav_enrichment": "Enrichment",
    "nav_lists": "Lists",
    "nav_people": "People",
    "nav_companies": "Companies",
    "nav_api": "API",
    "nav_alert": "Alerts",
    "nav_team": "Team",
    "nav_team_people_list": "Team People Lists",
    "nav_team_company_list": "Team Company Lists",
    "nav_member": "Members",
    "nav_role": "Role",
    "nav_dashboard": "Dashboard",
    "nav_engage": "Engage",
    "nav_engage_sequence": "Sequences",
    "nav_engage_emails": "Emails",
    "nav_engage_tasks": "Tasks",
    "nav_engage_templates": "Templates",
    "nav_engage_analytics": "Analytics",
    "nav_engage_settings": "Settings & Mailboxes",
    "button_edit_tag": "Edit Tag",

    
    "nav_visitor": "Visitors",
    "nav_visitor_analytics": "Analytics",
    "nav_visitor_management": "Management",

    "button_yes": "Yes",
    "button_no": "No",
    "button_cancel": "Cancel",
    "button_clear": "Clear",
    "button_clear_all": "Clear All",
    "button_save_search": "Save Search",
    "button_load_search": "Load Search",

    "label_see_how_works": "See how it works?",
    "button_recent_searches": "Recent Searches",
    "button_saved_searches": "Saved Searches",

    //== Saved Search ====
    "label_saved_searches": "Saved Searches",
    "label_saved_search_title": "Saved Search",
    "button_save": "Save",
    "label_search_rule_name": "Search Rule Name",
    "info_success_prospect_search_saved": "Search saved successfully.",
    "info_success_prospect_feedback": "Thanks for your feedback!",

    "label_website":"Website",
    "label_specialty":"Specialty",
    "label_specialties":"Specialties",
    "label_overview":"Overview",
    "label_social":"Social",

    //== Recent Search ====
    "label_recent_searches": "Recent Searches",
    "info_recent_search_no_result_title": "Resume your recent prospecting activity",
    "info_recent_search_no_result_content": "Start a new search using filters, and your activity<br/> will be saved here for the next time you'll need it.",

    "info_saved_search_no_result_title": "Pickup where you left off with saved searches",
    "info_saved_search_no_result_content": "To save a search, apply your filters, then click on <strong class='text-primary'>“Save Search”</strong>. Save as many searches as you need to find them here later on",

    //== Home ====
    "label_home": "Home",

    //== Prospector ====
    "label_prospector": "Prospector",
    "label_prospector_filters": "Filters",
    "label_prospector_filters_company": "COMPANY",
    "label_prospector_filters_people": "PEOPLE",

    "info_locked_confirm_title": "Upgrade Your Plan and Use {text} Filters",
    "info_locked_confirm_content": "Find and get your targeted company details instantly.",
    "info_locked_confirm_content_2": "Get exclusive company data with laser focused searches.",
    "info_locked_confirm_content_3": "Get exclusive contact data with laser focused searches.",
    "button_upgrade_now": "Upgrade Now",
    "button_upgrade_to_lite": "Upgrade to Plus+",
    "button_upgrade_to_premium": "Upgrade to Premium+",
    "button_upgrade_to_premium_trial": "Start a Premium Trial",
    "button_upgrade_to_basic": "Upgrade to Basic+",
    "button_get_started": "Get Started",

    "info_prospect_people_no_result_title": "Sorry, Contacts Not Found",
    "info_prospect_people_no_result_content": "We couldn't find results matching your search. Please try refining your search or clearing some of your filters.",
    "info_prospect_people_no_result_content_laws": "According to relevant laws, regulations and policies, these search results cannot be shown.",
    "info_prospect_gdpr_hide": "EU contacts hidden from search results.",
    "button_clear_all_filters": "Clear All Filters",
    "button_change_settings": "Change Settings",

    "info_prospect_company_no_result_title": "Sorry, Companies Not Found",
    "info_prospect_company_no_result_content": "We couldn't find results matching your search. Please try refining your search or clearing some of your filters.",

    "label_getting_started": "Getting Started",
    "label_show_and_add_tooltip_title": "Show contact details and save to list. It will cost 1 credit.",
    "info_prospect_people_getting_started": "Add filters to begin your search or try an example search.",
    "label_try_some_examples": "Try some examples",
    "button_hide": "Hide",
    "button_show": "Show",
    "button_try_it": "Try it",
    "button_show_and_add": "Show and Add",
    "button_save_contact": "Save Contact",
    "button_save_contacts": "Save Contacts",
    "button_save_selected": "Save Selected",
    "button_save_all": "Save All",
    "button_show_all_contacts": "Show All Contacts",
    "label_selected_count_items": "{count} Selected",
    "label_selected_count_items_0": "0 Selected",
    "button_contact_not_found":"Contact Not Found",
    "button_find_employees":"Find Employees",

    "label_prospector_people_time_to_start_title": "Time to Start Finding Targeted Contacts!",
    "label_prospector_company_time_to_start_title": "Time to Start Finding Targeted Companies!",
    "info_prospector_people_time_to_start_title": "Start your search by applying any filter in the left panel",
    "info_prospector_company_time_to_start_title": "Start your search by applying any filter in the left panel",

    "info_saved_search_try": "Select your filters and create a saved search.",
    "info_recent_search_try": "Select your filters and create a saved search.",

    "info_arrow_guider": "Add filters to <br />begin your search.",

    "label_add_to_people_list_title": "Show Contact Details and Add to a People List",
    "label_title_save_contacts_in": "Save Contacts in...",
    "label_title_save_all_contacts_in": "Save <b class='text-primary'>{count}</b> Contacts in...",
    "label_title_saving_contacts": "Saving Contacts...",
    "label_add_to_company_list_title": "Add to a Company List",
    "alert_prospector_free_trial_1": "Need more leads? Upgrade now to reach your entire market.",
    "alert_prospector_free_trial_2": "Need more leads? Start your free trial today to reach your entire market.",
    "alert_prospector_free_trial_3": "Need more leads? Skip trial and purchase now to prospect faster.",
    "button_view_plans": "View Plans",

    "info_sucess_prospect_added_to_list": "{count} prospect(s) added successfully.",
    "info_sucess_prospect_showed": "{count} contact(s) showed successfully.",
	"info_sucess_company_added_to_list": "{count} company(s) added to list successfully.",

    "info_warning_credit_insufficient_title":"Insufficient credits.",
    "info_warning_credit_insufficient":"Insufficient credits. Please contact sales to buy more credits.",
    "info_warning_credit_insufficient_content":"This prospector will cost {credits}. You need upgrade to perform it.",

    "info_success_prospector_save_all":"We have successfully enqueued your request. We will notify you once it is done.",
    "info_error_prospects_lookup_max_number": "The number of prospects may not be greater than 20,000.",

    "info_modal_title":"Add up to 100 ",
    "info_modal_subscribe_1":"Bulk search ",
    "info_modal_subscribe_2":" from our database.",
    "info_modal_textarea_ph_1":"Paste ",
    "info_modal_textarea_ph_2":" here, with one keyword per row. For example,",
    "button_clear_list":"Clear list",
    "button_search":"Search",

    //== Lists ====
    "label_my_lists": "My Lists",
    "label_my_lists_purchased": "Purchased List",
    "button_purchased_list": "Purchased Contacts",
    "button_purchased": "View all purchased contacts",
    "label_title_purchased_contacts": "Purchased Contacts",
    "label_list_col_name": "Name",
    "label_list_col_contacts": "Contacts",
    "label_list_col_created_date": "Created Date",
    "label_list_col_last_updated": "Last Updated",
    "label_list_col_companys": "Companies",
    "button_create_new_list": "New List",
    "label_modal_create_list": "Create a New List",
    "label_modal_create_company_list": "Create a New Company List",
    "label_view_in": "View in ...",
    "label_view": "View",
    "button_list_rename": "Rename List",
    "button_list_delete": "Delete List",
    "info_list_empty": "No list now.",
    "info_confirm_list_delete": "Are you sure you want to delete this list?",
    "label_modal_rename_list": "Rename List",
    "button_rename_list": "Rename List",
    "button_rename": "Rename",
    "info_success_list_renamed": "List renamed successfully.",
	"info_success_list_deleted": "List deleted successfully.",
	"info_success_list_created": "List created successfully.",
	"info_success_sequence_created": "Sequence created successfully.",
	"info_success_sequence_contact_added": "Contacts added successfully.",
	"info_success_engate_template_created": "Engage Template created successfully.",
	"info_success_engate_template_edit": "Engage Template edited successfully.",
	"info_success_email_message_created": "Email Message created successfully.",
	"info_success_email_message_edit": "Email Message edited successfully.",

    "label_list_people_detail_title":"No Contacts Yet",
    "label_list_company_detail_title":"No Companies Yet",
    "label_list_people_detail_subtitle":"Start adding contacts to this list",
    "label_list_company_detail_subtitle":"Start adding companies to this list",
    "button_start_prospecting":"Start Prospecting",
    "label_results":"Results",
    "label_result":"Result",
    "label_over_million": "Over 1 Million",
    "button_export_all":"Export All",
    "button_delete":"Delete",
    "button_move_to_list": "Move to List",
    "button_export_selected": "Export Selected",
    "label_list_detail_title": "List - People - #{key}",
	"label_company_list_detail_title": "List - Company - #{key}",
	"label_job_title": "Job Title",
	"label_company": "Company",
    "label_list": "List",
	"label_location": "Location",
	"label_business_email": "Business Email",
	"label_business": "Business",
	"label_direct": "Direct",
	"label_verification_code": "Verification Code",
	"label_personal_email": "Personal Email",
    "label_founded_year":"Founded Year",
	"label_tel": "Tel",
    "label_employee":"Employee",
    "label_department":"Department",
	"label_headquarter":"Headquarter",
	"label_mobile": "Mobile",
	"label_industry": "Industry",
	"label_date": "Date",
	"label_date_added": "Date Added",
	"label_export_free_title": "Export Contacts is an advanced feature",
	"label_export_free_subtitle": "Export your contacts from AroundDeal Lists to a CSV file",
    "info_success_contact_exported": "Prospect(s) exported successfully.",
    "info_success_company_exported": "Company exported successfully.",
    "info_success_contact_selected_exported": "Prospect(s) selected exported successfully.",
    "info_success_company_selected_exported": "Company selected exported successfully.",
    "info_success_contact_moved": "Prospect(s) moved successfully.",
	"info_success_company_moved": "Company moved successfully.",
    "info_confirm_list_contact_delete": "Are you sure you want to delete this prospect?",
	"info_confirm_list_company_delete": "Are you sure you want to delete this company?",
    "info_success_contact_deleted": "Prospect(s) deleted successfully.",
	"info_success_company_deleted": "Company deleted successfully.",
    "label_delete_saved_search_title": "Are you sure you want to delete this saved search?",
    "info_success_rename_saved_search": "Saved search renamed successfully.",
	"info_success_delete_saved_search": "Saved search deleted successfully.",
	"label_rename_search_rule_title": "Rename Search Rule",

    //== Support ====
    "label_support": "Support",
    "label_support_title": "Contact Us",
    "info_support_subtitle": "Do you have a question about AroundDeal or want to get in touch to us? Just fill in the form and we will be in touch shortly.",
    "info_support_form_title": "We look forward to hearing from you!",
    "label_support_form_name": "What is your name?",
    "info_support_form_name_ph": "Enter your name.",
    "label_support_form_email": "What is your email address?",
    "label_support_form_phone": "What is your phone number?",
    "info_support_form_email_ph": "Enter your email address.",
    "info_support_form_phone_ph": "Enter your phone number.",
    "label_support_form_question": "What is your question?",
    "info_support_form_question_ph": "Fill in the message of your enquiry here.",
    "label_support_submit": "Send Message",
    "label_thank_you": "Thank You",
    "info_support_sent": "Thanks for your feedback, we will contact you within 2 workdays.",
    "button_send_another_message": "Send Another Message",
    "button.send.message": "Send Message",

    //== Upgrade ====
    "label_upgrade": "Upgrade",
    "label_pricing": "Pricing",
    "label_upgrade_title": "Choose Your Plan, Reach Your Leads!",
    "label_upgrade_subtitle": "Find targeted contacts & company info in one click",
    "label_upgrade_year": "Annual",
    "label_upgrade_month": "Monthly",
    "label_upgrade_year_discount": "Saved 20%",

    "label_upgrade_package_lite": "Lite",
    "label_upgrade_package_pro": "Pro",
    // "label_upgrade_package_enterprise": "ENTERPRISE",
    "label_custom": "Custom",

    "label_upgrade_per_month": "mo / user",
    "label_upgrade_contact_us": "Contact Us",
    "button_renew": "Renew",
    "button_contact_us": "Contact Us",
    "button_join_now": "Join Now",

    "label_upgrade_package_free": "Free",
    "label_upgrade_package_basic": "Basic",
    "label_upgrade_package_premium": "Premium",
    "label_upgrade_package_enterprise": "Enterprise",

    "label_upgrade_package_tooltip_chrome_extension":"Find contacts on LinkedIn and websites.",
    "label_upgrade_package_tooltip_prospector":"Find prospects based on Company name, company industry, Contact name and more.",
    "label_upgrade_package_tooltip_gmail_extension":"Easily load templates and see when people open & click your emails.",
    "label_upgrade_package_tooltip_basic_sequence_automation":"Sequences are used to create an automated process to get in contact with prospects. You will be able to create up to 1 sequence.",
    "label_upgrade_package_tooltip_send":"This is a limit of how many emails you can send through AroundDeal every day.",
    "label_upgrade_package_tooltip_email_open":"Track performance of your campaigns and emails.",

    "label_upgrade_package_tooltip_export_contacts":"Easily export your prospect lists.",
    "label_upgrade_package_tooltip_advanced_search_filters":"Find prospects based on LinkedIn URL, Company website and Headcount.",
    "label_upgrade_package_tooltip_no_sequence_limit":"Create as many sequences as you'd like to continue testing your messaging to improve success retes.",
    "label_upgrade_package_tooltip_bulks":"Search, cleanse or verify contact lists in minutes with bulk tasks.",
    "label_upgrade_package_tooltip_basic_team_management":"Invite and remove users in your account.",
    "label_upgrade_package_tooltip_basic_usage_analytics":"View your account usage and basic credit usage.",
    "label_upgrade_package_tooltip_integration":"Save contacts to your CRM in 1 click.",
    "label_upgrade_package_tooltip_csv_enrichment":"Enrich your CSV file manually with AroundDeal data.",
    "label_upgrade_package_tooltip_premium_search_filters":"Find prospects based on Skills, Company specialties and Distributor tag.",
    "label_upgrade_package_tooltip_advanced_usage_analytics":"A deep look at your saved prospector profile and find look-a-like prospects with our recommendation engines.",
    "label_upgrade_package_tooltip_prospect_update_alert":"Receive alerts and notifications when designated contacts change jobs or get promoted.",
    "label_upgrade_package_tooltip_job_change_alert":"Receive alerts and notifications when designated contacts change jobs or get promoted.",
    "label_upgrade_package_tooltip_A/B_testing":"A/B Testing is used to test subject lines to increase open rate and  email bodies to increase reply rates.",
    "label_upgrade_package_tooltip_manual_tasks":"Add tasks manually or automatically as a step within a sequence.",

    "label_upgrade_package_tooltip_select":"Select up to 20,000 new records at a time, instead of the standard 25.",
    "label_upgrade_package_tooltip_visitors":"Identify who are visiting your website, why are they looking, and where did they come from.",
    "label_upgrade_package_tooltip_advanced_team_management":"Define admin seats and allocate credits.",
    "label_upgrade_package_tooltip_salesforce_data_enrichment":"Enrich your Salesforce data at the frequency of your choice with easy-to-setup enrichment workflows.",
    "label_upgrade_package_tooltip_API_access":"Access the AroundDeal API to develop custom prospecting and enrichment solutions for your business.",
    "label_upgrade_package_tooltip_delicated_success_manager":"You'll be coupled with a customer success manager to ensure you get the most out of AroundDeal.",

    "label_time_limited":"24 Hours Only",
    "label_limited_price":"$9.9",
    "label_limited_credit" : '/ 20 credits',
    "label_phone_numbers":"Phone Numbers",
    "label_export_contacts":"Export Contacts",
    "button_buy_now":"Buy Now",
    "label_alert_limited_title":"24 Hours Only - Get 20 credits for only $9.9!",
    "label_upgrade_confirm_title":"Upgrade your plan to use advanced filters",
    "label_upgrade_confirm_title_free":"Upgrade your plan and keep on browsing",
    "label_upgrade_confirm_content_company":"Find and get your targeted companies instantly.",
    "label_upgrade_confirm_content_people":"Find and get your targeted contact details instantly.",


    "label_upgrade_faq_title": "Frequently Asked Questions",
    "label_upgrade_faq_q1": "What are AroundDeal credits?",
    'label_upgrade_faq_a1': "AroundDeal credits are a system of currency at AroundDeal. 1  credit = 1 prospect contact details.",
    "label_upgrade_faq_q2": "Will my unused credits roll over to the next month/year?",
    'label_upgrade_faq_a2': "Your billing cycle is set based on your plan's date of purchase. And your pool of credits will reset at the beginning of every billing cycle.",
    "label_upgrade_faq_q3": "What is the difference between price plans?",
    'label_upgrade_faq_a3': "Each plan offers a different number of credits and features, depending on your needs.",
    "label_upgrade_faq_q4": "Am I charged automatically every month/year?",
    "label_upgrade_faq_a4": "Yes, your plan renews automatically and your monthly or annual credits are issued automatically.",

    "modal_upgrade_contact_us_title" : "Speak with our Sales Team",
    "modal_upgrade_contact_us_needs" : "Tell us about your business needs",
    "modal_upgrade_contact_us_needs_a" : "I want more seats",
    "modal_upgrade_contact_us_needs_b" : "I want more credits",
    "modal_upgrade_contact_us_needs_c" : "I want to learn more about API",
    "modal_upgrade_contact_us_needs_d" : "Others",
    "modal_upgrade_contact_us_needs_a_input" : "How many users would you like to have on the account?",
    "modal_upgrade_contact_us_needs_b_input" : "How many credits would you like to have on the account?",
    "modal_upgrade_contact_us_needs_d_input" : "Please enter your needs",
    "label_email" : "Email",
    "contact_us" : "Contact Us",
    "label_people_name" : "Name",
    "label_phone_number" : "Phone Number",
    "label_company_name" : "Company Name",
    "label_company_name_s" : "Company name",
    "label_billing_email" : "Billing email",
    "label_credit_card_detail" : "Credit card number",
    "label_city" : "City",
    "label_country" : "Country",
    "label_zipcode" : "Zip code",
    "label_billing_address" : "Billing address",
    "label_how_can_we_help" : "How can we help?",
    "info_success_contact_us" : "Thanks for your submission, we will contact you within 2 workdays.",

    "label_forever_free":"Forever Free!",
    "label_free_includes":"Free includes:",
    "label_basic_includes":"Everything in Free, plus:",
    "label_premium_includes":"Everything in Basic, plus:",
    "label_enterprise_includes":"Everything in Premium, plus:",

    // invoice
    "secure_checkout":"Secure Checkout",
    "button_i_have_promo":"I have a promo code.",
    "promo_code_ph":"Promo Code",
    "button_apply":"Apply",
    "label_invoice_total":"Total",
    "label_invoice_subtotal":"Subtotal",
    "label_invoice_discount":"Discount",
    "label_invoice_checkout_agree": "By placing this order, you agree to our <a href=\"https://www.arounddeal.com/terms/\" target=\"_blank\">Terms of Service</a>.",
    "button_pay_with_stripe": "Pay with Stripe",
    "label_checkout": "Checkout",
    "label_invoice": "Invoice",
    "label_instructions": "Instructions",
    "label_date_of_due": "Date of due",
    "label_address_info_1": "AROUNDDEAL HOLDINGS LIMITED",
    "label_address_info_2": "Room 1318-19,13/F Hollywood Plaza, 610 Nathan Road, Mongkok, Kowloon, HK",
    "label_address_info_3": "info@arounddeal.com",
    "button_print": "Print",
    "label_receipt": "Receipt",
    "label_invoice_number": "Invoice number",
    "label_invoice_date_paid": "Date paid",
    "label_invoice_payment_method": "Payment method",
    "label_unsubscribe_success_title": "Unsubscribe Successful",
    "label_unsubscribe_success_subtitle": "You have been successfully unsubsrcibed from email communications.<br/>If you did this in error, you may re-subscribe by clicking the button below.",
    "button_re_subscribe":"Re-subscribe",
    "label_resubscribe_title":"Thank You",
    "label_resubscribe_subtitle":"Thanks for your continued interest. You will continue to receive newsletters and updates from AroundDeal.",
    "label_payment_success_title":"Thank You for Your Order!",
    "label_payment_success_title_free_trail":"Welcome to Your AroundDeal {plan} Free Trial",
    "label_payment_success_subtitle":"Your order has been placed successfully.<br/>To view your invoices, please go to your account billing tab to check.<br/>Thank you and enjoy AroundDeal!",
    "button_go_to_my_account": "Go to My Account",
    "label_payment_failed_title": "Your payment failed.",
    "label_payment_success_subtitle_free_trail": "Thanks for requesting a 7 days free trial of AroundDeal.<br/> We're excited that you've decided to give us a try.",
    "label_payment_failed_subtitle": "You've cancelled your payment. We hope to welcome you back to soon! <br/> If you have any questions or concerns, don't hesitate to email us: <br/> <a href=\"mailto:support@arounddeal.com\">support@arounddeal.com</a>.",
    "button_try_again":"Retry Payment",
    "button_back_to_home":"Back to Home",
    "label_page_404_title":"Sorry, something went wrong!",
    "label_page_404":"404 - Not Found",
    "label_refer_link_title":"The link you entered is invalid or expired.",
    "label_refer_link_subtitle":"The link you entered is invalid or expired, please check your link and try again. Or you can sign up without partner code below.",
    "button_sign_up_now":"Sign Up Now",
    "label_pay_method":"Pay Method",
    "label_description":"Description",
    "label_qty":"Qty",
    "label_unit_price":"Unit Price",

    "label_payment_failed":'Payment Failed',
    "label_payment_success":'Payment Succeed',
    "label_checkout_select_title":'How many credits/users would you like in your new plan?',
    "label_checkout_text_1":'Need more users to get the full benefits of AroundDeal?',
    

    // verify email
    "label_verify_email_title":"Verify Your Email Address",
    "label_verify_email_info":"We just sent a verification code to your mailbox. <br/>To verify that this is your email address, enter it below. If you don't see it, you may need to check your spam folder or junk mail folder.",
    "resend_code_left": "Resend Code（{count}s）",
    "resend_verification_code": "Resend Code",
    "button_go_back": "Go Back",
    "label_verify_email": "Send Code",
    "label_email_sent": "Email sent!",

    


    //== Account ====
    "label_nav_account_plan": "Your Plan",
    "label_subscription": "Subscription",
    "label_nav_account_billing": "Billing",
    "label_nav_account_edit": "Edit Profile",
    "label_nav_account_change_password": "Change Password",
    "label_nav_account_settings": "GDPR Setting",
    "label_nav_integration_salesforce": "Salesforce Integration Setup",
    "label_nav_integration_Hubspot": "HubSpot Integration Setup",
    "label_nav_account_api": "API",
    "label_nav_account_integration": "Integrations",
    "label_nav_account_notification": "Notification",
    "button_integrations": "Integrations",
    "label_nav_account_csv_export": "CSV Export Setting",
    "label_nav_account_csv_import": "CSV Import Setting",
    "label_nav_redeem_code": "Redeem Code",

    "label_personal_profile":"Personal Profile",
    "label_account_plan":"Account Plan",
    "label_configurations":"Configurations",
    "label_premium_features":"Premium Features",
    "label_exports":"Exports",
    "label_import":"Imports",
    

    "label_hubspot": "HubSpot",
    "label_integration_hubspot": "Easily push targeted lists and capture the most valuable leads.",
    "label_account_connected": "Account connected",
    "button_message":"Message",
    "button_connect":"Connect",
    "button_connecting":"Connecting",
    "button_disconnect":"Disconnect",
    "modal_label_integration_hubspot_title":"Upgrade Your Plan to Save Contacts to HubSpot",
    "modal_label_integration_hubspot_content":"Use one-click integrations to supercharge your workflows.",

    "label_salesforce":"Salesforce",
    "label_integration_salesforce":"Instantly save the right lead/contact data to the right Salesforce field.",
    "modal_label_integration_salesforce_title":"Upgrade Your Plan to Save Contacts to Salesforce",
    "modal_label_integration_salesforce_content":"Use one-click integrations to supercharge your workflows.",
    
    "label_invoice_status_pending": "Pending",
    "label_invoice_status_paid": "Paid",
    "label_invoice_status_cancelled": "Cancelled",

    "label_account_current_plan": "Current Plan",
    "label_account_your_plan": "Your Plan",
    "label_account_billing_plan": "Billing Plan",
    "label_account_credits": "Credits",
    "label_account_cleansings": "Cleansings",
    "label_account_verifications": "Verifications",
    "info_number_credit_left": "You have used <span style='color: rgb(6, 144, 131);'>{credit}</span> of {creditAll} available credits in this billing cycle.",
    "info_number_cleansing_left": "You have used <span style='color: rgb(6, 144, 131);'>{cleansing}</span> of {cleansingAll} available cleansings in this billing cycle.",
    "info_number_cleansing_left_enterprise": "You have unlimited cleansings in this billing cycle.",
    "info_number_cleansing_left_enterprise_tooltip": "AroundDeal's enterprise plans are governed by Use Policy in order to prevent potential abuse and give you the best experience. The cleansing limit is 1,000,000 per account per year on an enterprise plan. You can get in touch with our team to get additional cleansings customized to your needs.",
    "info_number_verification_left": "You have used <span style='color: rgb(6, 144, 131);'>{verification}</span> of {verificationAll} available verifications in this billing cycle.",
    "info_number_verification_left_enterprise": "You have unlimited verifications in this billing cycle.",
    "info_number_verification_left_enterprise_tooltip": "AroundDeal's enterprise plans are governed by Use Policy in order to prevent potential abuse and give you the best experience. The verification limit is 1,000,000 emails per account per year on an enterprise plan. You can get in touch with our team to get additional verifications customized to your needs.",
    "info_account_next_credit_cycle_date": "Next credit cycle: {date}.",
	"info_account_plan_expiring_date": "Your current plan will expire on {date}.",
	"info_account_next_renewal_date": "Next renewal date: {date}.",
    "info_number_credits_per_month": "{number} credits per month",
    "info_number_credits_per_year": "{number} credits per year",
    "label_count_credits": "{count} credits",
    "label_count_credit": "{count} credit",

    "button_reorder": "Re-order",
    "button_view_instructions": "View Instructions",
    "button_view_invoice": "View Invoice",
    "button_view_receipt": "View Receipt",
    "button_cancel_subscription": "Cancel Subscription",
    "button_update_payment_info": "Update Payment Info",

    "info_invoice_confirm_cancel": "Are you sure you want to cancel this invoice?",
    "button_confirm": "Confirm",
    "button_verify": "Verify",
    "info_success_invoice_cancelled": "Invoice cancelled.",

    "label_account_edit_title": "Edit Profile",
    "info_success_edit_profile": "Profile saved.",

    "label_account_change_password_title": "Change Password",
    "label_current_password": "Current Password",
    "label_new_password": "New Password",
    "label_confirm_password": "Confirm New Password",

    "label_account_info": "Account Info",
    "label_account_bind_email": "You have not linked a business email account. You can link your email",
    "label_gdpr_compliance": "GDPR Compliance",
    "label_gdpr_compliance_h5": "Forbid prospecting of people in the European Union",
    "label_gdpr_compliance_p": "GDPR stands for General Data Protection Regulation, which is a set of regulations created by the European Union (EU) to protect the privacy and personal data of EU citizens. AroundDeal has added safeguards to help you ensure compliance with GDPR.",
    "label_account_email_setting_h5":"Append the opt-out message after my signature in sequences",

    "label_account_settings_gdpr": "GDPR",
    "label_account_gdpr": "Hide EU contacts on search results",
    "label_account_gdpr_hidden": "EU countries results hidden",

    "info_success_change_password": "Password changed successfully.",

    "label_account_api_no_permission":"Put Best-in-class Data Into Your System",
    "info_account_api_no_permission": "AroundDeal's API makes it easy to build targeted contact lists or enrich any system with our B2B database.",

	"button_get_api_key": "Get Your API Key",
	"button_explore_api_docs": "Explore Our API Docs",

	"info_account_api_1": "Add premium prospects to your systems",
	"info_account_api_2": "Update contact and company data in your systems",
    "info_api_get_started": "Get started with our API.",
    "button_view_docs": "View Docs",

    "info_confirm_integration_disconnect": "Are you sure you want to disconnect {platform} account?",
    "info_confirm_integration_disconnect_content": "Your existing data in {platform} from AroundDeal will stay, but will no longer be updated.",
    "label_your_api_key": "Your API Key",
    "label_copied": "Copied",
    "label_copy": "Copy",
    "label_copy_link": "Copy Link",
    "label_credits": "Credits",
    "label_credits_left": "Credits Left",
    "modal_cancel_subsciption_title": "We're sad to see you go...",
    "modal_cancel_subsciption_checkbox_a": "Not sure how to use the data & tools",
    "modal_cancel_subsciption_checkbox_b": "Incorrect information / Irrelevant results",
    "modal_cancel_subsciption_checkbox_c": "Switching to another product",
    "modal_cancel_subsciption_checkbox_d": "Too expensive",
    "modal_cancel_subsciption_checkbox_e": "Misssing features I need",
    "modal_cancel_subsciption_checkbox_f": "Others",
    "modal_cancel_subsciption_checkbox_c_input": "Which alternative would you choose?",
    "modal_cancel_subsciption_checkbox_e_input": "What features do you think are missing?",
    "modal_cancel_subsciption_checkbox_f_input": "Please explain below",
    "modal_cancel_subsciption_content_p": "Before you cancel, please let us know the reason you are leaving. Every bit of feedback helps!",

    "modal_prospector_feedback_checkbox_a":"Lack of filters",
    "modal_prospector_feedback_checkbox_b":"Incorrect information",
    "modal_prospector_feedback_checkbox_b_1":"Incorrect prospects",
    "modal_prospector_feedback_checkbox_b_2":"Incorrect companies",
    "modal_prospector_feedback_checkbox_b_3":"Incorrect email addresses",
    "modal_prospector_feedback_checkbox_b_4":"Incorrect phone numbers",
    "modal_prospector_feedback_checkbox_b_5":"Incorrect job titles",
    "modal_prospector_feedback_checkbox_c":"Complicated interface",
    "modal_prospector_feedback_checkbox_d":"Irrelevant results",
    "modal_prospector_feedback_checkbox_d_1":"Irrelevant prospects",
    "modal_prospector_feedback_checkbox_d_2":"Irrelevant companies",
    "modal_prospector_feedback_checkbox_d_3":"Irrelevant email addresses",
    "modal_prospector_feedback_checkbox_d_4":"Irrelevant phone numbers",
    "modal_prospector_feedback_checkbox_d_5":"Irrelevant job titles",
    "modal_prospector_feedback_checkbox_e":"Restrictive plan",
    "modal_prospector_feedback_checkbox_f":"Other",
    "modal_prospector_feedback_checkbox_a_input":"What filters do you think are missing?",
    "modal_prospector_feedback_checkbox_f_input":"Please explain below",



    "label_api": "API",
    "label_api_documentation": "API Documentation",

    "label_account_invoice_history": "Invoice History",
    "info_account_invoice_empty": "No invoice history now.",

    "label_invoice_col_id": "ID",
    "label_invoice_col_type": "Payment type",
    "label_invoice_col_date": "Date",
    "label_invoice_col_plan": "Plan",
    "label_invoice_col_amount": "Amount",
    "label_invoice_col_status": "Status",
    "label_invoice_type_stripe": "Stripe",
    "label_invoice_type_paypal": "PayPal",
    "label_invoice_type_alipay": "Alipay",
    "label_invoice_type_wechat_pay": "Wechat Pay",
    "label_invoice_type_bank": "Bank Transfer",

    "button_change_plan": "Change Plan",
    "button_see_all_saved": "See All Saved",

    "info_prospect_no_result": "No Results Found. Try Clearing Some Filters?",

    "label_accuracy_score": "Accuracy Score",
    "info_updated_3m": "Updated in the past 3 months",
    "info_updated_6m": "Updated in the past 6 months",
    "info_updated_in_1y": "Updated in 1 year",
    "info_updated_1y": "Updated 1 year ago",
    "info_success_cancel_subscription": "You have successfully canceled your subscription.",
    "info_success_account_csv_export_setting": "CSV export settings saved successfully.",

    "label_account_csv_export":'Account CSV Export',
    "label_csv_export_settings":'CSV Export Settings',
    "label_csv_export_settings_title":'What is the CSV Export settings for?',
    "label_csv_export_settings_p":'You can customise the default fields you want to download every time you export a CSV file. This is a personal account setting and wont be applied to the rest of your team.',
    "label_account_csv_export_settings":'Account CSV Export Settings',
    "label_contact_csv_export_settings":'Contact CSV Export Settings',
    "label_contact_csv_export":'Contact CSV Export',
    "label_contact_csv_enrichment":'Contact CSV Enrichment Settings',
    "label_account_csv_enrichment":'Account CSV Enrichment Settings',
    "label_enrichment":'Enrichment',
    "label_field_to_enterprise":'This field is available to Enterprise users only.',

    "label_enrichment_settings":"Enrichment Settings",
    "label_csv_enrichment_settings":"CSV Enrichment Settings",
    "label_csv_enrichment_settings_h4":"What is the CSV Enrichment settings for?",
    "label_csv_enrichment_settings_p":"You can customise the default fields you want to enrich every time you export a CSV file. This is a personal account setting and wont be applied to the rest of your team.",
    
    "label_placeholder_enter_redeem_code": "Please enter your redeem code...",
    "label_expired_date": "Expired Date",
    "label_redeem_code_history": "Redemption History",
    "label_account_redeem_code_empty":'No redemption history now.',
    "label_error_code_expired":'The code you entered was invaild or expired.',
    "label_success_redeem_code":'Redeemed successfully.',

    "button_redeem":"Redeem",

    "label_push_records_to_salesforce":"Push Records to Salesforce",
    "label_pull_records_to_salesforce":"Pull Records to Salesforce",
    "label_salesforce_settings":"Salesforce Settings",
    "label_hubspot_settings":"HubSpot Settings",
    "label_field_mappings":"Field Mappings",
    "label_push":"Push",
    "label_pull":"Pull",
    "label_pull_salesforce_to_aroundDeal":"Pull Your Salesforce Records to AroundDeal",
    "label_pull_salesforce_to_aroundDeal_content":"Before you begin, you need to pull your accounts/ leads/ contacts to AroundDeal first.",


    "button_push_now":"Push Now",
    "button_pull_records":"Pull Records",

    "label_system_activity":"System Activity",
    "label_account_notification":"Notification",
    "label_notification":"Notification",
    "label_all_notification":"All notifications",
    "label_account_you":"You",
    "label_account_mailbox":"Mailboxes",
    "label_notification_no_results":"You have viewed all notifications",
    

    // == Credit =====
    "label_credit_get_free": "Get Free Credits",
    "label_invite_your_friends": "Invite Your Friends",
    "label_write_a_review": "Write a Review",
    "label_credit_get_free_title": "How to Get Free AroundDeal Credits?",
    "label_credit_invite_friends": "Invite your friends",
    "info_credit_invite_friends": "Invite your friends who are not on AroundDeal yet.",
    "button_get_number_credits": "Get {number} Free Credits",
    "label_credit_write_review": "Write a review",
    "info_credit_write_review": "Write a five-star review on G2, Capterra, etc.",

    "label_refer_title": "You have 5 more invites, keep sharing and get more credits!",
    "label_refer_subtitle": "For every friend who joins AroundDeal, we will give you 5 bonus credits (up to a limit of 25 credits). To get even more credits, please upgrade your account.",
    "label_refer_how_it_works": "How it Works",
    "info_refer_how_it_works_1": "Share your link with friends and on social media.",
    "info_refer_how_it_works_2": "Get credits when your friends install AroundDeal extension.",
    "info_refer_how_it_works_3": "Get 5 credits per referral.",
    "label_refer_faq_title": "Frequently Asked Questions",
    "info_refer_faq_q1": "I referred a friend but did not receive the referral credits.",
    'info_refer_faq_a1': "I referred a friend but did not receive the referral credits. Refferal credits will be granted to free accounts only.",
    "info_refer_faq_q2": "I am having trouble with referrals.",
    'info_refer_faq_a2': "Email <a href='mailto:support@arounddeal.com'>support@arounddeal.com</a> and tell us what errors you are experiencing. Please be as specific as possible and include a screenshot.",
    "label_my_refer": "My Referrals",
    "label_refferals": "Refferals",
    "label_credits_earned": "Credits Earned",
    "label_refer_col_user_email": "User Email",
    "label_refer_col_date": "Date",
    "label_refer_col_reward": "Reward",
    "info_refer_empty": "No one has joined AroundDeal through your refferal code so far.",

    "label_platform": "Platform",
    "modal_label_salesforce_save_to":"Create New Records as Contacts or Leads?",
    "modal_label_salesforce_save_to_tooltip":"While Salesforce has two record types for people (Contacts and Leads), AroundDeal only has one record type for people (Contacts). Because of this, you must choose whether new AroundDeal Contacts are created in Salesforce as Contacts or Leads.",
    "label_choose_a_list": "Choose a List",
    "label_move_list_title": "Move to Another List",
    "button_move": "Move",

    "info_refer_earn_col_task": "Task",
    "info_refer_earn_title": "Get 5 free credits for per five stars review!",
    "info_refer_earn_subtitle": "Write five stars review, and email us at <a href='mailto:support@arounddeal.com'>support@arounddeal.com</a> with the title of your review & your email address (up to a limit of 30 credits). And we will find it and deposit the credits manually for you. To get even more credits, please upgrade your account.",

    "info_refer_earn_how_it_works_1": "Write a five star review.",
    "info_refer_earn_how_it_works_2": "Email us at <a href='mailto:support@arounddeal.com'>support@arounddeal.com</a> with the title of your review &amp; your email address. And we will find it and deposit the credits manually for you.",
    "info_refer_earn_how_it_works_3": "You will receive credits per review.",

    "button_write_now": "Write Now",
    "info_refer_review_on_g2": "Review us on G2",
    "info_refer_review_on_capterra": "Review us on Capterra",
    "info_refer_review_on_cws": "Review us on Chrome Web Store",
    "info_refer_review_on_tr": "Review us on TrustRadius",
    "info_refer_add_number_credits": "+{number} credits",

    // === Team ====
    "label_team":"Team",
    "label_lists":"Lists",
    "label_team_lists":"Team Lists",
    "label_team_lists_people":"Team Lists - People - #{key}",
    "label_team_lists_company":"Team Lists - Company - #{key}",
    "label_list_col_role":"Role",
    "label_list_col_status":"Status",
    "label_list_col_created_by":"Created by",
    "label_team_credits_left":"Team Credits Left",
    "label_invite_team_member_title":"Invite Team Members",
    "label_invite_team_required_business_email_content":"To use this feature, you need to link a business email account.",
    "label_invite_team_required_business_email_title":"Please link your business email",
    "label_invite_team_limit_title":"You’ve reached your team member limit",
    "label_invite_team_limit_content":"It's great that you make full use of AroundDeal!<br/>Upgrade your plan to invite more team members.",
    "label_set_admin_confirm_title":"Add account admin",
    "label_set_admin_confirm_content":"Are you sure you want to set {name} as admin",
    "label_dashboard":"Dashboard",
    "label_analytics":"Analytics",
    "label_admin":"Admin",
    "label_choose_a_role":"Choose a Role",
    "label_all_roles":"All roles",
    "label_all_members":"All members",
    "label_search_company_name":"Search company name",
    "label_search_email":"Search email",
    "label_search_name":"Search name",
    "label_search_list_name":"Search list name",
    "label_user":"User",
    "label_member":"Member",
    "label_job_level":"Job Level",
    "label_manager":"Manager",
    "label_team_members":"Team Members",
    "label_saved_prospects":"Saved Prospects",
    "label_pending":"Pending",
    "label_active":"Active",
    "label_deleted":"Deleted",
    "label_declined":"declined",
    "label_prospector_feedback_modal_title":"We want to make AroundDeal better for you",
    "label_prospector_feedback_modal_q1":"What didn't work well for you in the search process?",
    "label_prospector_feedback_modal_q2":"Anything else we should know about these issues? <span class='text-gray-light'>(options)</span>",
    "label_saved_prospect_profile":"Saved Prospect Profile",
    "label_business_email_address":"Business Email Address",
    "label_validate_code":"Enter Verfication Code",
    "label_resend_email_left": "Resend Code（{count}s）",
    "label_revealed_prospects_top_parameter": "Revealed Prospects Top Parameter",
    "label_team_dashboard_alert_message": "You May Also Like",
    "label_team_dashboard_alert_description": "We found {count} similar contacts based on your activity.",
    "label_view_contacts": "View Contacts",
    "label_view_prospects": "View Prospects",
    "const_data_source_bulk": "Bulk Finder",
    "const_data_source_domain": "API",
    "const_data_source_prospect": "Prospect",
    "const_data_source_extension": "Chrome Extension",
    "const_data_source_visitor": "Visitor",
    "label_cancel_now": "Cancel Now",
    "label_keep_my_subscription": "Keep My Subscription",
    "label_cancel_subscription_modal_title": "Are you sure you want to cancel your subscription?",
    "label_cancel_subscription_modal_content": "Your subscription will remain active until {date}. The subscription can be reactivated before it expires. Are you sure you want to cancel?",
    "label_people_list": "People List",
    "label_company_list": "Company List",
    "label_invite_member_modal_select_0":"Select the status of the member",
    "label_invite_member_modal_select_1":"Invite members from your organization",
    "label_invite_member_modal_select_2":"Invite new members by email",
    "label_invite_member_modal_select_option_1_text_1":"We find {countNum} users from your orginazation who already use AroundDeal.",
    "label_invite_member_modal_select_option_1_text_2":"Select the users you'd like to add to your account:",
    "label_invite_member_modal_select_option_2_text_1":"Add email addresses of the contact you'd like to add to your account:",
    "label_invite_member_modal_select_option_2_text_2":"Please enter the email with your organization email domain.",
    "label_invite_member_modal_select_text_p":"<b class='text-primary'>{count}</b> seat(s) available.",
    "label_invite_member_out_of_invitation_title":"You are out of invitation now.",
    "label_invite_member_out_of_invitation_content":"Click \"Buy Seats\" to purchase additional seats.",
    "label_invite_member_out_of_invitation_content_free_trial":"This feature is not available during the free trial.",
    "label_prospector_free_to_upgrade":"Upgrade your plan to access phone numbers",
    "label_prospector_free_to_upgrade_content":"Reach the right decision maker on the phone and move deals forward.",
    "label_prospector_upgrade_to_hubspot":"Upgrade Your Plan to Save Contacts to HubSpot",
    "label_prospector_upgrade_to_hubspot_content":"Use one-click integrations to supercharge your workflows.",
    "button_start_for_free":"Start for Free",
    "button_try_basic_free":"Try Basic Free",
    "button_try_premium_free":"Try Premium Free",
    "button_7_day_free_trial":"7-Days Free Trial",
    "button_choose_plan":"⚡ Choose Plan",
    "label_skip_free_trial_title":"Are You Sure You Want to Skip the Free Trial?",
    "label_skip_free_trial_content":"You will be charged today to start your paid subscription. And your payment will be auto-renewed (unless cancelled before next renewal date).",
    "label_skip_free_trial_immediately":"Skip Trial and Pay Immediately ",
    "button_skip_free_trial":"Skip Free Trial",
    "button_get_50_off":"Get 50% Off",
    "button_skip_trial":"Skip Trial",
    "label_payment_method":"Payment Method",
    "info_text_free_trial":"Free Trail Remaining: {date} {day}",


    "info_success_set_role":"Role set successfully.",
    "info_success_team_resend_invite":"Resend invite Successfully.",
    "info_success_bind_business_email":"Your business email linked successfully.",
    "info_success_invite_member_successfully":"Invitation sent successfully.",
    "info_success_invite_member_accept_successfully":"You've joined {name}'s team successfully.",
    "info_success_invite_member_declined_successfully":"You've declined {name}'s invitation.",
    "info_confirm_list_member_delete_title":"Are you sure you want to remove this member?",
    "info_confirm_list_role_delete_title":"Are you sure you want to delete this role?",
    "info_confirm_list_member_delete_content":"Removing this member will <span class='text-danger'>delete all of his saved prospects, lists and credits left</span>.",
    "info_success_member_deleted": "Member deleted successfully.",
    "info_success_role_deleted": "Role deleted successfully.",
    "info_success_set_credit_successfully": "Credits set successfully.",
    "label_add_credits":"Add Credits",
    "label_take_back_credits":"Take Back Credits",
    "label_manage_credits_max_number_minus":"You cannot exceed {name} current credit balance.",
    "label_manage_credits_max_number_plus":"You cannot exceed your account credit balance.",
    "label_manage_credits_balance_plus":"Unassigned credits: <b class='text-primary'>{num}</b>",
    "label_manage_credits_balance_minus":"{name} current credit balance: <b class='text-primary'>{num}</b>",

    "button_invite_member":"Invite Members",
    "button_send_invites":"Send Invites",
    "button_resend_invite":"Re-send Invite",
    "button_buy_seats":"Buy Seats",
    "button_remove_member":"Remove Member",
    "button_remove_role":"Remove Role",
    "button_set_role":"Set Role",
    "button_upgrade_plan":"Upgrade Plan",
    "button_submit_feedback":"Submit Feedback",
    "button_bind_business_email":"Link Business Email",
    "button_close":"Close",
    "button_add_credits":"Add Credits",
    "button_take_back_credits":"Take Back Credits",

    // === Engage ===
    "label_engage_sequence":"Sequence",
    "label_engage_sequences":"Sequences",
    "label_engage_contacts":"Contacts",
    "label_engage_emails":"Emails",
    "label_engage_tasks":"Tasks",
    "label_engage_templates":"Templates",
    "label_engage_analytics":"Analytics",
    "label_engage_settings":"Settings & Mailboxes",
    "label_modal_edit_tag":"Add or edit tags",
    "label_tag_name":"Tags",
    "label_tag_name_ph":"Enter tags",
    "button_archive":"Archive",
    "button_clone":"Clone",
    "button_export":"Export",
    "button_add_task":"New Task",
    "button_upload_file":"Upload CSV",
    "button_add_contacts":"Add Contacts",
    "label_engage_template_body_error":"Please enter your content.",
    "label_upload_csv":"Upload CSV File",
    // === Dashboard ====
    "label_team_credits_used":"Team Credits Used",
    "label_credit_usage":"Credit Usage",
    "label_team_no_permission_title":"Collaborate with Your Team to Hit Prospecting Goals",
    "label_team_no_permission_subtitle":"AroundDeal brings your team together to work, track, and collaborate on prospecting — all in one place.",
    "info_team_no_permission_ul_1":"Organize team at a glance",
    "info_team_no_permission_ul_2":"Find your ideal prospects",
    "info_team_no_permission_ul_3":"View analytics for account usage and credit usage",
    "label_team_role_no_permission_title":"You don't currently have permission to access this page",
    "label_team_role_no_permission_subtitle":"Please ask your team's admin to grant permission before you can use it.",
    "label_team_enterprise_permission_title":"Accelerate Your Prospecting with Data Insights",
    "label_team_enterprise_permission_ul_1":"Get a 360° view of your prospects",
    "label_team_enterprise_permission_ul_2":"Get the insights you need to make smarter decisions",
    "label_team_enterprise_permission_ul_3":"Boost prospecting efficiency with ideal customer profile",

    "label_prospector_alert_message":"Haven't installed the extension yet?",
    "label_prospector_alert_content":"Get targeted contact and company information on LinkedIn, company website and CRM.",
    "modal_label_prospector_preference_collect_title":"Build Your List with AroundDeal in 3 Steps!",
    "modal_label_prospector_preference_collect_step_1":"1. What's your target location?",
    "modal_label_prospector_preference_collect_step_2":"2. What's your target industry?",
    "modal_label_prospector_preference_collect_step_3":"3. What's your target department?",
    "modal_label_oops":"OOPS!",
    "modal_label_oops_description":"Your target market is too small. Please edit your choices.",

    "button_install_extension":"Install Chrome Extension",
    "button_discover_your_list":"Discover Your List",
    "button_next":"Next",
    "button_skip":"Skip",

    "label_free_trial_modal_title":'Start Your Free Trial to Unlock Full Access',
    "label_free_trial_modal_content_p":'Instant access. Cancel anytime.',
    "label_free_trial_modal_content_1":'5 contact details',
    "label_free_trial_modal_content_2":'20 email/phone cleansing',
    "label_free_trial_modal_content_3":'20 email verfications',
    "label_free_trial_modal_content_4":'Unlimited Search & Advanced Filter',
    "label_free_trial_modal_content_5":'Bulks（Bulk finder, Cleansing, Verifier)',
    "label_free_trial_modal_content_6":'Team (Analytics, Members)',
    "label_free_trial_modal_content_7":'Enrichment (CRM, CSV, API Enrichment)',
    "label_free_trial_modal_content_8":'Integrations (HubSpot, Salesforce)',

    "label_free_trial_title":'Save Contacts for FREE',
    "label_free_trial_content_1":'Get 5 free contacts now',
    "label_free_trial_content_2":'Unlimited search & advanced filter',
    "label_free_trial_content_3":'Try all AroundDeal full access today',

    // ==== Visitor ====
    "label_visitor":"Visitor",
    "label_add_new_domain_ph":"e.g. arounddeal.com",
    "label_new_domain":"Domain Address",
    "label_analytics":"Analytics",
    "label_analytics_people":"People",
    "label_analytics_company":"Companies",
    "label_management":"Management",
    "label_visitor_analytics":"Analytics - Visitor",
    "label_visitor_management":"Management - Visitor",
    "label_visitor_analytics":"Analytics - Visitor",
    "label_companies_over_time":"Companies over Time",
    "label_most_visited_companies":"Most Visited Companies",
    "label_most_visited_pages":"Most Visited Pages",
    "label_company_headcount":"Company Headcount",
    "label_companies":"Companies",
    "label_headcount":"Headcount",
    "label_industries":"Industries",
    "colunm_website":"Website",
    "colunm_status":"Status",
    "label_last_visit":"Last Visit",
    "label_visit_identification_status":"Visitor Identification Status",
    "colunm_installation":"Installation",
    "label_person_filter":"Persona Filter",
    "label_person_filter_status":"Persona Filter Status",
    "label_ideal_customer_profiles":"Ideal Customer Profiles",
    "label_manage":"Manage",
    "colunm_employees":"Employees",
    "label_company_visited":"Companies Visited Your Website",
    "label_visits":"Visits",
    "label_unique_visitors":"Unique Visitors",
    "label_page_views":"Page Views",
    "label_visits_last_7_days":"Visits last 7 days",
    "label_visits_prior_7_days":"Visits prior 7 days",
    "label_visits_change":"Visits change",
    "label_high_fit_prospects":"High-fit Prospects",
    "info_confirm_visitor_domain_delete":"Are you sure you want to delete this domain?",
    "info_confirm_visitor_filter_delete":"Are you sure you want to delete this filter?",
    "info_confirm_visitor_status_turn_off_title":"Are you sure you want to turn off active status?",
    "info_confirm_visitor_status_turn_off_content":"You won't be able to identify what companies are on your website, the actions they're taking, and which products and solutions they care about.",
    "info_confirm_visitor_filter_turn_off_title":"Are you sure you want to turn off persona filter?",
    "info_confirm_visitor_filter_turn_off_content":"You won't be able to prospect faster and receive personalized recommendations.",
    "label_modal_visitor_installation_title":"Visitors Installation",
    "label_modal_visitor_add_persona_filter":"Add Persona Filter",
    "label_modal_visitor_add_visitor_filter":"Add a Ideal Customer Profile",
    "label_modal_visitor_edit_persona_filter":"Edit Persona Filter",
    "label_modal_visitor_edit_visitor_filter":"Edit the Ideal Customer Profile",
    "label_domain_name":"Domain Name",
    "label_dates":"Dates",
    "label_webpages_url":"Webpage(s) URL",
    "label_persona_name":"Persona Name",
    "label_idea_customer_profile_name":"Idea Customer Profile Name",
    "label_contacts_per_domain":"Contacts per domain: {number}",
    "label_persona_name_ph":"e.g. Marketing Leaders",
    "label_ideal_customer_profiles_name_ph":"e.g. Automotive Companies in USA",
    "label_filter_by_function":"Filter by Function",
    "label_filter_by_seniority_level":"Filter by Seniority Level",
    "label_filter_by_headquarters":"Filter by Contact Location",
    "label_filter_by_company_industry":"Filter by Company Industry",
    "label_filter_by_company_employee_range":"Filter by Company Headcount",
    "label_filter_by_company_headquarters":"Filter by Company Headquarters",
    "info_visitor_persona_filter_no_result_title":"Let's get started!",
    "info_visitor_persona_filter_no_result_content":"Personas are sets of locations, seniorities, and departments that define different groups of people that you target within key accounts. After setting them up, you will able to search faster and receive better recommendations.",
    "info_visitor_company_filter_no_result_content":"An ideal customer profile is defined as the type of company that would benefit the most from your product or service. After creating an ideal customer profile, you are able to receive better visitor identification.",
    "label_visitor_no_permission_title":"Turn Website Visitors into Qualified Leads",
    "info_visitor_no_permission_content_1":"Find out what companies are visiting your website and engage them the right way at the right time.",
    "info_visitor_no_permission_ul_1":"Turn anonymous traffic into real company names",
    "info_visitor_no_permission_ul_2":"Reveal the exact behavior of the companies visiting your website",
    "info_visitor_no_permission_ul_3":"View analytics by most visited pages, company industry, location and more",
    "info_visitor_no_permission_ul_4":"Get direct access to decision makers with contact data",
    "info_visitor_company_no_results_card_title":"Start Adding a Domain to Identify Website Visitors",
    "info_visitor_company_no_results_card_p":"Open Management Tab of Visitors to add your website domain.",
    "info_visitor_people_no_results_card_title":"Start Adding a Persona Filter to Receive High-fit Prospects ",
    "info_visitor_people_no_results_card_p":"Prospect faster and receive personalized recommendations.",
    "label_what_persona":"What's Persona?",
    "label_what_ideal_customer_profiles":"What's Ideal Customer Profile?",
    "label_verifying":"Verifying",
    "label_verified":"Verified",
    "label_settings":"Settings",
    "label_save":"Save",
    "label_budget":"Company Analytics Budget",
    "label_hourly_budget":"Hourly Budget",
    "label_daily_budget":"Daily Budget",
    "button_budget_setttings":"Budget Settings",
    "button_set_budget":"Set Budget",
    "input_number_min_10":"The number may not be less than 10.",
    "label_set_contacts_per_company":"Set contacts per company on this filter",
    "label_modal_management_settings_content_p":"Define how much you'd like to spend. 1 unique company costs 1 credit",
    "info_error_visitor_management_verify":"You are able to turn your active status on when your domain has been successfully verified.",
    "label_what_persona_tooltip":"Group different roles & seniority levels into different personas to prospect faster and receive personalized recommendations.",
    "label_what_company_tooltip":"An ideal customer profile is defined as the type of company that would benefit the most from your product or service. Create an ideal customer profile to receive personalized recommendations.",
    "label_1_unique_company_costs_1_credit":"1 unique company costs 1 credit.",
    "label_1_unique_prospect_costs_1_credit":"1 unique prospect costs 1 credit.",
    "label_visitor_company_analytics_top_card_title":'Want to receive personalized company analytics? Manage settings.',
    "label_visitor_people_analytics_top_card_title":'Want to see the best prospects from each account? Manage Personas.',
    "label_visitor_people_analytics_top_card_p":'Prospect faster and receive personalized recommendations. 1 unique prospect costs 1 credit.',
    "label_visitor_company_analytics_top_card_p":"Manage your budget and ideal customer profiles to make AroundDeal Visitors work better for you",
    "label_alert_visitor_people_analytics":'Turn your active status on to view analytics.',
    "label_modal_management_company_filter_content_p":'Create your ideal customer profile to filter through the noise of your website visitors.',
    "label_modal_management_persona_filter_content_p":'Create personas to receive personalized recommendations.',

    "label_last_7_days":"Last 7 days",
    "label_last_30_days":"Last 30 days",
    "label_last_90_days":"Last 90 days",
    "label_last_180_days":"Last 180 days",
    "label_last_calendar_year":"Last calendar year",

    "info_success_domain_paused":"Turned off successfully.",
    "info_success_filter_paused":"Turned off successfully.",
    "info_success_domain_verify":"Domain verified succeefully.",
    "info_failed_domain_verify":"Domain verification failed.",
    "info_success_visitor_add_new_domain":"Domain added successfully.",
    "info_success_visitor_domain_deleted": "Domain deleted succeefully.",
    "info_success_visitor_persona_fliter_deleted": "Filter deleted succeefully.",
    "info_success_visitor_filter_added": "Filter added successfully.",
    "info_success_visitor_filter_edit": "Filter edit successfully.",
    "info_success_visitor_settings": "Settings saved successfully.",
    "info_success_visitor_company_list_export": "List exported successfully.",


    "button_add_new_domain":"Add New Domain",
    "button_change_status":"Change Status",
    "button_manage_personas":"Manage Personas",
    "button_add":"Add",
    "button_view_installation_script":"View Installation Script",
    "button_turn_off":"Turn Off",
    "button_edit":"Edit",
    "button_contact_sales":"Contact Sales",
    "button_copy_script":"Copy Script",
    "button_visitor_settings":"Visitor Settings",
    "button_ideal_customer_profiles":"Create Ideal Customer Profiles",

    // ======Alert=====
    "label_prospect_updates_available":"Prospect updates available",
    "label_prospect_updates_alert":"Prospect Update Alert",
    "label_prospect_no_alert_yet":"No alert yet",
    "label_alert_data_update_title":"Prospect Update Alert",
    "label_alert_no_result_title":"No Alerts Yet",
    "label_alert_no_result_subtitle":"Start adding contacts to receive update alerts",
    "label_table_no_data_yet":"No Data Yet",
    "label_update_date":"Update Date",
    "label_new":"New",
    "label_status":"Status",
    "label_data_updated":"Data Updated",
    "label_update_available":"Update Available",
    "label_updated":"Updated",
    "label_reject_update":"Reject Update",
    "label_rejected":"Rejected",
    "label_reject_update_content":"Are you sure you would like to reject this prospect updates? You won't get any more updates.",
    "label_update_check":"Update this prospect for free.",
    "label_update_reject":"Reject this prospect updates.",
    "label_data_updated_tooltip":"{name}'s contact info/ role was changed. Click to view the latest updates.",
    "label_new_contacts_available":"Contacts Available",
    "label_alert_no_permission_title":"Receive Alerts When Designated Contacts Change Contacts or Jobs",
    "info_alert_no_permission_content_1":"Capitalize on new business opportunities that come with managerial changes in target accounts!",
    "label_alert_no_permission_page_title":'Receive alerts when designated contacts change contacts or jobs',
    "label_alert_no_permission_page_content":'Capitalize on new business opportunities that come with managerial changes in target accounts!',
    "info_success_data_updated":"Updated successfully.",
    "info_success_reject_data_updated":"Reject successfully.",

    "button_update":"Update",
    "button_update_all":"Update All",
    "button_update_selected":"Update Selected",
    "button_reject":"Yes, Reject",

    // ====Tour====
    "label_people_tour_step_1":"Before your search, lets show you how it works. Let's search contacts by company name.",
    "label_people_tour_step_2":"When search results come back, you'll see the card view of all contacts that fit your search requirements. Click “Save Contact”to get contact info and save to a list on AroundDeal. Save 1 contact will cost 1 credit.",
    "label_people_tour_step_3":"Nice! You just added 1 contact info! (This contact is free of charge)",
    "label_people_tour_step_3_b":"What's Next? You can:",
    "label_people_tour_step_3_li_1":"Continue prospecting and adding to your list",
    "label_people_tour_step_3_li_2":"Download your contacts or sync to your CRM",
    "label_people_tour_step_3_li_3":"Invite your team members to AroundDeal",

    // ===== Page No Permission======
    "label_bulk_no_permission_title_3":"Knowledge about the Bulk Domain Search",
    "label_bulk_no_permission_faq_header_1":"How can I find prospects with the Bulk Domain Search?",
    "label_bulk_no_permission_faq_header_2":"How much does it cost?",
    "label_bulk_no_permission_faq_header_3":"What is the max number of contacts I can find in Bulk Finder at a time?",
    "label_bulk_no_permission_faq_header_4":"Will AroundDeal charge me for contacts already saved in Prospector?",
    "label_bulk_no_permission_faq_content_1_p_1":"To use the Bulk Finder, you will need to have a list of domain names or company names ready. This list can be in a file (CSV), or you can simply copy and paste your list directly into AroundDeal.",
    "label_bulk_no_permission_faq_content_1_p_2":"Enter a unique name for your bulk task, and select your type of input. If your file contains website URLs, select Domain names.",
    "label_bulk_no_permission_faq_content_2_p_1":"We charge 1 credit per prospect. If a prospect is invalid or wasn' found, no credit will be charged. You will not be charged again for duplicates.",
    "label_bulk_no_permission_faq_content_3_p_1":"AroundDeal can process 50 prospects per domain and up to 10,000 prospects per Bulk Finder task.",
    "label_bulk_no_permission_faq_content_4_p_1":"No. Any prospects, that you've previously added (viewed contact info) will be excluded from the generated list.",

    "label_bulk_verifier_no_permission_title_1":"Knowledge about the Bulk Verifier",
    "label_bulk_verifier_no_permission_faq_header_1":"1.What is the Bulk Verifier?",
    "label_bulk_verifier_no_permission_faq_header_2":"2. Is there a cost for using the Bulk Verifier? ",
    "label_bulk_verifier_no_permission_faq_header_3":"3. Can email address marked Valid in the Bulk Verifier bounce?",
    "label_bulk_verifier_no_permission_faq_content_1_p_1":"The Bulk Email Verifier is a tool to verify a list of email addresses. Using the Bulk Verifier, you can test whether each email address is vaild without sending emails.",
    "label_bulk_verifier_no_permission_faq_content_2_p_1":"You can start a free trial to verify 20 emails. You can upgrade your plan for higher volumes if you desire. Your subscription can be canceled at any time.",
    "label_bulk_verifier_no_permission_faq_content_3_p_1":"These email addresses were recently verified and marked as Valid, so they are safe to use. We usually observe a bounce rate of less than 1% for these addresses.",

    "label_bulk_cleansing_no_permission_title_1":"Knowledge about the Bulk Cleansing",
    "label_bulk_cleansing_no_permission_faq_header_1":"1. How can I use the Bulk Cleansing?",
    "label_bulk_cleansing_no_permission_faq_header_2":"2. What is the max number of emails/phoens I can find in Bulk Cleansing at a time?",
    "label_bulk_cleansing_no_permission_faq_header_3":"3. Is there a cost for using the Bulk Cleansing?",
    "label_bulk_cleansing_no_permission_faq_content_1_p_1":"To use the Bulk Cleansing, you will need to have a list of emails or phones ready. This list can be in a file (CSV), or you can simply copy and paste your list directly into AroundDeal.",
    "label_bulk_cleansing_no_permission_faq_content_2_p_1":"AroundDeal can process up to 10,000 emails / phones per Bulk Cleansing task.",
    "label_bulk_cleansing_no_permission_faq_content_3_p_1":"You can start a free trial to clean 20 emails or phones. You can upgrade your plan for higher volumes if you desire.",
    "label_bulk_cleansing_no_permission_faq_content_3_p_2":"Your subscription can be canceled at any time.",

    "label_team_no_permission_title_2":"So What is Team Management All About?",
    "label_team_no_permission_title_3":"Knowledge about the Team Management",
    "label_team_no_permission_section_2_h4_1":"Organize Team at a Glance",
    "label_team_no_permission_section_2_h4_2":"Meet Your Goals with Reports",
    "label_team_no_permission_section_2_h4_3":"Identify Your Ideal Prospects",
    "label_team_no_permission_section_2_p_1":"Invite and remove users in your account. Define admin seats and allocate credits.",
    "label_team_no_permission_section_2_p_2":"Gain complete visibility to team performance with actionable insights.",
    "label_team_no_permission_section_2_p_3":"A deep look at your saved prospector profile and find look-a-like prospects with our recommendation engines.",
    "label_team_no_permission_faq_header_1":"Can I share my AroundDeal subscription with my team?",
    "label_team_no_permission_faq_header_2":"How many users can I invite?",
    "label_team_no_permission_faq_header_3":"Can I allocate credits?",
    "label_team_no_permission_faq_header_4":"What are user roles and permissions?",
    "label_team_no_permission_faq_content_1_p_1":"Only one user can access a single AroundDeal subscription. If you want have multiple users, you will need to buy more seats.",
    "label_team_no_permission_faq_content_1_p_2":"Each Team Seat is linked to ONE Paid AroundDeal Subscription and will be billed as part of the team total each month.",
    "label_team_no_permission_faq_content_1_p_3":"For example, if you add 3 Basic Monthly Seats to your Team Plan, you will be billed 5 x $39 = $195/month",
    "label_team_no_permission_faq_content_2_p_1":"Each plan offers a different number of users, depending on your needs.",
    "label_team_no_permission_faq_content_2_p_2":"Basic: 1-5 users",
    "label_team_no_permission_faq_content_2_p_3":"Premium: 1-10 users",
    "label_team_no_permission_faq_content_2_p_4":"If you want more seats please",
    "label_team_no_permission_faq_content_3_p_1":"If you have an Enterprise plan and as an admin, you can allocate your team of users credits. ",
    "label_team_no_permission_faq_content_4_p_1":"<p>Each user is assigned to a specific “role” with the relevant permissions.</p><p>Admin permissions:<br/>Use AroundDeal's extension and use credits<br/>Use the prospector feature<br/>Export all lists<br/>Manage the team members of the account (invite member, remove member, change roles)<br/>Manage the account billing details<br/>Manage domain (Visitors)</p><p>User permissions:<br/>Use AroundDeal's extension and use credits<br/>Use the prospector feature<br/>Export only contacts that were saved by the user<br/>View Team & Visitors dashboard & analytics page</p>",

    "label_visitor_no_permission_title_3":"Knowledge about the Visitors",
    "label_visitor_no_permission_section_2_h4_1":"1、Add Our Tracking Script",
    "label_visitor_no_permission_section_2_h4_2":"2、View Your Leads",
    "label_visitor_no_permission_section_2_p_1":"You'll need to add our unique tracking script to your website so we can begin finding you leads. This only needs to be done once and can be completed in a matter of minutes!",
    "label_visitor_no_permission_section_2_p_2":"After 24 hours, you'll begin to see your dashboard start to fill up with information about the companies that visited your website.",
    "label_visitor_no_permission_faq_header_1":"How does Visitors work?",
    "label_visitor_no_permission_faq_header_2":"Who has access to Visitors?",
    "label_visitor_no_permission_faq_header_3":"Can I track more than one website?",
    "label_visitor_no_permission_faq_header_4":"Can I use AroundDeal Visitors on my competitor's website?",
    "label_visitor_no_permission_faq_header_5":"How much does it cost?",
    "label_visitor_no_permission_faq_content_1_p_1":"AroundDeal Visitors, AroundDeal's anonymous visitor identification feature, works by identifying visitors through a combination of IP address, browser information. We exclusively track visitors all over the world. We utilize artificial intelligence (AI) and plain old human intelligence (HI) to eliminate visitors that cannot be correlated to a business, such as those that use a VPN, a mobile phone network or their personal ISP.",
    "label_visitor_no_permission_faq_content_2_p_1":"This function is only for enterprise users. If necessary, please",
    "label_visitor_no_permission_faq_content_2_p_2":"to activate it.",
    "label_visitor_no_permission_faq_content_3_p_1":"Yes, you can track multiple websites or website views. This article shows you how to do that.",
    "label_visitor_no_permission_faq_content_4_p_1":"No, you can only use AroundDeal with websites where you have permission to insert the AroundDeal script.",
    "label_visitor_no_permission_faq_content_5_p_1":"We charge 1 credit per unique company/prospect. You will not be charged again for duplicates.",


    // =====Enrichment====
    "nav_enrichment_csv":"CSV Enrichment",
    "nav_enrichment_api":"API Enrichment",
    "nav_enrichment_crm":"CRM Enrichment",
    "nav_enrichment_crm_run_list":"CRM Enrichment - Runs",
    "nav_enrichment_crm_job_list":"CRM Enrichment - Jobs",
    "nav_overview":"Overview",
    "nav_enrichment_overview":"Enrichment Overview",
    "label_crm_enricment_overview_title":"Welcome to Enrichment",
    "label_crm_enricment_overview_content":"Here you will find a variety of ways to enrich and update your records with information from AroundDeal.",
    "label_crm_enrichment_overview_p":"Update records in your CRM with AroundDeal's information.",
    "label_csv_enrichment_overview_p":"Enrich a CSV of records with AroundDeal's information.",
    "label_api_enrichment_overview_p":"Use our API enrich records programmatically.",
    "label_csv_enrichment_no_permission_title":"Enrich Your .csv File without Effort",
    "label_csv_enrichment_no_permission_content":"Upload a .csv file and let us do the work for you! We enrich your records with 50+ fields in the AroundDeal Database to complete records with the data that is critical for your business.",
    "label_csv_enrichment_no_permission_title_2":"So what can CSV Enrichment do?",
    "label_csv_enrichment_no_permission_section_2_h4_1":"People Enrichment",
    "label_csv_enrichment_no_permission_section_2_p_1":"Refresh your list with accurate profile, like business email, phone, title, location, etc.",
    "label_csv_enrichment_no_permission_section_2_p_2":"Get a 360-degree view of company profile, including details like company name, industry, website, speciality and more.",
    "label_csv_enrichment_no_permission_section_2_h4_2":"Company Enrichment",
    "label_csv_enrichment_no_permission_title_3":"Knowledge about CSV Enrichment",
    "label_csv_enrichment_no_permission_faq_header_1":"How can I enrich my .csv files?",
    "label_csv_enrichment_no_permission_faq_content_1_p_1":"1. Upload a CSV with your contact list",
    "label_csv_enrichment_no_permission_faq_content_1_p_2":"2. Map \"AroundDeal Fields\" to the columns in your CSV",
    "label_csv_enrichment_no_permission_faq_content_1_p_3":"3. Check Enrichment Report and Download CSVs",
    "label_csv_enrichment_no_permission_faq_header_2":"Who has the access to CSV Enrichment function?",
    "label_csv_enrichment_no_permission_faq_content_2_p_1":"CSV Enrichment is only available for Basic+ Users. If you want learn more details, you can find it out in <a href=\"/pricing\"><b class=\"text-primary\">Pricing</b></a>.",
    "label_csv_enrichment_no_permission_faq_header_3":"What is the available maximum size for raw .csv files ?",
    "label_csv_enrichment_no_permission_faq_content_3_p_1":"AroundDeal CSV Enrichment supports CSV files with a maximum size of 1,000 total rows. Files with more than 1,000 rows will cause an error. You will need to split the CSV into separate smaller files with fewer than 1,000 rows. ",
    "label_csv_enrichment_no_permission_faq_header_4":"What should my .csv file comply with?",
    "label_csv_enrichment_no_permission_faq_content_4_p_1":"<p>File must be in CSV format with a max file size of 20MB. The file should not exceed 1,000 rows. For an optimal enrichment make sure to have a Linkedin profile URL for each contact. </p><b>People Enrichment:</b><p>Map all of the items in at least one of the following groups:<br/>· Full Name, Company Domain<br/>· Full Name, Company Name<br/>· Person Linkedin URL<br/>· Email</p><b>Company Enrichment:</b><p>Map all of the items in at least one of the following groups:<br/>· Company Name<br/>· Company Linkedin URL<br/>· Company Domain</p>",
    "label_api_enrichment_no_permission_title":"Put Best-in-class Data Into Your System",
    "label_api_enrichment_no_permission_content_1":"AroundDeal's API makes it easy to build targeted contact lists or enrich any system with our B2B database.",
    "label_api_enrichment_no_permission_ul_1":"Add premium prospects to your systems",
    "label_api_enrichment_no_permission_ul_2":"Update contact and company data in your system",
    "label_api_enrichment_no_permission_title_2":"Supercharge Your Systems with AroundDeal's API",
    "label_api_enrichment_no_permission_section_2_h4_1":"Enrichment API",
    "label_api_enrichment_no_permission_section_2_h4_2":"Prospector API",
    "label_api_enrichment_no_permission_section_2_p_1":"Keep your records clean and up to date automatically with the Enrichment API",
    "label_api_enrichment_no_permission_section_2_p_2":"Search for over 100M contacts with the Prospector API",
    "label_api_enrichment_p":"Makes it easy to build targeted contact lists or enrich any system with our B2B database.",
    "label_crm_enrichment_no_permission_title":"Maintain the Accuracy and Timeliness of Your Salesforce Data",
    "label_crm_enrichment_no_permission_content":"Enrich your CRM at the frequency of your choice with easy-to-setup enrichment workflows.",
    "label_crm_enrichment_no_permission_title_2":"How it Works?",
    "label_crm_enrichment_no_permission_title_3":"Knowledge about the CRM Enrichment",
    "label_crm_enrichment_no_permission_section_2_h4_1":"1. Connect with Your Salesforce Account",
    "label_crm_enrichment_no_permission_section_2_h4_2":"2. Create a Workflow and Let the Automation Begin",
    "label_crm_enrichment_no_permission_section_2_h4_3":"3. View Enrichment Report and Results",
    "label_crm_enrichment_no_permission_section_2_p_1":"Use 1-click Integrations to connect AroundDeal to your CRM platform instantly.",
    "label_crm_enrichment_no_permission_section_2_p_2":"Set your enrichment rules like enrichment frequency, and any additional enrichment criteria that you desire.",
    "label_crm_enrichment_no_permission_section_2_p_3":"Go to our dashboard and Salesforce to check your enrichment results. ",
    "label_crm_enrichment_no_permission_faq_header_1":"1. How to connect AroundDeal to Salesforce?",
    "label_crm_enrichment_no_permission_faq_header_2":"2. How many credits are required for Salesforce enrichment?",
    "label_crm_enrichment_no_permission_faq_header_3":"3. Who has the access to CRM Enrichment?",
    "label_crm_enrichment_no_permission_faq_content_1_p_1":"Click “Connect” and log in to Salesforce using your credentials. This process will authorize AroundDeal to enrich Salesforce data on user’s behalf using OAuth2 security protocol.",
    "label_crm_enrichment_no_permission_faq_content_2_p_1":"One credit is used for each successful Salesforce contact/lead/company record enrichment. ",
    "label_crm_enrichment_no_permission_faq_content_3_p_1":"CRM Enrichment is only available for Enterprise Users.",
    "label_crm_enrichment_no_permission_faq_content_3_p_2":"to learn more details.",
    "label_crm_enrichment_no_result_title":"Enrich Your Salesforce Data with Easy-to-setup Workflows",
    "label_crm_enrichment_step_1_title":"1. Connect with Your Salesforce Account",
    "label_crm_enrichment_step_2_title":"2. Create a Workflow and Let the Automation Begin",
    "label_crm_enrichment_step_3_title":"3. View Enrichment Report and Results",
    "label_crm_enrichment_step_1_p":"Use 1-click Integrations to connect AroundDeal to your CRM platform instantly.",
    "label_crm_enrichment_step_2_p":"Set your enrichment rules like enrichment frequency, and any additional enrichment criteria that you desire.",
    "label_crm_enrichment_step_3_p":"Go to our dashboard and Salesforce to check your enrichment results. ",

    "label_most_popular":"Most Popular",
    "colunm_view_report":"View Report",
    "label_csv_enrichment_report":"CSV Enrichment Report",
    "label_company_csv_enrichment_report":"Company CSV Enrichment Report",
    "label_people_csv_enrichment_report":"People CSV Enrichment Report",
    "label_people_csv_enrichment_instant_report":"{type} Enrichment Report",
    "label_scheduled_crm_enrichment_report":"{type} Enrichment Report",
    "label_company_csv_enrichment_instant_report":"Accounts Instant Enrichment Report",
    "label_csv_enrichment_report_title":"CSV Enrichment Report #{id}",
    "label_crm_enrichment_report_title":"CRM Enrichment Report #{id}",
    "label_crm__instant_enrichment_report_title":"Instant Enrich Report #{id}",
    "label_companies_enriched":"Companies Enriched",
    "label_contacts_enriched":"Contacts Enriched",
    "label_credits_used":"Credits Used",
    "label_companies_match_rate":"Companies Match Rate",
    "label_contacts_match_rate":"Contacts Match Rate",
    "label_completeness_score":"Completeness Score",
    "label_enrichment_result":"Enrichment Result",
    "label_matched_fields":"Matched Fields",
    "label_emails_enriched":"Emails Enriched",
    "label_fileds_enriched":"Fields Enriched",
    "label_exported_buying_committee":"Exported Buying Committee",
    "label_credit_used":"{count} {credit} used",
    "label_companies_that_require_attention":"Companies that Require Attention",
    "label_contacts_that_require_attention":"Contacts that Require Attention",
    "colunm_contact_name":"Contact Name",
    "label_duplicates":"Duplicates({count})",
    "label_unmatched":"Unmatched({count})",
    "label_matched":"Matched({count})",
    "label_parent_company":"Parent Company",
    "label_ultimate_parent_company":"Ultimate Parent Company",
    "label_company_domain":"Company Domain",
    "label_company_linkedIn_url":"Company LinkedIn URL",
    "label_former_title":"Former Title",
    "label_former_company":"Former Company",
    "label_current_title":"Current Title",
    "label_current_company":"Current Company",
    "label_contact_info":"Contact Info",
    "label_back_to_csv_enrichment":"Back to CSV Enrichment Listing",
    "label_enriched_date":"Enriched Date",
    "label_task_name":"Task Name",
    "label_list_name":"List Name",
    "label_job_name":"Job Name",
    "label_run_name":"Run Name",
    "enrich_people_initial_list_name":"Enrich People - {time}",
    "enrich_company_initial_list_name":"Enrich Company - {time}",
    "enrich_scheduled_initial_list_name":"Scheduled Enrich - {time}",
    "enrich_instant_initial_list_name":"Instant Enrich - {time}",
    "task_name_required":"Please enter your task name",
    "job_name_required":"Please enter your job name",
    "button_back_to_bulk_finder_listing":"Back to Bulk Finder Listing",
    "alert_enrichment_people_message":"Please map at least one of the following: (<b>Full Name, Company Domain</b>) or (<b>Full Name, Company Name</b>) or (<b>Person Linkedin URL</b>) or (<b>Email</b>).",
    "alert_enrichment_company_message":"Please map at least one of the following: (<b>Company Name</b>, <b>Company Linkedin URL</b> or <b>Company Domain</b>).",
    "label_enrichment_results_cost":"Export enrichment results will cost {count}",
    "label_buying_committee_cost":"Export buying committee will cost {count}",
    "label_unique_contacts":"{count} unique contacts",
    "label_unique_companies":"{count} unique companies",
    "colunm_progress":"Progress",
    "colunm_total_records":"Total Records",
    "colunm_matched_records":"Matched Records",
    "colunm_no_matched_records":"No Matched Records",
    "colunm_buying_committee":"Buying Committee",
    "colunm_duplicates":"Duplicates",
    "label_column_company_domain":"Company Domain",
    "colunm_valid_records":"Valid Records",
    "contact_import_step_1_tips":"1. Import your data into AroundDeal",
    "contact_import_step_2_tips":"2. Mapping",
    "contact_import_step_1_info":'You can import up to 100,000 records at a time.',
    "contact_import_step_2_info":'Map "AroundDeal Fields" to the columns in your CSV',
    "list_created_successfully":"List created successfully.",

    "home_search_title":"Quick Search",
    "home_search_text":"Want to search by other criterial?",
    "home_search_link":"Go to Prospect Search",
    "home-list_1_title":"Connect your Gmail to AroundDeal",
    "home-list_1_text":"Link your emailbox now to automate emails, create templates and follow-ups, view open rates, and more. ",
    "home-list_2_title":"Create a sequence",
    "home-list_2_text":"Build automated outreach process to speed up sales processes, maximize work productivity, and achieve sales goals. ",
    "home-list_3_title":"Upload your own prospect list",
    "home-list_3_text":"Add contacts in sequences with a CSV to automate email outreach and move your sales opportunities forward.",
    "home-list_4_title":"Enrich targeted prospects in bulk",
    "home-list_4_text":"Refresh your list with accurate profile, like business email, phone, title, location, etc. Keep your data complete and accurate.",
    "home-list_5_title":"Verify email list",
    "home-list_5_text":"Easiest way to clean customer emails & databases and boost the ROI of your email marketing campaigns.",
    "home_usage_analytics":"Usage Analytics",
    "home_engagement_analytics":"Engagement Analytics",
    "home_all_analytics":"All analytics",
    "home_usage_analytics_credits":"Credits used",
    "home_usage_analytics_saved":"Saved prospects",
    "home_usage_analytics_lists":"Lists",
    "home_engagement_analytics_delivered":"Delivered",
    "home_engagement_analytics_opened":"Opened",
    "home_engagement_analytics_replied":"Replied",
    "home_engagement_analytics_interested":"Interested",
    

    "label_enrichment_instant":"Instant Enrich",
    "label_enrichment_scheduled":"Scheduled Enrich",
    "label_field_label":"Field Label",
    "label_operator":"Operator",
    "label_operation":"Operation",
    "label_value":"Value",
    "label_weekly":"Weekly",
    "label_monthly":"Monthly",
    "label_every":"Every",
    "label_enrichment_instant_step1_title":"1. Run Settings",
    "label_enrichment_scheduled_step1_title":"1. Job Settings",
    "label_enrichment_scheduled_step2_title":"2. Job Filters",
    "label_enrichment_instant_step2_title":"2. Run Filters",
    "label_enrichment_instant_step1_p_1":"Select the schedule to run this job",
    "label_enrichment_instant_step2_p_1":"Specify which record you would like to enrich",
    "label_enrichment_instant_step2_radio_1":"Enrich all records",
    "label_enrichment_instant_step2_radio_2":"Enrich records with specific criteria",
    "label_congigure_field_mapping":"Configure the field mapping",
    "label_congigure_field_mapping_p":"of AroundDeal to your Salesforce account and the update logic for each field.",
    "label_object_type":"Object Type",
    "label_job_frequency":"Job Frequency",
    "label_object_type_pp":"Please Select Object Type",
    "label_job_frequency_pp":"Please Select Job Frequency",
    "label_every_pp":"Please Select Every",
    "label_back_to_crm_enrichment":"Back to CRM Enrichment Listing",

    "info_success_integration_salesforce_pull":"We have successfully enqueued your request. Please wait a couple of hours for all records to show up.",
    "info_success_integration_salesforce_push":"Pushed {type} successfully.",
    "info_success_integration_salesforce_setting":"Settings saved successfully.",
    "label_job_setting_successfully":"Job status set successfully.",
    "info_success_enrichment_job_delete_successfully":"Job deleted successfully.",

    "label_integration_salesforce_pull_records":"Pull Records (Salesforce &gt; AroundDeal)",
    "label_integration_salesforce_pull_records_content":"This section contains all of the settings that control how information is pulled (downloaded) from Salesforce.  This includes information from Salesforce Contacts/Leads, Accounts.",
    "label_integration_salesforce_push_records":"Push Records (AroundDeal &gt; Salesforce)",
    "label_integration_salesforce_push_records_content":"This section contains all settings for pushing (uploading) records into Salesforce.  Use it to configure which records and record types are allowed to sync, and also control how they are synced. This includes Contacts/Leads, Accounts.",
    "label_integration_salesforce_mapping_content":"This section allows you to configure what record information is allowed to bi-drectionally sync between Salesforce and AroundDeal, and also control how it is synced. This includes Contacts/Leads, Accounts.",
    "label_integration_hubspot_mapping_content":"This section allows you to configure what record information is allowed to bi-drectionally sync between HubSpot and AroundDeal, and also control how it is synced. This includes Contacts, Accounts.",

    "label_integration_salesforce_pull_card_title_1":"Pull all Salesforce Contacts/Leads to AroundDeal contacts",
    "label_integration_salesforce_pull_card_content_p_1":"By default, your Salesforce Contacts and Leads will be pulled into AroundDeal every 15 minutes. Use this button to manually initiate a pull. Note: When a Contact and Lead exist for the same person, we will only pull the Contact.",
    "label_integration_salesforce_pull_card_content_p_2":"Automatically match my leads to accounts in AroundDeal",
    "label_integration_salesforce_pull_card_content_p_2_tooltip":"Salesforce doesn't allow Accounts to be associated with Leads (only Contacts), so this setting allows us to infer their Account for you. Learn more about how this works here.",
    "label_integration_salesforce_pull_card_title_2":"Pull all Salesforce Accounts to AroundDeal accounts",
    "label_integration_salesforce_pull_card_content_p_3":"By default, your Salesforce Accounts will be pulled into AroundDeal every 60 minutes. Use this button to manually initiate a pull.",
    "label_integration_salesforce_pull_card_content_p_4":"Infer missing data for Salesforce accounts with no name or no website",
    "label_integration_salesforce_pull_card_content_p_4_tooltip":"AroundDeal identifies Accounts based on their website and name, so when only one of those things exists in Salesforce, we can use it to infer the other. Then, once we have both, we can enrich the account with information from our database.",
    "label_pull_contacts_or_leads":"Pull Contacts/Leads",
    "label_push_contacts_or_leads":"Push Contacts/Leads",
    "label_pull_accounts":"Pull Accounts",
    "label_push_accounts":"Push Accounts",
    "label_push_contacts_or_leads_modal_p_1":"Please select which AroundDeal contacts/leads to push to Salesforce:",
    "label_push_contacts_or_leads_modal_p_2":"Push new contacts/leads and their accounts to Salesforce. This will only push AroundDeal contacts/leads not yet in Salesforce.",
    "label_push_contacts_or_leads_modal_p_3":"Overwrite existing Salesforce contacts/leads. This will overwrite existing Salesforce contacts/leads with AroundDeal information, if they differ.",
    "label_push_contacts_or_leads_modal_p_4":"Please select which AroundDeal accounts to push to Salesforce:",
    "label_push_contacts_or_leads_modal_p_5":"Push new accounts to Salesforce. This will only push AroundDeal accounts not yet in Salesforce.",
    "label_push_contacts_or_leads_modal_p_6":"Overwrite existing Salesforce accounts. This will overwrite existing Salesforce accounts with AroundDeal information, if they differ.",
    "label_pull_contacts_or_leads_content":"Are you sure? This will pull all contacts/leads and their accounts from Salesforce into AroundDeal. This updates records in AroundDeal that are already mapped to Salesforce, so it will reflect changes in Salesforce and changes in the integration settings (e.g. the contact status mapping)",
    "label_pull_accounts_content":"Are you sure? This will pull all accounts from Salesforce into AroundDeal. This updates records in AroundDeal that are already mapped to Salesforce, so it will reflect changes in Salesforce and changes in the integration settings (e.g. the contact status mapping)",
    "label_contacts_or_leads":"Contacts/Leads",
    "label_contacts":"Contacts",
    "label_leads":"Leads",
    "label_accounts":"Accounts",
    "label_integration_salesforce_push_card_title_1":"Push new AroundDeal Contacts/Leads to Salesforce",
    "label_integration_salesforce_push_card_title_2":"Create new records in Salesforce as Contacts or Leads?",
    "label_integration_salesforce_push_card_title_3":"For Contacts that are pushed to Salesforce, what should their “Source” be?",
    "label_integration_salesforce_push_card_title_4":"Push new AroundDeal Accounts to Salesforce",
    "label_integration_salesforce_push_card_title_5":"Which Salesforce field should we use for Account source?",
    "label_integration_salesforce_push_card_content_p_1":"New or updated AroundDeal Contacts/Leads are pushed to Salesforce in real-time. Use the button below to manually initiate a push.",
    "label_integration_salesforce_push_card_content_p_2":"While Salesforce has two record types for people (Contacts and Leads), AroundDeal only has one record type for people (Contacts). Because of this, you must choose whether new AroundDeal Contacts are created in Salesforce as Contacts or Leads.",
    "label_integration_salesforce_push_card_content_p_alert":"AroundDeal will create new Accounts during the Contact push.",
    "label_integration_salesforce_push_card_content_p_3":"Salesforce records have a “Source” field by default, and this setting allows you to customize what we put in that field.",
    "label_integration_salesforce_push_card_content_p_4":"New or updated AroundDeal accounts are pushed to Salesforce in real-time. Use the button below to manually initiate a push.",
    "label_integration_salesforce_push_card_content_p_4_alert":"AroundDeal is still configured to push to Salesforce Contacts, which will still push new Accounts during the Contact pushes if they do not exist.",
    "label_integration_salesforce_push_card_content_p_5":"Salesforce Accounts don't have a “Source” field by default, so this setting allows you to customize which field we push to.",
    "label_integration_salesforce_push_card_content_p_6":'Type in a value for Account source (e.g. "Created from AroundDeal")',
    "label_integration_salesforce_mapping_contact_p":'Use this page to configure what is synced between Salesforce Contacts and AroundDeal Contacts.',
    "label_integration_hubspot_mapping_p":'Map every AroundDeal Field you want to sync to a corresponding field in HubSpot (bi-directional sync). Fields left unmapped will not sync with your HubSpot fields.',
    "label_integration_salesforce_mapping_leads_p":'Use this page to configure what is synced between Salesforce Leads and AroundDeal Contacts.',
    "label_integration_salesforce_mapping_accounts_p":'Use this page to configure what is synced between Salesforce Accounts and AroundDeal Accounts.',
    "label_integration_salesforce_mapping_upgrade":'Upgrade your plan to access continuous CRM enrichment',

    "label_auto_overwrite":"Auto Overwrite",
    "label_autofill":"Autofill",

    "button_pull_all":"Pull All",
    "button_pull_now":"Pull Now",
    "button_push_all":"Push All",
    "button_not_started":"Not Started",
    "button_view":"View",
    "button_view_report":"View Report",
    "button_download_report":"Download Report",
    "button_download_buying_committee":"Download Buying Committee",
    "button_download_enrichment_results":"Download Enrichment Results",
    "button_download_duplicates":"Download Duplicates",
    "button_download_unmatched":"Download Unmatched",
    "button_download_no_matched_records":"Download No Matched Records",
    "button_download_matched_records":"Download Matched Records",
    "button_view_results":"View Results",
    "button_download_results":"Download Results",

    // ===== bulks =====
    "label_bulk_finder":"Bulk Finder",
    "label_bulk_cleansing":"Bulk Cleansing",
    "label_bulk_verifier":"Email Verifier",
    "label_back_to_bulk_cleansing_list":"Back to Bulk Cleansing Listing",
    "label_back_to_bulk_verifier_list":"Back to Bulk Verifier Listing",
    "label_bulk_finder_overview_p":"Enter or upload your targeted company names or domain names list, you will get your results with verified contacts.",
    "label_bulk_cleansing_overview_p":"Use Bulk Cleansing to remove incorrect, incomplete, duplicate, and improperly formatted data.",
    "label_bulk_verifier_overview_p":"Easiest way to clean customer emails & databases and boost the ROI of your email marketing campaigns.",
    "button_ok":"Ok",

    // ===== memu =====
    "label_people":"People",
    "label_memu_prospector_text_1":"Build highly targeted prospect lists with 10+ filters.",
    "label_memu_prospector_text_2":"Create your ideal filters based company lists.",
    "label_memu_prospector_text_3":"Easily manage and export your lists.",
    "label_memu_list_text_1":"Easily manage and export your prospect lists.",
    "label_memu_list_text_2":"Easily manage and export your company lists.",
    "label_memu_bulk_text_1":"Search or verify contact lists at lightning speed.",
    "label_memu_bulk_text_2":"Get a contact list from a list of companies or websites.",
    "label_memu_bulk_text_3":"Remove incorrect, incomplete, duplicate, and improperly formatted data.",
    "label_memu_bulk_text_4":"Decrease hard bounce rate with bulk email verification.",
    "label_memu_bulk_text_5":" nullllllll ",
    "label_memu_enrichment_text_1":"Get complete, up-to-date data for all of records.",
    "label_memu_enrichment_text_2":"Enrich your CSV file manually with AroundDeal data.",
    "label_memu_enrichment_text_3":"Enrich your CRM at the frequency of your choice with easy-to-setup enrichment workflows.",
    "label_memu_enrichment_text_4":"Makes it easy to enrich any system with AroundDeal's API.",
    "label_memu_alert_text_1":"Receive alerts when your prospects change contacts or jobs.",
    "label_memu_visitor_text_1":"View analytics by most visited pages, company industry, location and more.",
    "label_memu_visitor_text_2":"Find out what companies are visiting your website.",
    "label_memu_visitor_text_3":"Find the best person to contact from a visiting company.",
    "label_memu_visitor_text_4":"Add your tracking websites and filters to identify anonymous traffic.",
    "label_memu_team_text_1":"View analytics for account usage and credit usage.",
    "label_memu_team_text_2":"Effortlessly manage your team's people lists in one place.",
    "label_memu_team_text_3":"Effortlessly manage your team's company lists in one place.",
    "label_memu_team_text_4":"Easily invite new members to your team and manage their roles.",
    "label_memu_engage_text_1":"Build automated process to set meetings.",
    "label_memu_engage_text_2":"See who opened, or replied you from your emails.",
    "label_memu_engage_text_3":"Create and manage tasks to stay on top of your activities.",
    "label_memu_engage_text_4":"Create and manage email templates to speed up your workflow.",
    "label_memu_engage_text_5":"View your team's performance and prospecting results to make better decisions.",

    "label_account_edit_account":"You don't have permission to edit team's accounts/contacts owned by.",
    "label_account_edit_template":"You don't have permission to edit templates owned by other users.",
    "label_account_export":"You don't have permission to export contacts/accounts.",
    "label_account_add_sequences":"You don't have permission to create sequences.",
    "label_account_access_sequences":"You don't have permission to access this sequence.",
    "label_account_edit_setting":"You don't have permission to edit settings.",
    "label_account_send_email":"You don't have permission to send email to contacts owned by other users.",

    "label_company_specialties":"Company Specialty",
    "label_modal_discard_unsaved_changes_title":"Discard unsaved changes?",
    "label_modal_discard_unsaved_changes_content":"You have unsaved changes. Do you want to discard them?",
    "button_keep_editing":"No, keep editing",
    "button_discard":"Yes, discard",

    "engage_sequence_upload_file_info": "Please download the <a href=\"/data/sample.upload.csv\" target=\"_blank\" ><u>sample file</u></a> here.",

    // == System ====
    "label_page_maintenance":"Down for Maintenance",
    "label_page_maintenance_subtitle":"AroundDeal is temporarily unavailable due to scheduled system maintenance. This downtime will last <h3 class='text-primary d-inline-block'>2</h3> hours from <h3 class='text-primary d-inline-block'>1:30 pm - 3:30 pm (PST)</h3>.",

    // == Errors ====
    "==== VERY COMMON ====": "所有 ==== 开头的CODE，是为了备注和分隔线，并记录重复的CODE，并不是翻译用",
    "error_network": "Something went wrong. Please try again later.",
    "error_network:404": "Something went wrong. Please try again later.",
    "error_token:required": "Your session has expired, please login again.",
    "error_api:required": "Your session has expired, please login again.",
    "error_api:error": "Something went wrong. Please try again later.",
    "error_api:fetch": "Server currently unavailable. Please try again later.",
    "error_auth:require_login": "Please login again.",
    "error_db": "Server currently unavailable. Please try again later.",
    "error_params": "An unexpected error occurred. Please try again later.",
    "error_response": "Something went wrong. Please try again later.",
    
    "==== LOGIN ====": "Duplicated Keys：",
    "error_user:not_exist": "User does not exist.",
    "error_auth:pawword_incorrect" : "Password incorrect.",
    "error_auth:password_incorrect" : "Password incorrect.",
    "error_user:status": "The account is currently suspended.",
    "error_user:destroyed": "This account has been terminated, Contact us if you have any questions.",
    "error_user:disabled": "This account has been suspended for abuse violations, Contact us if you have any questions.",
    "error_user:reviewing": "This account is being reviewed, Contact us if you have any questions.",
    
    "==== REGISTER ====": "Duplicated Keys：",
    "error_email:require": "Email address is required.",
    "error_name:require": "Name is required.",
    "error_password:require": "Password is required.",
    "error_user:exist": "User already exists.",
    "error_partner:error": "The link you entered is invalid or expired.",
    "error_referr:error": "The refer link you entered is invalid or expired.",
    "error_auth:register_error": "Sign up error.",
    "error_email:black": "Your account has been restricted.",
    
    "==== CONFIRM ====": "Duplicated Keys：USER:NOT_EXIST",
    "error_auth:invalid_code": "The verification code is invalid.",
    
    "==== FORGET PASSWORD ====": "Duplicated Keys：USER:NOT_EXIST",
    "error_email:invalid": "Please enter a valid email address.",
    
    "==== RESET PASSWORD ====": "Duplicated Keys：USER:NOT_EXIST",
    "error_key:require": "The reset password link is invalid. Please try again.",
    "error_code:require": "The reset password link is invalid. Please try again.",
    "error_password:confrim_error": "The new password and confirmation password do not match.",
    "error_password:length": "Passwords must be at least 6 characters",
    "error_code:expired": "The reset password link has expired.",
    "error_code:error": "The reset password link is invalid. Please try again.",
    "error_domain:exist": "Domain is not exist.",

    "==== USER CHANGE INFO ====": "Duplicated Keys：USER:NOT_EXIST",
    
    "==== USER CHANGE PASSWORD ====": "Duplicated Keys：USER:NOT_EXIST, PASSWORD:LENGTH, PASSWORD:CONFRIM_ERROR",
    "error_password:error": "The current password is incorrect.",
    "error_password:wrong": "The current password is incorrect.",

    "==== RULE CREATE ====": "Duplicated Keys: NAME:REQUIRE",

    "==== RULE RENAME ====": "Duplicated Keys: ",
    "error_rule:not_exist": "Saved search does not exist.",
    "error_id:require": "Please select a saved search.",
    "error_name:exist": "The name you entered already exists.",
    
    "==== LIST CREATE ====": "Duplicated Keys: NAME:REQUIRE, NAME:EXIST",
    "error_list:error": "List is invalid.",
    
    "==== LIST RENAME ====": "Duplicated Keys: KEY:REQUIRE",
    "error_list:not_exist": "List does not exist.",
    "error_list:name_exist": "A list with the same name already exists. Please enter a new name.",

    "==== LIST ADD DATA ====": "Duplicated Keys: LIST:REQUIRE, ID:REQUIRE, LIST:NOT_EXIST",
    
    "==== LIST ADD AND CREATE DATA ====": "Duplicated Keys: LIST:REQUIRE, ID:REQUIRE, LIST:NOT_EXIST",
    "error_list:create": "Failed to create list. Please try again.",

    "==== LIST MOVE DATA ====": "Duplicated Keys: LIST:REQUIRE, ID:REQUIRE, LIST:NOT_EXIST",
    
    "==== LIST DELETE DATA ====": "Duplicated Keys: LIST:REQUIRE, ID:REQUIRE, LIST:NOT_EXIST",
    
    "==== LIST EXPORT DATA ====": "Duplicated Keys: LIST:REQUIRE, LIST:NOT_EXIST",
    "error_file:invalid": "File is invalid.",
    "error_file:expired": "File is expired.",

    "==== INVOICE CREATE ====": "Duplicated Keys: ",
    "error_package:error": "Package is invalid.",
    "error_promcode:error": "The promo code you entered is invalid or expired.",

    "==== INVOICE GET BY KEY ====": "Duplicated Keys: KEY:REQUIRE",
    "error_invoice:error": "Invoice is invalid.",
    "error_invoice:trial_card_type_error": "The free trial period only supports credit card payments.",

    "==== INVOICE CONTACT ====": "Duplicated Keys: ",
    "error_email:required": "Email is required.",
    "error_email:private": "Please enter a valid business email address.",
    "error_email:binded": "The email you entered is already used by another user.",

    "==== PALACE ====": "Duplicated Keys: INVOICE:ERROR, PROMCODE:ERROR",

    "==== PROMOCODE CHECK ====": "Duplicated Keys: PROMCODE:ERROR, INVOICE:ERROR",

    "==== FEEDBACK CREATE ====": "Duplicated Keys: NAME:REQUIRE, EMAIL:REQUIRE",
    "error_content:require": "Content is required.",

    // "==== TEAM ====": "Duplicated Keys:",
    "error_team:domain_error":"Please enter the email with your organization email domain.",
    "error_email:exist":"The email you entered is already registered. Please select 'invited members from your organization.'",
    "error_premission:denied":"You don't have permission to access this feature.",
    "error_invoice:card_error":"This credit card was used for multiple accounts.",


    "==== ERROR CODE FROM FRONTEND ====": "",
    "error_form:rule_name_required": "Please input your rule name!",
    "error_form:rule_rename_required": "Please input your rename!",
    "error_form:platform_required": "Please Select a Platform",
    "error_form:push_to_required": "Please Select push to",
    "error_form:list_required": "Please Select a List",
    "error_form:role_required": "Please Select a Role",
    "error_form:validate_code" : "Please enter validate code.",
    "error_form:people_name_required" : "Please enter your name.",
    "error_form:email_template_name_required" : "Please enter your email template name.",
    "error_form:email_template_subject_required" : "Please enter your email template subject.",
    "error_form:email_required" : "Please enter your email address.",
    "error_form:contact_required" : "Please Select at least one contact.",
    "error_form:email_invalid": "Please enter a valid email address.",
    "error_form:phone_invalid": "Please enter a valid phone number.",
    "error_form:password_required" : "Please enter your password.",
    "error_form:new_password_required" : "'Please enter your new password'",
    "error_form:confirm_password_required" : "Please confirm your password!",
    "error_form:verify_code_required" : "Please enter the verification code.",
    "error_form:password_not_match" : "Password not matched!",
    "error_form:support_message_required": "Please enter your message.",
    "error_form:list_name_required" : "Please enter list name.",
    "error_form:first_name_required" : "First name is required",
    "error_form:last_name_required" : "Last name is required",
    "error_form:sequence_name_required" : "Please enter sequence name.",
    "error_form:tag_name_required" : "Please enter a tag name.",
    "error_form:domain_address_required" : "Please enter domain address.",
    "error_form:persona_name_required" : "Please enter persona name.",
    "error_form:idea_customer_profile_name_required" : "Please enter idea customer profile name.",
    "error_form:domain_number_required" : "Please enter a number.",
    "error_form:number_required" : "Please enter a number.",
    "error_form:promo_expired": "The promo code you entered is invalid or expired.",
    "error_list:move_same": "You move to the same list.",
    "error_form:phone_required" : "Please enter your phone number.",
    "error_form:company_required" : "Please enter your company name.",
    "error_form:billing_address_required" : "Please enter your billing address.",
    "error_form:target_country_required" : "Please enter your target country.",
    "error_form:card_number_required" : "Please review the card details and try again.",
    "error_list:require": "List is required.",
    "error_credit:insufficient": "Sorry you have no credits left. Upgrade your plan now.",
	"ip:access_error": "You have been banned. Sorry, there have been too many login from this IP address.",
	"data:require": "DATA:REQUIRE",
	"consume:not_enough": "Insufficient credits. You need upgrade to perform it.",
	"consume:error": "Something went wrong. Please try again later.",
	"file:invaild": "The file was loaded in the wrong encoding. Please try another file.",

    "==== Engage ====": "Duplicated Keys：USER:NOT_EXIST",

    "==== Other ====": "MAILBOX:NOT_EXIST",
    "error_mailbox:not_exist": "Please connect your gmail to AroundDeal first to create the task.",
    "error_user:access_denied": "You've reached pageview limit, please try again later.",
    "error_number:only_one_step": "At least one step is required for the sequence.",
    "error_number:only_one_task": "At least one task is required for the step.",
    "error_permission:denied": "You don't have permission to access this page.",
    "error_promocode:error": "The promo code you entered is invalid.",
    "error_promocode:expired": "The promo code you entered has expired.",
    "error_promocode:used": "The promo code you entered has already been used.",



    
	"==== need to check below ====": "",
    
	"bulk:file_limit": "File must be smaller than 20MB.",
	"task:error": "Task is invalid.",
	"form:duplicate_mapping": "Duplicate Mapping",
	"task:limit": "To create new bulk domain search task, please add the previous search results to list first.",
	"enrichment:limit": "To create new enrichment task, please pay the previous task first.",
    "error_task_data:require":"Domain or company name is required.",
    "error_task_data:limit":"The number by rows may not be greater than 10,000.",

    "error_hello:world" : "Hello World!!!",
};

export default texts;
