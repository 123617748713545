import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableInvoiceList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageMsInvoice = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("Invoice列表 - AroundDeal管理后台");

	const inputOptions = [{ placeholder: "搜索Key", key: "key" }];

	const selectOptions = [
		{
			placeholder: "请选择状态",
			key: "status",
			options: [
				{ value: 0, label: "请选择状态" },
				{ value: 1, label: "Pending" },
				{ value: 2, label: "Paid" },
				{ value: 3, label: "Overdue" },
				{ value: 4, label: "Cancelled" },
			],
		},
		{
			placeholder: "请选择类型",
			key: "type",
			options: [
				{ value: 0, label: "请选择类型" },
				{ value: 1, label: "Normal" },
				{ value: 2, label: "Subscription" },
				{ value: 3, label: "Free Trial" },
			],
		},
		{
			placeholder: "请选择支付类型",
			key: "pay_type",
			options: [
				{ value: 0, label: "请选择支付类型" },
				{ value: 1, label: "Stripe" },
				{ value: 2, label: "Paypal" },
				{ value: 3, label: "Alipay" },
				{ value: 4, label: "WechatPay" },
				{ value: 5, label: "银行转账" },
				{ value: 6, label: "中文版-WechatPay" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/invoice"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								datePickerKey={"time_created"}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableInvoiceList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsInvoice;
