import React, { useState } from "react";
import { App, Card, Descriptions, Row, Spin, Dropdown, Button, Modal, notification } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { getDateCN, strictWithCnMap, userLevelMap, userStatusMap, subscriptionMap, userRoleStatusMap, userTypeMap} from "tools";
import { ModalSetUserLevel } from "./ModalSetUserLevel";
import { ModalSetTeamCredit } from "./ModalSetTeamCredit";
import { ModalSetUserType } from "./ModalSetUserType";
import { ModalSetUserStatus } from "./ModalSetUserStatus";

import { ModalSetUserPromocode } from "./ModalSetUserPromocode";

import { useSelector } from "react-redux";
import { useFetchUserSettingVisitor, useFetchUserAdminLogin, useFetchUserSettingCnData, useFetchUserCancelSubscription } from "hooks";
import { ModalSetUserLocation } from "./ModalSetUserLocation";

export const CardBasicInfo = ({ loading, userInfo, currentId, onHandleUpdateInfo }) => {
	const { modal, notification } = App.useApp();
	const user = useSelector((state) => state.user);
	const [openSetUserLevel, setOpenSetUserLevel] = useState(false);
	const [openSetTeamCredit, setOpenSetTeamCredit] = useState(false);
	const [openSetUserType, setOpenSetUserType] = useState(false);
	const [openSetUserStatus, setOpenSetUserStatus] = useState(false);
	const [openSetLocation, setOpenSetLocation] = useState(false); //设置地区的modal显示
	const [openSetPromocode, setOpenSetPromocode] = useState(false); //设置地区的modal显示
	const {
		id,
		level,
		tid,
		key,
		time_expired,
		tKey,
		name,
		time_create,
		role,
		email,
		role_status,
		status,
		ip,
		ip_text,
		credit,
		source,
		formateInfo,
		setting,
		code,
		type,
		email_status,
		aiQuery,
		visitorCompany,
		verification,
		has_visitor,
		teamCreditDetail,
		area
	} = userInfo || {};

	const [fetchSetVisitor] = useFetchUserSettingVisitor();
	const [fetchAdminLogin] = useFetchUserAdminLogin();
	const [fetchUserSettingCnData] = useFetchUserSettingCnData();
	const [fetchUserCancelSubscription] = useFetchUserCancelSubscription();

	const dataSource = [
		{ key: "ID", value: id },
		{ key: "等级", value: userLevelMap[level] },
		{ key: "用户类型", value: userTypeMap[type] },
		{ key: "Team ID", value: tid },
		{ key: "Key", value: key },
		{ key: "过期时间", value: time_expired ? getDateCN(time_expired) : null },
		{ key: "Team Key", value: tKey },
		{ key: "姓名", value: name },
		{ key: "创建时间", value: time_create ? getDateCN(time_create) : null },
		{ key: "Role", value: role?.name },
		{ key: "邮箱", value: email },
		{ key: "来源页", value: source },
		{ key: "Role status", value: userRoleStatusMap[role_status] },
		{ key: "状态", value: userStatusMap[status] },
		{ key: "IP", value: ip_text },
		{ key: "Team 现有成员数", value: formateInfo?.memberNumber },
		{ key: "剩余Credit", value: credit },
		{ key: "剩余aiQuery", value: aiQuery },
		{ key: "剩余Visitor Company", value: visitorCompany },
		{ key: "剩余verification", value: verification },
		{ key: "Cn权限", value: strictWithCnMap[setting?.strict_with_cn ? 1 : 0] },
		{ key: "Visitor权限", value: strictWithCnMap[has_visitor ? 1 : 0] },
		{ key: "Team 最大成员数", value: formateInfo?.maxMemberNumber },
		{ key: "订阅状态", value: subscriptionMap[setting?.subscription_status ? setting?.subscription_status : 3] },
		{ key: "验证码", value: status === 1 || status === 9 ? code : '-' },
		{ key: "Area", value: area },
		tid ? { key: "Team Credit 详情", value: teamCreditDetail } : {},
	];

	const formatData = dataSource.map((item) => {
		return {
			...item,
			value: item.value || "",
		};
	});

	const handleSetVisitor = () =>{
		modal.confirm({
			title:"分配Visitor权限",
			content:"您确定要分配给该账号Visitor权限吗?",
			maskClosable:'true',
            okText: '确定',
            onOk() {
                return new Promise((resolve, reject) => {
					fetchSetVisitor({
						uid :currentId,
						admin: user.name
					},
						{
							success: (data) => {
								if(onHandleUpdateInfo){
									onHandleUpdateInfo();
								}
								notification.success({message :"操作成功！"});
							},
							error: (error) => {
								notification.error({message : error.code});
							},
						});
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
                }).catch(() => console.log('Oops errors!'));
            },
		})
	}
	const handleAdminLogin = () =>{
		modal.confirm({
			title:"Tips",
			content:"您确定要登录该用户账号吗?",
			maskClosable:'true',
            okText: '确定',
            onOk() {
                return new Promise((resolve, reject) => {
					fetchAdminLogin({
						id:currentId,
						admin: user.name
					},
					{
						success: (data) => {
							window.open(data.url)
						},
						error: (error) => {
							notification.error({message : error.code});
						},
					});
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
                }).catch(() => console.log('Oops errors!'));
            },
		})
	}
	const handleSetCnData = () =>{
		modal.confirm({
			title:"Tips",
			content:"您确定要分配该账号CN数据吗?",
			maskClosable:'true',
            okText: '确定',
            onOk() {
                return new Promise((resolve, reject) => {
					fetchUserSettingCnData({
						uid :currentId,
						admin: user.name
					},
						{
							success: (data) => {
								if(onHandleUpdateInfo){
									onHandleUpdateInfo();
								}
								notification.success({message :"操作成功！"});
							},
							error: (error) => {
								notification.error({message : error.code});
							},
						});
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
                }).catch(() => console.log('Oops errors!'));
            },
		})
	}
	const handleSystemCancel = () =>{
		modal.confirm({
			title:"Tips",
			content:"您确定要取消该用户当前的订阅吗?",
			maskClosable:'true',
            okText: '确定',
            onOk() {
                return new Promise((resolve, reject) => {
					fetchUserCancelSubscription({
						uid :currentId,
						admin: user.name
					},
						{
							success: (data) => {
								if(onHandleUpdateInfo){
									onHandleUpdateInfo();
								}
								notification.success({message :"取消成功！"});
							},
							error: (error) => {
								notification.error({message : error.code});
							},
						});
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 500);
                }).catch(() => console.log('Oops errors!'));
            },
		})
	}

	const itemsDropdown = user && user.is_super_admin === 1 ? [
		{
			label: <a onClick={() => { setOpenSetUserLevel(true) }}>修改账号等级</a>,
			key: 'edit_level',
		},
		level != 'free' ?  {
			label: <a onClick={() => { setOpenSetTeamCredit(true) }}>发放权益</a>,
			key: 'credit',
		} : null,
		{
			label: <a onClick={() => { setOpenSetUserType(true) }}>设置用户类型</a>,
			key: 'set_type',
		},
		{
			label: <a onClick={() => { setOpenSetUserStatus(true) }}>设置用户状态</a>,
			key: 'set_status',
		},
		user && user.is_super_admin === 1 ?
		{
			label: <a onClick={() =>{setOpenSetLocation(true)}}>设置用户地区</a>,
			key: 'area',
		}:null,
		user && user.is_super_admin === 1 ?
		{
				label: <a onClick={() => { setOpenSetPromocode(true)}}>发放优惠券</a>,
			key: 'promocode',
		}:null,
		user && user.is_super_admin === 1 ?
		{
			label: <a onClick={handleSetVisitor}>分配Visitor权限</a>,
			key: 'visitor_permissions',
		}:null,
		user && user.is_super_admin === 1 ?
		{
			label: <a onClick={handleSetCnData}>分配Cn Data权限</a>,
			key: 'cn_data',
		}:null,
		user && user.is_super_admin === 1 ?
		{
			label: <a onClick={handleAdminLogin}>管理员登录</a>,
			key: 'admin_login',
		}:null,
		user && user.is_super_admin === 1 && setting?.subscription_status == 9 ? {
			label: <a onClick={handleSystemCancel}>系统取消订阅</a>,
			key: 'system_cancel',
		} : '',
	] : [];

	return (
		<>
			<Row align="middle" justify="space-between" style={{ marginBottom: "25px" }}>
				<div>
					<h2>
						用户详情 - {!name ? <Spin /> : name}
					</h2>
				</div>
				<div>
					<Dropdown
						menu={{
							items: itemsDropdown,
						}}
						trigger={['click']}
						placement="bottomRight"
					>
						<Button type="primary">操作 <DownOutlined /></Button>
					</Dropdown>
				</div>
			</Row>
			<Card title="基础信息" loading={loading}>
				<Descriptions
					bordered
					layout="horizontal"
					labelStyle={{ fontWeight: "bold" }}
				>
					{formatData.map((item, index) => (
						<Descriptions.Item label={item.key} key={index}>
							{item.value}
						</Descriptions.Item>
					))}
				</Descriptions>
			</Card>
			{
				openSetUserLevel && 
				<ModalSetUserLevel
					open={openSetUserLevel}
					currentId={currentId}
					onCancel={() => setOpenSetUserLevel(false)}
					onHandleUpdateInfo={onHandleUpdateInfo}
					userInfo={userInfo}
				/>
			}
			{
				openSetTeamCredit && 
				<ModalSetTeamCredit
					open={openSetTeamCredit}
					currentId={currentId}
					onCancel={() => setOpenSetTeamCredit(false)}
					onHandleUpdateInfo={onHandleUpdateInfo}
					userInfo={userInfo}
				/>
			}
			{
				openSetUserType && 
				<ModalSetUserType
					open={openSetUserType}
					currentId={currentId}
					onCancel={() => setOpenSetUserType(false)}
					onHandleUpdateInfo={onHandleUpdateInfo}
					userInfo={userInfo}
				/>
			}
			{
				openSetUserStatus && 
				<ModalSetUserStatus
					open={openSetUserStatus}
					currentId={currentId}
					onCancel={() => setOpenSetUserStatus(false)}
					onHandleUpdateInfo={onHandleUpdateInfo}
					userInfo={userInfo}
				/>
			}
			{
				openSetLocation && 
				<ModalSetUserLocation
					open={openSetLocation}
					currentId={currentId}
					onCancel={() => setOpenSetLocation(false)}
					onHandleUpdateInfo={onHandleUpdateInfo}
					userInfo={userInfo}
				/>
			}
			{
				openSetPromocode && 
				<ModalSetUserPromocode
					open={openSetPromocode}
					currentId={currentId}
					onCancel={() => setOpenSetPromocode(false)}
					onHandleUpdateInfo={onHandleUpdateInfo}
					userInfo={userInfo}
				/>
			}
		</>
	);
};
