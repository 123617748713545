import { createSlice } from "@reduxjs/toolkit";

const initValue = {
  info: {
    email: null,
    id: null,
    meta: [],
    mobile: null,
    name: "",
    picture: "",
    time_create: null,
    time_login: null,
    type: null,
  },
  is_super_admin: null,
  apps: [],
  permissions:[]
};

const userSlice = createSlice({
  name: "user",
  initialState: initValue,
  reducers: {
    setCreditAvailable(state, action) {
      state.credit = {
        ...state.credit,
        available: action.payload.credit,
      };
    },
    setData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setCreditAvailable, setData } = userSlice.actions;
export default userSlice.reducer;
