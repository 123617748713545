import { Layout } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav, LayoutNavForms } from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableMailList } from "./components";
import usePageTitle from "hooks/usePageTitle";
import { Fragment, useState } from "react";

const PageMsMail = () => {
	const [filterData, setFilterData] = useState({});
	usePageTitle("Mail列表 - AroundDeal管理后台");

	const inputOptions = [{ key: "email", placeholder: "搜索收件人邮箱" }];
	
	const selectOptions = [
		{
			key: "status",
			placeholder: "请选择推送状态",
			options: [
				{ value: "", label: "请选择推送状态" },
				{ value: 1, label: "待推送" },
				{ value: 2, label: "推送中" },
				{ value: 8, label: "推送成功" },
				{ value: 9, label: "推送失败" }
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/mail"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								datePickerKey={"time_add"}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableMailList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsMail;
