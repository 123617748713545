import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableUserList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageMsUser = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("User列表 - AroundDeal管理后台");

	const inputOptions = [
		{ key: "name", placeholder: "搜索用户名" },
		{ key: "email", placeholder: "搜索邮箱" },
		{ key: "source", placeholder: "搜索来源" },
		{ key: "partner_key", placeholder: "搜索Partner Key" },
	];

	const selectOptions = [
		{
			key: "level",
			placeholder: "选择账号类型",
			options: [
				{ value: "", label: "选择账号类型" },
				{ value: "free", label: "Free" },
				{ value: "lite", label: "Lite" },
				{ value: "basic", label: "Basic" },
				{ value: "plus", label: "Plus" },
				{ value: "pro", label: "Pro" },
				{ value: "premium", label: "Premium" },
				{ value: "growth", label: "Growth" },
				{ value: "ultimate", label: "Ultimate" },
				{ value: "enterprise", label: "Enterprise" },
			],
		},
		{
			key: "status",
			placeholder: "选择账户状态",
			options: [
				{ value: 0, label: "选择账户状态" },
				{ value: 1, label: "未激活" },
				{ value: 2, label: "已激活" },
				{ value: 8, label: "待审核" },
				{ value: 9, label: "邀请待激活" },
				{ value: 11, label: "已暂停" },
				{ value: 19, label: "已禁用" }
			],
		},
		{
			key: "email_source",
			placeholder: "选择注册来源",
			options: [
				{ value: 0, label: "选择注册来源" },
				{ value: 1, label: "Google" },
				{ value: 2, label: "Microsoft" },
				{ value: 3, label: "GoogleOneTap" },
				{ value: 4, label: "Websign" },
			],
		},
		{
			key: "area",
			placeholder: "选择归属地",
			options: [
				{ value: '', label: "选择归属地" },
				{ value: 'cn', label: "中国" },
				{ value: 'en', label: "其他" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/user"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableUserList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsUser;
