import React, { useState } from 'react';
import { Modal, Form, Button, App, Input } from 'antd';
import { consoleLog, langError } from 'tools';
import { useFetchRiskifySetMembership } from "hooks";

export const ModalSettings = ({ open, currentKey, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

    const [fetchSave] = useFetchRiskifySetMembership();

    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                expires: values.expires,
                key: currentKey,
            }, {
                success: (data) => {
                    setLoadingSave(false);
                    onCancel();
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    notification.success({"message":"保存成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }
    return (
        <Modal
            destroyOnClose
            open={open}
            title={"设为会员"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="expires"
                    label={"过期时间"}
                    style={{marginBottom:10}}
                >
                   <Input />
                </Form.Item>
                <p style={{color:"#636363"}}>* 例如：2024-02-21</p>
            </Form>
        </Modal>
    );
};
