import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableWorkflowList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageOperationWorkflow = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("工作流 - IT管理后台");

	// const inputOptions = [
	//   { key: "domain", placeholder: "搜索Workflow" },
	// ];

	// const selectOptions = [
	//   {
	//     key: "status",
	//     placeholder: "选择状态",
	//     options: [
	//       { value: 0, label: "选择状态" },
	//       { value: 1, label: "Active" },
	//       { value: 2, label: "Warning" },
	//       { value: 3, label: "Expired" },
	//     ],
	//   },
	// ];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["operation/workflow"]} />
					<LayoutPageContent>
						<Fragment>
							{/* <LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/> */}
							<TableWorkflowList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageOperationWorkflow;
