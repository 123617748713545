import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, InputNumber, DatePicker, Select, Col, Row } from 'antd';
import { lang, consoleLog, langError, getDateSimple } from 'tools';
import { useFetchMsAddRedeemCode, useFetchGetMsPartnerList } from "hooks";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

const dateFormat = 'YYYY-MM-DD';

export const ModalAddRedeemCode = ({ open, userInfo, currentId, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [partnerOptions, setPartnerOptions] = useState([]);

    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果

    const user = useSelector((state) => state.user);
    const { name } = user?.info;
    const [dateString, setDateString] = useState('');

    const [fetchSave] = useFetchMsAddRedeemCode();
    const [fetchGetMsPartnerList] = useFetchGetMsPartnerList();


    useEffect(() => {
        if (userInfo) {
            form.setFieldsValue({
                // number: userInfo.credit?userInfo.credit:0,
                time_expired: userInfo.time_expired ? dayjs(getDateSimple(userInfo.time_expired), dateFormat) : '',
            });
            setDateString(userInfo.time_expired ? getDateSimple(userInfo.time_expired) : '')
        }

    }, [userInfo]);

    useEffect(() => {
        // setLoading(true);
        fetchGetMsPartnerList({}, {
            success: (data) => {
                let newArr = []
                if (data && data.list.length > 0) {
                    newArr = data.list.map((item) => {
                        return {
                            value: item.id,
                            label: item.name
                        }
                    })
                }
                setPartnerOptions(newArr);
            },
            error: (error) => {
                setLoading(false);
                notification.error({ message: langError(error.code) });
            },
        });
    }, []);


    const handleSave = () => {
        form
            .validateFields()
            .then(values => {
                setLoadingSave(true);
                values.time_expired = dateString
                fetchSave(values, {
                    success: (data) => {
                        setLoadingSave(false);
                        onCancel();
                        if (onHandleUpdateInfo) {
                            onHandleUpdateInfo();
                        }
                        notification.success({ "message": "添加成功" });
                    },
                    error: (error) => {
                        setLoadingSave(false);
                        notification.error({ 'message': langError(error.code) });
                    },
                });
            })
            .catch(info => {
                consoleLog('Validate Failed:', info);
            });
    }

    const handleDateChange = (date, dateString) => {
        setDateString(dateString);
    }

    return (
        <Modal
            destroyOnClose
            open={open}
            title={"新增Redeem Code"}
            onCancel={onCancel}
            width={800}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="type"
                            label="类型"
                            initialValue="1"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择类型",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value="1">单次</Select.Option>
                                <Select.Option value="2">周期</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="level"
                            label="Level"
                            initialValue="basic"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择Level",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value="basic">Basic</Select.Option>
                                <Select.Option value="premium">Premium</Select.Option>
                                <Select.Option value="enterprise">Enterprise</Select.Option>
                                <Select.Option value="growth">Growth</Select.Option>
                                <Select.Option value="ultimate">Ultimate</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="credit"
                            label={"Credit数量"}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入Credit数量",
                                },
                            ]}
                        >
                            <InputNumber placeholder='请输入Credit数量' style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="ai"
                            label={"AI数量"}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入AI数量",
                                },
                            ]}
                        >
                            <InputNumber placeholder='请输入AI数量' style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="verify"
                            label={"Verifier数量"}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入Verifier数量",
                                },
                            ]}
                        >
                            <InputNumber placeholder='请输入Verifier数量' style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="visitor"
                            label={"Visitor数量"}
                        >
                            <InputNumber placeholder='请输入Visitor数量' style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="team_number"
                            label={"Team Member"}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入Team Member数量",
                                },
                            ]}
                        >
                            <InputNumber placeholder='请输入Team Member数量' style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="time"
                            label={"兑换码生效时间"}
                            rules={[
                                {
                                    required: true,
                                    message: "结合生效类型设定",
                                },
                            ]}
                        >
                            <InputNumber placeholder='请输入数量' style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="time_type"
                            label="兑换码生效时间类型"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择兑换码生效时间类型",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option value="day">day</Select.Option>
                                <Select.Option value="month">month</Select.Option>
                                <Select.Option value="year">year</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="number"
                            label={"申请数量"}
                            rules={[
                                {
                                    required: true,
                                    message: "请输入数量",
                                },
                            ]}
                        >
                            <InputNumber placeholder='请输入数量' style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="time_expired"
                            label="兑换码过期时间"
                        >
                            <DatePicker onChange={handleDateChange} style={{ width: "100%" }} format={dateFormat} allowClear={false} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="请选择Partner"
                            name="id_partner"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择Partner",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                key="project"
                                style={{ width: "100%" }}
                                placeholder="请选择Partner"
                                loading={loading}
                            >
                                {partnerOptions.map((option, index) => (
                                    <Select.Option value={option.value} key={index}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
