import { Fragment, useState } from "react";
import { Layout } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav, LayoutNavForms } from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableFeedbackList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageMsFeedback = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("Feedback列表 - AroundDeal管理后台");

	const inputOptions = [{ key: "data", placeholder: "搜索内容" }];
	
	const selectOptions = [
		{
			key: "type",
			placeholder: "请选择来源",
			options: [
				{ value: "", label: "请选择状态" },
				{ value: "normal", label: "FeedBack" },
				{ value: "prospect_feedback", label: "Prospect Feedback" },
				{ value: "subscription_cancel", label: "Subscription Cancel" },
				{ value: "invoice", label: "后台/Pricing" },
				{ value: "web", label: "官网/Pricing(历史)" },
				{ value: "web-support", label: "官网/Support" },
				{ value: "contact-outreach", label: "官网/Cold Email Outreach" },
				{ value: "web-contact-sales", label: "官网/Service" },
				{ value: "web-edit-profile", label: "官网/Edit Profile" },
				{ value: "web-remove-profile", label: "官网/Remove Profile" },
				{ value: "web-visitors", label: "官网/Visitors" },
				{ value: "web-contact-us", label: "官网/Product" },
				{ value: "uninstall", label: "官网/Uninstall" },
				{ value: "request", label: "官网(cn)/Request" },
				{ value: "dengta", label: "灯塔官网" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/feedback"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableFeedbackList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsFeedback;
