import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms,
} from "components";
import { LayoutPageContent, LayoutPageSidebar } from "../components";
import { TableSequenceList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageMsSequence = () => {
	const [filterData, setFilterData] = useState({});
	usePageTitle("Sequence列表 - AroundDeal管理后台");

	const inputOptions = [{ placeholder: "请输入用户名", key: "user_name" },{ placeholder: "请输入Sequence名称", key: "name" }];

	const selectOptions = [
		{
			placeholder: "请选择状态",
			key: "status",
			options: [
				{ value: "", label: "请选择状态" },
				{ value: 1, label: "已启用" },
				{ value: 2, label: "未启用" },
			],
		},
	];

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["ms/sequence"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								inputOptions={inputOptions}
								selectOptions={selectOptions}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableSequenceList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageMsSequence;
