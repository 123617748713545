import { useDispatch } from "react-redux";
import { setAuth } from "redux/reducer/initial";
import { fetchAPI, setAuthLocal } from "tools";

export const useFetchLogin = () => {
    const dispatch = useDispatch();
    const fetch = (data, opts) => {
        const options = Object.assign({
            'success': null,
            'error': null,
        }, opts);
        return fetchAPI('/sys/login', {
            method: 'post',
            data: data,
            success: (res) => {
                setAuthLocal(res);
                dispatch(setAuth(res));
                if (options['success']) {
                    options['success'](res);
                }
            },
            error: (error) => {
                if (options['error']) {
                    options['error'](error);
                }
            },
        });
    };
    return [fetch];
};
