import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Layout, Space } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { CardBasicInfo, CardListInfo } from "./components";
import { useFetchUserDetail } from "hooks";

const PageMsUserDetails = () => {
    const [loading, setLoading] = useState(false);

    usePageTitle("用户详情 - AroundDeal管理后台");

    const [search] = useSearchParams();
    const navigate = useNavigate();
    const id = search.get("uid");
    const filter_type = search.get("filter_type");

    const [fetchUserDetail] = useFetchUserDetail();
    const [userInfo, setUserInfo] = useState(null);
    const [listData, setListData] = useState([]);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [dataTotal, setDataTotal] = useState(0);
    const [forceFetch, setForceFetch] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetchUserDetail({
            id,
            type: filter_type ? filter_type : "log",
            page: pageCurrent
        },
            {
                success: (data) => {
                    const { user } = data;
                    setUserInfo(user);
                    setListData(data.log);
                    if (filter_type) {
                        if (filter_type === "log") {
                            if (data.log && data.log.list) {
                                setListData(data.log.list);
                                setDataTotal(data.log.page.total);
                                setPageSize(data.log.page.limit);
                            }
                        } else if (filter_type === "team_user") {
                            if (data.team && data.team.user) {
                                setListData(data.team.user);
                            }
                        } else if (filter_type === "team_credit") {
                            if (data.teamCredit) {
                                setListData(data.teamCredit);
                            }
                        } else if (filter_type === "credit") {
                            if (data.credits) {
                                setListData(data.credits);
                            }
                        } else if (filter_type === "people") {
                            if (data.list) {
                                setListData(data.list);
                            }
                        } else if (filter_type === "company") {
                            if (data.company_list) {
                                setListData(data.company_list);
                            }
                        } else if (filter_type === "invoice") {
                            if (data.invoices) {
                                setListData(data.invoices);
                            }
                        } else if (filter_type === "subscription") {
                            if (data.subscriptions) {
                                setListData(data.subscriptions);
                            }
                        } else if (filter_type === "extra") {
                            if (data.extra) {
                                setListData(data.extra);
                            }
                        }
                    } else {
                        if (data.log && data.log.list) {
                            setListData(data.log.list);
                            setDataTotal(data.log.page.total);
                            setPageSize(data.log.page.limit);
                        }
                    }
                    setLoading(false);
                },
                error: (error) => {
                    console.log(error);
                    setLoading(false);
                },
            });
    }, [id, search, pageCurrent, forceFetch]);

    const handleChangeTab = key => {
        let url = `&filter_type=${key}`;
        navigate(`/ms/user/detail?uid=${id}${url}`)
    }

    const handleChangePage = (page) => {
        setPageCurrent(page);
        window.scroll(0, 0);
    };

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            <CardBasicInfo loading={loading} userInfo={userInfo} currentId={id} onHandleUpdateInfo={() =>setForceFetch(forceFetch => forceFetch +1)} />
                            <CardListInfo loading={loading} listData={listData} activeKey={filter_type ? filter_type : "log"} pageCurrent={pageCurrent} dataTotal={dataTotal} pageSize={pageSize} onHandleChangeTab={handleChangeTab} onHandleChangePage={handleChangePage} />
                        </Space>
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageMsUserDetails;
