import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useFetchGetMailboxMailList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalMailDetail } from "./ModalMailDetail";
import { ModalForward } from "./ModalForward";

export const TableInboxList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [fetchGetMailboxInboxList] = useFetchGetMailboxMailList();
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [openModal, setOpenModal] = useState(false);
	const [openModalForward, setOpenModalForward] = useState(false);
	const [currentRow, setCurrentRow] = useState({});

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const items = [
		{
			label: "转发",
			key: "forward",
		},
	];

	const typeMap = {
		"reply": <Tag color="green">回复</Tag>,
		"auto": <Tag color="orange">自动回复</Tag>,
	};
	
	const columns = [
		{
			title: "时间",
			dataIndex: "time",

		},
		{
			title: "分类",
			dataIndex: "type",
		},
		{
			title: "发件人",
			dataIndex: "from_email",
			render: (_, record) => (
				<>
					<div style={{color: record.is_seen === 1? "#777":"#000", fontWeight: record.is_seen === 1? 400:600}}>{record.from_name}</div>
					<div style={{color: record.is_seen === 1? "#777":"#000" }}>{record.from_email}</div>
				</>
			)
		},
		{
			title: "标题",
			dataIndex: "subject",
			render: (_, record) => (
				<div onClick={() => { setOpenModal(true); setCurrentRow(record); }}>
					<div style={{color: record.is_seen === 1? "#777":"#000", fontWeight: record.is_seen === 1? 400:600}}>{record.subject}</div>
					<div style={{color: record.is_seen === 1? "#777":"#000" }}>{record.intro}</div>
				</div>
			)
		},
		{
			title: "操作",
			dataIndex: "operate",
			width:80,
			render: (_, record) => (
				<Dropdown
					menu={{
						items,
						onClick: (item) => {
							if (item.key === "forward") {
								setCurrentRow(record);
								setOpenModalForward(true);
							}
						},
					}}
				>
					<a onClick={(e) => e.stopPropagation()}>
						<Space>
							<EllipsisOutlined style={{ fontSize: 24 }} />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMailboxInboxList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								key: item.id,
								id: item.id,
								type: typeMap[item.type],
								time: getDateCN(item.time),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
					<ModalMailDetail
						open={openModal}
						onCancel={() => { setOpenModal(false); setCurrentRow({}) }}
						currentId={currentRow?.id}
					/>
					<ModalForward
						open={openModalForward}
						currentId={currentRow?.id}
						onCancel={() => { setOpenModalForward(false); setCurrentRow({}) }}
					/>

				</>
			)}
		</Fragment>
	);
};
