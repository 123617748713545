import React, { useState } from "react";
import { Button, Form, Modal, Input, App } from "antd";
import { useFetchMailboxForward } from "hooks";
import { langError } from "tools";

export const ModalForward = ({ open, currentId, onCancel }) => {
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [fetchForward] = useFetchMailboxForward();

	const [form] = Form.useForm();

	const handleAddApp = () => {
		form
			.validateFields()
			.then((values) => {
				values.id = currentId;
				setLoading(true);
				fetchForward(values, {
                    success: (data) => {
                        setLoading(false);
                        onCancel();
                        notification.success({ message: "已转发！" });
                    },
                    error: (error) => {
                        setLoading(false);
                        onCancel();
                        notification.error({ message: langError(error.code) });
                    },
                });
			})
			.catch((info) => { });
	};

	return (
		<Modal
			title="邮件转发"
			width={500}
			open={open}
			onCancel={onCancel}
			destroyOnClose={true}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					取消
				</Button>,
				<Button
					key="add"
					type="primary"
					loading={loading}
					onClick={handleAddApp}
				>
					确定
				</Button>,
			]}
		>
			<Form
				name="basic"
				form={form}
				layout="vertical"
				style={{ margin: "30px 0" }}
			>
				<Form.Item
					label="转发对象"
					name="email"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请填写转发对象",
						},
					]}
				>
					<Input />
				</Form.Item>
			</Form>
		</Modal>
	);
};
