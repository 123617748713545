import axios from "axios";
import Cookies from "universal-cookie";
import moment from "moment";
import { notification, Modal, Avatar, Tag } from "antd";
import { Base64 } from "js-base64";
import AppLocale from "../lang";
import config from "../config";
import {
	industry,
	company_type,
	joblevel,
	country,
	contact_type,
	functions,
	employee_range,
	people_status,
	company_status,
	stock_market,
	language_proficiency,
	distributor_type,
} from "../data/consts";

export const consoleLog = (object, title = "") => {
	if (config.env !== "prod") {
		if (title) {
			console.log("== " + title + " ==");
			console.log(object);
		} else {
			console.log(object);
		}
	}
};

export const getCurrency = (currency) => {
	if (currency === 1) {
		return "$";
	} else if (currency === 2) {
		return "¥";
	}
};

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const assets = (path) => {
	return "https://assets.arounddeal.com/ms" + path;
};

export const lang = (code, data) => {
	const locale = "en";
	let ret = code;
	if (AppLocale && AppLocale[locale] && AppLocale[locale]["texts"][code]) {
		ret = AppLocale[locale]["texts"][code];
	}

	if (data) {
		ret = t(ret, data);
	}

	return ret;
};

export const langError = (code, data) => {
	let code_error = "error_" + code.toLowerCase();

	return lang(code_error, data);
};

export const ireplace = (str, s, r) => {
	const regex = new RegExp(s, "ig");
	return str.replace(regex, r);
};

export const rand = (min, max) => {
	return parseInt(Math.random() * (max - min) + min);
};

export const getRandomString = (length) => {
	length = parseInt(length);
	if (!length || length <= 0) {
		length = 8;
	}
	let result = "";
	const characters = "abcdefghijklmnopqrstuvwxyz";
	const charactersLength = characters.length;
	let i = 0;
	for (i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const t = (tpl, data) => {
	let ret = tpl;
	let value = "";
	if (typeof data === "object" && Object.entries(data).length > 0) {
		for (const key in data) {
			value = data[key];
			ret = ireplace(ret, "{" + key.toLowerCase() + "}", value);
		}
	}
	return ret;
};

export const getDate = (timestamp) => {
	// return moment.unix(timestamp).format('YYYY/MM/DD')
	return moment.unix(timestamp).format("MMM DD, Y, h:mm A");
};
export const getDateSimple = (timestamp) => {
	return moment.unix(timestamp).format("YYYY-MM-DD");
};
export const getTime = (timestamp) => {
	return moment.unix(timestamp).format('HH:mm:ss')
};
export const getDateCN = (timestamp) => {
	return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss')
};

export const getDateTime = (timestamp) => {
	return moment.unix(timestamp).format("MMM DD, Y, h:mm A");
};

export const getDateTimeSecond = (timestamp) => {
	return moment.unix(timestamp).format("MMM DD, Y, h:mm:ss A");
};

export const getDateTimeSecondTwo = (timestamp) => {
	return moment.unix(timestamp).format("MMM DD, Y, HH:mm:ss A");
};

export const getTimestamp = () => {
	return Math.floor(Date.now() / 1000);
};
export const getFreeTrialModal = (onHandleOk) => {
	return Modal.confirm({
		maskClosable: "true",
		className: "free-trial",
		closable: true,
		icon: null,
		title: null,
		content: (
			<div className="my-3" style={{ textAlign: "center" }}>
				<img
					alt="example"
					src={assets("/img/others/free-gift.png")}
					width="50%"
				/>
				<h2 className="mt-1">{lang("label_free_trial_title")}</h2>
				<ul style={{ textAlign: "left", width: "80%", marginLeft: "50px" }}>
					<li>{lang("label_free_trial_content_1")}</li>
					<li>{lang("label_free_trial_content_2")}</li>
					<li>{lang("label_free_trial_content_3")}</li>
				</ul>
			</div>
		),
		okText: "🎁 " + lang("button_7_day_free_trial"),
		cancelButtonProps: { style: { display: "none" } },
		okButtonProps: { style: { margin: "0 0 20px 0" } },
		onOk() {
			onHandleOk();
		},
		onCancel() { },
	});
};

export const getAvatarName = (name, size) => {
	let str = "";
	if (name) {
		var y = name.replace(/\./g, " ").replace(/'-'/g, " ").replace(/','/g, " ");
		var z = y.split(" ");
		if (z[0]) {
			if (z[1]) {
				str = z[0].substr(0, 1).toUpperCase() + z[1].substr(0, 1).toUpperCase();
			} else {
				str = z[0].substr(0, 1).toUpperCase();
			}
		}
	} else {
		str = "-";
	}
	return (
		<Avatar
			size={size ? size : 34}
			style={{
				backgroundColor: "rgb(217 241 238)",
				color: "#069083",
				verticalAlign: "middle",
				fontSize: "13px",
			}}
		>
			<b>{str}</b>
		</Avatar>
	);
};

export const getIsMailboxLinked = (mailboxList) => {
	let flag = true;
	if (mailboxList) {
		if (mailboxList.length > 0) {
			// mailboxList.forEach(item =>{
			//     if(item.status === 2){   // 2为失败状态
			//         flag = false;
			//     }
			// })
			flag = true;
		} else {
			flag = false;
		}
	} else {
		flag = false;
	}
	return flag;
};

/**
 * 调用 输入提示 系统的请求
 * @param {string} path
 * @param {object} data
 * @param {object} opts
 * @returns
 */
export const doFetchTypeSuggester = (auth, path, data, opts) => {
	if (typeof auth != "object") {
		auth = {};
	}
	if (typeof opts != "object") {
		opts = {};
	}
	if (typeof opts != "object") {
		opts = {};
	}
	if (typeof opts.headers != "object") {
		opts.headers = {};
	}
	if (opts.method !== "get") {
		opts.method = "post";
	}

	opts.data = data;
	opts.responseType = "json";
	opts.timeout = 60000;

	return new Promise((resolve, reject) => {
		if (path) {
			opts.url = "https://tas.arounddeal.com/api/mv3/" + path;
			opts.headers["Authorization"] = "Bearer " + auth.token;
			axios(opts)
				.then(function (res) {
					if (res.data.ok) {
						resolve(res.data.data);
					} else {
						if (res.data.error && res.data.error.code) {
							res.data.error.code = res.data.error.code.toLowerCase();

							reject({
								code: res.data.error.code.toLowerCase(),
								detail: res.data.error.detail,
							});
						} else {
							reject({
								code: "response",
								detail: "",
							});
						}
					}
				})
				.catch(function (error) {
					reject({
						code: "network",
						detail: "",
						cancel: axios.isCancel(error) ? true : false,
					});
				});
		} else {
			reject("request");
		}
	});
};

/**
 * 调用 输入提示 系统的请求
 * @param {string} path
 * @param {object} data
 * @param {object} opts
 * @returns
 */
export const doFetchTypeSuggesterV2 = (auth, path, data, opts) => {
	if (typeof auth != "object") {
		auth = {};
	}
	if (typeof opts != "object") {
		opts = {};
	}
	if (typeof opts != "object") {
		opts = {};
	}
	if (typeof opts.headers != "object") {
		opts.headers = {};
	}
	if (opts.method !== "get") {
		opts.method = "post";
	}

	opts.data = data;
	opts.responseType = "json";
	opts.timeout = 60000;

	return new Promise((resolve, reject) => {
		if (path) {
			opts.url = "https://acb-1.arounddeal.com/v2/" + path;
			opts.headers["Authorization"] = "Bearer " + auth.token;
			axios(opts)
				.then(function (res) {
					if (res.data.ok) {
						resolve(res.data.data);
					} else {
						if (res.data.error && res.data.error.code) {
							res.data.error.code = res.data.error.code.toLowerCase();

							reject({
								code: res.data.error.code.toLowerCase(),
								detail: res.data.error.detail,
							});
						} else {
							reject({
								code: "response",
								detail: "",
							});
						}
					}
				})
				.catch(function (error) {
					reject({
						code: "network",
						detail: "",
						cancel: axios.isCancel(error) ? true : false,
					});
				});
		} else {
			reject("request");
		}
	});
};

/**
 * 往本地存储 Auth 信息
 *
 * @param {object} data
 */
export const setAuthLocal = (data) => {
	const cookies = new Cookies();
	if (data.jwt) {
		localStorage.setItem(config.keyStorageToken, data.jwt);
		cookies.set("v1-admin-center-user-token", data.jwt, {
			// domain: config.tld,
			path: "/",
			expires: new Date("2099-12-1"),
		});
		cookies.set("mv1-admin-center-user-token", data.jwt, {
			// domain: '.arounddeal.com',
			path: "/",
			expires: new Date("2099-12-1"),
		});
	}
	if (data.api) {
		localStorage.setItem(config.keyStorageAPI, data.api);
		cookies.set("v1-admin-center-user-api", data.api, {
			// domain: config.tld,
			path: "/",
			expires: new Date("2099-12-1"),
		});
		cookies.set("mv1-admin-center-user-api", data.api, {
			// domain: '.arounddeal.com',
			path: "/",
			expires: new Date("2099-12-1"),
		});
	}
	if (data.user) {
		if (data.user.name) {
			localStorage.setItem(config.keyStorageName, data.user.name);
		}
		cookies.set(
			"v1-admin-center-user-info",
			JSON.stringify({
				key: data.user.key,
				name: data.user.name,
			}),
			{
				// domain: config.tld,
				path: "/",
				expires: new Date("2099-12-1"),
			}
		);
	}
};

/**
 * 从本地存储中删除 Auth 信息
 *
 * @param {object} data
 */
export const unsetAuthLocal = () => {
	localStorage.removeItem(config.keyStorageToken);
	localStorage.removeItem(config.keyStorageAPI);
	localStorage.removeItem(config.keyStorageName);
	localStorage.removeItem(config.keyStorageApp);
	const cookies = new Cookies();
	cookies.remove("mv1-admin-center-user-token", {
		domain: ".arounddeal.com",
		path: "/",
	});
	cookies.remove("mv1-admin-center-user-api", {
		domain: ".arounddeal.com",
		path: "/",
	});
	cookies.remove("v1-admin-center-user-api");
	cookies.remove("v1-admin-center-user-token");
	cookies.remove("v1-admin-center-user-info");
};

export const jsonEncode = (obj) => {
	let ret = false;
	try {
		ret = JSON.stringify(obj);
	} catch (e) { }
	return ret;
};
export const jsonDecode = (string) => {
	let ret = false;
	try {
		ret = JSON.parse(string);
	} catch (e) { }
	return ret;
};

export const base64Encode = (obj) => {
	let ret = false;
	try {
		ret = Base64.encode(obj);
	} catch (e) { }
	return ret;
};
export const base64Decode = (string) => {
	let ret = false;
	try {
		ret = Base64.decode(string);
	} catch (e) { }
	return ret;
};

/**
 * 显示全局消息提示
 *
 * @param {string} type         // notification的类型，[success, error]
 * @param {string} text
 *
 */
export const showNotification = (type, text) => {
	if (type === "success") {
		if (text) {
			notification.success({
				message: text,
			});
		}
	} else if (type === "error") {
		if (text) {
			notification.error({
				message: text,
			});
		}
	}
};

export const listConstIndustry = () => {
	let ret = [];
	industry.forEach((id) => {
		const text = lang("const_industry_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextIndustry = (id) => {
	return lang("const_industry_" + id);
};

export const getConstTextProduct = (id) => {
	return lang("const_product_" + id);
};

export const listConstCompanyType = () => {
	let ret = [];
	company_type.forEach((id) => {
		const text = lang("const_company_type_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextCompanyType = (id) => {
	return lang("const_company_type_" + id);
};

export const listConstJoblevel = () => {
	let ret = [];
	joblevel.forEach((id) => {
		const text = lang("const_joblevel_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextJoblevel = (id) => {
	return lang("const_joblevel_" + id);
};

export const listConstCountry = () => {
	let ret = [];
	country.forEach((id) => {
		const text = lang("const_country_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextCountry = (id) => {
	return lang("const_country_" + id);
};

export const getTextLocation = (id, labels) => {
	return labels[id];
};

export const listConstDistributor = () => {
	let ret = [];
	distributor_type.forEach((id) => {
		const text = lang("const_distributor_type_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstDistributor = (id) => {
	return lang("const_distributor_type_" + id);
};

export const listConstContactType = () => {
	let ret = [];
	contact_type.forEach((id) => {
		const text = lang("const_contact_type_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextContactType = (id) => {
	return lang("const_contact_type_" + id);
};

export const listConstFunction = () => {
	let ret = [];
	functions.forEach((id) => {
		const text = lang("const_function_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextFunction = (id) => {
	return lang("const_function_" + id);
};

export const listConstEmployeeRange = () => {
	let ret = [];
	employee_range.forEach((id) => {
		const text = lang("const_employee_range_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextEmployeeRange = (id) => {
	return lang("const_employee_range_" + id);
};

export const listConstPeopleStatus = () => {
	let ret = [];
	people_status.forEach((id) => {
		const text = lang("const_people_status_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextPeopleStatus = (id) => {
	return lang("const_people_status_" + id);
};

export const listConstCompanyStatus = () => {
	let ret = [];
	company_status.forEach((id) => {
		const text = lang("const_company_status_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextCompanyStatus = (id) => {
	return lang("const_company_status_" + id);
};

export const listConstStockMarket = () => {
	let ret = [];
	stock_market.forEach((id) => {
		const text = lang("const_stock_market_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextStockMarket = (id) => {
	return lang("const_stock_market_" + id);
};

export const listConstLanguageProficiency = () => {
	let ret = [];
	language_proficiency.forEach((id) => {
		const text = lang("const_language_proficiency_" + id);
		if (text) {
			ret.push({
				id: id,
				text: text,
			});
		}
	});
	return ret;
};

export const getConstTextLanguageProficiency = (id) => {
	return lang("const_language_proficiency_" + id);
};

export const wildCardSearch = (list, input) => {
	const searchText = (item) => {
		for (let key in item) {
			if (item[key] == null) {
				continue;
			}
			if (
				item[key]
					.toString()
					.toUpperCase()
					.indexOf(input.toString().toUpperCase()) !== -1
			) {
				return true;
			}
		}
	};
	list = list.filter((value) => searchText(value));
	return list;
};

export const convertFilterToFilterSelectData = (filter) => {
	let ret = [];
	let values = null;

	if (filter) {
		for (const [key] of Object.entries(filter)) {
			values = convertFilterItemToFilterSelectData(filter, key);
			if (values.length > 0) {
				ret[key] = values;
			}
		}
	}
	return ret;
};

export const convertFilterItemToFilterSelectData = (
	filter,
	key,
	isShowLabel
) => {
	let ret = [];
	if (filter) {
		if (filter[key]) {
			if (key.charAt(0) !== "_") {
				filter[key].forEach((value) => {
					if (isShowLabel) {
						ret.push({
							value: value,
							type: "include",
							label: filter["_label"][key][value],
						});
					} else {
						ret.push({
							value: value,
							type: "include",
						});
					}
				});
			}
		}
		if (filter["_exclude"] && filter["_exclude"][key]) {
			filter["_exclude"][key].forEach((value) => {
				if (isShowLabel) {
					ret.push({
						value: value,
						type: "exclude",
						label: filter["_label"][key][value],
					});
				} else {
					ret.push({
						value: value,
						type: "exclude",
					});
				}
			});
		}
	}

	return ret;
};

export const getGoogleCode = () => {
	return "";
};

export const fetchSystemAPIWithAuth = (auth, path, opts) => {
	if (auth && auth.token) {
		opts.api = "https://ab.adealcorp.com/api";
		opts.token = auth.token;
		return fetchAPI(path, opts);
	} else {
		if (opts && opts["error"]) {
			opts["error"]({
				code: "AUTH",
			});
		}
		return false;
	}
};

export const fetchAPI = (path, opts) => {
	let options = Object.assign(
		{
			api: "",
			token: "",
			method: "post",
			data: {},
			success: null,
			error: null,
		},
		opts
	);
	let headers = {
		"Content-Type": "application/json",
		"X-Requested-With": "mv3-dev",
	};
	if (options["token"]) {
		headers["Authorization"] = "Bearer " + options["token"];
	}
	let fetchOptions = {
		method: options["method"],
		headers: headers,
		cache: "no-cache",
	};
	if (options["method"] === "post") {
		fetchOptions.body = JSON.stringify(options["data"]);
	}

	const url = config.urlAPI + path;
	fetch(url, fetchOptions)
		.then((response) => {
			return response.json();
		})
		.then((response) => {
			if (response) {
				if (response["ok"]) {
					if (options["success"]) {
						options["success"](response["data"]);
					}
				} else {
					if (response["error"]) {
						if (response["error"]["code"].indexOf("AUTH:") === 0) {
							if (options["error"]) {
								options["error"](response["error"]);
							}
							window.location.href = '/';
                        	unsetAuthLocal();
						} else {
							if (options["error"]) {
								options["error"](response["error"]);
							}
						}
					} else {
						if (options["error"]) {
							options["error"]({
								code: "FETCH:ERROR",
							});
						}
					}
				}
			} else {
				if (options["error"]) {
					options["error"]({
						code: "FETCH:RESPONSE",
					});
				}
			}
		})
		.catch((error) => {
			if (options["error"]) {
				options["error"]({
					code: "FETCH:CATCH",
				});
			}
		});
};

/**
 * 根据传递的uname字段返回大写开头的字母
 * @description {*}
 * @param {*}
 * @return {*}
 **/
export const handleUnameInitial = (uname) => {
	return uname.match(/[A-Z]/g)?.join("");
};

export const userStatusMap = {
	1: <Tag color="#bfbfbf">未激活</Tag>,
	2: <Tag color="#52c41a">已激活</Tag>,
	8: <Tag color="#feb019">待审核</Tag>,
	9: <Tag color="#feb019">邀请待激活</Tag>,
	11: <Tag color="#f5222d">已暂停</Tag>,
	19: <Tag color="#f5222d">已禁用</Tag>,
};

export const userRoleStatusMap = {
	1: <Tag color="#feb019">Pending</Tag>,
	2: <Tag color="#52c41a">Active</Tag>,
	3: <Tag color="#bfbfbf">Deleted</Tag>,
};

export const userLevelMap = {
	"free": <Tag color="#bfbfbf">Free</Tag>,
	"lite": <Tag color="#87d068">Lite</Tag>,
	"basic": <Tag color="#87d068">Basic</Tag>,
	"plus": <Tag color="#2db7f5">Plus</Tag>,
	"pro": <Tag color="#108ee9">Pro</Tag>,
	"premium": <Tag color="#108ee9">Premiun</Tag>,
	"growth": <Tag color="blue">Growth</Tag>,
	"ultimate": <Tag color="purple">Ultimate</Tag>,
	"enterprise": <Tag color="#3b5999">Enterprise</Tag>,
}

export const emailSourceMap = {
	1: <Tag color="blue">Google</Tag>,
	2: <Tag color="cyan">Microsoft</Tag>,
	3: <Tag color="orange">GoogleOneTap</Tag>,
	4: <Tag color="magenta">Websign</Tag>,
};

export const userTypeMap = {
	1: <Tag color="blue">普通用户</Tag>,
	7: <Tag color="cyan">试用用户</Tag>,
	11: <Tag color="orange">内部用户</Tag>,
};

export const invoiceStatusMap = {
	1: <Tag color="#feb019">Pending</Tag>,
	2: <Tag color="#52c41a">Paid</Tag>,
	3: <Tag color="#2db7f5">Overdue</Tag>,
	4: <Tag color="#bfbfbf">Cancelled</Tag>,
}
export const extraEquityTypeMap = {
	1: <Tag color="#feb019">CLEANSE</Tag>,
	2: <Tag color="#52c41a">VERIFIER</Tag>,
	3: <Tag color="#2db7f5">AI_QUERY</Tag>,
	4: <Tag color="#bfbfbf">VISITOR_COMPANY</Tag>,
	6: <Tag color="#ff0000">EXPROTS</Tag>,
}

export const invoicePayTypeMap = {
	1: <Tag color="orange">Stripe</Tag>,
	2: <Tag color="geekblue">Paypal</Tag>,
	3: <Tag color="blue">Alipay</Tag>,
	4: <Tag color="green">WechatPay</Tag>,
	5: <Tag color="pink">银行转账</Tag>,
	6: <Tag color="cyan">中文版-WechatPay</Tag>,
};

export const invoiceTypeMap = {
	1: <Tag color="#87d068">Normal</Tag>,
	2: <Tag color="#3b5999">Subscription</Tag>,
	3: <Tag color="#feb019">Free Trial</Tag>,
};

export const subscriptionTypeMap = {
	1: <Tag color="orange">月付</Tag>,
	2: <Tag color="blue">年付</Tag>,
};

export const subscriptionStatusMap = {
	1: <Tag color="#2db7f5">Init</Tag>,
	2: <Tag color="#52c41a">Active</Tag>,
	3: <Tag color="#bfbfbf">Cancelled</Tag>,
	4: <Tag color="red">Under Review</Tag>,
};

export const subscriptionMap = {
	1: <Tag color="#52c41a">订阅中</Tag>,
	0: <Tag color="#bfbfbf">否</Tag>,
	3: '-',
	9: <Tag color="#f00">Under Review</Tag>,
};

export const sequenceActiveMap = {
	0: <Tag color="#bfbfbf">未启用</Tag>,
	1: <Tag color="#52c41a">已启用</Tag>,
};

export const enrichmentStatusMap = {
    1: <Tag color="orange">待处理</Tag>,
    2: <Tag color="cyan">处理中</Tag>,
    3: <Tag color="green">已完成</Tag>,
    4: <Tag color="#52c41a">已扣费</Tag>,
    7: <Tag color="red">已失败</Tag>,
};

export const enrichmentTypeMap = {
    "people": <Tag color="blue">people</Tag>,
    "company": <Tag color="purple">company</Tag>,
};

export const verifyStatusMap = {
    1: <Tag color="orange">待处理</Tag>,
    2: <Tag color="cyan">处理中</Tag>,
    3: <Tag color="green">已完成</Tag>,
    4: <Tag color="#52c41a">已扣费</Tag>,
};

export const feedbackSourceMap = {
	"normal": <Tag color="#faad14">FeedBack</Tag>,
	"prospect_feedback": <Tag color="#faad14">Prospect Feedback</Tag>,
	"subscription_cancel": <Tag color="#faad14">Subscription Cancel</Tag>,
	"invoice": <Tag color="#52c41a">后台/Pricing</Tag>,
	"web": <Tag color="#1677ff">官网/Pricing(历史)</Tag>,
	"web-support": <Tag color="#1677ff">官网/Support</Tag>,
	"contact-outreach": <Tag color="#1677ff">官网/Cold Email Outreach</Tag>,
	"web-contact-sales": <Tag color="#1677ff">官网/Service</Tag>,
	"web-edit-profile": <Tag color="#1677ff">官网/Edit Profile</Tag>,
	"web-remove-profile": <Tag color="#1677ff">官网/Remove Profile</Tag>,
	"web-visitors": <Tag color="#1677ff">官网/Visitors</Tag>,
	"web-contact-us": <Tag color="#1677ff">官网/Product</Tag>,
	"uninstall": <Tag color="#1677ff">官网/Uninstall</Tag>,
	"request": <Tag color="#1677ff">官网(cn)/Request</Tag>,
	"dengta": <Tag color="#9254de">灯塔官网</Tag>
};

export const mailStatusMap = {
	1: <Tag color="#bfbfbf">待推送</Tag>,
	2: <Tag color="orange">推送中</Tag>,
	8: <Tag color="green">推送成功</Tag>,
	9: <Tag color="red">推送失败</Tag>,
};

export const visitorPeopleFilterMap = {
	1: <Tag color="#52c41a">已启用</Tag>,
	2: <Tag color="#bfbfbf">未启用</Tag>,
};

export const visitorStatusMap = {
	1: <Tag color="#52c41a">已启用</Tag>,
	2: <Tag color="#bfbfbf">未启用</Tag>,
};

export const visitorVerifiedMap = {
	1: <Tag color="orange">未验证</Tag>,
	2: <Tag color="blue">验证中</Tag>,
	3: <Tag color="green">验证通过</Tag>,
};

export const strictWithCnMap = {
	1: <Tag color="#52c41a">已开通</Tag>,
	0: <Tag color="#bfbfbf">未开通</Tag>,
};

export const redeemCodeStatusMap = {
	1: <Tag color="#bfbfbf">未使用</Tag>,
	2: <Tag color="#52c41a">已使用</Tag>,
	3: <Tag color="red">已销毁</Tag>,
};
export const redeemCodeTypeMap = {
	1: <Tag color="#bfbfbf">单次</Tag>,
	2: <Tag color="#52c41a">周期</Tag>,
};

export const teamStatusMap = {
    1: <Tag color="#feb019">Pending</Tag>,
    2: <Tag color="#52c41a">Active</Tag>,
    3: <Tag color="#bfbfbf">Cancelled</Tag>,
};

export const apiStatusMap = {
    1: <Tag color="green">success</Tag>,
    2: <Tag color="red">未启用</Tag>,
};

export const apiKeyStatusMap = {
	1: <Tag color="#52c41a">已启用</Tag>,
	2: <Tag color="#bfbfbf">未启用</Tag>,
};
export const recentSearchTypeMap = {
	1: <Tag color="#52c41a">People</Tag>,
	2: <Tag color="#2db7f5">Company</Tag>,
};

export const bemLevelMap = {
	"STARTER": <Tag color="orange">STARTER</Tag>,
	"PRO": <Tag color="green">PRO</Tag>,
	"UNLIMITED": <Tag color="blue">UNLIMITED</Tag>,
	"UNLIMITED+": <Tag color="purple">UNLIMITED+</Tag>,
	"FREE": <Tag color="gray">FREE</Tag>,
};


