import { Fragment, useState } from "react";
import { Layout } from "antd";
import {
	LayoutAuthRequired,
	LayoutHeaderNav,
	LayoutNavForms
} from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import { TableLogsList } from "./components";
import usePageTitle from "hooks/usePageTitle";

const PageRiskifyLogs = () => {
	const [filterData, setFilterData] = useState({});

	usePageTitle("Logs - Riskify插件管理后台");

	const handleChangeFilter = (key, value) => {
		setFilterData((state) => {
			return {
				...state,
				[key]: value,
			};
		});
	};

	return (
		<LayoutAuthRequired>
			<Layout
				theme="light"
				style={{
					minHeight: "100vh",
				}}
			>
				<LayoutHeaderNav />
				<Layout>
					<LayoutPageSidebar selectedKey={["riskify/log"]} />
					<LayoutPageContent>
						<Fragment>
							<LayoutNavForms
								singleDatePickerKey={"time"}
								onHandleChangeFilter={handleChangeFilter}
							/>
							<TableLogsList filterData={filterData} />
						</Fragment>
					</LayoutPageContent>
				</Layout>
			</Layout>
		</LayoutAuthRequired>
	);
};
export default PageRiskifyLogs;
