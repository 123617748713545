import React from 'react';
import { Button, Modal } from 'antd';
import { lang } from 'tools';

export const ModalMailDetail = ({ open, title = "", data, onCancel }) => {

    return (
        <Modal
            destroyOnClose
            open={open}
            title={title}
            width={800}
            footer={[
                <Button key="cancel" onClick={onCancel}>{lang('button_close')}</Button>,
            ]}
            onCancel={onCancel}
            >
            <div style={{maxHeight:"600px", overflow:"scroll", paddingRight:"20px"}}>
                {data}
            </div>
        </Modal>
    );
}