import React, { useState } from "react";
import { Card, Table } from "antd";
import { getDateCN } from "tools";
import { ModalResponse } from "components";

const tabListTitle = [
    {
        key: 'peopleFilters',
        label: 'People Persona',
    },
    {
        key: 'companyFilters',
        label: 'Company Filter',
    },
    {
        key: 'company',
        label: 'Company数据',
    },
    {
        key: 'people',
        label: 'People数据',
    }
];

export const CardListInfo = ({ loading, listData, activeKey, pageCurrent, dataTotal, pageSize, onHandleChangeTab, onHandleChangePage }) => {
    const [openModal, setOpenModal] = useState(false);
	const [currentRow, setCurrentRow] = useState({});
    const onTabChange = (key) => {
        if (onHandleChangeTab) {
            onHandleChangeTab(key)
        }
    };

    let columns = [];
    if (activeKey === "peopleFilters" || activeKey === "companyFilters") {
        columns = [
            {
                title: "ID",
                dataIndex: "id",
            },
            {
                title: "Persona名称",
                dataIndex: "name",
            },
            {
                title: "Per domain number",
                dataIndex: "agent",
                width:200,
                render:(_,record) =>(
                    <span>{record.meta && record.meta.max_number}</span>
                )
            },
            {
                title: "Filter",
                dataIndex: "data",
                width:800,
                render:(_,record) =>(
                    <a onClick={()=> {setOpenModal(true);setCurrentRow(record);}}>{JSON.stringify(record.data)}</a>
                )
            },
            {
                title: "创建时间",
                dataIndex: "time_created",
                render: (_, record) => (
                    <span>{getDateCN(record.time_created)}</span>
                )
            }
        ];
    } else if (activeKey === "company") {
        columns = [
            {
                title: "ID",
                dataIndex: "id",
                width:120
            },
            {
                title: "公司名",
                dataIndex: "name",
            },
            {
                title: "地区",
                dataIndex: "location_hq_full",
            },
            {
                title: "第一次查看时间",
                dataIndex: "time_first_visit",
                render: (_, record) => (
                    <span>{record.time_first_visit? getDateCN(record.time_first_visit):"-"}</span>
                )
            },
            {
                title: "最后一次查看时间",
                dataIndex: "time_last_visit",
                render: (_, record) => (
                    <span>{record.time_last_visit? getDateCN(record.time_last_visit):"-"}</span>
                )
            },
        ];
    } else if (activeKey === "people") {
        columns = [
            {
                title: "ID",
                dataIndex: "eid",
                width:120
            },
            {
                title: "人名",
                dataIndex: "name",
                render: (_, record) => (
                    <span>{record.profile && record.profile.name}</span>
                )
            },
            {
                title: "公司名",
                dataIndex: "company_name",
                render: (_, record) => (
                    <span>{record.profile && record.profile.company_name}</span>
                )
            },
            {
                title: "创建时间",
                dataIndex: "time_add",
                render: (_, record) => (
                    <span>{record.time_add? getDateCN(record.time_add):"-"}</span>
                )
            },
        ];
    }

    return (
        <Card
            loading={loading}
            style={{
                width: '100%',
            }}
            tabList={tabListTitle}
            activeTabKey={activeKey}
            onTabChange={onTabChange}
        >
            <Table
                dataSource={listData}
                columns={columns}
                loading={loading}
                scroll={{ x: "max-content", y: 600 }}
                pagination={activeKey === "log" ? {
                    current: pageCurrent,
                    pageSize: pageSize,
                    total: dataTotal,
                    showSizeChanger: false,
                    onChange: onHandleChangePage,
                } : false}
                rowKey={record => record.id || record.uid || record.key}
            />
            <ModalResponse
                open={openModal}
                title={"Filter详情"}
                onCancel={()=>{setOpenModal(false);setCurrentRow({});}}
                data={currentRow.data && JSON.stringify(currentRow.data)}
            />
        </Card>
    );
};
