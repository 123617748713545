import React from "react";
import logo from "assets/img/logo/logo-light.svg";

export const LogoSm = () => {
  return (
    <img
      src={logo}
      alt="AroundDeal Logo"
      style={{
        width: "36px",
        height: "36px",
      }}
    />
  );
};
