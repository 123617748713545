import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Layout, Space } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { CardBasicInfo, CardListInfo } from "./components";
import { useFetchVisitorDetail } from "hooks";

const PageMsVisitorDetails = () => {
    const [loading, setLoading] = useState(false);

    usePageTitle("Visitor详情 - AroundDeal管理后台");

    const [search] = useSearchParams();
    const navigate = useNavigate();
    const id = search.get("uid");
    const filter_type = search.get("filter_type");

    const [fetchVisitorDetail] = useFetchVisitorDetail();
    const [dataInfo, setDataInfo] = useState(null);
    const [listData, setListData] = useState([]);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [dataTotal, setDataTotal] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetchVisitorDetail({
            id,
            type: filter_type ? filter_type : "peopleFilters",
            page: pageCurrent
        },
            {
                success: (data) => {
                    setDataInfo(data);
                    setListData(data.log);
                    if (filter_type) {
                        if (filter_type === "peopleFilters") {
                            if (data.peopleFilters) {
                                setListData(data.peopleFilters);
                            }
                        } else if (filter_type === "companyFilters") {
                            if (data.companyFilters) {
                                setListData(data.companyFilters);
                            }
                        } else if (filter_type === "company") {
                            if (data.company && data.company.list) {
                                setListData(data.company.list);
                            }
                            if(data.company && data.company.count){
                                setDataTotal(data.company.count)
                            }
                        } else if (filter_type === "people") {
                            if (data.people && data.people.list) {
                                setListData(data.people.list);
                            }
                            if(data.people && data.people.count){
                                setDataTotal(data.people.count)
                            }
                        }
                    }else{
                        if (data.peopleFilters) {
                            setListData(data.peopleFilters);
                        }
                    }
                    setLoading(false);
                },
                error: (error) => {
                    setLoading(false);
                },
            });
    }, [id, search, pageCurrent]);

    const handleChangeTab = key => {
        let url = `&filter_type=${key}`;
        navigate(`/ms/visitor/detail?uid=${id}${url}`)
    }

    const handleChangePage = (page) => {
        setPageCurrent(page);
        window.scroll(0, 0);
    };

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            <CardBasicInfo loading={loading} dataInfo={dataInfo} />
                            <CardListInfo loading={loading} listData={listData} activeKey={filter_type ? filter_type : "peopleFilters"} pageCurrent={pageCurrent} dataTotal={dataTotal} pageSize={pageSize} onHandleChangeTab={handleChangeTab} onHandleChangePage={handleChangePage} />
                        </Space>
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageMsVisitorDetails;
