import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton } from "antd";
import { useFetchGetLarkEnrichList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalResponse } from "components";



export const TableEnrichList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [fetchGetLarkEnrichList] = useFetchGetLarkEnrichList();
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [openModal, setOpenModal] = useState(false);
	const [currentRow, setCurrentRow] = useState({});
	const [currentModal, setCurrentModal] = useState("");

	const statusMap = {
		8 : <Tag color="green">Success</Tag>,
		9 : <Tag color="red">Failed</Tag>,
		11 : <Tag color="orange">insuffcient</Tag>,
	};
	const typeMap = {
		1 : <Tag color="purple">People</Tag>,
		2 : <Tag color="blue">Company</Tag>,
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			width: 120,
		},
		{
			title: "User_ID",
			dataIndex: "uid",
			width: 120,
		},
		{
			title: "Data",
			dataIndex: "data",
			ellipsis: true,
			render: (_, record) => (
				<a onClick={() => { setOpenModal(true); setCurrentRow(record); setCurrentModal("data") }}>{record.data}</a>
			)
		},
		{
			title: "补全类型",
			dataIndex: "type",
			width: 120,
		},
		{
			title: "Status",
			dataIndex: "status",
			width: 120,
		},
		{
			title: "Result",
			dataIndex: "result",
			ellipsis: true,
			render: (_, record) => (
				<a onClick={() => { setOpenModal(true); setCurrentRow(record); setCurrentModal("result") }}>{record.result}</a>
			)
		},
		{
			title: "创建时间",
			dataIndex: "time_created",
			width: 200,
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetLarkEnrichList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								key: item.id,
								status: statusMap[item.status],
								type: typeMap[item.type],
								time_created: getDateCN(item.time_created),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
					<ModalResponse
						open={openModal}
						title={"数据详情"}
						onCancel={() => { setOpenModal(false); setCurrentRow({}); setCurrentModal("") }}
						data={currentModal === "data" ? (currentRow?.data) : (currentRow?.result)}
					/>
				</>
			)}
		</Fragment>
	);
};
