import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Dropdown, Button } from "antd";
import { EllipsisOutlined, DownOutlined } from "@ant-design/icons";
import { useFetchGetEDMCoverList } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalMailDetail } from "./ModalMailDetail";
import { ModalSendTestEmail } from "./ModalSendTestEmail";
import { ModalAddNewsletter } from "./ModalAddNewsletter";
import { ModalCopy } from "./ModalCopy";
import { useNavigate } from "react-router-dom";

export const TableCoverList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [openModal, setOpenModal] = useState(false);
	const [openModalCopy, setOpenModalCopy] = useState(false);
	const [openModalTest, setOpenModalTest] = useState(false);
	const [openModalAddNewsletter, setOpenModalAddNewsletter] = useState(false);
	const [currentRow, setCurrentRow] = useState({});
	const [forceList, setForceList] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetEDMCoverList] = useFetchGetEDMCoverList();

	const navigate = useNavigate();

	const items = [
		{
			label: "编辑",
			key: "edit",
		},
		{
			label: "发送测试邮件",
			key: "send_test",
		},
		{
			label: "复制",
			key: "copy",
		},
		// {
		// 	label: "删除",
		// 	key: "delete",
		// },
	];

	const typeMap = {
		"stripo": <Tag color="#feb019">Newsletter</Tag>,
		"html": <Tag color="#52c41a">开发信</Tag>,
	};

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "名称",
			dataIndex: "name",
		},
		{
			title: "所属人",
			dataIndex: "user",
		},
		{
			title: "分类",
			dataIndex: "type",
			render: (_, record) => (
				<>{typeMap[record.type]}</>
			)
		},
		{
			title: "创建时间",
			dataIndex: "time",
		},
		{
			title: "操作",
			dataIndex: "operate",
			width: 80,
			render: (_, record) => (
				<Dropdown
					menu={{
						items,
						onClick: (item) => {
							setCurrentRow(record);
							if (item.key === "forward") {
								setOpenModalTest(true);
							} else if (item.key === "copy") {
								setOpenModalCopy(true);
							} else if (item.key === "send_test") {
								setOpenModalTest(true);
							} else if (item.key === "edit") {
								if (record.type === "html") {
									navigate(`/edm/cover/edit?id=${record.id}`);
								} else {
									navigate(`/edm/newsletter/edit?id=${record.id}`);
								}

							}
						},
					}}
				>
					<a onClick={(e) => e.stopPropagation()}>
						<Space>
							<EllipsisOutlined style={{ fontSize: 24 }} />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetEDMCoverList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								key: item.id,
								id: item.id,
								time: getDateCN(item.time),
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});


		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	const itemsDropdown = [
		{
			label: "创建Email Cover",
			key: 'add_cover',
		},
		{
			label: "创建Newsletter",
			key: 'add_newsletter',
		},
	];

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Dropdown
								menu={{
									items: itemsDropdown,
									onClick: (item) => {
										if (item.key === "add_cover") {
											navigate("/edm/cover/edit");
										} else if (item.key === "add_newsletter") {
											setOpenModalAddNewsletter(true);
										}
									},
								}}
								trigger={['click']}
								placement="bottomRight"
							>
								<Button type="primary">创建 <DownOutlined /></Button>
							</Dropdown>
						</Row>
					</Space>

					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
					<ModalMailDetail
						open={openModal}
						title={"邮件详情"}
						onCancel={() => { setOpenModal(false); setCurrentRow({}) }}
						data={currentRow?.intro}
					/>
					<ModalSendTestEmail
						open={openModalTest}
						currentId={currentRow?.id}
						onCancel={() => { setOpenModalTest(false); setCurrentRow({}) }}
					/>
					<ModalCopy
						open={openModalCopy}
						currentId={currentRow?.id}
						onCancel={() => { setOpenModalCopy(false); setCurrentRow({}) }}
						onUpdateList={() => setForceList(forceList => forceList + 1)}
					/>
					{
						openModalAddNewsletter &&
						<ModalAddNewsletter
							open={openModalAddNewsletter}
							onCancel={() => { setOpenModalAddNewsletter(false);}}
						/>
					}
				</>
			)}
		</Fragment>
	);
};
