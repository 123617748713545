const base = {
  name: "AroundDeal Admin Center",
  version: "1.1.0",
  tld: "arounddeal.com",
  urlAPI: "https://ab.adealcorp.com/api",
  keyStorageToken: "admin-center-user-token",
  keyStorageUser: "admin-center-user-info",
  keyStorageVerifyEmail: "admin-center-user-verify",
  keyStorageAPI: "admin-center-user-api",
  keyStorageName: "admin-center-user-name",
  keyStorageApp: "admin-center-main-app",
  keyStorageNotificationTime: "admin-center-user-ntime",
  pathLogin: "/login",
  // pathApp: '/dashboard',
  pathApp: "/app",
  pathHome: "/home",
  axiosTimeout: 60000,
  notificationTimeHold: 3,
  theme: {
    navCollapsed: false,
    sideNavTheme: "SIDE_NAV_DARK", // SIDE_NAV_LIGHT, SIDE_NAV_DARK
    locale: "en",
    navType: "TOP", // SIDE, TOP
    topNavColor: "transparent",
    headerNavColor: "#069083",
    mobileNav: false,
    current: "light",
    rowGutter: 16,
    sideNavWidth: 250,
    sideNavCollapsedWidth: 80,
    direction: "ltr", // ltr, rtl
  },
};

export default base;
