import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { EllipsisOutlined } from "@ant-design/icons";
import { App, Button, Row, Space, Table, Typography, Dropdown, Tag, Skeleton } from "antd";
import { useFetchGetWorkflowList,
	//  useFetchCheckWorkflow, useFetchDeleteWorkflow
	 } from "hooks";
import { langError, getDateCN } from "tools";
import { ModalAddWorkflow } from "./ModalAddWorkflow";


export const TableWorkflowList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { modal, notification } = App.useApp();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [fetchGetWorkflowList] = useFetchGetWorkflowList();
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [forceList, setForceList] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);


	const [openAddWorkflow, setOpenWorkflowModal] = useState(false);


	const statusMap = {
		8: <Tag color="green">成功</Tag>,
		9: <Tag color="red">失败</Tag>,
	};


	const columns = [
		{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "创建人",
			dataIndex: "uname",
		},
		{
			title: "操作",
			dataIndex: "action",
		},
		{
			title: "状态",
			dataIndex: "status",
		},
		{
			title: "内容",
			dataIndex: "data",
		},
		{
			title: "指派人",
			dataIndex: "assignor_name",
		},
		{
			title: "备注",
			dataIndex: "remark",
		},
		{
			title: "创建时间",
			dataIndex: "time_add",
		}
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetWorkflowList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								time_expired: getDateCN(item.time_expired),
								time_add: getDateCN(item.time_add),
								data : JSON.stringify(item.data),
								status: statusMap[item.status],

							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
	}, [id, pageCurrent, filterData, forceList]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
								<Button type="primary" onClick={() => setOpenWorkflowModal(true)}>
									创建上线请求
							</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
						rowKey={record => record.id}
					/>
				</>
			)}
			{openAddWorkflow && (
				<ModalAddWorkflow
					open={openAddWorkflow}
					onCancel={() => {
						setOpenWorkflowModal(false);
					}}
					onHandleUpdateList={() => setForceList(forceList => forceList + 1)}
				/>
			)}
		</Fragment>
	);
};
