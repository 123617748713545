import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	App,
	Row,
	Space,
	Table,
	Typography,
	Skeleton,
	Tooltip,
	Tag,
} from "antd";
import { useFetchGetMsUserList } from "hooks";
import { langError, getDateCN, userStatusMap, userLevelMap, emailSourceMap } from "tools";

export const TableUserList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [dataTotal, setDataTotal] = useState(0);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);

	const [fetchGetMsUserList] = useFetchGetMsUserList();

	const columns = [
		{
			title: "ID",
			dataIndex: "id",
			width: 100
		},
		{
			title: "姓名",
			dataIndex: "name",
			render: (_, record) => (
				<span> {record.area == 'cn' ? <Tag color="#ff0000">CN</Tag> : null} {record.name} {record.subscription_status ? <Tag color="green">订阅中</Tag> : null}</span>
			)
		},
		{
			title: "邮箱",
			dataIndex: "email",
		},
		{
			title: "手机号",
			dataIndex: "mobile",
		},
		{
			title: "注册来源",
			dataIndex: "email_source",
		},
		{
			title: "状态",
			dataIndex: "userStatus",
			width: 100
		},
		{
			title: "Credit",
			dataIndex: "credit",
			width: 100
		},
		{
			title: "Level",
			dataIndex: "level",
			width: 100
		},
		{
			title: "Ip",
			dataIndex: "ip_text",
			width: 200
		},
		{
			title: "来源",
			dataIndex: "source",
			ellipsis: true,
			render: (_, record) => (
				<Tooltip title={record.source}>{record.source}</Tooltip>
			)
		},
		{
			title: "创建时间",
			dataIndex: "time_created",
		}
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData };
			fetchGetMsUserList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(() => {
						return data.list.map((item) => {
							return {
								...item,
								operate: item.id,
								userStatus: userStatusMap[item.status],
								level: userLevelMap[item.level],
								email_source: emailSourceMap[item.email_source],
								time_created: getDateCN(item.time_create),
								time_expired: item.time_expired ? getDateCN(item.time_expired) : '',
							};
						});
					});
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	}

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<div className="cursor">
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						scroll={{ x: 1500 }}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}

						onRow={(record, index) => {
							return {
								onClick: event => {
									window.open(`/ms/user/detail?uid=${record.id}`);
								},
							};
						}}
					/>
				</div>
			)}
		</Fragment>
	);
};
