import React, { useState } from "react";
import { App, Card, Descriptions, Row, Spin, Dropdown, Button, Modal, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { getDateCN} from "tools";
import { useSelector } from "react-redux";

export const CardBasicInfo = ({ loading, taskInfo, currentId, onHandleUpdateInfo }) => {
	const user = useSelector((state) => state.user);
	const {
		id,
		ka,
		subject,
		status,
		type,
		time_create,
		data
	} = taskInfo || {};

	console.log(taskInfo);
	
	const typeMap = {
		"people": <Tag color="orange">People</Tag>,
		"company": <Tag color="green">Company</Tag>,
	};

	const statusMap = {
		1: <Tag color="grey">等待</Tag>,
		8: <Tag color="green">成功</Tag>,
		9: <Tag color="orange">失败</Tag>,
	};

	const dataSource = [
		{ key: "ID", value: id },
		{ key: "KA", value: ka },
		{ key: "标题", value: subject },
		{ key: "类型", value: typeMap[type] },
		{ key: "状态", value: statusMap[status] },
		{ key: "创建时间", value: time_create ? getDateCN(time_create) : null },
		{ key: "备注", value: data ? data.remark : '' }
	];

	const formatData = dataSource.map((item) => {
		return {
			...item,
			value: item.value || "",
		};
	});

	return (
		<>
			<Row align="middle" justify="space-between" style={{ marginBottom: "25px" }}>
				<div>
					<h2>
						任务详情 - {!subject ? <Spin /> : subject}
					</h2>
				</div>
			</Row>
			<Card title="基础信息" loading={loading}>
				<Descriptions
					bordered
					layout="horizontal"
					labelStyle={{ fontWeight: "bold" }}
				>
					{formatData.map((item, index) => (
						<Descriptions.Item label={item.key} key={index}>
							{item.value}
						</Descriptions.Item>
					))}
				</Descriptions>
			</Card>
		</>
	);
};
