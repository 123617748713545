import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Layout, Space } from "antd";
import { LayoutAuthRequired, LayoutHeaderNav } from "components";
import { LayoutPageSidebar, LayoutPageContent } from "../components";
import usePageTitle from "hooks/usePageTitle";
import { CardBasicInfo } from "./components";
import { useFetchRiskifyPackageCombines, useFetchRiskifyPackageDetail } from "hooks";

const PageRiskifyPackageDetails = () => {
    const [loading, setLoading] = useState(false);

    usePageTitle("Package详情 - Riskify插件管理后台");

    const [search] = useSearchParams();
    const id = search.get("uid");
    const type = search.get("type");

    const [fetchPackageDetail] = useFetchRiskifyPackageDetail();
    const [fetchPackageCombines] = useFetchRiskifyPackageCombines();
    const [userInfo, setUserInfo] = useState(null);
    const [listCombines, setListCombines] = useState([]);
    const [forceFetch, setForceFetch] = useState(0);

    useEffect(() => {
        setLoading(true);
        fetchPackageDetail({
            app: type,
            key: id
        },
            {
                success: (data) => {
                    setUserInfo(data);
                    setLoading(false);
                },
                error: (error) => {
                    setLoading(false);
                },
            });
            fetchPackageCombines({
            app: type,
            key: id
        },
            {
                success: (data) => {
                    setListCombines(data);
                    setLoading(false);
                },
                error: (error) => {
                    setLoading(false);
                },
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, search, forceFetch]);

    return (
        <LayoutAuthRequired>
            <Layout
                theme="light"
                style={{
                    minHeight: "100vh",
                }}
            >
                <LayoutHeaderNav />
                <Layout>
                    <LayoutPageSidebar />
                    <LayoutPageContent>
                        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                            <CardBasicInfo loading={loading} id={id} type={type} userInfo={userInfo} listCombines={listCombines} onHandleUpdateInfo={() => setForceFetch(forceFetch => forceFetch + 1)} />
                        </Space>
                    </LayoutPageContent>
                </Layout>
            </Layout>
        </LayoutAuthRequired>
    );
};

export default PageRiskifyPackageDetails;
