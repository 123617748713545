import { useState } from "react";
import { Layout, theme } from "antd";
import {
  LayoutAuthRequired,
  LayoutHeaderNav,
  LayoutNavForms,
} from "components";
import { LayoutPageSidebar } from "../components";
import { TableCleanseList } from "./components";
import usePageTitle from "hooks/usePageTitle";
// import { Sidebar } from "components";

const { Content } = Layout;

const PageMsCleanse = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  usePageTitle("Cleanse列表 - AroundDeal管理后台");

  const inputOptions = [{ id: 0, tip: "搜索任务名称" }];

  const selectOptions = [
    {
      id: 0,
      tip: "请选择状态",
      options: [
        { value: "待处理", label: "待处理" },
        { value: "处理中", label: "处理中" },
        { value: "已完成", label: "已完成" },
        { value: "已扣费", label: "已扣费" },
      ],
    },
  ];

  return (
    <LayoutAuthRequired>
      <Layout
        theme="light"
        style={{
          minHeight: "100vh",
        }}
      >
        <LayoutHeaderNav />
        <Layout>
          <LayoutPageSidebar selectedKey={["ms/cleanse"]} />
          <Layout
            style={{
              padding: "24px 24px",
            }}
          >
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
              }}
            >
              <LayoutNavForms
                inputOptions={inputOptions}
                selectOptions={selectOptions}
              />
              <TableCleanseList />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </LayoutAuthRequired>
  );
};
export default PageMsCleanse;
