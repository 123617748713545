import React, { useState } from "react";
import { App, Card, Descriptions, Row, Spin, Dropdown, Button, notification, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { bemLevelMap, getDateCN, langError} from "tools";
import { useFetchBemUserSuspend, useFetchBemUserUnSuspend } from "hooks";
import { ModalSettings } from "./ModalSettings";
import { ModalSettingsCredit } from "./ModalSettingsCredit";

export const CardBasicInfo = ({ loading, userInfo, onHandleUpdateInfo }) => {
	const {key, status, name, app, membership_type, membership_expires, startup, html_quotas_table} = userInfo || {};
	const { modal } = App.useApp();

	const [ openSettingModal,setOpenSettingModal] = useState(false);
	const [ openSettingCreditModal,setOpenSettingCreditModal] = useState(false);

	const [fetchSuspend] = useFetchBemUserSuspend();
	const [fetchUnSuspend] = useFetchBemUserUnSuspend();

	const typeMap = {
		1: <Tag color="green">正常</Tag>,
		9: <Tag color="red">禁用</Tag>,
	};

	const dataSource = [
		{ key: "Key", value: key },
		{ key: "状态", value: typeMap[status] },
		{ key: "姓名", value: name },
		{ key: "app", value: app },
		{ key: "membership_type", value: bemLevelMap[membership_type] },
		{ key: "membership_expires", value: membership_expires },
		{ key: "startup", value: startup ? getDateCN(startup) : null },
	];

	const formatData = dataSource.map((item) => {
		return {
			...item,
			value: item.value || "",
		};
	});

	const itemsDropdown = [
		{
			label: status === 1 ? "禁用":"启用",
			key: 'edit',
		},
		membership_type === "FREE"?
		{
			label: "设为会员",
			key: 'set',
		}:null,
		{
			label: "设置额度",
			key: 'set_credit',
		},
	];

	return (
		<>
			<Row align="middle" justify="space-between" style={{ marginBottom: "25px" }}>
				<div>
					<h2>
						用户详情 - {!name ? <Spin /> : name}
					</h2>
				</div>
				<div>
					<Dropdown
						menu={{
							items: itemsDropdown,
							onClick: (item) => {
								if (item.key === "edit") {
									modal.confirm({
										title:"设置状态",
										content:`确定${status === 1 ? "禁用":"启用"}吗？`,
										onOk:() =>{
											if(status === 1){
												fetchSuspend({
													app:"insight",
													key:key
												}, {
													success: (data) => {
														if(onHandleUpdateInfo){
															onHandleUpdateInfo();
														}
														notification.success({ message: "禁用成功" });
													},
													error: (error) => {
														notification.error({ message: langError(error.code) });
													},
												});
											}else{
												fetchUnSuspend({
													app:"insight",
													key:key
												}, {
													success: (data) => {
														if(onHandleUpdateInfo){
															onHandleUpdateInfo();
														}
														notification.success({ message: "启用成功" });
													},
													error: (error) => {
														notification.error({ message: langError(error.code) });
													},
												});
											}
											
										}
									})
								} else if (item.key === "set") {
									setOpenSettingModal(true);
								} else if (item.key === "set_credit") {
									setOpenSettingCreditModal(true);
								}
							},
						}}
						trigger={['click']}
						placement="bottomRight"
					>
						<Button type="primary">操作 <DownOutlined /></Button>
					</Dropdown>
				</div>
			</Row>
			<Card title="基础信息" loading={loading}>
				<Descriptions
					bordered
					layout="horizontal"
					labelStyle={{ fontWeight: "bold" }}
				>
					{formatData.map((item, index) => (
						<Descriptions.Item label={item.key} key={index}>
							{item.value}
						</Descriptions.Item>
					))}
				</Descriptions>
				<div dangerouslySetInnerHTML={{__html: html_quotas_table}} style={{marginTop:30}}></div>
			</Card>
			<ModalSettings 
				open={openSettingModal}
				currentKey={key}
				onCancel={()=>setOpenSettingModal(false)}
				onHandleUpdateInfo={onHandleUpdateInfo}
			/>
			<ModalSettingsCredit 
				open={openSettingCreditModal}
				currentKey={key}
				onCancel={()=>setOpenSettingCreditModal(false)}
				onHandleUpdateInfo={onHandleUpdateInfo}
			/>
		</>
	);
};
