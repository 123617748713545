
import base from "./base";
import dev from "./dev";
import prod from "./prod";
import test from "./test";

const getConfig = () => {
    let current = {};
    switch (process.env.NODE_ENV) {
        case 'development':
            current = { ...dev, env: 'dev' };
            break;
        case 'production':
            current = { ...prod, env: 'prod' };
            break;
        case 'test':
            current = { ...test, env: 'test' };
            break;
        default:
            current = { env: '' };
    }
    return { ...base, ...current };
}

const config = getConfig();

if (config.env !== 'prod') {
    console.log('== READ CONFIG ==');
    console.log(config);
}

export default config;
