import React from "react";
import { Link } from "react-router-dom";
import { Card, Table, Tag } from "antd";
import { getDateCN, teamStatusMap, invoiceStatusMap, subscriptionStatusMap, subscriptionTypeMap, userLevelMap, extraEquityTypeMap } from "tools";

const tabListTitle = [
    {
        key: 'log',
        label: 'Log List',
    },
    {
        key: 'team_user',
        label: 'Team User List',
    },
    {
        key: 'team_credit',
        label: 'Team Credit List',
    },
    {
        key: 'credit',
        label: 'Credit List',
    },
    {
        key: 'extra',
        label: 'Extra Equity List',
    },
    {
        key: 'people',
        label: 'People List',
    },
    {
        key: 'company',
        label: 'Company List',
    },
    {
        key: 'invoice',
        label: 'Invoice List',
    },
    {
        key: 'subscription',
        label: 'Subscription List',
    },
];

export const CardListInfo = ({ loading, listData, activeKey, pageCurrent, dataTotal, pageSize, onHandleChangeTab, onHandleChangePage }) => {

    const onTabChange = (key) => {
        if (onHandleChangeTab) {
            onHandleChangeTab(key)
        }
    };

    let columns = [];
    if (activeKey === "log") {
        columns = [
            {
                title: "创建时间",
                dataIndex: "time_created",
                render: (_, record) => (
                    <span>{getDateCN(record.time_created)}</span>
                )
            },
            {
                title: "ID",
                dataIndex: "id",
            },
            {
                title: "IP",
                dataIndex: "ip",
                width:150
            },
            {
                title: "浏览器",
                dataIndex: "agent",
                width:150
            },
            {
                title: "操作",
                dataIndex: "action",
                width:200
            },
            {
                title: "详情",
                dataIndex: "data",
                width:800
            }
        ];
    } else if (activeKey === "team_user") {
        columns = [
            {
                title: "ID",
                dataIndex: "uid",
                width:120
            },
            {
                title: "Name",
                dataIndex: "name",
                render: (name, info) => (
                    <Link
                        target="_blank"
                        to={`/ms/user/detail?uid=${info.uid}`}
                    >
                        {name}
                    </Link>
                ),
            },
            {
                title: "邮箱",
                dataIndex: "email",
            },
            {
                title: "等级",
                dataIndex: "level",
                render: (_, record) => (
                    userLevelMap[record.level]
                )
            },
            {
                title: "过期时间",
                dataIndex: "time_expired",
                render: (_, record) => (
                    <span>{record.time_expired ? getDateCN(record.time_expired) : "-"}</span>
                )
            },
            {
                title: "Credit",
                dataIndex: "credit",
            },
            {
                title: "角色",
                dataIndex: "role",
                render: (_, record) => (
                    <span>{record.role?.name}</span>
                )
            },
            {
                title: "状态",
                dataIndex: "status",
                render: (_, record) => (
                    teamStatusMap[record.status]
                )
            },
        ];
    } else if (activeKey === "team_credit") {
        columns = [
            {
                title: "ID",
                dataIndex: "id",
                width:120
            },
            {
                title: "总计",
                dataIndex: "total",
                width:120
            },
            {
                title: "已分配",
                dataIndex: "assigned",
                width:120
            },
            {
                title: "未分配",
                dataIndex: "left",
                width:120
            },
            {
                title: "开始时间",
                dataIndex: "time_start",
                render: (_, record) => (
                    <span>{ record.time_start ? getDateCN(record.time_start):"-"}</span>
                )
            },
            {
                title: "结束时间",
                dataIndex: "time_expired",
                render: (_, record) => (
                    <span>{ record.time_expired ? getDateCN(record.time_expired):"-"}</span>
                )
            },
        ];
    } else if (activeKey === "credit") {
        columns = [
            {
                title: "ID",
                dataIndex: "id",
                width:120
            },
            {
                title: "Team Credit ID",
                dataIndex: "id_team_credit",
                width:150
            },
            {
                title: "总额",
                dataIndex: "amount",
                width:100
            },
            {
                title: "余额",
                dataIndex: "left",
                width:100
            },
            {
                title: "开始时间",
                dataIndex: "time_start",
                render: (_, record) => (
                    <span>{ record.time_start ? getDateCN(record.time_start):"-"}</span>
                )
            },
            {
                title: "过期时间",
                dataIndex: "time_expired",
                render: (_, record) => (
                    <span>{ record.time_expired ? getDateCN(record.time_expired):"-"}</span>
                )
            },
            {
                title: "创建时间",
                dataIndex: "time_create",
                render: (_, record) => (
                    <span>{getDateCN(record.time_create)}</span>
                )
            },
        ];
    } else if (activeKey === "extra") {
        columns = [
            {
                title: "ID",
                dataIndex: "id",
                width: 120
            },
            {
                title: "总额",
                dataIndex: "amount",
                width: 100
            },
            {
                title: "余额",
                dataIndex: "left",
                width: 100
            },
            {
                title: "类型",
                dataIndex: "type",
                render: (_, record) => (
                    extraEquityTypeMap[record.type]
                )
            },
            {
                title: "开始时间",
                dataIndex: "time_start",
                render: (_, record) => (
                    <span>{record.time_start ? getDateCN(record.time_start) : "-"}</span>
                )
            },
            {
                title: "过期时间",
                dataIndex: "time_expired",
                render: (_, record) => (
                    <span>{record.time_expired ? getDateCN(record.time_expired) : "-"}</span>
                )
            },
            {
                title: "创建时间",
                dataIndex: "time_create",
                render: (_, record) => (
                    <span>{getDateCN(record.time_create)}</span>
                )
            },
        ];
    } else if (activeKey === "people") {
        columns = [
            {
                title: "Key",
                dataIndex: "key",
            },
            {
                title: "Name",
                dataIndex: "name",
            },
            {
                title: "Profile数量",
                dataIndex: "count",
                width:120,
            },
            {
                title: "创建时间",
                dataIndex: "time_create",
                render: (_, record) => (
                    <span>{getDateCN(record.time_created)}</span>
                )
            },
        ];
    } else if (activeKey === "company") {
        columns = [
            {
                title: "Key",
                dataIndex: "key",
            },
            {
                title: "Name",
                dataIndex: "name",
            },
            {
                title: "Profile数量",
                dataIndex: "count",
                width:120,
            },
            {
                title: "创建时间",
                dataIndex: "time_create",
                render: (_, record) => (
                    <span>{getDateCN(record.time_created)}</span>
                )
            },
        ];
    } else if (activeKey === "invoice") {
        columns = [
            {
                title: "ID",
                dataIndex: "id",
                width:120
            },
            {
                title: "Key",
                dataIndex: "key",
            },
            {
                title: "Type",
                dataIndex: "status",
                render: (_, record) => (
                    invoiceStatusMap[record.status]
                )
            },
            {
                title: "单价",
                dataIndex: "amount",
            },
            {
                title: "数量",
                dataIndex: "quantity",
            },
            {
                title: "Total",
                dataIndex: "total",
            },
            {
                title: "创建时间",
                dataIndex: "time_create",
                render: (_, record) => (
                    <span>{getDateCN(record.time_created)}</span>
                )
            },
        ];
    } else if (activeKey === "subscription") {
        columns = [
            {
                title: "ID",
                dataIndex: "id",
            },
            {
                title: "Remote ID",
                dataIndex: "id_remote",
            },
            {
                title: "Package Type",
                dataIndex: "package_type",
                render: (_, record) => (
                    subscriptionTypeMap[record.package_type]
                )
            },
            {
                title: "Package Level",
                dataIndex: "package_level",
                render: (_, record) => (
                    userLevelMap[record.package_level]
                )
            },
            {
                title: "状态",
                dataIndex: "status",
                render: (_, record) => (
                    subscriptionStatusMap[record.status]
                )
            },
            {
                title: "创建时间",
                dataIndex: "time_create",
                render: (_, record) => (
                    <span>{getDateCN(record.time_created)}</span>
                )
            },
        ];
    }

    return (
        <Card
            loading={loading}
            style={{
                width: '100%',
            }}
            tabList={tabListTitle}
            activeTabKey={activeKey}
            onTabChange={onTabChange}
        >
            <Table
                dataSource={listData}
                columns={columns}
                scroll={{ x: "max-content", y: 600 }}
                pagination={activeKey === "log" ? {
                    current: pageCurrent,
                    pageSize: pageSize,
                    total: dataTotal,
                    showSizeChanger: false,
                    onChange: onHandleChangePage,
                } : false}
                rowKey={record => record.id || record.uid || record.key}
            />
        </Card>
    );
};
