import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { App, Button, Row, Space, Table, Typography, Dropdown, Skeleton } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useFetchGetAppList } from "hooks";
import { langError } from "tools";
import { ModalAddApp } from "./ModalAddApp";

export const TableAppList = () => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;

	const { notification } = App.useApp();

	const [openAddApp, setOpenAppModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [fetchAppList] = useFetchGetAppList();
	const [forceList, setForceList] = useState(0);
	const [currentApp, setCurrentApp] = useState(null);

	const navigate = useNavigate();

	const items = [
		{
			label: "编辑App",
			key: "edit_app",
		},
	];

	const columns = [
		{
			title: "名称",
			dataIndex: "name",
		},
		{
			title: "标识",
			dataIndex: "key",
		},
		{
			title: "操作",
			dataIndex: "operate",
			render: (_, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<Dropdown
						menu={{
							items,
							onClick: () => {
								setOpenAppModal(true);
								setCurrentApp(record);
							},
						}}
					>
						<a>
							<Space>
								<EllipsisOutlined style={{fontSize:24}} />
							</Space>
						</a>
					</Dropdown>
				</div>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			fetchAppList({},{
					success: (data) => {
						setLoading(false);
						setListData(() => {
							return data.map((item) => {
								return {
									...item,
									operate: item.id,
								};
							});
						});
						setDataTotal(data.length);
					},
					error: (error) => {
						setLoading(false);
						if(error.code === "ACCESS:NO_PERMISSION"){
							navigate("/403");
						}else{
							notification.error({ message: langError(error.code) });
						}
					},
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, forceList]);

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<div className="cursor">
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							<Button type="primary" onClick={() => setOpenAppModal(true)}>
								添加App
							</Button>
						</Row>
					</Space>
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						onRow={(record, index) => {
							return {
								onClick: event => {
									window.open(`/${record.default_path}`);
								},
							};
						}}
					/>
				</div>
			)}
			{openAddApp && (
				<ModalAddApp
					open={openAddApp}
					currentApp={currentApp}
					onCancel={() => {
						setOpenAppModal(false);
						setCurrentApp(null);
					}}
					onHandleUpdateList={() => setForceList(forceList => forceList + 1)}
				/>
			)}
		</Fragment>
	);
};
