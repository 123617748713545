import React, { useEffect, useState } from "react";
import { Input, Space, DatePicker } from "antd";
import dayjs from 'dayjs';
import { SelectFilter } from "./SelectFilter";

const dateFormat = 'YYYY-MM-DD';

export const LayoutNavForms = ({
	inputOptions = [],
	selectOptions = [],
	datePickerKey = "",
	singleDatePickerKey = "",
	initialValues = {}, 
	onHandleChangeFilter = () => { },
}) => {

	const handlePressEnter = (e, key) => {
		if(onHandleChangeFilter){
			onHandleChangeFilter(key, e.target.value);
		}
	};

	const handleDateRangeChange = (date, dateStrings) =>{
		onHandleChangeFilter(datePickerKey, `${dateStrings[0]} - ${dateStrings[1]}`);
	}

	const handleSingleDateChange = (date, dateString) =>{
		onHandleChangeFilter(singleDatePickerKey, dateString);
	}

	return (
		<div
			className="site-space-compact-wrapper"
			style={{ marginBottom: "20px" }}
		>
			<Space.Compact block>
				{datePickerKey?(
					<DatePicker.RangePicker
						style={{ width: '100%' }}
						size="small"
						format={dateFormat}
						onChange={handleDateRangeChange}
						allowClear={false}
					/>
				):null}
				{singleDatePickerKey?(
					<DatePicker
						style={{ width: '100%' }}
						size="small"
						format={dateFormat}
						defaultValue={dayjs()}
						onChange={handleSingleDateChange}
						allowClear={false}
					/>
				):null}
				{inputOptions.map((item) => (
					<Input
						key={item.key}
						style={{ width: "100%" }}
						placeholder={item.placeholder}
						onPressEnter={(e) => handlePressEnter(e, item.key)}
						// allowClear
					/>
				))}
				{selectOptions.map((item) => (
					<SelectFilter key={item.key} data={item} loading={item?.loading} initialValues={initialValues} onHandleChangeFilter={(key, value)=>{onHandleChangeFilter(key, value)}} />
				))}
			</Space.Compact>
		</div>
	);
};
