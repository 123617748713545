import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, App, Input, DatePicker } from 'antd';
import { lang, consoleLog, langError } from 'tools';
import { useFetchUserSettingPromocode } from "hooks";
import { useSelector } from 'react-redux';

const dateFormat = 'YYYY-MM-DD';

export const ModalSetUserPromocode = ({ open, userInfo, currentId, onCancel, onHandleUpdateInfo }) => {
    const { notification } = App.useApp();
    const user = useSelector((state) => state.user);
	const { name } = user?.info;
    const [form] = Form.useForm();
    const [loadingSave, setLoadingSave] = useState(false);  // save按钮的loading效果
    const [dateString, setDateString] = useState('');


    const [fetchSave] = useFetchUserSettingPromocode();

    useEffect(()=>{
        if(userInfo){
            form.setFieldsValue({
                status: userInfo.status?userInfo.status:0,
            });
        }
    },[userInfo]);

    const handleSave = () =>{
        form
        .validateFields()
        .then(values => {
            setLoadingSave(true);
            fetchSave({
                code: values.code,
                time_expired: dateString,
                admin: name,
                uid: currentId
            }, {
                success: (data) => {
                    if(onHandleUpdateInfo){
                        onHandleUpdateInfo();
                    }
                    setLoadingSave(false);
                    onCancel();
                    notification.success({"message":"操作成功"});
                },
                error: (error) => {
                    setLoadingSave(false);
                    notification.error({'message':langError(error.code)});
                },
            });
        })
        .catch(info => {
            consoleLog('Validate Failed:', info);
        });
    }

    const handleDateChange = (date, dateString) => {
        setDateString(dateString);
    }

    return (
        <Modal
            destroyOnClose
            open={open}
            title={"发放优惠券"}
            onCancel={onCancel}
            width={500}
            footer={
                [
                    <Button key="cancel" onClick={onCancel}>取消</Button>,
                    <Button key="save" type='primary' loading={loadingSave} onClick={handleSave}>确定</Button>
                ]
            }
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete='off'
            >
                <Form.Item
                    name="code"
                    label={"优惠券"}
                    rules={[
                        {
                            required: true,
                            message: "请输入优惠券",
                        },
                    ]}
                >
                    <Input placeholder='请输入优惠券' style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    name="time_expired"
                    label="过期时间"
                >
                    <DatePicker onChange={handleDateChange} style={{ width: "100%" }} format={dateFormat} allowClear={false} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
