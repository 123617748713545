import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Input, App, Select } from "antd";
import { useFetchAddGitpull, useFetchGetProjectList } from "hooks";
import { langError } from "tools";
import { useSelector } from 'react-redux';


export const ModalAddWorkflow = ({ open, onCancel, onHandleUpdateList }) => {
	const { notification, modal } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [projectOptions, setProjectOptions] = useState([]);
	const user = useSelector((state) => state.user);

	const { id } = user?.info;

	const [fetchAddGitpull] = useFetchAddGitpull();
	const [fetchGetProjectList] = useFetchGetProjectList();

	useEffect(() => {
		// setLoading(true);
		fetchGetProjectList({}, {
			success: (data) => {
				let newArr = []
				if (data.length > 0) {
					newArr = data.map((item) => {
						return {
							value: item.key,
							label: item.value
						}
					})
				}
				setProjectOptions(newArr);
			},
			error: (error) => {
				setLoading(false);
				notification.error({ message: langError(error.code) });
			},
		});
	}, []);


	const [form] = Form.useForm();

	const handleAddApp = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				fetchAddGitpull(values, {
					success: (data) => {
						setLoading(false);
						onCancel();
						// notification.success({ message: "上线成功" });
						modal.success(({
							title: "上线成功",
							content: data
						}));
						if (onHandleUpdateList) {
							onHandleUpdateList();
						}
					},
					error: (error) => {
						setLoading(false);
						onCancel();
						notification.error({ message: langError(error.code) });
					},
				});
			})
			.catch((info) => { });
	};

	const filterOption = (input, option) =>
		(option?.children ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<Modal
			title={`创建上线请求`}
			width={500}
			open={open}
			onCancel={onCancel}
			destroyOnClose={true}
			footer={[
				<Button key="cancel" onClick={onCancel}>
					取消
				</Button>,
				<Button
					key="add"
					type="primary"
					loading={loading}
					onClick={handleAddApp}
				>
					确定
				</Button>,
			]}
		>
			<Form
				name="project"
				form={form}
				layout="vertical"
				style={{ margin: "30px 0" }}
			>

				<Form.Item
					label="project"
					name="project"
					style={{ margin: "10px 0" }}
					rules={[
						{
							required: true,
							message: "请选择项目",
						},
					]}
				>
					<Select
						showSearch
						optionFilterProp="children"
						key="project"
						style={{ width: "100%" }}
						placeholder="请选择项目"
						loading={loading}
						// value={selectedValue}
						filterOption={filterOption}
					>
						{projectOptions.map((option, index) => (
							<Select.Option value={option.value} key={index}>
								{option.label}
							</Select.Option>
						))}
					</Select>
				</Form.Item>


				<Form.Item
					label="备注"
					name="remark"
					style={{ margin: "10px 0" }}
					placeholder="请填写备注"
				>
					<Input.TextArea rows={5} />
				</Form.Item>


			</Form>
		</Modal>
	);
};
