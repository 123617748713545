import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import { App, Row, Space, Table, Typography, Tag, Skeleton, Dropdown, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { useFetchGetBemBillingList } from "hooks";
import { langError, getDateCN } from "tools";
import { Link } from "react-router-dom";

export const TableBillingList = ({ filterData }) => {
	const user = useSelector((state) => state.user);
	const { id } = user?.info;
	const { notification } = App.useApp();
	const [loading, setLoading] = useState(false);
	const [listData, setListData] = useState([]);
	const [dataTotal, setDataTotal] = useState(0);
	const [pageCurrent, setPageCurrent] = useState(1);
	const [pageSize, setPageSize] = useState(20);

	// useEffect(() =>{
	// 	setPageCurrent(1);
	// },[filterData]);
	
	const [fetchGetBemBillingList] = useFetchGetBemBillingList();

	const items = [
		{
			label: "查看Invoice",
			key: "view_invoice",
		},
	];

	const statusMap = {
		1: <Tag color="orange">等待付款</Tag>,
		8: <Tag color="green">成功</Tag>,
		9: <Tag color="red">失败</Tag>,
	};

	const typeMap = {
		1: <Tag color="blue">购买会员</Tag>,
		2: <Tag color="purple">充值额度</Tag>,
	};

	const columns = [
		{
			title: "UID",
			dataIndex: "user",
			render:(_,record) =>(
				<Link target="_blank" to={`/bem/user/detail?uid=${record.user}&type=insight`}>{record.user}</Link>
			)
		},
		{
			title: "用户名",
			dataIndex: "name",
		},
		{
			title: "邮箱",
			dataIndex: "email",
		},
		{
			title: "类型",
			dataIndex: "type",
			render:(_,record) =>(
				<>{typeMap[record.type]}</>
			)
		},
		{
			title: "金额",
			dataIndex: "amount",
		},
		{
			title: "状态",
			dataIndex: "status",
			render:(_,record) =>(
				<>{statusMap[record.status]}</>
			)
		},
		{
			title: "时间",
			dataIndex: "time_done",
			render:(_,record) =>(
				<span>{getDateCN(record.time_done)}</span>
			)
		},
		{
			title: "操作",
			dataIndex: "operate",
			width:80,
			render: (_, record) => (
				<Dropdown
					menu={{
						items,
						onClick: (item) => {
							if (item.key === "view_invoice") {
								window.open(record.invoice);
							}
						},
					}}
				>
					<a onClick={(e) => e.stopPropagation()}>
						<Space>
							<EllipsisOutlined style={{ fontSize: 24 }} />
						</Space>
					</a>
				</Dropdown>
			),
		},
	];

	useEffect(() => {
		if (id) {
			setLoading(true);
			const params = { page: pageCurrent, ...filterData, app:"insight" };
			fetchGetBemBillingList(params, {
				success: (data) => {
					setLoading(false);
					setDataTotal(data.page.total);
					setPageSize(data.page.limit);
					setListData(data.list);
				},
				error: (error) => {
					setLoading(false);
					notification.error({ message: langError(error.code) });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, pageCurrent, filterData]);

	const handleChangePage = (page) => {
		setPageCurrent(page);
		window.scroll(0, 0);
	};

	return (
		<Fragment>
			{!id ? (
				<Skeleton active />
			) : (
				<>
					<Space size="middle" direction="vertical" style={{ display: "flex" }}>
						<Row justify="space-between" style={{ marginBottom: "10px" }}>
							<Typography.Text>{dataTotal} 条结果</Typography.Text>
							{/* <Button type="primary">操作</Button> */}
						</Row>
					</Space>
					
					<Table
						loading={loading}
						columns={columns}
						dataSource={listData}
						pagination={{
							current: pageCurrent,
							pageSize: pageSize,
							total: dataTotal,
							showSizeChanger: false,
							onChange: handleChangePage,
						}}
					/>
				</>
			)}
		</Fragment>
	);
};
