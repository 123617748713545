
const texts = {
    "label_enrichment_no_permission_title":"Fill Your List with 50+ Firmographic Fields",
    "info_enrichment_no_permission_content_1":"Have a prospect lists with key data points missing?",
    "info_enrichment_no_permission_content_2":"Upload a .csv file and we will enrich your records with 50+ fields in the AroundDeal Database to complete records with the data that is critical for your business.",
    "label_no_enrichment_title":"No Enrichment Task Yet",
    "info_no_enrichment_content":"Upload a .csv file and let us do the work for you!",
    "enrichment_step_title_1":"1. Set Enrichment Settings",
    "enrichment_step_info_1":"Edit settings to control which field are enriched.",
    "enrichment_step_title_2":"2. Upload a CSV. file",
    "enrichment_step_info_2":"Upload your targeted company or people list.",
    "enrichment_step_title_3":"3. Download Results",
    "enrichment_step_info_3":"Once your task starts, you can check the progress of the task. When your search is complete, you can download the results.",
    "modal_enrichment_options_title":"Enrich People or Companies in a CSV",
    "modal_enrichment_options_info":"Select a CSV of either people or companies to enrich it with<br/> information from AroundDeal.",
    "enrichment_people":"Enrich People",
    "enrichment_company":"Enrich Companies",
    "enrichment_people_info":"Select a CSV of people and enrich their information.",
    "enrichment_companies":"Enrich Companies",
    "enrichment_companies_info":"Select a CSV of companies and enrich their information.",
    "modal_enrichment_tips":"Records in your CSV will not be created in AroundDeal.",
    "enrichment_settings_saved":"Settings saved successfully.",
    "modal_enrichment_people_settings_title":"People CSV Enrichment Settings",
    "modal_enrichment_company_settings_title":"Companies CSV Enrichment Settings",
    "standard_fields":"Standard Fields",
    "enrichment_people_step_1_title":"1. Enter your list of domains or company names",
    "enrichment_people_step_1_tips":"Records in your CSV will not be created in AroundDeal.",
    "enrichment_people_step_2_title":"2. Mapping",
    "enrichment_people_step_3_title":"3. Export Buying Committee",
    "enrichment_people_step_2_info":"To maximize your enrichment results, map as many fields from your CSV as possible.",
    "alert_enrichment_people_title":"Map all of the items in at least one of the following groups:",
    "alert_enrichment_people_li_1":"Full Name, Company Website",
    "alert_enrichment_people_li_2":"Full Name, Company Name",
    "alert_enrichment_people_li_3":"First Name, Last Name, Company Website",
    "alert_enrichment_people_li_4":"First Name, Last Name, Company Name",
    "alert_enrichment_people_li_5":"Person LinkedIn URL",
    "alert_enrichment_people_li_6":"Email",
    "alert_enrichment_people_li_7":"Company Website",
    "alert_enrichment_people_li_8":"Company Linkedin URL",
    "alert_enrichment_people_li_9":"Company Name",

    "label_column_first_name":"First Name",
    "label_column_last_name":"Last Name",
    "label_column_full_name":"Full Name",
    "label_column_company_name":"Company Name",
    "label_column_company_website":"Company Website",
    "label_column_person_linkedin_url":"Person Linkedin URL",
    "label_column_company_linkedin_url":"Company Linkedin URL",
    "label_column_email":"Email",
    "title_column_arounddeal_field":"AroundDeal Field",
    "title_column_salesforce_field":"Salesforce Field",
    "title_column_hubspot_field":"HubSpot Field",
    "title_column_csv_header":"Your CSV Column Header",
    "title_column_precview_data":"Preview Your Data",
    "select_column_ph":"Select...",

    "enrichment_empty_local":"Simply upload a .csv file and get a fresh and clean list all at a once.",
    "colunm_type":"Type",
    "enrichment":"Enrichment",
    "total_records":"Total records:",
    "matched_records":"Matched records:",
    "modal_download_title":"You are going to export {success} {type}(s)",
    "modal_download_content":"The export will take {success} credit(s) from your balance( 1 credit per {type}).You currently have {credit} credit(s) in your balance.",
    "insufficient_credits":"Insufficient credits",
    "insufficient_credits_info":"This search will cost {success} credits. You need upgrade to perform it.",
    "insufficient_verifications":"Insufficient verifications",
    "insufficient_verifications_info":"This search will cost {success} verifications. You need upgrade to perform it.",
    "insufficient_cleansings":"Insufficient cleansings",
    "insufficient_cleansings_info":"This search will cost {success} cleansings. You need upgrade to perform it.",

    "enrichment_created_successfully":"Enrichment created successfully.",
    "download_successfully":"Download Successfully.",

    "button_new_enrichment":"New Enrichment",
    "button_get_started":"Get Started",
    "button_edit_settings":"Edit Settings",
    "button_edit":"Edit",
    "button_save_settings":"Save Settings",
    "button_start":"Start",
    "button_download":"Download",
    "button_upgrade_now":"Upgrade Now",
};


export default texts;
